import React from "react";
import { TouchableOpacity, View } from "react-native";
import Cavy from "../../app/test/Cavy";
import Colors from "../Colors";
import { Text2 } from "../ui";
import GIcon from "../ui/GIcon";

const TouchArchive = Cavy.wrap(TouchableOpacity);

export default function ArchiveView({ archivedGuides, onArchivedGuides }) {
  const generateTestHook = Cavy.useCavy();
  return (
    <View style={{ backgroundColor: Colors.white, marginBottom: 16 }}>
      {archivedGuides.length > 0 ? (
        <TouchArchive
          activeOpacity={0.6}
          onPress={onArchivedGuides}
          ref={generateTestHook("Dashboard.ArchivedGuides")}
        >
          <View
            style={{
              height: 56,
              borderRadius: 12,
              marginHorizontal: 16,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: Colors.lightGray,
            }}
          >
            <GIcon.Archived style={{ color: Colors.darkerGray, marginRight: 8 }} />
            <Text2 style={{ color: Colors.darkerGray }}>Archived guides</Text2>
          </View>
        </TouchArchive>
      ) : null}
    </View>
  );
}
