import * as React from "react";
import Api from "../api/Api";
import Notification from "../constants/Notification";
import store from "../store/Store";
import Errors from "../util/Errors";
import ScreenNames from "./ScreenNames";

const { Types } = Notification;
export const navigationRef = React.createRef();

function navigate(navigation, screen, params) {
  navigation.navigate(screen, params);
}

export function navigatePushNotification(navigation, { type, userId, guideId }) {
  if (userId && type === Types.USER_UPGRADED) {
    try {
      store.setUser(async () => Api.user.me());
      navigation.navigate("gtab");
    } catch (error) {
      Errors.showGenericMessage(error);
    }
    return;
  }

  if (store.isAdmin() && type === Types.CREATOR_APPLICATION) {
    navigateToCreatorApplications(navigation, {});
    return;
  }

  if (userId) {
    navigateToProfile(navigation, { userId });
  }

  if (!guideId) {
    return;
  }

  if (
    type === Types.GUIDE_UPDATED ||
    type === Types.GUIDE_REPORTED ||
    type === Types.GUIDE_PUBLISHED ||
    type === Types.GUIDE_PRELANUCH ||
    type === Types.GUIDE_PENDING_APPROVAL ||
    type === Types.GUIDE_ATTENTION_REQUIRED
  ) {
    navigateToGuide(navigation, { guideId });
  } else if (type === Types.GUIDE_REMINDER) {
    // TODO: Check the user has access the edit page before navigating
    navigateToEditGuide(navigation, { guideId });
  } else if (type === Types.GUIDE_APPROVED) {
    navigateToShareGuide(navigation, { guideId });
  }
}

export function navigateToSearch(navigation, { name, location, autoFocus }) {
  navigate(navigation, ScreenNames.SEARCH, { name, location, autoFocus });
}

export function navigateToBrowse(navigation, { url, title }) {
  navigate(navigation, ScreenNames.BROWSE, { url, title });
}

export function navigateToGuide(navigation, { guideId }) {
  navigate(navigation, ScreenNames.GUIDE, { guideId });
}

export function navigateToShareGuide(navigation, { guideId }) {
  navigate(navigation, ScreenNames.SHARE_GUIDE, { guideId });
}

export function navigateToGuides(navigation, { title, guides, note }) {
  navigate(navigation, ScreenNames.GUIDES, { title, guides, note });
}

export function navigateToPendingGuides(navigation) {
  navigate(navigation, ScreenNames.PENDING_GUIDES, {});
}

export function navigateToGuidePlaces(navigation, { guideId, placeId }) {
  navigate(navigation, ScreenNames.GUIDE_PLACES, { guideId, placeId });
}

export function navigateToLikedPlaces(navigation) {
  navigate(navigation, ScreenNames.LIKED_PLACES, {});
}

export function navigateToLikedPlacesPager(navigation, { placeId }) {
  navigate(navigation, ScreenNames.LIKED_PLACES_PAGER, { placeId });
}

export function navigateToApplication(navigation) {
  navigate(navigation, ScreenNames.APPLICATION, {});
}

export function navigateToMonthlyReports(navigation) {
  navigate(navigation, ScreenNames.MONTHLY_REPORTS, {});
}

export function navigateToPdfViewer(navigation, { url, title }) {
  navigate(navigation, ScreenNames.PDF_VIEWER, { url, title });
}

export function navigateToProfile(navigation, { userId }) {
  navigate(navigation, ScreenNames.PROFILE, { userId });
}

export function navigateToReportedGuides(navigation, { name, onDone }) {
  navigate(navigation, ScreenNames.REPORTED_GUIDES, { name, onDone });
}

export function navigateToEditPlace(navigation, { index, placeId, guideId, location }) {
  navigate(navigation, ScreenNames.EDIT_PLACE, {
    index,
    placeId,
    guideId,
    location,
  });
}

export function navigateToSelect(navigation, { title, options, showCode, onSelect }) {
  navigate(navigation, ScreenNames.SELECT, { title, options, showCode, onSelect });
}

export function navigateToResources(navigation, { title, buttonLabel, onSelect }) {
  navigate(navigation, ScreenNames.RESOURCES, {
    title,
    onSelect,
    buttonLabel,
  });
}

export function navigateToGooglePhotoPicker(navigation, { gPlaceId, onDone, onCancel }) {
  navigate(navigation, ScreenNames.GOOGLE_PHOTO_PICKER, { gPlaceId, onDone, onCancel });
}

export function navigateToSettings(navigation, user) {
  navigate(navigation, ScreenNames.SETTINGS, user);
}

export function navigateToThemes(navigation) {
  navigate(navigation, ScreenNames.THEMES, {});
}

export function navigateToQuestions(navigation) {
  navigate(navigation, ScreenNames.QUESTIONS, {});
}

export function navigateToEditProfile(navigation) {
  navigate(navigation, ScreenNames.EDIT_PROFILE, {});
}

export function navigateToEditTheme(navigation, { theme }) {
  navigate(navigation, ScreenNames.EDIT_THEME, { theme });
}

export function navigateToStats(navigation) {
  navigate(navigation, ScreenNames.STATS, {});
}

export function navigateToCreators(navigation) {
  navigate(navigation, ScreenNames.CREATORS, {});
}

export function navigateToEditCreator(navigation, { creator }) {
  navigate(navigation, ScreenNames.EDIT_CREATOR, { creator });
}

export function navigateToCreatorApplications(navigation) {
  navigate(navigation, ScreenNames.CREATOR_APPLICATIONS, {});
}

export function navigateToEditQuestion(navigation, { question }) {
  navigate(navigation, ScreenNames.EDIT_QUESTION, { question });
}

export function navigateToChangePassword(navigation) {
  navigate(navigation, ScreenNames.CHANGE_PASSWORD, {});
}

export function navigateToReferCreator(navigation) {
  navigate(navigation, ScreenNames.REFER_CREATOR, {});
}

export function navigateToEditGuide(navigation, { guideId, onDone }) {
  navigation.navigate(ScreenNames.EDIT_GUIDE, {
    screen: ScreenNames.EDIT_GUIDE,
    params: { guideId, onDone },
  });
}

export function navigateToEditAdjustment(navigation, { guideId }) {
  navigate(navigation, ScreenNames.EDIT_ADJUSTMENT, { guideId });
}

export function navigateToLogin(navigation, { uid, onDone }) {
  navigate(navigation, ScreenNames.LOGIN, { uid, onDone });
}

export function navigateToSignup(navigation, { useEmail }) {
  navigation.navigate(ScreenNames.SIGNUP, {
    screen: ScreenNames.EMAIL,
    params: { useEmail },
  });
}

export function navigateToEmail(navigation, { reset }) {
  navigate(navigation, ScreenNames.EMAIL, { reset });
}

export function navigateToVerify(navigation, { email, phone, reset }) {
  navigate(navigation, ScreenNames.VERIFY, { email, phone, reset });
}

export function navigateToPassword(navigation, { email, phone, userToken, reset }) {
  navigate(navigation, ScreenNames.PASSWORD, {
    email,
    phone,
    reset,
    userToken,
  });
}

export function navigateToUsername(navigation, { email, phone, userToken, password }) {
  navigate(navigation, ScreenNames.USERNAME, {
    email,
    phone,
    password,
    userToken,
  });
}

export function navigateToImageCrop(navigation, { uri, ratio, lockAspectRatio, onDone, onCancel }) {
  navigate(navigation, ScreenNames.IMAGE_CROP, {
    uri,
    ratio,
    onDone,
    onCancel,
    lockAspectRatio,
  });
}
