import * as React from "react";
import { StyleSheet, Text, View } from "react-native";

export interface IIconProps {
  disabled?: boolean;
  text: string;
}

export function Icon({ disabled, text }: IIconProps) {
  return (
    <View style={styles.container}>
      <Text style={[styles.text, disabled && { color: "grey" }]}>{text}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: 80,
    height: 64,
    paddingVertical: 8,
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-end",
  },
  text: {
    textAlign: "center",
  },
});
