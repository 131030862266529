/* eslint-disable eqeqeq */
import React from "react";
import { useAutosave } from "./autosave";

export default function useSaveChanges({ data, onSave }) {
  const [value, setValue] = React.useState(data);
  const onChange = React.useCallback(
    (newText) => {
      if (newText != value) {
        onSave(newText);
      }
      setValue(newText);
    },
    [onSave, value]
  );

  useAutosave({ data, onSave: onChange, interval: 1000 });
}
