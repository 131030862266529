import React from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import Cavy from "../../app/test/Cavy";
import Colors from "../Colors";
import { Text2 } from "../ui";

const TabView = Cavy.wrap(({ text, selected, onPress }) => {
  return (
    <TouchableOpacity {...{ onPress }}>
      <View
        style={{
          height: 32,
          marginRight: 16,
          borderRadius: 16,
          paddingHorizontal: 16,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: selected ? Colors.crystal : Colors.white,
        }}
      >
        <Text2 style={[styles.text, { color: selected ? Colors.darkerGray : Colors.palladium }]}>
          {text}
        </Text2>
      </View>
    </TouchableOpacity>
  );
});

const TabsView = ({ tabs, select, onSelect, style }) => {
  const generateTestHook = Cavy.useCavy();
  return (
    <View>
      <ScrollView horizontal showsHorizontalScrollIndicator={false}>
        <View style={[styles.container, style]}>
          {tabs.map((tab) => (
            <TabView
              ref={generateTestHook(`Search.Tab${tab}`)}
              key={tab}
              text={tab}
              selected={tab === select}
              onPress={() => onSelect(tab)}
            />
          ))}
        </View>
      </ScrollView>
    </View>
  );
};

export default TabsView;

const styles = StyleSheet.create({
  text: {
    fontSize: 14,
    fontWeight: "bold",
  },

  container: {
    flexDirection: "row",
  },
});
