import { observer } from "mobx-react";
import React from "react";
import { View } from "react-native";
import GIcon from "../../components/ui/GIcon";
import TabsView from "../profile/ui/TabsView";

export const RESOURCES_TABS = {
  TIPS: "tips",
  NEWS: "news",
};

function ResourcesHeaderView({ tab, onTab }) {
  const [select, onSelect] = React.useState(tab);

  return (
    <View style={{ paddingTop: 7 }}>
      <TabsView
        flip
        tabs={[
          { id: RESOURCES_TABS.TIPS, title: "Tips", Icon: GIcon.LightbulbOn },
          { id: RESOURCES_TABS.NEWS, title: "News", Icon: GIcon.News },
        ].filter((t) => t)}
        {...{ select }}
        onSelect={(t) => {
          onTab(t);
          onSelect(t);
        }}
        style={{ marginTop: 1, marginHorizontal: 16, marginBottom: 6 }}
      />
    </View>
  );
}

export default observer(ResourcesHeaderView);
