/* eslint-disable no-nested-ternary */
import { Video } from "expo-av";
import React from "react";
import { Image, StyleSheet, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import Cavy from "../../../app/test/Cavy";
import { isWeb, screenWidth } from "../../../app/util/util";
import { useActionSheet } from "../../../components/actionsheet/context";
import Colors from "../../../components/Colors";
import PlayerView from "../../../components/PlayerView";
import RecordView from "../../../components/RecordView";
import GIcon from "../../../components/ui/GIcon";
import ScaledImage from "../../../components/ui/ScaledImage";
import ChangeCoverButton from "../../guide/ui/ChangeCoverButton";
import EditCaptionView from "../../guide/ui/EditCaptionView";

const mediaWidth = screenWidth - 32;

const MediaView = Cavy.wrap(
  ({
    media,
    status,
    onAudio,
    onChange,
    videoRef,
    setStatus,
    recording,
    savedAudio,
    savingAudio,
    buttonWidth,
    onCaptionFocus,
    setShowDeleteDialog,
  }) => {
    if (media.type === "video") {
      return (
        <MediaVideoView
          {...{
            video: videoRef,
            status,
            setStatus,
            media,
            buttonWidth,
          }}
        />
      );
    }

    return (
      <MediaImageView
        {...{
          media,
          onAudio,
          onChange,
          recording,
          buttonWidth,
          savedAudio,
          savingAudio,
          onCaptionFocus,
          setShowDeleteDialog,
        }}
      />
    );
  }
);

const MediaVideoView = Cavy.wrap(({ video, status, setStatus, media, style }) => {
  if (media.thumbnailUrl) {
    return (
      <View>
        {isWeb ? (
          <ScaledImage uri={media.thumbnailUrl} style={{ mediaWidth, marginTop: 16 }} />
        ) : null}

        <Video
          ref={video}
          style={[
            {
              marginBottom: 16,
              width: mediaWidth,
              height: mediaWidth / media.ratio,
              position: isWeb ? "absolute" : "relative",
            },
            style,
          ]}
          source={{
            uri: media.url,
          }}
          onLoad={async () => {
            if (!isWeb) {
              await media.current?.playAsync();
              await media.current?.pauseAsync();
            }
          }}
          useNativeControls
          resizeMode="cover"
          onPlaybackStatusUpdate={(newStatus) => {
            setStatus(() => newStatus);
            if (newStatus.didJustFinish) {
              if (video.current) {
                video.current.setStatusAsync({ positionMillis: 0 });
              }
            }
          }}
        />
        {(!status.isPlaying && status.positionMillis === 0) || status.didJustFinish ? (
          <View
            style={[StyleSheet.absoluteFill, { justifyContent: "center", alignItems: "center" }]}
          >
            <TouchableOpacity onPress={() => video?.current?.playAsync(true)}>
              <GIcon.Play style={{ color: Colors.white, fontSize: 72 }} />
            </TouchableOpacity>
          </View>
        ) : null}
      </View>
    );
  }

  return (
    <Video
      style={{
        width: mediaWidth,
        height: mediaWidth / media.ratio,
      }}
      isLooping
      useNativeControls
      resizeMode="cover"
      source={{ uri: media.url }}
    />
  );
});

const MediaImageView = Cavy.wrap(
  ({
    media,
    onCaptionFocus,
    buttonWidth,
    onAudio,
    savedAudio,
    savingAudio,
    setShowDeleteDialog,
    onChange,
  }) => {
    return (
      <View style={{ flex: 1, justifyContent: "center" }}>
        <View>
          <Image
            source={{ uri: media.url }}
            style={{
              width: mediaWidth,
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
              height: buttonWidth / media.ratio,
            }}
          />

          <View
            style={{
              width: mediaWidth,
              position: "absolute",
              alignItems: "center",
              justifyContent: "center",
              height: buttonWidth / media.ratio,
            }}
          >
            <ChangeCoverButton
              text="Change photo"
              {...{ loading: false, onPress: onChange, hasCover: true }}
            />
          </View>

          <View style={{ marginTop: 16, marginHorizontal: 16 }}>
            <EditRecordView
              onSave={onAudio}
              audio={media.audio}
              title="Talk about it"
              color={Colors.rainyGray}
              onDelete={() => setShowDeleteDialog(true)}
              {...{ savedAudio, savingAudio }}
            />

            <EditCaptionView
              media={media}
              caption={media.text}
              onFocus={onCaptionFocus}
              style={{ marginTop: 16, marginBottom: 16 }}
            />
          </View>
        </View>
      </View>
    );
  }
);

export default MediaView;

export function EditRecordView({
  style,
  title,
  color,
  record,
  onSave,
  onCancel,
  onDelete: onDeleteAudio,
  ...props
}) {
  const { showActionSheetWithOptions } = useActionSheet();
  const [audio, setAudio] = React.useState(props.audio);
  const [doneRecording, setDoneRecording] = React.useState(false);

  React.useEffect(() => {
    setAudio(props.audio);
  }, [props.audio]);

  async function onDoneRecording({ uri, duration }) {
    const value = { uri, duration, type: "audio" };
    setAudio(value);
    setDoneRecording(true);
    onSave(value);
  }

  function onDelete() {
    onDeleteAudio();
  }

  return (
    <View style={[{ justifyContent: "center" }, style]}>
      {!audio ? (
        <RecordView
          color={color || Colors.white}
          onStartRecording={onSave}
          {...{
            title,
            onDoneRecording,
            showActionSheetWithOptions,
            onAskForSelectFilePermission: () => {},
          }}
        />
      ) : (
        <PlayerView
          preview={doneRecording}
          savedAudio={props.savedAudio}
          savingAudio={props.savingAudio}
          media={audio}
          onDelete={audio ? onDelete : null}
        />
      )}
    </View>
  );
}
