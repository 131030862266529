import * as React from "react";

import { ActionSheetProps } from "./types";

const context = React.createContext<ActionSheetProps>({
  showActionSheetWithOptions: () => {},
});

export function useActionSheet() {
  return React.useContext(context);
}

const { Provider, Consumer } = context;

export { Provider, Consumer };
