/* eslint-disable no-alert */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/no-unused-modules */
import React from "react";
import { View } from "react-native";
import Api from "../../../app/api/Api";
import { navigateToSelect } from "../../../app/navigation/Navigator";
import store from "../../../app/store/Store";
import Track from "../../../app/Track";
import Errors from "../../../app/util/Errors";
import TermsAndPrivacyView from "../../../components/TermsAndPrivacyView";
import { Body2 } from "../../../components/typography";
import ButtonView from "../../../components/ui/ButtonView";
import GIcon from "../../../components/ui/GIcon";
import { InputLabel, PhoneInputView } from "../../../components/ui/inputs";
import countries from "../../picker/countries.json";

// const url = "http://localhost:4000/checkout";
const url = "https://guidepalserver.herokuapp.com/checkout";

export function userAgent() {
  if (!navigator) {
    return "";
  }

  return navigator.userAgent || navigator.vendor || window.opera;
}

export function isEmbeddedBrowser() {
  const ua = userAgent();
  if (ua?.indexOf("Instagram") > -1 || ua?.indexOf("FBAN") > -1) {
    return true;
  }
  return false;
}

export default function WebButtonView({ guide, go, phone, navigation, ...props }) {
  const isEmbedded = isEmbeddedBrowser();
  const [buyPressed, setBuyPressed] = React.useState(false);
  const [smsSent, setSmsSent] = React.useState(false);
  const [phoneValue, setPhoneValue] = React.useState("");
  const [country, setCountry] = React.useState("SE +46");

  const userId = store.getUserId() || "";
  const action = `${url}?guideId=${guide.id}&name=${
    guide.name
  }&userId=${userId}&phone=${getPhone()}`;

  React.useEffect(() => {
    if (go && document.checkout) {
      document.checkout.submit();
      Track.sendEvent({
        type: "SMS purchase",
        message: `SMS purchase link clicked ${phone}`,
        source: userAgent(),
        guideId: guide.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [document.checkout, go]);

  function getPhone() {
    if (go) {
      return phone;
    }

    if (!phoneValue) {
      return "";
    }

    const countryCode = country?.split(" ")[1];
    if (phoneValue.startsWith("00")) {
      return phoneValue.replace("00", "+");
    }

    if (phoneValue.startsWith("0")) {
      return countryCode + phoneValue.slice(1);
    }

    return countryCode + phoneValue;
  }

  async function onCheckout() {
    if (isEmbedded) {
      if (!buyPressed) {
        setBuyPressed(true);
        return;
      }
      Api.payment
        .mCheckout({
          userId,
          phone: getPhone(),
          name: guide.name,
          guideId: guide.id,
          username: guide.user.username,
        })
        .then(() => setSmsSent(true))
        .then(() => {
          Track.sendEvent({
            type: "Purchase link sent",
            message: getPhone(),
            source: userAgent(),
            guideId: guide.id,
          });
        })
        .catch((error) => {
          Errors.showErrorMessage(error);
          Track.sendEvent({
            type: "Purchase link error",
            message: `${getPhone()} Error: ${error.message}`,
            source: userAgent(),
            guideId: guide.id,
          });
        });
    } else {
      Track.sendEvent({
        type: "Buy button clicked",
        source: userAgent(),
        guideId: guide.id,
      });
      document.checkout.submit();
    }
  }

  if (isEmbedded && buyPressed) {
    return (
      <div>
        <form {...{ action }} method="POST" name="checkout">
          <div
            style={{
              marginLeft: 8,
              marginRight: 8,
              borderRadius: 16,
              paddingBottom: 16,
              backgroundColor: "#fff",
              border: "1px solid #eee",
            }}
          >
            <div
              style={{
                marginLeft: 24,
                marginRight: 24,
                marginBottom: 16,
                textAlign: smsSent ? "center" : "left",
              }}
            >
              {smsSent ? (
                <View
                  style={{
                    backgroundColor: "#CFFF65",
                    width: 32,
                    height: 32,
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: 40,
                    marginBottom: 16,
                    borderRadius: 16,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <GIcon.Check />
                </View>
              ) : null}
              {smsSent ? (
                <Body2>We just texted you 🌝</Body2>
              ) : (
                <View style={{ paddingTop: 16 }}>
                  <InputLabel>Enter your phone number</InputLabel>
                  <Body2 style={{ borderWidth: 0 }}>
                    We’ll text you a link to complete your purchase!
                  </Body2>

                  <PhoneInputView
                    marginTop={8}
                    value={phoneValue}
                    placeholder="Phone number"
                    onChangeText={(text) => setPhoneValue(text.trim())}
                    country={country}
                    onCountry={() =>
                      navigateToSelect(navigation, {
                        showCode: true,
                        title: "Select a country",
                        options: countries,
                        onSelect: ({ code, dial_code }) => setCountry(`${code} ${dial_code}`),
                      })
                    }
                  />
                </View>
              )}
            </div>

            {!smsSent ? (
              <View>
                <ButtonView
                  {...props}
                  gray={!phoneValue}
                  onPress={onCheckout}
                  disabled={!phoneValue}
                />
                <TermsAndPrivacyView {...{ navigation }} />
              </View>
            ) : null}
          </div>
        </form>
      </div>
    );
  }

  return (
    <div>
      <form {...{ action }} method="POST" name="checkout" style={go ? { display: "none" } : {}}>
        <ButtonView {...props} onPress={onCheckout} />
      </form>
    </div>
  );
}
