import React from "react";
import Api from "../../../app/api/Api";
import Errors from "../../../app/util/Errors";
import { TextEditView } from "../../../components/ui/inputs";
import useSaveChanges from "../../../components/useSaveChanges";

export default function EditGuideDescription({ style, guide, ...props }) {
  const [text, setText] = React.useState(guide.description?.trim());
  const [saved, setSaved] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const onSave = React.useCallback(
    (description) => {
      setSaving(true);
      return Api.guide
        .guideUpdate(guide.id, { description })
        .then(() => {
          setSaved(true);
          setTimeout(() => {
            setSaved(false);
          }, 1000);
        })
        .catch(Errors.showErrorMessage)
        .finally(() => setSaving(false));
    },
    [guide.id]
  );

  useSaveChanges({ data: text, onSave });

  return (
    <TextEditView
      {...props}
      {...{
        text,
        setText,
        saved,
        saving,
        style,
        maxLenght: 500,
        placeholder: "Write about your guide",
      }}
      testId={"Guide.Description"}
    />
  );
}
