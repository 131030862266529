import { gql } from "graphql-request";
import { MESSAGE_MODEL } from "../models";

export const QUERY_MESSAGES = gql`
  query messages {
    messages {
     ${MESSAGE_MODEL}
    }
  }
`;

export const MESSAGE_CREATE = gql`
  mutation messageCreate(
    $title: String
    $message: String
  ) {
    messageCreate(
      title: $title
      message: $message
    ) {
      ${MESSAGE_MODEL}
    }
  }
`;

export const MESSAGE_UPDATE = gql`
  mutation messageUpdate(
    $id: String
    $title: String
    $message: String
  ) {
    messageUpdate(
      id: $id
      title: $title
      message: $message
    ) {
      ${MESSAGE_MODEL}
    }
  }
`;

export const MESSAGE_DELETE = gql`
  mutation messageDelete(
    $id: String
  ) {
    messageDelete(
      id: $id
    ) {
      ${MESSAGE_MODEL}
    }
  }
`;
