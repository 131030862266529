import { useNavigation } from "@react-navigation/native";
import * as Linking from "expo-linking";
import { observer } from "mobx-react";
import React from "react";
import { Alert, TouchableOpacity, View } from "react-native";
import Api from "../../../app/api/Api";
import { navigateToMonthlyReports } from "../../../app/navigation/Navigator";
import store from "../../../app/store/Store";
import Errors from "../../../app/util/Errors";
import Colors from "../../../components/Colors";
import { Text2, Text2B, Text2L, Text2SB } from "../../../components/ui";
import GIcon from "../../../components/ui/GIcon";
import faqs from "../faqs.json";

export default observer(function PayoutTabView() {
  const navigation = useNavigation();
  const [loading, setLoading] = React.useState(false);

  function onConnect() {
    setLoading(true);
    Api.payment
      .connect()
      .then((url) => {
        if (url.startsWith("Connected")) {
          Alert.alert("Connected successfully!");
        } else {
          Linking.openURL(url);
        }
      })
      .catch(Errors.showErrorMessage)
      .finally(() => setLoading(false));
  }

  function AccordionView({ title, text, ...props }) {
    const [open, setOpen] = React.useState(false);
    return (
      <TouchableOpacity
        style={{
          backgroundColor: Colors.white,
          borderBottomWidth: 1,
          borderBottomColor: Colors.lightGray,
        }}
        {...props}
        onPress={() => setOpen(!open)}
      >
        <View style={{ paddingVertical: 16 }}>
          <View style={{ flexDirection: "row" }}>
            <Text2B style={{ flex: 1 }}>{title}</Text2B>
            {open ? <GIcon.ArrowUp /> : <GIcon.ArrowDown />}
          </View>
          {open ? <Text2L style={{ marginTop: 16 }}>{text}</Text2L> : null}
        </View>
      </TouchableOpacity>
    );
  }

  return (
    <View style={{ marginHorizontal: 24 }}>
      {!store.user.stripeAccountReady ? (
        <View style={{ marginTop: 24 }}>
          <Text2 style={{ fontSize: 20 }}>Stripe Connect</Text2>
          <Text2L style={{ fontSize: 14, marginTop: 12 }}>
            You’ll need the following to <Text2SB>connect your account: </Text2SB> {" \n"} • Your
            full legal name and address {" \n"} • Bank account details {" \n"} • Photo of your ID,
            drivers license, or passport.
          </Text2L>
        </View>
      ) : null}

      {!store.user.stripeAccountReady ? (
        <TouchableOpacity activeOpacity={0.8} onPress={onConnect} disabled={loading}>
          <View
            style={{
              height: 56,
              marginTop: 24,
              borderRadius: 8,
              alignItems: "center",
              flexDirection: "row",
              backgroundColor: Colors.primary,
              justifyContent: "center",
            }}
          >
            <Text2B style={{ color: "#fff", fontSize: 18 }}>
              {loading ? `Please wait...` : `Connect with`}
            </Text2B>
            <GIcon.Stripe style={{ marginLeft: 2 }} />
          </View>
        </TouchableOpacity>
      ) : null}

      {store.user.stripeAccountReady ? (
        <TouchableOpacity
          activeOpacity={0.8}
          onPress={() => navigateToMonthlyReports(navigation)}
          disabled={loading}
        >
          <View
            style={{
              height: 56,
              marginTop: 24,
              borderRadius: 8,
              flexDirection: "row",
              backgroundColor: Colors.lightGray,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Text2SB
              style={{
                color: Colors.black,
                fontSize: 16,
              }}
            >
              Monthly reports
            </Text2SB>
            <GIcon.ArrowForward style={{ fontSize: 42, position: "absolute", right: 10 }} />
          </View>
        </TouchableOpacity>
      ) : null}

      <View style={{ marginTop: 24 }}>
        <Text2 style={{ fontSize: 20 }}>FAQ</Text2>
        <View
          style={{
            padding: 4,
            borderBottomWidth: 1,
            borderBottomColor: Colors.gray,
          }}
        />
      </View>

      {faqs.map((item) => (
        <AccordionView title={item.title} key={item.title} text={item.message} />
      ))}
    </View>
  );
});
