import React from "react";
import { FlatList, TouchableOpacity, View } from "react-native";
import { useEvents } from "../../app/api/event/eventHooks";
import Cavy from "../../app/test/Cavy";
import { Container, Header, Text2 } from "../../components/ui";
import LoadingView from "../../components/ui/LoadingView";

const TouchTheme = Cavy.wrap(TouchableOpacity);

export default function StatsScreen({ navigation }) {
  const { isLoading, data: events } = useEvents();

  const [items, setItems] = React.useState([]);

  React.useEffect(() => {
    const map = {};
    if (events) {
      const newItems = [];
      events.forEach((event) => {
        if (map[event.type]) {
          map[event.type].push(event);
        } else {
          map[event.type] = [event];
        }
      });

      Object.keys(map).forEach((key) => {
        const platform = { web: [], ios: [], android: [] };
        map[key].forEach((event) => {
          if (platform[event.platform]) {
            platform[event.platform].push(event);
          } else {
            platform[event.platform] = [event];
          }
        });

        newItems.push({ key, value: map[key].length });
        Object.keys(platform).forEach((p) => {
          newItems.push({ key: p, value: platform[p].length });
        });

        newItems.push({});
      });

      setItems(newItems);
    }
  }, [events]);

  if (isLoading) {
    return <LoadingView />;
  }

  function renderRow(item) {
    return (
      <TouchTheme key={item.id}>
        <View
          style={{
            flexDirection: "row",
            padding: 16,
            marginLeft: 16,
            borderBottomWidth: 1,
            borderBottomColor: "#d4d4d4",
          }}
        >
          <Text2 style={{ flex: 1, fontSize: 16 }}>{item.key}</Text2>
          <Text2 style={{ fontSize: 16 }}>{item.value}</Text2>
        </View>
      </TouchTheme>
    );
  }

  return (
    <Container>
      <Header onBack={() => navigation.goBack()} title="Stats" />

      <FlatList
        data={items}
        keyboardShouldPersistTaps="handled"
        renderItem={({ item }) => renderRow(item)}
      />
    </Container>
  );
}
