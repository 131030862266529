import React from "react";
import { Alert, FlatList, Image, TouchableOpacity } from "react-native";
import { ScreenSize } from "../../app/util/util";
import { Container, Header } from "../../components/ui";

const GOOGLE_MAP_PHOTO_URL =
  "https://maps.googleapis.com/maps/api/place/photo?key=AIzaSyBpVPUR8JmwhJbtpl0aJss45Jn-Hy5Xwv4";

const GOOGLE_MAP_PLACE_URL =
  "https://maps.googleapis.com/maps/api/place/details/json?key=AIzaSyBpVPUR8JmwhJbtpl0aJss45Jn-Hy5Xwv4";

export default function GooglePhotoPickerScreen({ route, navigation }) {
  const { gPlaceId, onDone, onCancel } = route.params;
  const [photos, setPhotos] = React.useState([]);

  React.useEffect(() => {
    async function load() {
      // eslint-disable-next-line no-undef
      const response = await fetch(`${GOOGLE_MAP_PLACE_URL}&place_id=${gPlaceId}`).then((res) =>
        res.json()
      );

      if ((((response || {}).result || {}).photos || []).length === 0) {
        Alert.alert(
          "Oops, no photos available!",
          "Make sure the address is correct and try again!"
        );

        onCancel();
        navigation.goBack();
        return;
      }

      setPhotos(
        response.result.photos.map((photo) => {
          const ratio = photo.width / photo.height;
          const width = photo.width > 600 ? photo.width : 600;
          const height = width / ratio;
          const url = `${GOOGLE_MAP_PHOTO_URL}&maxwidth=${width}&photo_reference=${photo.photo_reference}`;
          return {
            url,
            ratio,
            width,
            height,
          };
        })
      );
    }

    load();
  }, [gPlaceId, navigation, onCancel]);

  const renderRow = (item) => {
    return (
      <TouchableOpacity
        activeOpacity={0.9}
        onPress={() => {
          onDone(item);
          navigation.goBack();
        }}
      >
        <Image
          source={{ uri: item.url }}
          style={{
            marginTop: 16,
            marginLeft: 16,
            borderRadius: 5,
            width: ScreenSize.width - 32,
            height: (ScreenSize.width - 32) / item.ratio,
          }}
        />
      </TouchableOpacity>
    );
  };

  return (
    <Container>
      <Header
        transparent
        onBack={() => {
          onCancel();
          navigation.goBack();
        }}
        title="Select photo from Google Maps"
      />

      <FlatList
        data={photos}
        keyExtractor={(item) => item.url}
        keyboardShouldPersistTaps="handled"
        renderItem={({ item, index }) => renderRow(item, index)}
      />
    </Container>
  );
}

/*
const PHOTOS = [
  {
    height: 3000,
    html_attributions: [
      '<a href="https://maps.google.com/maps/contrib/112776210440851015110">Cynthia Stella Isley</a>',
    ],
    photo_reference:
      "Aap_uECu9rXqKb75wPJzgJkJgexOQvXrTu_AOFx0EnnHvA10MoqV9Kx50xL7gv6CHAU-N02LXbRd41T9VV6sQlOllwCu7XFFusa0yPou2KbNQtV4y9NtEZgm0EmVV4rKoo-6l47jtd1VnYdXU6fGh6d14skIPFYeeJF2vigiOQi8GiCSuYsg",
    width: 4000,
  },
];
*/
