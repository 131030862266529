/* eslint-disable import/no-unused-modules */
import React from "react";
import { Image, View } from "react-native";
import { screenWidth } from "../app/util/util";
import Colors from "./Colors";

export default function WaveFormView() {
  return (
    <View style={{ flex: 1, borderWidth: 0, alignItems: "center", backgroundColor: Colors.white }}>
      <Image
        style={{
          flex: 1,
          height: 40,
          width: Math.min(screenWidth - 160, 240),
        }}
        source={require("../../assets/images/waveform.png")}
      />
    </View>
  );
}
