/* eslint-disable no-nested-ternary */
import React from "react";
import { Alert, Keyboard, StyleSheet, View } from "react-native";
import { navigateToEmail, navigateToSelect } from "../../app/navigation/Navigator";
import store, { getLoginCredentials, saveLoginCredentials } from "../../app/store/Store";
import Cavy from "../../app/test/Cavy";
import Errors from "../../app/util/Errors";
import { isWeb } from "../../app/util/util";
import Colors from "../../components/Colors";
import { Body2, Header1 } from "../../components/typography";
import { Container, Content, Header } from "../../components/ui";
import ButtonView from "../../components/ui/ButtonView";
import CheckBoxView from "../../components/ui/CheckBoxView";
import GIcon from "../../components/ui/GIcon";
import { EmailInputView, PasswordInputView, PhoneInputView } from "../../components/ui/inputs";
import IOSTabView from "../place/ui/IOSTabView";
import countries from "../picker/countries.json";
import Api from "../../app/api/Api";

export default function LoginScreen({ route, navigation }) {
  const params = route.params || {};

  const generateTestHook = Cavy.useCavy();

  const [uid, setUid] = React.useState("");
  const [password, setPassword] = React.useState("");
  const rememberMe = JSON.parse(store.rememberMe);
  const [loading, setLoading] = React.useState(false);
  const [tab, setTab] = React.useState("Email/Username");
  const [phoneValue, setPhoneValue] = React.useState("");
  const [country, setCountry] = React.useState("SE +46");

  const useEmail = tab === "Email/Username";

  React.useEffect(() => {
    if (!isWeb && rememberMe) {
      getLoginCredentials()
        .then((cred) => {
          setUid(cred[0]);
          setPassword(cred[1] || "");
        })
        .catch(Errors.showErrorMessage);
    }
  }, [rememberMe]);

  function onUserToken({ user, token }) {
    store.setUser(user);
    store.setToken(token);
    if (rememberMe) {
      store.setRememberMe(rememberMe);
      saveLoginCredentials(uid, password);
    }
    navigation.navigate("hometab");
  }

  function onLoginPressed() {
    Keyboard.dismiss();
    setLoading(true);

    const id = getUid();

    if (id.startsWith("+") && !phoneReg.test(id.slice(1))) {
      Alert.alert("Invalid phone!");
      return;
    }

    Api.auth
      .login(getUid(), password)
      .then(onUserToken)
      .then(() => {
        setLoading(false);
        if (params.onDone) {
          params.onDone();
        }
      })
      .catch((e) => {
        setLoading(false);
        Errors.showErrorMessage(e);
      });
  }

  const phoneReg = new RegExp("^[0-9]+$");

  function getUid() {
    if (useEmail) {
      return uid;
    }

    if (phoneValue.startsWith("+")) {
      return phoneValue;
    }

    if (phoneValue.startsWith("00")) {
      return phoneValue.replace("00", "+");
    }

    const countryCode = country?.split(" ")[1];

    if (phoneValue.startsWith("0")) {
      return countryCode + phoneValue.slice(1);
    }

    return countryCode + phoneValue;
  }

  const isEmail = uid.indexOf("@") !== -1;

  function isLoginButtonDisabled() {
    if (!password) {
      return true;
    }

    if (useEmail) {
      return !uid;
    }

    return !phoneValue;
  }

  const loginButtonDisabled = isLoginButtonDisabled();

  return (
    <Container>
      <Header
        title="Login"
        onBack={params.uid ? navigation.goBack : null}
        onClose={params.uid ? null : () => navigation.goBack(null)}
      />

      <Content style={{ padding: 29 }}>
        <View style={styles.main}>
          {params.uid ? (
            <View>
              <Header1 style={{ color: Colors.black }}>{`You've already\nsigned up!`}</Header1>
              <Body2>Enter your password to log into your account.</Body2>
            </View>
          ) : null}

          <IOSTabView
            select={tab}
            onTab={setTab}
            tabs={["Phone", "Email/Username"]}
            style={{ backgroundColor: Colors.white, padding: 4, marginVertical: 4 }}
          />

          {tab === "Phone" ? (
            <PhoneInputView
              ref={generateTestHook("Login.Phone")}
              value={phoneValue}
              style={{ borderWidth: 1, height: 60 }}
              label="Phone"
              placeholder="Phone number"
              onChangeText={(text) => setPhoneValue(text.trim())}
              country={country}
              onCountry={() =>
                navigateToSelect(navigation, {
                  showCode: true,
                  title: "Select a country",
                  options: countries,
                  onSelect: ({ code, dial_code }) => setCountry(`${code} ${dial_code}`),
                })
              }
            />
          ) : (
            <EmailInputView
              value={uid}
              ref={generateTestHook("Login.Email")}
              label={isEmail ? "Email" : "Email or username"}
              onChangeText={(text) => setUid(text.trim().toLowerCase())}
            />
          )}

          <PasswordInputView
            value={password}
            ref={generateTestHook("Login.Password")}
            label="Password"
            onChangeText={setPassword}
          />

          <ButtonView
            small
            dontCenter
            ref={generateTestHook("Login.Forgot")}
            style={{
              width: 135,
              height: 30,
              marginTop: 1,
              borderWidth: 0,
              paddingVertical: 0,
              paddingHorizontal: 4,
            }}
            onPress={() => navigateToEmail(navigation, { reset: true })}
          >
            Forgot password?
          </ButtonView>

          {!isWeb ? (
            <CheckBoxView checked={rememberMe} style={{ marginTop: 16, padding: 4 }}>
              Remember me
            </CheckBoxView>
          ) : null}

          <ButtonView
            primary={!loginButtonDisabled}
            gray={loginButtonDisabled}
            {...{ loading }}
            onPress={onLoginPressed}
            style={{ marginTop: 16 }}
            disabled={loginButtonDisabled}
            ref={generateTestHook("Login.Login")}
          >
            Login
          </ButtonView>

          {false && (
            <ButtonView style={{ marginTop: 24 }} Icon={GIcon.Apple}>
              Continue with apple
            </ButtonView>
          )}

          <View style={{ height: 50 }} />
        </View>
      </Content>
    </Container>
  );
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
    flexDirection: "column",
    justifyContent: "center",
  },
});
