import client from "../client";
import { QUERY_MESSAGES, MESSAGE_CREATE, MESSAGE_DELETE, MESSAGE_UPDATE } from "./graphql";

function messages() {
  return client
    .query({
      variables: {},
      query: QUERY_MESSAGES,
    })
    .then((response) => response.messages);
}

function messageCreate(title, message) {
  return client
    .mutate({
      variables: {
        title,
        message,
      },
      mutation: MESSAGE_CREATE,
    })
    .then((response) => response.messageCreate);
}

function messageUpdate(id, title, message) {
  return client
    .mutate({
      variables: {
        id,
        title,
        message,
      },
      mutation: MESSAGE_UPDATE,
    })
    .then((response) => response.messageUpdate);
}

function messageDelete(id) {
  return client
    .mutate({
      variables: {
        id,
      },
      mutation: MESSAGE_DELETE,
    })
    .then((response) => response.messageDelete);
}

export default {
  messages,
  messageCreate,
  messageUpdate,
  messageDelete,
};
