import * as React from "react";
import { useContext } from "react";
import { StyleSheet, View } from "react-native";
import { useRecoilState } from "recoil";
import Colors from "../../../components/Colors";
import { IconButton } from "./components/IconButton";
import usePerformCrop from "./customHooks/usePerformCrop";
import EditorContext from "./EditorContext";
import { editingModeState, imageDataState } from "./Store";

export default function ControlBar() {
  //
  const [editingMode] = useRecoilState(editingModeState);
  const [imageData] = useRecoilState(imageDataState);
  const { mode, onCloseEditor, onEditingComplete } = useContext(EditorContext);

  const performCrop = usePerformCrop();

  const shouldDisableDoneButton = editingMode !== "operation-select" && mode !== "crop-only";

  const onFinishEditing = async () => {
    await performCrop();
  };

  const onPressBack = () => {
    onCloseEditor();
  };

  // Complete the editing process if we are in crop only mode after the editingMode gets set
  // back to operation select (happens internally in usePerformCrop) - can't do it in onFinishEditing
  // else it gets stale state - may need to refactor the hook as this feels hacky
  React.useEffect(() => {
    if (imageData.uri && editingMode === "operation-select") {
      onEditingComplete(imageData);
      onCloseEditor();
    }
  }, [imageData, editingMode, onEditingComplete, onCloseEditor]);

  return (
    <View style={styles.container}>
      <IconButton iconID="arrow-back" text="Back" onPress={onPressBack} />
      <IconButton
        iconID="done"
        text="Done"
        onPress={onFinishEditing}
        disabled={shouldDisableDoneButton}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    height: 80,
    backgroundColor: Colors.white,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 4,
  },
});
