import { useNavigation } from "@react-navigation/native";
import React, { useRef } from "react";
import { findNodeHandle, ScrollView, TextInput, View } from "react-native";
import { usePlace } from "../../app/api/place/placeHooks";
import Colors from "../../components/Colors";
import { Container, HeaderContainer } from "../../components/ui";
import ErrorView from "../../components/ui/ErrorView";
import LoadingView from "../../components/ui/LoadingView";
import { checkPlace } from "../guide/GuideScreen/util";
import CreatePlaceView, { UpdateAddressView } from "./ui/CreatePlaceView";
import EditPlaceStatusView from "./ui/EditPlaceStatusView";
import EditPlaceView from "./ui/EditPlaceView";
import IOSTabView from "./ui/IOSTabView";
import PlaceView from "./ui/PlaceView";

export default function EditPlaceScreen({ route, navigation }) {
  const params = route.params || {};
  const { index, guideId, location } = params;

  const [placeId, setPlaceId] = React.useState(params.placeId);
  const [updateLocation, setUpdateLocation] = React.useState(false);

  if (!placeId) {
    return (
      <CreatePlaceView
        onPlaceId={setPlaceId}
        {...{ guideId, location }}
        onCancel={() => navigation.goBack()}
      />
    );
  }

  if (updateLocation) {
    return (
      <UpdateAddressView
        {...{ placeId, location }}
        onDone={() => setUpdateLocation(false)}
        onCancel={() => setUpdateLocation(false)}
      />
    );
  }

  return (
    <UpdateOrPreviewPlaceView
      {...{ index, placeId, location }}
      onChangeAddress={() => setUpdateLocation(true)}
    />
  );
}

function UpdateOrPreviewPlaceView({ index, placeId, location, onChangeAddress }) {
  const navigation = useNavigation();
  const [tab, setTab] = React.useState("Edit");

  const { isLoading, error, data: place } = usePlace(placeId);

  if (isLoading || !place) {
    return <LoadingView />;
  }

  if (error) {
    return <ErrorView error="Something went wrong, try again!" />;
  }

  return (
    <Container noScroll style={{ backgroundColor: Colors.white }}>
      <HeaderContainer
        color={Colors.black}
        right={{ label: "Done", onPress: () => navigation.goBack() }}
      >
        <IOSTabView
          select={tab}
          onTab={setTab}
          tabs={["Edit", "Preview"]}
          style={{ backgroundColor: Colors.white, padding: 4, marginVertical: 4 }}
        />
      </HeaderContainer>

      {tab === "Preview" ? (
        <PreviewPlaceView {...{ index, place }} />
      ) : (
        <UpdatePlaceView {...{ index, place, location, onChangeAddress }} />
      )}
    </Container>
  );
}

function UpdatePlaceView({ index, place, location, onChangeAddress }) {
  const scrollRef = useRef(null);
  const [isComplete, setIsComplete] = React.useState(false);

  React.useEffect(() => {
    try {
      checkPlace(place);
      setIsComplete(true);
    } catch (e) {
      setIsComplete(false);
    }
  }, [place]);

  function onCaptionFocus() {
    if (TextInput.State.currentlyFocusedInput) {
      setTimeout(() => {
        scrollRef.current
          .getScrollResponder()
          .scrollResponderScrollNativeHandleToKeyboard(
            findNodeHandle(TextInput.State.currentlyFocusedInput()),
            150,
            true
          );
      }, 100);
    }
  }

  return (
    <ScrollView
      ref={scrollRef}
      style={{ flex: 1 }}
      keyboardShouldPersistTaps="handled"
      showsVerticalScrollIndicator={false}
    >
      <View style={{ backgroundColor: Colors.lynxWhite }}>
        {!isComplete ? <EditPlaceStatusView style={{ marginTop: 8 }} {...{ place }} /> : null}
        <EditPlaceView {...{ index, place, location, onCaptionFocus, onChangeAddress }} />
      </View>
    </ScrollView>
  );
}

function PreviewPlaceView({ index, place }) {
  return (
    <ScrollView
      style={{ flex: 1 }}
      keyboardShouldPersistTaps="handled"
      showsVerticalScrollIndicator={false}
    >
      <PlaceView {...{ index, place }} />
    </ScrollView>
  );
}
