import React from "react";
import { FlatList, View } from "react-native";
import { useQuery } from "react-query";
import Api from "../../app/api/Api";
import Queries from "../../app/constants/Queries";
import { navigateToGuide, navigateToProfile } from "../../app/navigation/Navigator";
import Cavy from "../../app/test/Cavy";
import GuideListItemView from "../../components/guide/GuideListItemView";
import { Container as UIContainer, Content, Header, Text2B } from "../../components/ui";
import { SearchInputView } from "../../components/ui/inputs";
import UserListItemView from "../../components/ui/listItems";
import TabsView from "../../components/ui/TabsView";

const USERS = "Creators";
const GUIDES = "Guides";

const TABS = [GUIDES, USERS];

const Container = Cavy.wrap(UIContainer);

function ShowPopularGuides({ renderGuide, onGuides }) {
  const { data: guides, isLoading } = useQuery(Queries.POPULAR_GUIDES, () =>
    Api.guide.popularGuides(0, 50)
  );

  React.useEffect(() => {
    if (onGuides) {
      onGuides(guides);
    }
  }, [guides, onGuides]);

  return (
    <FlatList
      data={guides}
      refreshing={isLoading}
      showsVerticalScrollIndicator={false}
      renderItem={({ item, index }) => renderGuide(item, index)}
    />
  );
}
export default function SearchScreen({ navigation }) {
  const generateTestHook = Cavy.useCavy();

  const [text, onChangeText] = React.useState("");
  const [select, onSelect] = React.useState(GUIDES);

  const { data: guides, isLoading: loadingGuides } = useQuery([Queries.SEARCH_GUIDES, text], () =>
    Api.guide.searchGuides(text, {}, 0, 50)
  );

  const { data: users, isLoading: loadingUsers } = useQuery([Queries.SEARCH_USERS, text], () =>
    Api.user.searchUsers(text, {}, 0, 50)
  );

  function renderUser(user, index) {
    return (
      <UserListItemView
        key={user.id}
        ref={generateTestHook(`Search.Creator${index}`)}
        {...{ user }}
        onUser={() => navigateToProfile(navigation, { userId: user.id })}
        onPress={() => navigateToProfile(navigation, { userId: user.id })}
      />
    );
  }

  function renderGuide(guide, index) {
    return (
      <GuideListItemView
        {...{ guide }}
        ref={generateTestHook(`Search.Guide${index}`)}
        onPress={() => navigateToGuide(navigation, { guideId: guide.id })}
      />
    );
  }

  return (
    <Container removeClippedSubviews={false} ref={generateTestHook("Search")}>
      <Header title="Search" onBack={() => navigation.goBack()} />
      <SearchInputView {...{ onChangeText }} placeholder="Search guides, creators..." />
      <Content>
        {text || select === USERS ? (
          <TabsView
            tabs={TABS}
            {...{ select, onSelect }}
            style={{ marginTop: 16, paddingLeft: 16, marginBottom: 6 }}
          />
        ) : null}

        {select === GUIDES && text ? (
          <FlatList
            data={guides}
            refreshing={loadingGuides}
            showsVerticalScrollIndicator={false}
            renderItem={({ item, index }) => renderGuide(item, index)}
          />
        ) : null}

        {select === GUIDES && !text ? (
          <View>
            <Text2B style={{ marginLeft: 16, marginTop: 16, marginBottom: 8 }}>
              {"Popular guides".toUpperCase()}
            </Text2B>

            <ShowPopularGuides {...{ renderGuide }} />
          </View>
        ) : null}

        {select === USERS && text ? (
          <FlatList
            data={users}
            refreshing={loadingUsers}
            showsVerticalScrollIndicator={false}
            renderItem={({ item, index }) => renderUser(item, index)}
          />
        ) : null}
      </Content>
    </Container>
  );
}
