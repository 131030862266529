import React from "react";
import { ActivityIndicator, TouchableOpacity, View } from "react-native";
import Colors from "../../../components/Colors";
import { Text2 } from "../../../components/ui";
import GIcon from "../../../components/ui/GIcon";

export default function ChangeCoverButton({ text, loading, onPress, hasCover }) {
  return (
    <TouchableOpacity onPress={loading ? null : onPress}>
      <View
        style={{
          padding: 16,
          borderRadius: 10,
          alignSelf: "center",
          alignItems: "center",
        }}
      >
        {loading ? (
          <ActivityIndicator size="small" color={Colors.white} />
        ) : (
          <>
            <GIcon.CameraOutline
              style={{
                fontSize: 32,
                shadowOpacity: 0.2,
                shadowColor: Colors.black,
                shadowOffset: { width: 0, height: 0 },
                color: hasCover ? Colors.white : Colors.rainyGray,
              }}
            />

            <Text2
              style={{
                fontSize: 16,
                marginTop: 0,
                shadowOpacity: 0.2,
                shadowColor: Colors.black,
                textAlign: "center",
                shadowOffset: { width: 0, height: 0 },
                color: hasCover ? Colors.white : Colors.rainyGray,
              }}
            >
              {text || (hasCover ? "Change guide cover photo" : "+ Add guide cover photo\n")}
              {!hasCover ? (
                <Text2
                  style={{
                    fontSize: 12,
                  }}
                >
                  (we recommend a photo of yourself)
                </Text2>
              ) : null}
            </Text2>
          </>
        )}
      </View>
    </TouchableOpacity>
  );
}
