import React from "react";
import { TouchableOpacity, View } from "react-native";
import { useQuoteOptions } from "../../../app/api/question/questionHooks";
import Cavy from "../../../app/test/Cavy";
import ClosableView from "../../../components/ClosableView";
import Colors from "../../../components/Colors";
import { Text2 } from "../../../components/ui";
import ButtonView from "../../../components/ui/ButtonView";
import GIcon from "../../../components/ui/GIcon";
import QuoteItemView from "./QuoteItemView";

export const QAButton = Cavy.wrap(({ style, quote, onEdit, onWrite, onPress, onClear }) => {
  const [index, setIndex] = React.useState(1);

  const { isLoading, options } = useQuoteOptions();

  if (quote) {
    return <QuoteView {...{ style, quote, onEdit, onClear }} />;
  }

  if (isLoading) {
    return null;
  }

  return (
    <View
      style={{
        borderWidth: 1,
        borderRadius: 10,
        marginTop: 8,
        marginBottom: 8,
        borderColor: Colors.gray,
        justifyContent: "center",
      }}
    >
      <View style={{ flexDirection: "row", alignItems: "center", minHeight: 180 }}>
        <TouchableOpacity
          activeOpacity={0.8}
          onPress={() => setIndex(index - 1 < 0 ? options.length - 1 : index - 1)}
        >
          <View>
            <GIcon.ArrowBack style={{ fontSize: 42 }} />
          </View>
        </TouchableOpacity>

        <QuoteItemView title={options[index].title} text={options[index].text} />

        <TouchableOpacity
          activeOpacity={0.8}
          onPress={() => setIndex(index + 1 < options.length ? index + 1 : 0)}
        >
          <View>
            <GIcon.ArrowForward style={{ fontSize: 42 }} />
          </View>
        </TouchableOpacity>
      </View>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginBottom: 12,
          justifyContent: "center",
        }}
      >
        <ButtonView
          primary
          small
          onPress={() => onEdit(options[index])}
          style={{ width: 120, paddingHorizontal: 4 }}
        >
          Edit this
        </ButtonView>

        <ButtonView
          outline
          small
          onPress={() => onWrite()}
          style={{ width: 120, paddingHorizontal: 4 }}
        >
          Write my own
        </ButtonView>
      </View>
    </View>
  );
});

function QuoteView({ style, quote, onEdit, onClear }) {
  return (
    <ClosableView
      style={[
        {
          marginTop: 8,
          paddingTop: 12,
          paddingBottom: 16,
          paddingHorizontal: 16,
          backgroundColor: Colors.white,
        },
        style,
      ]}
      onClose={onClear}
    >
      <TouchableOpacity onPress={onEdit} activeOpacity={0.6}>
        <View>
          <Text2 style={{ marginVertical: 8 }}>{quote.question}</Text2>
          <Text2 style={{ color: "#aaa" }}>{quote.answer}</Text2>
        </View>
      </TouchableOpacity>
    </ClosableView>
  );
}
