import * as Clipboard from "expo-clipboard";
import { observer } from "mobx-react";
import React from "react";
import { ActivityIndicator, Alert, Platform, ScrollView, View } from "react-native";
import { useQuery } from "react-query";
import Api from "../../../app/api/Api";
import { useUser } from "../../../app/api/user/userHooks";
import Queries from "../../../app/constants/Queries";
import {
  navigateToEditAdjustment,
  navigateToEditGuide,
  navigateToGuidePlaces,
  navigateToSelect,
  navigateToShareGuide,
} from "../../../app/navigation/Navigator";
import ICloud from "../../../app/store/ICloud";
import { usePlayerStore } from "../../../app/store/PlayerStore";
import store from "../../../app/store/Store";
import Cavy from "../../../app/test/Cavy";
import Errors from "../../../app/util/Errors";
import { isIOS, isWeb, screenWidth } from "../../../app/util/util";
import connectActionSheet from "../../../components/actionsheet/connectActionSheet";
import AlertDialog from "../../../components/AlertDialog";
import Colors from "../../../components/Colors";
import Status from "../../../components/guide/Status";
import InAppPurchases from "../../../components/InAppPurchases";
import PlayerView from "../../../components/PlayerView";
import { Container, Header, Text2, Text2B } from "../../../components/ui";
import ErrorView from "../../../components/ui/ErrorView";
import GIcon from "../../../components/ui/GIcon";
import LoadingView from "../../../components/ui/LoadingView";
import categories from "../../picker/report-categories.json";
import GuideDescriptionView from "../ui/GuideDescriptionView";
import GuideMap from "../ui/GuideMap";
import { PlaceGridView } from "../ui/PlaceCellView";
import TouchablePlaceCellView from "../ui/TouchablePlaceCellView";
import WebButtonView from "../ui/WebButtonView";
import { isEmbeddedBrowser } from "../ui/WebButtonView.web";
import GetTheAppView from "./GetTheAppView";
import SuccessView from "./SuccessView";
import GuideAppBar from "./ui/GuideAppBar";
import GuideHeader from "./ui/GuideHeader";
import { createOptions, Options } from "./util";
import StoreReviewView from "../../../components/StoreReviewView";
import Track from "../../../app/Track";

function GuideScreen({ route, navigation, ...props }) {
  const { params } = route;
  const { otp } = params;

  const [success, setSuccess] = React.useState(params.success);
  const [guideId, go, phone] = route.params.guideId.split("-");
  const scrollRef = React.useRef(null);
  const [ownerId, setOwnerId] = React.useState(null);
  const [buying, setBuying] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [togglingLike, setTogglingLike] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [showReviewDialog, setShowReviewDialog] = React.useState(false);
  const [solidAppBar, setSolidAppBar] = React.useState(false);

  const generateTestHook = Cavy.useCavy();
  const userId = store.getUserId();
  const { data: user } = useUser(userId);
  const player = usePlayerStore();

  const {
    error,
    isLoading,
    data: guide,
  } = useQuery([Queries.GUIDE, guideId], () => Api.guide.guideGet(guideId));

  React.useEffect(() => {
    if (guide && guide.user && !ownerId) {
      setOwnerId(guide.user.id);
    }
  }, [guide, ownerId]);

  React.useEffect(() => {
    if (success) {
      store.savePurchasedGuides([guideId]);
    }
  }, [success, guideId]);

  React.useEffect(() => {
    Api.auth
      .login(otp)
      .then((res) => {
        store.setUser(res.user);
        store.setToken(res.token);
      })
      .catch(() => null);
  }, [otp]);

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("beforeRemove", () => {
      player.stop();
    });
    return unsubscribe;
  }, [navigation]);

  React.useEffect(() => {
    Track.sendEvent({ type: "Open guide", guideId });
  }, [guideId]);

  React.useEffect(() => {
    if (guide && guide.user && guide.user.id !== userId && !store.isAdmin()) {
      Api.guide.guideViewed(guideId).catch(Errors.log);
    }
    async function initInAppPurchases() {
      await InAppPurchases.connectAsync();
      const iCloudUserId = await ICloud.getUserId();

      await InAppPurchases.getProductsAsync(["guide_v0"]);

      InAppPurchases.setPurchaseListener(({ responseCode, results, errorCode }) => {
        // Purchase was successful
        if (responseCode === InAppPurchases.IAPResponseCode.OK) {
          results.forEach((purchase) => {
            if (!purchase.acknowledged) {
              const { orderId, transactionReceipt, purchaseToken } = purchase;
              Api.guide
                .guideBuy(guideId, {
                  orderId,
                  iCloudUserId,
                  googleReceipt: purchaseToken,
                  appleReceipt: transactionReceipt,
                })
                .then(() => markAsPurchased())
                .then(() => InAppPurchases.finishTransactionAsync(purchase, true))
                .then(async () => {
                  if (store.isLoggedIn()) {
                    // await Api.user.userFollow(ownerId);
                    store.setUser(await Api.user.me());
                  }

                  Track.sendEvent({ type: "Successfull Purchase", guideId });
                })
                .catch((error) => {
                  Errors.showGenericMessage(error);
                  Track.sendEvent({ type: "Purchase error", message: error.message, guideId });
                })
                .finally(() => setBuying(false));
            }
          });
        } else if (responseCode === InAppPurchases.IAPResponseCode.USER_CANCELED) {
          console.log("User canceled the transaction");
          setBuying(false);
        } else if (responseCode === InAppPurchases.IAPResponseCode.DEFERRED) {
          console.log(
            "User does not have permissions to buy but requested parental approval (iOS only)"
          );
          setBuying(false);
        } else {
          console.log({ responseCode, results, errorCode });
          console.warn(`Something went wrong with the purchase. Received errorCode ${errorCode}`);
          setBuying(false);
        }
      });
    }

    initInAppPurchases();

    return () => InAppPurchases.disconnectAsync().catch(() => null);
  }, []);

  if (isLoading) {
    return <LoadingView />;
  }

  if (error) {
    return <ErrorView error="Something went wrong, try again!" />;
  }

  const isLiked = user && (user.likedGuides || []).find((id) => id === guideId);
  const isUnlocked = store.isUnlocked(guide);

  function onDeleteGuide() {
    setShowDeleteDialog(true);
  }

  function doDeleteGuide() {
    setDeleting(true);
    Api.guide
      .guideDelete(guide.id)
      .then(() => navigation.goBack())
      .catch(Errors.showGenericMessage)
      .finally(() => setDeleting(false));
  }

  function onEditGuide() {
    navigateToEditGuide(navigation, { guideId: guide.id });
  }

  function onCopyGuide() {
    Clipboard.setString(`https://web.guidepal.com/guide/${guide.id}`);
  }

  function onShareGuide() {
    navigateToShareGuide(navigation, { guideId: guide.id });
  }

  function onReportGuide() {
    navigateToSelect(navigation, {
      title: "Select a category",
      options: categories,
      onSelect: ({ name }) => doReportGuide(name),
    });
  }

  function doReportGuide(message) {
    Api.report
      .reportCreate({ guideId, message })
      .then(() => Alert.alert("Report sent!"))
      .catch(Errors.log);
  }

  function onApproveGuide() {
    Api.guide
      .guideUpdate(guide.id, { status: Status.PUBLISHED })
      .then(() => Alert.alert("Yay, published!"))
      .catch((err) => Alert.alert(err.message));
  }

  function onRequestAdjustment() {
    navigateToEditAdjustment(navigation, { guideId });
  }

  function onUpdateIsFree(isFree) {
    Api.guide.guideUpdate(guide.id, { isFree }).catch(Errors.showErrorMessage);
  }

  function onArchiveGuide() {
    Api.guide
      .guideUpdate(guide.id, { status: Status.ARCHIVED })
      .then(() => Alert.alert("Guide archived!"))
      .catch((err) => Alert.alert(err.message));
  }

  function onLike() {
    if (!store.isLoggedIn()) {
      Alert.alert("Sign in or create an account to save this place", "", [
        {
          text: "Cancel",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel",
        },
        { text: "Sure!", onPress: () => navigation.navigate("profiletab") },
      ]);

      return;
    }
    setTogglingLike(true);
    Api.guide
      .guideLike(guide.id)
      .then(() => Api.user.userFollow(guide.user.id))
      .then(async () => store.setUser(await Api.user.me()))
      .catch(Errors.showGenericMessage)
      .finally(() => setTimeout(() => setTogglingLike(false), 500));
  }

  function onUnlike() {
    setTogglingLike(true);
    Api.guide
      .guideUnlike(guide.id)
      .catch(Errors.showGenericMessage)
      .finally(() => setTimeout(() => setTogglingLike(false), 500));
  }

  const buildActions = ({ guide: theGuide, color }) => {
    const onPress = () => {
      const options = createOptions(theGuide);

      props.showActionSheetWithOptions(options, (buttonIndex) => {
        switch (options.options[buttonIndex]) {
          case Options.EDIT:
            onEditGuide();
            break;
          case Options.DELETE:
            onDeleteGuide();
            break;
          case Options.REPORT:
            onReportGuide();
            break;
          case Options.COPY:
            onCopyGuide();
            break;
          case Options.SHARE:
            onShareGuide();
            break;
          case Options.APPROVE:
            onApproveGuide();
            break;
          case Options.ARCHIVE:
            onArchiveGuide();
            break;
          case Options.REPUBLISH:
            onApproveGuide();
            break;
          case Options.REQUEST_ADJUSTMENT:
            onRequestAdjustment();
            break;
          case Options.MAKE_IT_FREE:
            onUpdateIsFree(true);
            break;
          case Options.MAKE_IT_FOR_PURCHASE:
            onUpdateIsFree(false);
            break;
          default:
        }
      });
    };

    return (
      <Header.Action
        ref={generateTestHook("Guide.More")}
        fontSize={32}
        {...{ onPress }}
        Icon={GIcon.MoreHoriz}
        color={color || "#000"}
        style={{ marginRight: 8 }}
      />
    );
  };

  const openPlace = (placeId) => {
    navigateToGuidePlaces(navigation, { guideId, placeId });
  };

  const pleaseLogin = () => {
    Alert.alert(
      "Sign in or create an account",
      "To get full access to this guide\n you’ll need an account.\nSign up in under 30 sec!",
      [
        {
          text: "Cancel",
          onPress: () => console.log("Cancel Pressed"),
          style: "cancel",
        },
        { text: "Sure!", onPress: () => navigation.navigate("profiletab") },
      ]
    );
  };

  const onBuy = async () => {
    Track.sendEvent({ type: "Buy button clicked", guideId });

    if (!isIOS && !store.isLoggedIn()) {
      pleaseLogin();
      return;
    }

    try {
      setBuying(true);
      const iCloudUserId = await ICloud.getUserId();
      const guides = await Api.guide.purchasedGuides(0, 10000, iCloudUserId);
      const purchased = guides.find(({ id }) => id === guideId);
      if (purchased) {
        markAsPurchased();

        setBuying(false);
        Alert.alert(
          "This In-App Purchase has already been bought.",
          "It will be restored for free."
        );

        Track.sendEvent({ type: "Already purchased", guideId });
      } else {
        InAppPurchases.purchaseItemAsync("guide_v0")
          .then((response) => console.log({ response }))
          .catch(Errors.showErrorMessage);
      }
    } catch (err) {
      setBuying(false);

      Track.sendEvent({ type: "Purchase error", message: err.message, guideId });

      if (err === ICloud.Errors.ICLOUD_ACCESS_ERROR) {
        pleaseLogin();
        return;
      }

      Errors.showErrorMessage(err);
    }
  };

  function markAsPurchased() {
    const map = {};
    map[guideId] = true;
    store.purchasedGuides.forEach((id) => {
      map[id] = true;
    });

    store.savePurchasedGuides(Object.keys(map));
  }

  const places = [...(guide.places || [])];

  const { media } = guide;
  const hasCover = media && media.url;

  const isDraft = guide.status === Status.DRAFT;

  const isOwner = guide.user.id === userId;
  const showAdjustment = guide.adjustment && (store.isAdmin() || isOwner);

  return (
    <Container style={{ flex: 1 }}>
      <ScrollView
        ref={scrollRef}
        scrollEventThrottle={16}
        showsVerticalScrollIndicator={false}
        onScroll={(event) => setSolidAppBar(event.nativeEvent.contentOffset.y > 440)}
      >
        <View style={isWeb ? { paddingBottom: 72 } : {}}>
          <GuideHeader
            {...{
              media,
              guide,
              onLike,
              isLiked,
              hasCover,
              onUnlike,
              navigation,
              togglingLike,
              onShareGuide,
            }}
          />

          <View style={{ paddingHorizontal: 16, backgroundColor: Colors.white }}>
            {showAdjustment ? (
              <View
                style={{
                  padding: 16,
                  marginTop: 16,
                  borderRadius: 10,
                  backgroundColor: Colors.goldenYellow,
                }}
              >
                <Text2
                  style={{ lineHeight: 20, color: Colors.black }}
                >{`Your guide is not available for sales because it doesn’t follow our Community Guidelines.\n\n${guide.adjustment}\n\n-The guidepal team`}</Text2>
              </View>
            ) : null}
            {guide.description ? (
              <GuideDescriptionView text={guide.description} />
            ) : (
              <View style={{ height: 8 }} />
            )}

            <View style={{ marginTop: 8, marginBottom: 8 }}>
              {guide.audio ? <PlayerView media={guide.audio} /> : null}
            </View>

            {media?.audio ? (
              <PlayerView
                style={{
                  height: 52,
                  marginTop: 12,
                  alignSelf: "center",
                  width: screenWidth - 12,
                }}
                media={media?.audio}
              />
            ) : null}

            <Text2B style={{ color: Colors.darkerGray, marginTop: 8, marginBottom: 4 }}>
              Places
            </Text2B>
            <PlaceGridView>
              {places.map((place, index) => {
                const locked = !isUnlocked && index !== 0;
                return (
                  <TouchablePlaceCellView
                    showName
                    key={place.id}
                    {...{ index, place, locked }}
                    style={{ borderWidth: 0 }}
                    onPress={locked ? null : () => openPlace(place.id)}
                    ref={generateTestHook(`Guide.Place${index}`)}
                  />
                );
              })}
            </PlaceGridView>
          </View>

          {Platform.OS !== "web" && (
            <GuideMap
              lite
              {...{ guide }}
              locked={!isUnlocked}
              style={{ marginTop: 24 }}
              onPlace={(placeId) => openPlace(placeId)}
            />
          )}

          {!isUnlocked && store.isTablet ? (
            <View style={{ bottom: 24, width: screenWidth, borderWidth: 0 }}>
              <WebButtonView
                onPress={onBuy}
                loading={buying}
                bgColor={Colors.primary}
                childrenColor={Colors.white}
                {...{ guide, go, phone, navigation }}
                style={{ marginHorizontal: 16, borderRadius: 64 }}
              >
                Unlock guide for $1.99
              </WebButtonView>
            </View>
          ) : null}

          {isWeb ? <GetTheAppView /> : null}

          {isEmbeddedBrowser() ? <View style={{ height: 190 }} /> : null}
        </View>
      </ScrollView>

      <View
        style={{
          left: 0,
          right: 0,
          paddingBottom: 8,
          position: "absolute",
          backgroundColor: solidAppBar ? Colors.white : Colors.transparent,
        }}
      >
        <GuideAppBar
          {...{
            guide,
            isDraft,
            navigation,
            buildActions,
            hasCover: !solidAppBar,
          }}
        />
      </View>
      {!isUnlocked && !store.isTablet ? (
        <View
          style={{
            bottom: 24,
            borderWidth: 0,
            width: screenWidth,
            position: "absolute",
          }}
        >
          <WebButtonView
            onPress={onBuy}
            loading={buying}
            bgColor={Colors.primary}
            childrenColor={Colors.white}
            {...{ guide, go, phone, navigation }}
            style={{ marginHorizontal: 16, borderRadius: 64 }}
          >
            Unlock guide for $1.99
          </WebButtonView>
        </View>
      ) : null}

      {deleting && (
        <View
          style={{
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            position: "absolute",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "rgba(255, 255, 255, 0.9)",
          }}
        >
          <ActivityIndicator />
          <Text2 style={{ marginTop: 16, color: Colors.darkerGray }}>Deleting...</Text2>
        </View>
      )}

      <AlertDialog
        title="Delete"
        message="Are you sure?"
        visible={showDeleteDialog}
        options={[
          { text: "No", onPress: () => setShowDeleteDialog(false) },
          {
            text: "Yes",
            onPress: () => {
              doDeleteGuide();
              setShowDeleteDialog(null);
            },
          },
        ]}
      />

      {showReviewDialog ? <StoreReviewView /> : null}

      {success && isWeb ? <SuccessView onOpenGuide={() => setSuccess(false)} /> : null}
    </Container>
  );
}

export default connectActionSheet(observer(GuideScreen));
