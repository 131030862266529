import React from "react";
import { Alert, View } from "react-native";
import Api from "../../../app/api/Api";
import Errors from "../../../app/util/Errors";
import { Container, Header, Text2 } from "../../../components/ui";
import LocationPicker from "../../guide/ui/LocationPicker";

export default function CreatePlaceView({ guideId, location, onPlaceId, onCancel }) {
  const [isCreating, setCreating] = React.useState(false);

  function onPlaceCreate({
    name,
    phone,
    address,
    website,
    gPlaceId,
    latitude,
    longitude,
    originalName,
  }) {
    setCreating(true);
    Api.place
      .placeCreate(
        {
          name,
          phone,
          address,
          website,
          gPlaceId,
          originalName,
          location: { latitude, longitude },
        },
        guideId
      )
      .then(({ id }) => {
        Api.guide
          .guideAddPlace(id, guideId)
          .then(() => onPlaceId(id))
          .catch(Errors.log);
      })
      .catch((e) => {
        Errors.log(e);
        setCreating(false);
      });
  }

  return (
    <EditPlaceLocationView
      {...{ location }}
      onClose={onCancel}
      loading={isCreating}
      onSave={onPlaceCreate}
    />
  );
}

export function UpdateAddressView({ placeId, location, onDone, onCancel }) {
  const [isWorking, setWorking] = React.useState(false);

  function onPlaceUpdate({ phone, address, website, gPlaceId, latitude, longitude, originalName }) {
    setWorking(true);
    Api.place
      .placeUpdate(placeId, {
        phone,
        address,
        website,
        gPlaceId,
        originalName,
        location: { latitude, longitude },
      })
      .then(() => {
        onDone();
        Alert.alert("Address updated!");
      })
      .catch((e) => {
        Errors.log(e);
        setWorking(false);
      });
  }

  return (
    <EditPlaceLocationView
      onClose={onCancel}
      loading={isWorking}
      onSave={onPlaceUpdate}
      {...{ placeId, location }}
    />
  );
}

function EditPlaceLocationView({ placeId, location, loading, onClose, onSave }) {
  return (
    <Container>
      <Header transparent circleBack useCloseIconForBack onBack={onClose} />
      <View style={{ flex: 1, marginTop: 8 }}>
        <Text2 style={{ fontSize: 20, marginHorizontal: 16 }}>
          {placeId ? "Change place address" : "Add Place"}
        </Text2>
        <LocationPicker
          onDone={onSave}
          {...{ location, loading }}
          placeholder="Find the place"
          title={placeId ? "Update place address" : "Add place"}
        />
      </View>
    </Container>
  );
}
