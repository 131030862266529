import { observer } from "mobx-react";
import React from "react";
import { Image, View } from "react-native";
import { navigateToApplication } from "../../app/navigation/Navigator";
import Cavy from "../../app/test/Cavy";
import { screenHeight, screenWidth } from "../../app/util/util";
import Colors from "../../components/Colors";
import { LogoNew } from "../../components/Logo";
import { Text1, Text2 } from "../../components/ui";
import ButtonView from "../../components/ui/ButtonView";

function ApplyScreen({ navigation }) {
  const generateTestHook = Cavy.useCavy();

  const renderHeader = () => {
    const photo = require("../../../assets/images/apply_bg.png");

    return (
      <View
        style={{
          flex: 1,
        }}
      >
        <Image
          source={photo}
          resizeMode="cover"
          style={{ position: "absolute", height: screenHeight, width: screenWidth }}
        />

        <LogoNew />

        <View
          style={{
            flex: 1,
            marginTop: 24,
          }}
        >
          <Text1
            style={{
              color: Colors.pinkySwear,
              fontSize: 44,
              lineHeight: 44,
              textAlign: "center",
            }}
          >
            Want to
            <Text2> start</Text2>
          </Text1>

          <Text2
            style={{
              color: Colors.pinkySwear,
              fontSize: 44,
              lineHeight: 44,
              textAlign: "center",
            }}
          >
            creating
            <Text1> your</Text1>
          </Text2>

          <Text1
            style={{
              color: Colors.pinkySwear,
              fontSize: 44,
              lineHeight: 44,
              textAlign: "center",
            }}
          >
            own guides?
          </Text1>
        </View>

        <View style={{ alignItems: "center", paddingHorizontal: 24, paddingBottom: 12 }}>
          <ButtonView
            primary
            ref={generateTestHook("Apply.BecomeCreator")}
            style={{ width: 260, marginBottom: 12 }}
            onPress={() => navigateToApplication(navigation, {})}
          >
            Apply to become a creator
          </ButtonView>
        </View>
      </View>
    );
  };

  return renderHeader();
}

export default observer(ApplyScreen);
