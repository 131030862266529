import client from "../client";
import {
  PAYMENT_CONNECT,
  PAYMENT_M_CHECKOUT,
  PAYMENT_PAYOUTS,
  PAYMENT_REPORT,
  PAYMENT_STATS,
} from "./graphql";

function connect() {
  return client
    .mutate({
      variables: {},
      mutation: PAYMENT_CONNECT,
    })
    .then((response) => response.connect);
}

function mCheckout({ userId, guideId, name, username, phone }) {
  return client
    .mutate({
      variables: { userId, guideId, name, username, phone },
      mutation: PAYMENT_M_CHECKOUT,
    })
    .then((response) => response.mCheckout);
}

function report({ year, month, userId }) {
  return client
    .query({
      variables: {
        userId,
        year,
        month,
      },
      query: PAYMENT_REPORT,
    })
    .then((response) => response.report);
}

function paymentStats({ year, month, userId }) {
  return client
    .query({
      variables: {
        userId,
        year,
        month,
      },
      query: PAYMENT_STATS,
    })
    .then((response) => response.stats);
}

function paymentPayouts({ year, month, userId }) {
  return client
    .query({
      variables: {
        userId,
        year,
        month,
      },
      query: PAYMENT_PAYOUTS,
    })
    .then((response) => response.payouts);
}

export default {
  report,
  connect,
  mCheckout,
  paymentStats,
  paymentPayouts,
};
