import { useQuery } from "react-query";
import Queries from "../../constants/Queries";
import Api from "../Api";

export function useThemes() {
  return useQuery(Queries.THEMES, () => Api.theme.themes());
}

export function useThemeOptions() {
  const { isLoading, data } = useThemes();

  return {
    isLoading,
    options: (data || []).map(({ name, description }) => ({ title: name, text: description })),
  };
}
