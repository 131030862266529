import client from "../client";
import {
  GUIDE_ADD_PLACE,
  GUIDE_BUY,
  GUIDE_CREATE,
  GUIDE_DELETE,
  GUIDE_GET,
  GUIDE_LIKE,
  GUIDE_REMOVE_PLACE,
  GUIDE_UNLIKE,
  GUIDE_UPDATE,
  GUIDE_VIEWED,
  LIKED_GUIDES,
  PENDING_GUIDES,
  POPULAR_GUIDES,
  PUBLISHED_GUIDES,
  PURCHASED_GUIDES,
  SEARCH_GUIDES,
  USER_GUIDES,
} from "./graphql";

function guideGet(id) {
  return client
    .query({
      query: GUIDE_GET,
      variables: {
        id,
      },
    })
    .then((response) => response.guide);
}

function popularGuides(skip, limit) {
  return client
    .query({
      query: POPULAR_GUIDES,
      variables: {
        skip,
        limit,
      },
    })
    .then((response) => response.popularGuides);
}

function publishedGuides(skip, limit) {
  return client
    .query({
      query: PUBLISHED_GUIDES,
      variables: {
        skip,
        limit,
      },
    })
    .then((response) => response.publishedGuides);
}

function pendingGuides(skip, limit) {
  return client
    .query({
      query: PENDING_GUIDES,
      variables: {
        skip,
        limit,
      },
    })
    .then((response) => response.pendingGuides);
}

function userGuides(userId, skip, limit) {
  return client
    .query({
      query: USER_GUIDES,
      variables: {
        skip,
        limit,
        userId,
      },
    })
    .then((response) => response.userGuides);
}

function likedGuides(skip, limit) {
  return client
    .query({
      query: LIKED_GUIDES,
      variables: {
        skip,
        limit,
      },
    })
    .then((response) => response.likedGuides);
}

function purchasedGuides(skip, limit, iCloudUserId) {
  return client
    .query({
      query: PURCHASED_GUIDES,
      variables: {
        skip,
        limit,
        iCloudUserId,
      },
    })
    .then((response) => response.purchasedGuides);
}

function searchGuides(keywords, location, skip, limit) {
  return client
    .query({
      query: SEARCH_GUIDES,
      variables: {
        skip,
        limit,
        keywords,
        location,
      },
    })
    .then((response) => response.searchGuides);
}

function guideBuy(id, { orderId, appleReceipt, googleReceipt, iCloudUserId }) {
  return client
    .mutate({
      variables: {
        id,
        orderId,
        appleReceipt,
        googleReceipt,
        iCloudUserId,
      },
      mutation: GUIDE_BUY,
    })
    .then((response) => response.guideBuy);
}

function guideLike(id) {
  return client
    .mutate({
      variables: {
        id,
      },
      mutation: GUIDE_LIKE,
    })
    .then((response) => response.guideLike);
}

function guideUnlike(id) {
  return client
    .mutate({
      variables: {
        id,
      },
      mutation: GUIDE_UNLIKE,
    })
    .then((response) => response.guideUnlike);
}

function guideViewed(id) {
  return client
    .mutate({
      variables: {
        id,
      },
      mutation: GUIDE_VIEWED,
    })
    .then((response) => response.guideViewed);
}

function guideDelete(id) {
  return client
    .mutate({
      variables: {
        id,
      },
      mutation: GUIDE_DELETE,
    })
    .then((response) => response.guideDelete);
}

function guideCreate({ name, media, gPlaceId, address, location, description }) {
  return client
    .mutate({
      variables: {
        name,
        media,
        address,
        gPlaceId,
        location,
        description,
      },
      mutation: GUIDE_CREATE,
    })
    .then((response) => response.guideCreate);
}

function guideUpdate(
  id,
  {
    name,
    audio,
    media,
    status,
    isFree,
    places,
    address,
    gPlaceId,
    location,
    adjustment,
    description,
  }
) {
  return client
    .mutate({
      variables: {
        id,
        name,
        audio,
        media,
        status,
        isFree,
        places,
        address,
        gPlaceId,
        location,
        adjustment,
        description,
      },
      mutation: GUIDE_UPDATE,
    })
    .then((response) => response.guideUpdate);
}

function guideAddPlace(placeId, guideId) {
  return client
    .mutate({
      variables: {
        placeId,
        guideId,
      },
      mutation: GUIDE_ADD_PLACE,
    })
    .then((response) => response.guideAddPlace);
}

function guideRemovePlace(placeId, guideId) {
  return client
    .mutate({
      variables: {
        placeId,
        guideId,
      },
      mutation: GUIDE_REMOVE_PLACE,
    })
    .then((response) => response.guideRemovePlace);
}

export default {
  guideGet,
  guideBuy,
  guideLike,
  guideUnlike,
  guideViewed,
  guideDelete,
  guideCreate,
  guideUpdate,
  guideAddPlace,
  guideRemovePlace,
  userGuides,
  likedGuides,
  searchGuides,
  popularGuides,
  pendingGuides,
  publishedGuides,
  purchasedGuides,
};
