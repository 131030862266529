import { observer } from "mobx-react";
import React from "react";
import PageHeader from "../../components/PageHeader";
import { Container, Content } from "../../components/ui";
import InsightHeaderView, { INSIGHT_TABS } from "./ui/InsightHeaderView";
import PayoutTabView from "./ui/PayoutTabView";
import StatTabView from "./ui/StatTabView";

export default observer(function InsightScreen() {
  const [tab, setTab] = React.useState(INSIGHT_TABS.STATS);

  return (
    <Container>
      <PageHeader title="Insights" />

      <Content>
        <InsightHeaderView onTab={setTab} />
        {tab === INSIGHT_TABS.STATS ? <StatTabView /> : <PayoutTabView />}
      </Content>
    </Container>
  );
});
