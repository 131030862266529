/* eslint-disable no-undef */
import React from "react";
import { Animated, StyleSheet, View } from "react-native";
import store from "../../app/store/Store";
import { Container, Content, Header } from "../../components/ui";
import ErrorView from "../../components/ui/ErrorView";
import GIcon from "../../components/ui/GIcon";
import LoadingView from "../../components/ui/LoadingView";
import PlaceView from "../place/ui/PlaceView";
import { useGuide } from "../../app/api/guide/guideHooks";
import { useUser } from "../../app/api/user/userHooks";
import Cavy from "../../app/test/Cavy";
import { isWeb, ScreenSize } from "../../app/util/util";
import Colors from "../../components/Colors";
import Api from "../../app/api/Api";
import Errors from "../../app/util/Errors";
import GetTheAppView from "./GuideScreen/GetTheAppView";
import { usePlayerStore } from "../../app/store/PlayerStore";

const HeaderAction = Cavy.wrap(Header.Action);

/*
const IconButton = Cavy.wrap(({ style, onPress }) => {
  return (
    <TouchableOpacity
      activeOpacity={0.9}
      style={{
        height: 48,
        minWidth: 48,
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        ...style,
      }}
      {...{ onPress }}
    >
      <View
        style={{
          width: 24,
          height: 24,
          borderRadius: 12,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#00000080",
        }}
      >
        <GIcon.Close
          style={{
            fontSize: 18,
            color: "#fff",
          }}
        />
      </View>
    </TouchableOpacity>
  );
});
*/

// eslint-disable-next-line import/no-unused-modules
export default function GuidePlacesScreen({ route, navigation }) {
  const { guideId, placeId } = route.params;
  const [togglingLike, setTogglingLike] = React.useState(false);
  const [selectedPageIndex, setSelectedPageIndex] = React.useState(0);
  const generateTestHook = Cavy.useCavy();
  const player = usePlayerStore();

  const userId = store.getUserId();
  const { data: user } = useUser(userId);

  const {
    error,
    isLoading,
    data: { places, ...guide },
  } = useGuide(guideId);

  React.useEffect(() => {
    if (guide) {
      document.getElementById(placeId).scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  }, [guide, placeId]);

  const scrollOffsetAnimatedValue = React.useRef(new Animated.Value(0)).current;
  const positionAnimatedValue = React.useRef(new Animated.Value(0)).current;

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("beforeRemove", () => {
      player.stop();
    });
    return unsubscribe;
  }, [navigation, player]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (error) {
    return <ErrorView error="Something went wrong, try again!" />;
  }

  const selectedIndex = (places || []).findIndex((place) => place.id === placeId);

  const isUnlocked = store.isUnlocked(guide);

  const items = isUnlocked ? places : [places[0]];
  const selectedPlace = items[selectedPageIndex];

  const inputRange = [0, items.length];
  const scrollX = Animated.add(scrollOffsetAnimatedValue, positionAnimatedValue).interpolate({
    inputRange,
    outputRange: [0, items.length * ScreenSize.width],
  });

  function onLike() {
    setTogglingLike(true);
    Api.place
      .placeLike(selectedPlace.id, guideId)
      .catch(Errors.showErrorMessage)
      .finally(() => setTimeout(() => setTogglingLike(false), 500));
  }

  function onUnlike() {
    setTogglingLike(true);
    Api.place
      .placeUnlike(selectedPlace.id)
      .catch(Errors.showErrorMessage)
      .finally(() => setTimeout(() => setTogglingLike(false), 500));
  }

  const isLiked = user && (user.likedPlaces || []).find((id) => id === selectedPlace.id);

  return (
    <Container>
      <Content
        style={{ backgroundColor: Colors.lynxWhite, paddingTop: 56 }}
        showsVerticalScrollIndicator={false}
      >
        <div style={{ marginBottom: 100 }}>
          {items.map((place, index) => {
            return (
              <div id={place.id} key={place.id}>
                <View style={styles.content}>
                  <PlaceView
                    {...{ index, place, user, guideId }}
                    isVisible={index === selectedIndex}
                  />
                </View>
              </div>
            );
          })}

          <GetTheAppView style={{ marginTop: 24 }} />
        </div>
      </Content>

      <View
        style={{
          left: 0,
          right: 0,
          paddingBottom: 8,
          position: "absolute",
          backgroundColor: Colors.transparent,
        }}
      >
        <Header
          onBack={() => {
            navigation.goBack();
            player.stop();
          }}
          useCloseIconForBack
          circleBack
          transparent
          style={{ paddingTop: 16, paddingBottom: 16 }}
          actions={
            !isWeb ? (
              <HeaderAction
                loading={togglingLike}
                onPress={isLiked ? onUnlike : onLike}
                color={isLiked ? Colors.primary : Colors.black}
                Icon={isLiked ? GIcon.Bookmark : GIcon.BookmarkOutline}
                ref={generateTestHook("Profile.Bookmark")}
              />
            ) : null
          }
        />
      </View>
    </Container>
  );
}

const styles = StyleSheet.create({
  content: { flex: 1, marginBottom: 2 },
});
