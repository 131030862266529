import React from "react";
import {
  ActivityIndicator,
  KeyboardAvoidingView,
  Platform,
  Text,
  TouchableOpacity as RNTouchableOpacity,
  TouchableHighlight as RNTouchableHighlight,
  View,
} from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import Cavy from "../app/test/Cavy";
import { isAndroid, screenWidth } from "../app/util/util";
import Colors from "./Colors";
import GIcon from "./ui/GIcon";
import Verified from "./ui/Verified";

export const TouchableOpacity = Cavy.wrap(RNTouchableOpacity);
export const TouchableHighlight = Cavy.wrap(RNTouchableHighlight);

export const Text1 = ({ style, ...props }) => (
  <Text {...props} style={[style, { fontFamily: "RecoletaBold" }]} />
);

export const Text2 = ({ style, ...props }) => (
  <Text {...props} style={[style, { fontFamily: "OutfitMedium" }]} />
);

export const Text2L = ({ style, ...props }) => (
  <Text {...props} style={[style, { fontFamily: "OutfitLight" }]} />
);

export const Text2SB = ({ style, ...props }) => (
  <Text {...props} style={[style, { fontFamily: "OutfitSemiBold" }]} />
);

export const Text2B = ({ style, ...props }) => (
  <Text {...props} style={[style, { fontFamily: "OutfitBold" }]} />
);

export function Content(props) {
  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      keyboardShouldPersistTaps="handled"
      {...props}
    />
  );
}

export const Container = Cavy.wrap(({ style, noScroll, ...props }) => {
  if (noScroll) {
    return <View style={[{ flex: 1, backgroundColor: Colors.white }, style]} {...props} />;
  }
  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{ flex: 1 }}
    >
      <View style={[{ flex: 1, backgroundColor: Colors.white }, style]} {...props} />
    </KeyboardAvoidingView>
  );
});

const ToolBarButton = Cavy.wrap(
  ({ label, primary, outlined, color, loading, onPress, ...props }) => {
    return (
      <TouchableOpacity {...props} onPress={loading ? null : onPress} activeOpacity={0.9}>
        <View
          style={{
            height: 28,
            paddingLeft: 16,
            paddingRight: primary ? 16 : 0,
            marginRight: 20,
            borderRadius: 20,
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
            justifyContent: "center",
            backgroundColor: primary ? Colors.primary : Colors.transparent,
          }}
        >
          {loading ? (
            <ActivityIndicator
              style={{ marginRight: 4 }}
              size="small"
              color={color || (primary ? Colors.white : Colors.primary)}
            />
          ) : (
            <Text2 style={{ fontSize: 16, color: primary ? Colors.white : Colors.primary }}>
              {label}
            </Text2>
          )}
        </View>
      </TouchableOpacity>
    );
  }
);

const IconButton = Cavy.wrap(
  ({ Icon, circleBack, fontSize, loading, color, onPress, ...props }) => {
    return (
      <TouchableOpacity {...props} {...{ onPress }} activeOpacity={0.9}>
        <View
          style={{
            width: circleBack ? 30 : 32,
            height: circleBack ? 30 : 32,
            borderRadius: 20,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: circleBack ? Colors.semiTransparentBlack : undefined,
          }}
        >
          {loading ? (
            <ActivityIndicator color={color || Colors.primary} />
          ) : (
            <Icon
              style={{
                fontSize: (circleBack ? 20 : fontSize) || 24,
                color: (circleBack ? Colors.white : color) || Colors.black,
              }}
            />
          )}
        </View>
      </TouchableOpacity>
    );
  }
);

export function Header({
  style,
  circleBack,
  transparent,
  useCloseIconForBack,
  verified,
  onBack,
  onClose,
  Logo,
  title,
  color,
  left,
  right,
  actions,
  children,
}) {
  const generateTestHook = Cavy.useCavy();

  return (
    <View style={[{ paddingTop: isAndroid ? 42 : 52 }, style]}>
      <View
        style={{
          top: isAndroid ? 40 : 50,
          left: 0,
          right: 0,
          bottom: 0,
          position: "absolute",
        }}
      >
        {Logo ? <Logo /> : null}
      </View>
      <View
        style={{
          flexWrap: "wrap",
          alignItems: "center",
          flexDirection: "row",
          backgroundColor: transparent ? Colors.transparent : Colors.white,
        }}
      >
        {!onBack && !left && (onClose || right || actions) ? <View style={{ width: 48 }} /> : null}

        {onBack ? (
          <IconButton
            fontSize={circleBack ? 24 : 36}
            onPress={onBack}
            {...{ color, circleBack }}
            ref={generateTestHook("Header.Back")}
            style={circleBack ? { marginLeft: 16, borderWidth: 0 } : null}
            Icon={useCloseIconForBack ? GIcon.Close : GIcon.ArrowBack}
          />
        ) : null}
        {left ? (
          <ToolBarButton
            ref={generateTestHook("Header.LeftAction")}
            {...{ color }}
            primary={left.primary}
            onPress={left.onPress}
            label={left.label}
          />
        ) : null}
        {!Logo ? <View style={{ flex: 1 }} /> : null}
        {!Logo ? (
          <View
            style={{
              flex: 1,
              left: 48,
              right: 48,
              position: "absolute",
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
              width: screenWidth - 2 * 48,
            }}
          >
            <Text2B style={{ textAlign: "center", borderWidth: 0 }}>{title || ""}</Text2B>
            {false && verified && <Verified />}
          </View>
        ) : null}
        {Logo ? <View style={{ flex: 1 }} /> : null}

        {actions}
        {right ? (
          <ToolBarButton
            ref={generateTestHook("Header.RightAction")}
            {...{ color }}
            outlined={right.outlined}
            label={right.label}
            primary={right.primary}
            loading={right.loading}
            onPress={right.onPress}
            style={right.style}
          />
        ) : null}
        {onClose ? (
          <IconButton
            ref={generateTestHook("Header.Close")}
            {...{ color }}
            Icon={GIcon.Close}
            onPress={onClose}
          />
        ) : null}

        {!onClose && !right && !actions && (onBack || left) ? <View style={{ width: 48 }} /> : null}
      </View>

      {children}
    </View>
  );
}

export function HeaderContainer({ right, color, style, children, transparent }) {
  const generateTestHook = Cavy.useCavy();

  const { label, primary, loading, ...rightProps } = right;

  return (
    <View style={[{ paddingTop: isAndroid ? 42 : 52 }, style]}>
      <View
        style={{
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          backgroundColor: transparent ? Colors.transparent : Colors.white,
        }}
      >
        <View style={{ width: 80 }} />

        <View style={{ flex: 1 }}>{children}</View>

        <ToolBarButton
          {...rightProps}
          {...{ color, label, primary, loading }}
          ref={generateTestHook("Header.RightAction")}
        />
      </View>
    </View>
  );
}

Header.Action = IconButton;
