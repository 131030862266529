import React from "react";
import { Alert, Keyboard, View } from "react-native";
import Api from "../../app/api/Api";
import { navigateToUsername } from "../../app/navigation/Navigator";
import store from "../../app/store/Store";
import Errors from "../../app/util/Errors";
import Colors from "../../components/Colors";
import { Header1 } from "../../components/typography";
import { Container, Content, Header, Text2 } from "../../components/ui";
import { PasswordInputView } from "../../components/ui/inputs";

export default function PasswordScreen({ route, navigation }) {
  const { email, phone, userToken, reset } = route.params;

  const {
    user: { id },
    token,
  } = userToken;

  const [password, setPassword] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  function onResetPassword() {
    Keyboard.dismiss();
    setLoading(true);
    Api.user
      .userUpdate(id, { email, phone, password, token })
      .then((user) => {
        setLoading(false);
        store.setToken(token);
        store.setUser(user);
        navigation.navigate("Root");
      })
      .catch((e) => {
        setLoading(false);
        Errors.showErrorMessage(e);
      });
  }

  function onPassword() {
    if (password.length < 8) {
      Alert.alert("Password is less than 8 characters!");
      return;
    }

    if (reset) {
      onResetPassword();
      return;
    }

    navigateToUsername(navigation, { email, phone, userToken, password });
  }

  return (
    <Container>
      <Header
        transparent
        onBack={() => navigation.goBack(null)}
        right={{ label: "Next", onPress: onPassword, loading }}
      />

      <Content
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ flex: 1 }}
        style={{
          flex: 1,
          paddingBottom: 24,
          paddingHorizontal: 24,
          flexDirection: "column",
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
          }}
        >
          <Header1 style={{ textAlign: "center", color: Colors.black }}>Set a password</Header1>
          <View style={{ alignItems: "center", marginTop: 5, marginBottom: 20 }}>
            <Text2 style={{ color: "#555", textAlign: "center", lineHeight: 20 }}>
              Your password should be at least 8 characters
            </Text2>
          </View>
          <PasswordInputView value={password} style={{ height: 70 }} onChangeText={setPassword} />
        </View>
      </Content>
    </Container>
  );
}
