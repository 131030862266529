import { StatusBar } from "expo-status-bar";
import React from "react";
import { Keyboard, View } from "react-native";
import { useQuery } from "react-query";
import Api from "../../app/api/Api";
import Queries from "../../app/constants/Queries";
import store from "../../app/store/Store";
import Cavy from "../../app/test/Cavy";
import { isAndroid } from "../../app/util/util";
import Colors from "../../components/Colors";
import Status from "../../components/guide/Status";
import {
  Container,
  Content,
  Text1,
  Text2,
  Text2B,
  Text2L,
  Text2SB,
  TouchableOpacity,
} from "../../components/ui";
import ButtonView from "../../components/ui/ButtonView";
import GIcon from "../../components/ui/GIcon";
import { InputView } from "../../components/ui/inputs";

export default function CreatorReferScreen({ navigation }) {
  const userId = store.getUserId();
  const generateTestHook = Cavy.useCavy();

  const [loading, setLoading] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const [numberOfFollowers, setNumberOfFollowers] = React.useState("");
  const [email2, setEmail2] = React.useState("");
  const [numberOfFollowers2, setNumberOfFollowers2] = React.useState("");

  const { data: guides } = useQuery([Queries.USER_GUIDES, userId], () =>
    Api.guide.userGuides(userId, 0, 1000)
  );

  const unlockSecondInvitation = email2 && numberOfFollowers2 && true; // publishedGuides > 0 ? true : false;

  // TODO
  function onSendInvitation() {
    Keyboard.dismiss();
    setLoading(true);
    console.log(email, numberOfFollowers);
  }

  const header = (
    <View
      style={{
        backgroundColor: Colors.bleuFrance,
      }}
    >
      <View
        style={{
          height: 48,
          paddingHorizontal: 10,
          marginTop: isAndroid ? 42 : 52,
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <TouchableOpacity onPress={navigation.goBack} activeOpacity={0.9}>
          <Text2 style={{ color: Colors.white }}>Cancel</Text2>
        </TouchableOpacity>

        <Text2B
          style={{
            textAlign: "center",
            color: Colors.white,
          }}
        >
          Refer a creator
        </Text2B>

        <TouchableOpacity onPress={() => console.log("Done")} activeOpacity={0.9}>
          <Text2 style={{ color: Colors.white }}>Done</Text2>
        </TouchableOpacity>
      </View>
    </View>
  );

  return (
    <Container>
      <StatusBar hidden />
      {header}

      <Content>
        <View
          style={{
            backgroundColor: Colors.bleuFrance,
          }}
        >
          <View style={{ marginTop: 24, minHeight: 120 }}>
            <Text1
              style={{
                textAlign: "center",
                color: Colors.secondary,
                marginBottom: 16,
                lineHeight: 28,
                fontSize: 24,
              }}
            >
              Invite a friend with {"\n"}
              <Text2SB>20k+ followers, </Text2SB>
              get
              <Text2SB> $15. </Text2SB>
            </Text1>

            <Text2SB
              style={{
                textAlign: "center",
                color: Colors.secondary,
                marginBottom: 16,
                lineHeight: 24,
                fontSize: 16,
              }}
            >
              $5
              <Text2L> when they sign up {"\n"}</Text2L>
              $10
              <Text2L> when they publish a guide.</Text2L>
            </Text2SB>

            <Text2L
              style={{
                textAlign: "center",
                color: Colors.secondary,
                marginBottom: 16,
                fontSize: 13,
              }}
            >
              Referral bonuses are added to your monthly {"\n"}
              earnings. Your invitee must have a minimum of {"\n"}
              20.000 folowers for your bonus to be approved.
            </Text2L>

            <View
              style={{
                height: 24,
              }}
            ></View>
          </View>
        </View>

        <View
          style={{
            padding: 24,
          }}
        >
          <Text2
            style={{
              fontSize: 16,
            }}
          >
            1st invite
          </Text2>

          <InputView
            testId="Referral.Email"
            marginTop={8}
            value={email}
            autoCorrect={false}
            onChangeText={setEmail}
            placeholder="Your referrals email address"
            Icon={() => <GIcon.Mail style={{ fontSize: 20 }} />}
          />

          <InputView
            testId="Referral.Followers"
            marginTop={8}
            value={numberOfFollowers}
            autoCorrect={false}
            onChangeText={setNumberOfFollowers}
            placeholder="How many followers do they have?"
            Icon={() => <GIcon.AccountCheck style={{ fontSize: 20 }} />}
          />

          <ButtonView
            primary
            {...{ loading }}
            onPress={onSendInvitation}
            style={{ marginTop: 24 }}
            disabled={!email && !numberOfFollowers}
            ref={generateTestHook("Referral.Invite")}
          >
            Send invitation
          </ButtonView>
        </View>

        <View
          style={{
            padding: 24,
          }}
        >
          <Text2
            style={{
              fontSize: 16,
            }}
          >
            2nd invite <Text2L>- Publish a guide to unlock!</Text2L>
          </Text2>

          <InputView
            testId="Referral.Email"
            marginTop={8}
            value={email2}
            autoCorrect={false}
            onChangeText={setEmail2}
            placeholder="Your referrals email address"
            Icon={() => <GIcon.Mail style={{ fontSize: 20 }} />}
          />

          <InputView
            testId="Referral.Followers"
            marginTop={8}
            value={numberOfFollowers2}
            autoCorrect={false}
            onChangeText={setNumberOfFollowers2}
            placeholder="How many followers do they have?"
            Icon={() => <GIcon.AccountCheck style={{ fontSize: 20 }} />}
          />

          <ButtonView
            {...{ loading }}
            darkGray={!unlockSecondInvitation}
            primary={unlockSecondInvitation}
            onPress={unlockSecondInvitation ? onSendInvitation : null}
            style={{ marginTop: 24 }}
            disabled={!unlockSecondInvitation}
            ref={generateTestHook("Referral.Invite")}
          >
            {unlockSecondInvitation ? "Send invitation" : "Publish a guide to send your 2nd invite"}
          </ButtonView>
        </View>
      </Content>
    </Container>
  );
}
