import { gql } from "graphql-request";
import { QUESTION_MODEL } from "../models";

export const QUERY_QUESTIONS = gql`
  query questions {
    questions {
     ${QUESTION_MODEL}
    }
  }
`;

export const QUESTION_CREATE = gql`
  mutation questionCreate(
    $text: String
    $description: String
  ) {
    questionCreate(
      text: $text
      description: $description
    ) {
      ${QUESTION_MODEL}
    }
  }
`;

export const QUESTION_UPDATE = gql`
  mutation questionUpdate(
    $id: String
    $text: String
    $description: String
  ) {
    questionUpdate(
      id: $id
      text: $text
      description: $description
    ) {
      ${QUESTION_MODEL}
    }
  }
`;

export const QUESTION_DELETE = gql`
  mutation questionDelete(
    $id: String
  ) {
    questionDelete(
      id: $id
    ) {
      ${QUESTION_MODEL}
    }
  }
`;
