import React from "react";
import { View } from "react-native";
import Cavy from "../../../app/test/Cavy";
import { screenWidth } from "../../../app/util/util";
import AddContainerView from "../../../components/AddContainerView";
import ClosableView from "../../../components/ClosableView";
import Colors from "../../../components/Colors";
import { Border } from "../../../components/Theme";
import { Text2 } from "../../../components/ui";
import GIcon from "../../../components/ui/GIcon";
import ImageView from "../../../components/ui/ImageView";

const size = Math.floor((screenWidth - 50) / 3);

const EmptyPlaceCellView = Cavy.wrap(({ index, style, onCreate }) => {
  return (
    <View>
      <AddContainerView
        key={index}
        onPress={onCreate}
        style={[{ width: size, height: size }, style]}
      >
        <View style={{ width: size, height: size, alignItems: "center", justifyContent: "center" }}>
          <GIcon.MapMarkerOutline style={{ color: Colors.gray, fontSize: 32 }} />
        </View>
      </AddContainerView>
      <Text2 style={{ fontSize: 12, paddingLeft: 2, color: Colors.gray }}>
        {`${index + 1}. Add place`}
      </Text2>
    </View>
  );
});

const PlaceCellView = Cavy.wrap(({ index, place, style, locked, showName, onClose, onPress }) => {
  if (!place) {
    return <EmptyPlaceCellView {...{ index, style, onCreate: onPress }} />;
  }
  let url = "";

  if (place && place.media) {
    url = place.media.url;
  } else if (place && place.medias && place.medias.length > 0) {
    url = place.medias[0].url;
    if (url.includes("mp4")) {
      place.medias
        .filter((media) => media.type === "video")
        .map((media) => (url = media.thumbnailUrl));
    }
  }

  return (
    <ClosableView style={[{ width: size, height: size }, style]} {...{ onClose }}>
      {url ? (
        <ImageView
          width={size}
          height={size}
          uri={url}
          style={{
            width: size,
            borderRadius: 10,
          }}
          blurRadius={locked ? 4 : 0}
        />
      ) : (
        <View
          style={{
            width: size,
            height: size,
            borderWidth: 1,
            borderRadius: 10,
            alignItems: "center",
            borderStyle: "dashed",
            justifyContent: "center",
            backgroundColor: Colors.white,
            borderColor: Border.dashedColor,
          }}
        >
          <GIcon.ImageOutline style={{ fontSize: 36, color: Colors.middleGray }} />
        </View>
      )}
      {showName ? (
        <Text2
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{ fontSize: 13, paddingLeft: 2, color: Colors.black }}
        >
          {`${index + 1}. `}
          {locked ? "Place" : place.name}
        </Text2>
      ) : null}

      <View
        style={{
          width: size,
          height: size,
          borderRadius: 10,
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {locked ? <GIcon.Lock style={{ color: Colors.white, fontSize: 32 }} /> : null}
      </View>
    </ClosableView>
  );
});

export default PlaceCellView;

export function PlaceGridView({ style, children }) {
  return (
    <View
      style={[
        {
          flex: 1,
          flexWrap: "wrap",
          flexDirection: "row",
        },
        style,
      ]}
    >
      {children}
    </View>
  );
}
