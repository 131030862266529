/* eslint-disable import/no-named-as-default-member */
import { observer } from "mobx-react";
import React from "react";
import { Share } from "react-native";
import { useQuery } from "react-query";
import Api from "../../app/api/Api";
import { useUser } from "../../app/api/user/userHooks";
import Queries from "../../app/constants/Queries";
import {
  navigateToEditProfile,
  navigateToGuide,
  navigateToLikedPlaces,
  navigateToSettings,
} from "../../app/navigation/Navigator";
import store, { isCreator } from "../../app/store/Store";
import Cavy from "../../app/test/Cavy";
import Errors from "../../app/util/Errors";
import GuideGridView from "../../components/guide/GuideGridView";
import Status from "../../components/guide/Status";
import { Container, Header } from "../../components/ui";
import EmptyView from "../../components/ui/EmptyView";
import GIcon from "../../components/ui/GIcon";
import LoadingView from "../../components/ui/LoadingView";
import GuidesMap from "./ui/GuidesMap";
import ProfileHeaderView, { PROFILE_TABS } from "./ui/ProfileHeaderView";

const HeaderAction = Cavy.wrap(Header.Action);

function ProfileScreen({ route, navigation }) {
  const isProfileTab = route.name === "profiletab";
  const userId = isProfileTab ? store.getUserId() : route.params.userId;

  const generateTestHook = Cavy.useCavy();
  const [togglingFollow, setTogglingFollow] = React.useState(false);

  const isLoggedIn = store.getUserId();
  const isOwnProfile = store.getUserId() === userId;

  const isLoggedInAsCreator = store.isCreator();

  const [tab, setTab] = React.useState(
    isOwnProfile && !isLoggedInAsCreator ? PROFILE_TABS.PURCHASED_GUIDES : PROFILE_TABS.MY_GUIDES
  );

  const { data: user } = useUser(userId);

  const { data: guides } = useQuery([Queries.USER_GUIDES, userId], () =>
    Api.guide.userGuides(userId, 0, 1000)
  );

  const likedGuides = useQuery(Queries.LIKED_GUIDES, () => Api.guide.likedGuides(0, 1000));

  const purchasedGuides = useQuery(Queries.PURCHASED_GUIDES, () =>
    Api.guide.purchasedGuides(0, 1000)
  );

  if (!user) {
    return <LoadingView />;
  }

  let items = [];
  let Icon = null;
  let message = null;
  let onAction = null;
  let actionText = null;

  const isMapTab = tab === PROFILE_TABS.GUIDES_MAP;

  if (tab === PROFILE_TABS.MY_GUIDES) {
    items = (guides || []).filter(
      ({ status }) => status === Status.PENDING || status === Status.PUBLISHED
    );

    Icon = GIcon.Book;
    if (isOwnProfile) {
      actionText = "Make your first guide now";
      onAction = () => navigation.navigate("gtab");
      message = "You haven't created\nany guides yet!";
    } else {
      message = "This user hasn’t created any guides yet!";
    }
  } else if (tab === PROFILE_TABS.GUIDES_MAP) {
    Icon = GIcon.Map;
    if (isOwnProfile) {
      actionText = "Make your first guide now";
      onAction = () => navigation.navigate("gtab");
      message = "You haven't published\nany guides to show on the map yet!";
    } else {
      message = "This user hasn’t created any guides yet!";
    }
    items = (guides || []).filter(
      ({ status }) => status === Status.PENDING || status === Status.PUBLISHED
    );
  } else if (tab === PROFILE_TABS.PURCHASED_GUIDES) {
    Icon = GIcon.Book;
    items = purchasedGuides.data;
    message = "You haven't purchased\nany guide yet!";
  } else if (tab === PROFILE_TABS.LIKED_GUIDES) {
    Icon = GIcon.Heart;
    items = likedGuides.data;
    message = "You haven't liked any guides\nor places yet!";
  }

  items = items || [];

  const nrGuides = (items || []).length;

  const isFollowing = store.isFollowing(userId);

  const emptyState =
    items && items.length === 0 ? (
      <EmptyView onPress={onAction} {...{ Icon, message, actionText }} style={{ minHeight: 400 }} />
    ) : null;

  const isViewingCreator = isCreator(user);

  function onToggleFollow() {
    setTogglingFollow(true);

    const promise = isFollowing ? Api.user.userUnfollow(userId) : Api.user.userFollow(userId);

    promise
      .then(async () => store.setUser(await Api.user.me()))
      .catch(Errors.showGenericMessage)
      .finally(() => setTogglingFollow(false));
  }

  async function onShowSharingOptions() {
    try {
      Share.share({
        title: user.username,
        subject: user.username,
        message: `guidepal.com/${user.username}`,
        excludedActivityTypes: [],
      });
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <Container>
      {isOwnProfile ? (
        <Header
          title={user.username}
          verified={isViewingCreator}
          actions={[
            user.role === "creator" || "admin" ? (
              <HeaderAction
                Icon={GIcon.ShareOutline}
                ref={generateTestHook("Profile.Share")}
                onPress={() => onShowSharingOptions()}
              />
            ) : null,
            <HeaderAction
              key="profile.settings"
              Icon={GIcon.Settings}
              ref={generateTestHook("Profile.Settings")}
              onPress={() => navigateToSettings(navigation)}
            />,
          ]}
        />
      ) : (
        <Header
          title={user.username}
          verified={isViewingCreator}
          onBack={() => navigation.goBack(null)}
          actions={
            isLoggedIn
              ? [
                  user.role === "creator" || "admin" ? (
                    <HeaderAction
                      Icon={GIcon.ShareOutline}
                      ref={generateTestHook("Profile.Share")}
                      onPress={() => onShowSharingOptions()}
                    />
                  ) : null,
                  <HeaderAction
                    Icon={isFollowing ? GIcon.NotificationOn : GIcon.NotificationOff}
                    ref={generateTestHook("Profile.Notification")}
                    loading={togglingFollow}
                    onPress={onToggleFollow}
                    key="profile.notification"
                  />,
                ]
              : null
          }
        />
      )}

      <GuideGridView
        showBadges
        ListHeaderComponent={
          <ProfileHeaderView
            onTab={setTab}
            {...{ user, tab }}
            showFollowButton={isLoggedIn && !isProfileTab}
            showLikedGuidesTitle={(likedGuides.data || []).length > 0}
            onLikedPlaces={() => navigateToLikedPlaces(navigation, {})}
            onAvatarPress={() => (isLoggedIn === user.id ? navigateToEditProfile(navigation) : {})}
          />
        }
        ListFooterComponent={
          isMapTab && nrGuides > 0 ? (
            <GuidesMap
              guides={items || []}
              onGuide={(guide) => navigateToGuide(navigation, { guideId: guide.id })}
              style={{ marginLeft: 16, marginTop: 0, paddingTop: 8, paddingBottom: 50 }}
            />
          ) : (
            emptyState
          )
        }
        guides={isMapTab ? [] : items}
        onGuide={(guide) => navigateToGuide(navigation, { guideId: guide.id })}
      />
    </Container>
  );
}

export default observer(ProfileScreen);
