export default {
  gray: "#CCCCCC",
  black: "#000000",
  white: "#FFFFFF",
  steam: "#dddddd",
  coral: "#FA4B01",
  carbon: "#333333",
  crystal: "#efefef",
  primary: "#938ADD",
  magenta: "#CB2AE7",
  charcoal: "#232323",
  blackOut: "#222222",
  snowBank: "#e9e9e9",
  darkGray: "#A5A5A5",
  secondary: "#CFFF65",
  lightBlue: "#3478F6",
  lynxWhite: "#f7f7f7",
  lightGray: "#F0F0F0",
  discoBall: "#d4d4d4",
  palladium: "#b0b0b0",
  rainyGray: "#A5A5A5",
  middleGray: "#d8d8d8",
  titanWhite: "#e4e4e4",
  pixelWhite: "#DBDBDB",
  tinSoldier: "#bebebe",
  dhusarGray: "#aaaaaa",
  basaltGray: "#999999",
  taupeGray: "#888888",
  darkerGray: "#555555",
  buttonBlue: "#1DA1F2",
  flickrBlue: "#1b74e4",
  bleuFrance: "#3B86E5",
  pinkySwear: "#F1B0EC",
  superSilver: "#eeeeee",
  chrisSilver: "#e0e0e0",
  primaryDark: "#685EBB",
  carminePink: "#EB4D3D",
  goldenYellow: "#FFE100",
  whiteAsHeaven: "#fefefe",
  transparent: "#00000000",
  semiTransparentBlack: "#00000080",
  semiTransparentGray: "#d8d8d880",
};
