import { gql } from "graphql-request";
import { PAYOUT_MODEL } from "../models";

export const PAYMENT_CONNECT = gql`
  mutation connect {
    connect
  }
`;

export const PAYMENT_M_CHECKOUT = gql`
  mutation mCheckout(
    $userId: String
    $guideId: String
    $name: String
    $phone: String
    $username: String
  ) {
    mCheckout(userId: $userId, guideId: $guideId, name: $name, phone: $phone, username: $username)
  }
`;

export const PAYMENT_STATS = gql`
  query stats($year: Int, $month: Int, $userId: String) {
    stats(year: $year, month: $month, userId: $userId) {
      count
      payouts{
       ${PAYOUT_MODEL}
      }
      sales{
        date
        count
      }
    }
  }
`;

export const PAYMENT_PAYOUTS = gql`
  query payouts($year: Int, $month: Int, $userId: String) {
    payouts(year: $year, month: $month, userId: $userId) {
     ${PAYOUT_MODEL}
    }
  }
`;

export const PAYMENT_REPORT = gql`
  query report($year: Int, $month: Int, $userId: String) {
    report(year: $year, month: $month, userId: $userId)
  }
`;
