import { useNavigation } from "@react-navigation/native";
import { observer } from "mobx-react";
import React from "react";
import { View } from "react-native";
import { useQuery } from "react-query";
import Api from "../../../app/api/Api";
import Queries from "../../../app/constants/Queries";
import { navigateToLikedPlacesPager } from "../../../app/navigation/Navigator";
import store, { isCreator } from "../../../app/store/Store";
import Cavy from "../../../app/test/Cavy";
import { isWeb } from "../../../app/util/util";
import Colors from "../../../components/Colors";
import { PageSubtitle } from "../../../components/typography";
import { Text2, Text2L } from "../../../components/ui";
import GIcon from "../../../components/ui/GIcon";
import { PlaceGridView } from "../../guide/ui/PlaceCellView";
import TouchablePlaceCellView, {
  ShowAllPlacesCellView,
} from "../../guide/ui/TouchablePlaceCellView";
import ProfileAvatarView from "./ProfileAvatarView";
import TabsView from "./TabsView";

export const PROFILE_TABS = {
  MY_GUIDES: "my-guides",
  GUIDES_MAP: "guides-map",
  LIKED_GUIDES: "liked-guides",
  PURCHASED_GUIDES: "purchased-guides",
};

const MAX_LIKED_PLACES = 6;

function ProfileHeaderView({
  user,
  tab,
  onTab,
  onLikedPlaces,
  onAvatarPress,
  showLikedGuidesTitle,
}) {
  const generateTestHook = Cavy.useCavy();
  const navigation = useNavigation();
  const [select, onSelect] = React.useState(tab);

  const ownProfile = user.id === store.getUserId();
  const creator = isCreator(user);

  const { isLoading, data: likedPlaces } = useQuery(Queries.LIKED_PLACES, () =>
    Api.place.likedPlaces(0, 1000)
  );

  const avatarUri = user.media ? user.media.url : undefined;

  const nrLikedPlaces = (likedPlaces || []).length;

  const places = (likedPlaces || []).slice(
    0,
    nrLikedPlaces === MAX_LIKED_PLACES ? MAX_LIKED_PLACES : MAX_LIKED_PLACES - 1
  );

  const openPlace = (placeId) => {
    navigateToLikedPlacesPager(navigation, { placeId });
  };

  return (
    <View style={{ paddingTop: 7 }}>
      <View
        style={{
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <ProfileAvatarView uri={avatarUri} onPress={onAvatarPress} />
      </View>

      <Text2 style={{ marginTop: 16, marginHorizontal: 16, textAlign: "center", color: "#000" }}>
        {user.name || user.username}
      </Text2>

      <Text2L
        style={{
          color: Colors.black,
          marginHorizontal: 16,
          marginBottom: 16,
          marginTop: 8,
          lineHeight: 18,
          textAlign: "center",
        }}
      >
        {user.bio}
      </Text2L>

      <TabsView
        tabs={[
          creator
            ? { id: PROFILE_TABS.MY_GUIDES, title: "My guides", Icon: GIcon.PersonOutline }
            : null,
          !isWeb && creator
            ? { id: PROFILE_TABS.GUIDES_MAP, title: "Map", Icon: GIcon.MapMarkerOutline }
            : null,
          ownProfile
            ? { id: PROFILE_TABS.PURCHASED_GUIDES, title: "Unlocked", Icon: GIcon.Unlocked }
            : null,
          ownProfile
            ? { id: PROFILE_TABS.LIKED_GUIDES, title: "Saved", Icon: GIcon.BookmarkOutline }
            : null,
        ].filter((t) => t)}
        {...{ select }}
        onSelect={(t) => {
          onTab(t);
          onSelect(t);
        }}
        style={{ marginTop: 1, marginHorizontal: 16, marginBottom: 6 }}
      />

      {select === PROFILE_TABS.LIKED_GUIDES && !isLoading && nrLikedPlaces > 0 ? (
        <View style={{ margin: 16 }}>
          <PageSubtitle style={{ marginTop: 8 }}>Saved places</PageSubtitle>
          <PlaceGridView style={{ paddingBottom: 0 }}>
            {(places || []).map((place, index) => (
              <TouchablePlaceCellView
                ref={generateTestHook(`Guide.LikedPlace${index}`)}
                showName
                hideIndex
                key={place.id}
                {...{ index, place }}
                style={{ borderWidth: 0 }}
                onPress={() => openPlace(place.id)}
              />
            ))}
            {nrLikedPlaces > MAX_LIKED_PLACES ? (
              <ShowAllPlacesCellView
                ref={generateTestHook("Guide.ShowAllLike")}
                onPress={onLikedPlaces}
              />
            ) : null}
          </PlaceGridView>
        </View>
      ) : null}

      {showLikedGuidesTitle && select === PROFILE_TABS.LIKED_GUIDES ? (
        <PageSubtitle style={{ marginTop: 8, marginHorizontal: 16 }}>Saved guides</PageSubtitle>
      ) : null}
    </View>
  );
}

export default observer(ProfileHeaderView);
