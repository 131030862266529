import React from "react";
import { Image, StyleSheet, TouchableOpacity, View } from "react-native";
import Colors from "./Colors";
import { time } from "./time";
import { Text2, Text2L, Text2SB } from "./ui";
import ImageView from "./ui/ImageView";

const fallback = "http://s3-eu-west-1.amazonaws.com/userimages.guidewall/empty.jpg";
const PLACEHOLDER = "http://s3.amazonaws.com/gajdavatar/dummyAvatar.jpg";

export default function EventView({
  avatarUrl,
  part0,
  part1,
  part2,
  eventDate,
  guideUrl,
  onPress,
  ...props
}) {
  return (
    <TouchableOpacity {...props} activeOpacity={0.9} style={styles.container} {...{ onPress }}>
      <View style={{ flexDirection: "row", paddingHorizontal: 16 }}>
        <Image
          style={{ width: 48, height: 48, borderRadius: 24 }}
          source={{ uri: avatarUrl || PLACEHOLDER }}
        />

        <View
          style={{
            flex: 1,
            flexWrap: "wrap",
            flexDirection: "row",
            alignItems: "center",
            alignContent: "center",
            paddingHorizontal: 12,
          }}
        >
          <Text2SB
            style={{
              fontSize: 14,
              lineHeight: 18,
            }}
          >
            {part0 ? `${part0} ` : null}
            <Text2L
              style={{
                fontSize: 14,
                lineHeight: 18,
              }}
            >
              {part1 ? `${part1} ` : null}
            </Text2L>

            {part2 || null}

            <Text2
              style={{
                fontSize: 14,
                lineHeight: 18,
                color: Colors.gray,
              }}
            >
              {` ${time(eventDate)}`}
            </Text2>
          </Text2SB>
        </View>

        <ImageView
          width={56}
          height={56}
          testID="image-view"
          style={{ width: 56, borderRadius: 10 }}
          uri={guideUrl || fallback}
        />
      </View>
    </TouchableOpacity>
  );
}

const styles = StyleSheet.create({
  container: {
    marginBottom: 16,
    backgroundColor: Colors.transparent,
  },
});
