import React from "react";
import { ActivityIndicator, FlatList, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import moment from "moment-timezone";
import * as FileSystem from "expo-file-system";
import store from "../../app/store/Store";
import Colors from "../../components/Colors";
import { Container, Header, Text2 } from "../../components/ui";
import Api from "../../app/api/Api";
import { navigateToPdfViewer } from "../../app/navigation/Navigator";

export default function MonthlyReportsScreen({ navigation }) {
  const userId = store.getUserId();
  const [months, setMonths] = React.useState([]);
  const [loading, setLoading] = React.useState(null);

  React.useEffect(() => {
    const items = [];
    for (let i = 1; i < 12; i++) {
      const item = moment().subtract(i, "month");
      if (item.year() > 2021) {
        items.push(item);
      }
    }
    setMonths(items);
  }, []);

  function renderItem(date) {
    const row = date.format("MMMM YY");

    const isLoading = loading === row;

    return (
      <TouchableOpacity key={row} onPress={() => onReport(date)} disabled={isLoading}>
        <View
          style={{
            flexDirection: "row",
            padding: 16,
            marginLeft: 16,
            marginRight: 16,
            borderBottomWidth: 1,
            borderBottomColor: Colors.lightGray,
          }}
        >
          <Text2 style={{ flex: 1, fontSize: 16 }}>{row}</Text2>
          {isLoading ? <ActivityIndicator /> : null}
        </View>
      </TouchableOpacity>
    );
  }

  const renderEmptyList = () => (
    <Text2 style={{ textAlign: "center", color: Colors.darkGray, marginTop: 16 }}>
      There are no reports to display!
    </Text2>
  );

  function onReport(date) {
    console.log(date.year(), date.month());
    const title = date.format("MMMM YY");
    setLoading(title);
    const year = date.year();
    const month = date.month() + 1;
    Api.payment
      .report({ year, month })
      .then(async (base64) => {
        const filename = `${FileSystem.cacheDirectory}title.pdf`;
        await FileSystem.writeAsStringAsync(filename, base64.split("base64,")[1], {
          encoding: FileSystem.EncodingType.Base64,
        });

        navigateToPdfViewer(navigation, { url: filename, title });
      })
      .catch((error) => console.log({ error }))
      .finally(() => setLoading(null));
  }

  return (
    <Container>
      <Header title="Monthly Reports" onBack={() => navigation.goBack()} />

      <FlatList
        ListHeaderComponent={
          <View
            style={{
              padding: 4,
              marginLeft: 16,
              marginRight: 16,
              borderBottomWidth: 1,
              borderBottomColor: Colors.gray,
            }}
          />
        }
        data={months}
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
        ListEmptyComponent={renderEmptyList()}
        renderItem={({ item, index }) => renderItem(item, index)}
      />
    </Container>
  );
}
