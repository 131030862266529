import * as Updates from "expo-updates";
import { observer } from "mobx-react";
import React from "react";
import { View } from "react-native";
import { useQuery } from "react-query";
import Api from "../../app/api/Api";
import Queries from "../../app/constants/Queries";
import { navigateToGuide, navigateToSearch } from "../../app/navigation/Navigator";
import Cavy from "../../app/test/Cavy";
import Errors from "../../app/util/Errors";
import Colors from "../../components/Colors";
import GuideGridView from "../../components/guide/GuideGridView";
import { Body2 } from "../../components/typography";
import { Container } from "../../components/ui";
import ButtonView from "../../components/ui/ButtonView";
import LoadingView from "../../components/ui/LoadingView";
import HomeHeader from "./ui/HomeHeader";

export default observer(({ navigation }) => {
  const generateTestHook = Cavy.useCavy();
  const [downloading, setDownloading] = React.useState(false);
  const [updateAvailable, setUpdateAvailable] = React.useState(false);

  const { isLoading, data: guides } = useQuery(Queries.POPULAR_GUIDES, () =>
    Api.guide.popularGuides(0, 50)
  );

  React.useEffect(() => {
    Updates.checkForUpdateAsync()
      .then(({ isAvailable }) => setUpdateAvailable(isAvailable))
      .catch(Errors.log);
  }, []);

  function onDownload() {
    setDownloading(true);
    Updates.fetchUpdateAsync()
      .then(({ isNew }) => {
        if (isNew) {
          Updates.reloadAsync().catch(() => console.log("Failed to reload app!"));
        }
      })
      .catch((e) => {
        Errors.log(e);
        setUpdateAvailable(false);
      })
      .finally(() => setDownloading(false));
  }

  if (isLoading) {
    return <LoadingView />;
  }

  return (
    <Container ref={generateTestHook(guides ? "data.loaded" : "data.not.loaded")}>
      {updateAvailable ? (
        <View style={{ backgroundColor: Colors.white, paddingTop: 32, height: 130 }}>
          <Body2 style={{ padding: 16, textAlign: "center" }}>Update available</Body2>
          <View style={{ flexDirection: "row", justifyContent: "center" }}>
            <ButtonView
              small
              primary
              outline
              style={{ width: 140, marginRight: 8 }}
              onPress={() => setUpdateAvailable(false)}
            >
              Cancel
            </ButtonView>

            <ButtonView
              loading={downloading}
              small
              primary
              style={{ width: 140, marginLeft: 8 }}
              onPress={onDownload}
            >
              Download
            </ButtonView>
          </View>
        </View>
      ) : null}

      <GuideGridView
        {...{ guides }}
        showBadges
        onGuide={(guide) => navigateToGuide(navigation, { guideId: guide.id })}
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={<HomeHeader onSearch={() => navigateToSearch(navigation, {})} />}
      />
    </Container>
  );
});
