import { useNavigation } from "@react-navigation/native";
import React from "react";
import { View } from "react-native";
import Api from "../../../app/api/Api";
import Errors from "../../../app/util/Errors";
import { isWeb, screenWidth } from "../../../app/util/util";
import MediaPicker from "../../../components/MediaPicker";
import PlaceInfoView, { Button } from "../../../components/place/PlaceInfoView";
import PlaceMapView from "../../../components/place/PlaceMapView";
import GIcon from "../../../components/ui/GIcon";
import LoadingView from "../../../components/ui/LoadingView";
import EditPlaceName from "../../guide/ui/EditPlaceName";
import AddMediaPlaceholderView from "./AddMediaPlaceholderView";
import MediaItemView from "./MediaItemView";
import RenderQuotesView from "./RenderQuotesView";
import usePermissions from "./usePermissions";

const buttonWidth = screenWidth - 40;

export default function EditPlaceView({
  index,
  loading,
  location,
  isPreview,
  onCaptionFocus,
  onChangeAddress,
  ...props
}) {
  const navigation = useNavigation();

  const [place, setPlace] = React.useState(props.place);
  const [medias, setMedias] = React.useState(props.place.medias || []);

  const [loadingMedia, setLoadingMedia] = React.useState(false);

  const { camera, photos, recording } = usePermissions();

  React.useEffect(() => {
    if (props.place) {
      setPlace(props.place);
    }
  }, [props.place]);

  function onNoPermissions() {
    if (!camera.enabled) {
      camera.ask(true);
    }

    if (!photos.enabled) {
      photos.ask(true);
    }

    if (!recording.enabled) {
      recording.ask(true);
    }
  }

  const hasPermissions = camera.enabled && photos.enabled;

  const onPlaceUpdate = React.useCallback(
    (fields) => {
      return (
        Api.place
          .placeUpdate(place.id, { ...fields })
          .then((updatedPlace) => {
            setPlace(updatedPlace);
          })
          // .then(() => showMessage({ message: "Changes saved!", type: "success" }))
          .catch(Errors.log)
      );
    },
    [place.id]
  );

  function onAddMedia(newMedia) {
    const newMedias = [...medias, newMedia];
    setMedias(newMedias);
    setPlace({ ...place, medias: newMedias });
    const updatedMedias = newMedias.filter((media) => media).map((media) => media.id);
    onPlaceUpdate({ medias: updatedMedias });
  }

  function onDeleteMedia(media) {
    const newMedias = medias.filter(({ id }) => id !== media.id);
    setMedias(newMedias);
    setPlace({ ...place, medias: newMedias });
    const updatedMedias = newMedias.filter((m) => m).map((m) => m.id);
    onPlaceUpdate({ medias: updatedMedias });
  }

  function onOpenPhotos() {
    if (!hasPermissions) {
      return onNoPermissions();
    }

    setLoadingMedia(true);
    return MediaPicker.openPhotos(navigation, { canSelectVideo: true })
      .then((newMedia) => onAddMedia(newMedia))
      .catch((error) => console.log("Error: ", error))
      .finally(() => setLoadingMedia(false));
  }

  function onOpenCamera() {
    if (!hasPermissions) {
      return onNoPermissions();
    }

    setLoadingMedia(true);
    return MediaPicker.openCamera(navigation, { canSelectVideo: true })
      .then((newMedia) => onAddMedia(newMedia))
      .catch((error) => console.log("Error: ", error))
      .finally(() => setLoadingMedia(false));
  }

  function onOpenGooglePhotos() {
    setLoadingMedia(true);

    return MediaPicker.openGooglePhotos(navigation, { gPlaceId: place.gPlaceId })
      .then((newMedia) => onAddMedia(newMedia))
      .catch((error) => console.log("Error: ", error))
      .finally(() => setLoadingMedia(false));
  }

  const onMediaUpdated = React.useCallback(
    (media) => {
      const mediaIndex = (medias || []).findIndex((m) => m.id === media.id);
      if (mediaIndex !== -1) {
        medias[mediaIndex] = media;
        setMedias(medias);

        const updatedPlace = { ...place, medias };
        setPlace(updatedPlace);
      }
    },
    [medias, place]
  );

  const { quotes } = place;

  const [quote0, quote1, quote2] = quotes || [];

  const quoteList = [quote0, quote1, quote2];

  if (camera.view) {
    return camera.view();
  }

  if (photos.view) {
    return photos.view();
  }

  if (recording.view) {
    return recording.view();
  }

  return (
    <View>
      <View>
        <View
          style={{
            marginTop: 32,
            marginBottom: 16,
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <EditPlaceName {...{ place }} style={{ flex: 1 }} />
        </View>

        <View>
          {(place.medias || []).map((media) => (
            <MediaItemView
              key={media.url}
              {...{
                media,
                recording,
                buttonWidth,
                onDeleteMedia,
                onCaptionFocus,
                onMediaUpdated,
                gPlaceId: place.gPlaceId,
              }}
            />
          ))}

          {loadingMedia ? <LoadingView /> : null}
        </View>

        {(place.medias || [])?.length < 10 ? (
          <View>
            <AddMediaPlaceholderView
              fullSize={(place.medias || []).length === 0 || true}
              {...{ onOpenCamera, onOpenPhotos, onOpenGooglePhotos }}
              title={(place.medias || []).length === 0 ? "Add photo" : "Add photo or video"}
            />
          </View>
        ) : null}
      </View>

      <RenderQuotesView
        {...{
          place,
          quote0,
          quote1,
          quote2,
          setPlace,
          quoteList,
          onPlaceUpdate,
        }}
      />

      <PlaceInfoView phone={place.phone} website={place.website} style={{ marginTop: 32 }} />

      <Button
        style={{ marginLeft: 16, marginRight: 4, marginTop: 16 }}
        active
        Icon={GIcon.Edit}
        onPress={onChangeAddress}
        label={place.address}
      />

      {!isWeb ? (
        <PlaceMapView lite hidePopup style={{ marginTop: 24 }} {...{ index, place }} />
      ) : null}
    </View>
  );
}
