import client from "../client";
import {
  LIKED_PLACES,
  PLACE_CREATE,
  PLACE_GET,
  PLACE_LIKE,
  PLACE_UNLIKE,
  PLACE_UPDATE,
} from "./graphql";

function placeGet(id) {
  return client
    .query({
      variables: {
        id,
      },
      query: PLACE_GET,
    })
    .then((response) => response.placeGet);
}

function likedPlaces(skip, limit) {
  return client
    .query({
      query: LIKED_PLACES,
      variables: {
        skip,
        limit,
      },
    })
    .then((response) => response.likedPlaces);
}

function placeLike(id, guideId) {
  return client
    .mutate({
      variables: {
        id,
        guideId,
      },
      mutation: PLACE_LIKE,
    })
    .then((response) => response.placeLike);
}

function placeUnlike(id) {
  return client
    .mutate({
      variables: {
        id,
      },
      mutation: PLACE_UNLIKE,
    })
    .then((response) => response.placeUnlike);
}

function placeCreate({ name, gPlaceId, address, location, website, phone, originalName }, guideId) {
  return client
    .mutate({
      variables: {
        name,
        address,
        guideId,
        gPlaceId,
        location,
        website,
        phone,
        originalName,
      },
      mutation: PLACE_CREATE,
    })
    .then((response) => response.placeCreate);
}

function placeUpdate(
  id,
  {
    name,
    gPlaceId,
    address,
    location,
    website,
    phone,
    category,
    categories,
    price,
    audio,
    media,
    medias,
    quotes,
  }
) {
  return client
    .mutate({
      variables: {
        id,
        name,
        price,
        media,
        audio,
        medias,
        quotes,
        address,
        website,
        category,
        location,
        gPlaceId,
        categories,
        phone,
      },
      mutation: PLACE_UPDATE,
    })
    .then((response) => response.placeUpdate);
}

export default {
  placeGet,
  placeLike,
  placeUnlike,
  placeCreate,
  placeUpdate,
  likedPlaces,
};
