import { gql } from "graphql-request";
import { APPLICATION_MODEL } from "../models";

export const QUERY_APPLICATIONS = gql`
  query applications {
    applications {
     ${APPLICATION_MODEL}
    }
  }
`;

export const APPLICATION_CREATE = gql`
  mutation applicationCreate(
    $email: String
    $country: String
    $platform: String
    $socialUrl: String
    $numberOfFollowers: String
  ) {
    applicationCreate(
      email: $email
      country: $country
      platform: $platform
      socialUrl: $socialUrl
      numberOfFollowers: $numberOfFollowers
    ) {
      ${APPLICATION_MODEL}
    }
  }
`;

export const APPLICATION_APPROVE = gql`
  mutation applicationApprove(
    $id: String
  ) {
    applicationApprove(
      id: $id
    ) {
      ${APPLICATION_MODEL}
    }
  }
`;

export const APPLICATION_REJECT = gql`
  mutation applicationReject(
    $id: String
  ) {
    applicationReject(
      id: $id
    ) {
      ${APPLICATION_MODEL}
    }
  }
`;
