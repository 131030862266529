import * as Clipboard from "expo-clipboard";
import * as FileSystem from "expo-file-system";
import * as MediaLibrary from "expo-media-library";
import { observer } from "mobx-react";
import React from "react";
import { Alert, Image, Linking, PixelRatio, ScrollView, View } from "react-native";
import { captureRef } from "react-native-view-shot";
import { useQuery } from "react-query";
import Api from "../../app/api/Api";
import Queries from "../../app/constants/Queries";
import Errors from "../../app/util/Errors";
import { screenWidth } from "../../app/util/util";
import connectActionSheet from "../../components/actionsheet/connectActionSheet";
import Colors from "../../components/Colors";
import { IconButton } from "../../components/guide/GuideCellView";
import { LogoNew } from "../../components/Logo";
import Share from "../../components/Share";
import { Container, Header, Text2 } from "../../components/ui";
import ErrorView from "../../components/ui/ErrorView";
import GIcon from "../../components/ui/GIcon";
import LoadingView from "../../components/ui/LoadingView";
import { GuideLocationView, GuideTitleView } from "./ui";

const imageWidth = screenWidth - 32;
const PLACEHOLDER = "http://s3.amazonaws.com/gajdavatar/dummyAvatar.jpg";

function Button({ label, Icon, style, onPress, ...props }) {
  return (
    <View
      style={[
        {
          alignItems: "center",
          marginHorizontal: 8,
        },
        style,
      ]}
      {...props}
    >
      <IconButton
        style={{ borderWidth: 1, borderColor: Colors.middleGray, borderRadius: 24 }}
        {...{ Icon, onPress }}
      />
      <Text2 style={{ padding: 8, textAlign: "center" }}>{label}</Text2>
    </View>
  );
}

function ShareGuideScreen({ route, navigation }) {
  const { guideId } = route.params;

  const ref = React.useRef(null);

  const {
    error,
    isLoading,
    data: guide,
  } = useQuery([Queries.GUIDE, guideId], () => Api.guide.guideGet(guideId));

  const hasAudio = guide?.places?.filter(
    (place) => (place.medias || []).filter((media) => media.audio).length > 0
  );

  if (isLoading) {
    return <LoadingView />;
  }

  if (error) {
    return <ErrorView error="Something went wrong, try again!" />;
  }

  function onCopyGuide() {
    Clipboard.setString(`https://web.guidepal.com/guide/${guide.id}`);
    Alert.alert("Copied!");
  }

  async function onShowSharingOptions() {
    try {
      const base64 = await createPoster("base64");
      const filename = `${FileSystem.documentDirectory}Share.png`;
      await FileSystem.writeAsStringAsync(filename, base64, {
        encoding: FileSystem.EncodingType.Base64,
      }).then((data) => console.log({ data }));

      Share.open({
        title: guide.name,
        subject: guide.name,
        dialogTitle: guide.name,
        message: `https://web.guidepal.com/guide/${guide.id}`,
        excludedActivityTypes: [],
        url: filename,
      }).catch(() => null);
    } catch (e) {
      Errors.showGenericMessage(e);
    }
  }

  async function onTwitter() {
    const guideUrl = `https://web.guidepal.com/guide/${guide.id}`;
    Linking.openURL(`http://twitter.com/share?text=${guide.name}&url=${guideUrl}`);
  }

  function onInstagram() {
    Clipboard.setString(`https://web.guidepal.com/guide/${guide.id}`);
    Alert.alert("Guide link is copied to clipboard!", "", [
      { text: "Continue with sharing", onPress: onContinueSharingOnInstagram },
    ]);
  }

  async function onContinueSharingOnInstagram() {
    const base64 = await createPoster("base64");

    const shareOptions = {
      backgroundTopColor: "#000000",
      backgroundBottomColor: "#000000",
      backgroundImage: `data:image/png;base64,${base64}`,
      stickerImage: `data:image/png;base64,${base64}`, // or you can use "data:" link
      attributionURL: `https://web.guidepal.com/guide/${guide.id}`, // in beta
      social: Share.Social.INSTAGRAM_STORIES,
    };

    Share.shareSingle(shareOptions).catch((e) => {
      Alert.alert("Something went wrong!", e.message);
    });
  }

  async function onFacebook() {
    const base64 = await createPoster("base64");

    const shareOptions = {
      title: guide.name,
      message: guide.description,
      appId: "129667887072528",
      backgroundTopColor: "#938ADD",
      backgroundBottomColor: "#938ADD",
      stickerImage: `data:image/png;base64,${base64}`,
      backgroundImage: `data:image/png;base64,${base64}`,
      attributionURL: `https://web.guidepal.com/guide/${guide.id}`,
      url: `https://web.guidepal.com/guide/${guide.id}`,
      social: Share.Social.FACEBOOK,
    };

    Share.shareSingle(shareOptions).catch((e) => {
      Errors.log(e);
      Alert.alert("Something went wrong!", "Make sure Facebook is installed.");
    });
  }

  const { media } = guide;
  const hasCover = media && media.url;

  async function createPoster(result) {
    const targetPixelCount = 1080; // If you want full HD pictures
    const pixelRatio = PixelRatio.get(); // The pixel ratio of the device
    // pixels * pixelratio = targetPixelCount, so pixels = targetPixelCount / pixelRatio
    const pixels = targetPixelCount / pixelRatio;
    const params = result ? { result } : {};
    return captureRef(ref, {
      result: "tmpfile",
      width: pixels,
      height: pixels / 0.75,
      quality: 1,
      format: "png",
      ...params,
    });
  }

  return (
    <Container style={{ flex: 1 }}>
      <Header title="Share" useCloseIconForBack circleBack onBack={() => navigation.goBack(null)} />

      <View>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View>
            <View
              ref={ref}
              style={{
                marginTop: 8,
                marginBottom: 24,
                alignSelf: "center",
              }}
              collapsable={false}
            >
              {hasCover ? (
                <Image
                  resizeMode="cover"
                  style={{
                    height: imageWidth / 0.75,
                    width: imageWidth,
                    borderRadius: 16,
                  }}
                  source={{ uri: media.url.replace("300x300", "600x600") }}
                />
              ) : (
                <View
                  style={{
                    width: imageWidth,
                    height: imageWidth / 0.75,
                  }}
                />
              )}

              {hasCover ? (
                <View
                  style={{
                    borderRadius: 16,
                    width: imageWidth,
                    height: imageWidth / 0.75,
                    position: "absolute",
                    backgroundColor: "rgba(0,0,0,0.2)",
                  }}
                />
              ) : null}

              <View style={{ width: imageWidth, height: imageWidth / 0.75, position: "absolute" }}>
                <LogoNew
                  style={{ top: 14, right: 0, left: 0, opacity: 0.7, position: "absolute" }}
                />

                <View style={{ bottom: 0, padding: 16, width: imageWidth, position: "absolute" }}>
                  <GuideTitleView
                    title={guide.name}
                    color={hasCover ? Colors.white : Colors.black}
                    style={{ marginTop: 13, width: screenWidth - 64 }}
                  />
                  <Text2
                    style={{
                      fontSize: 16,
                      paddingRight: 8,
                      shadowOpacity: 0.7,
                      color: Colors.white,
                      shadowColor: Colors.black,
                      shadowOffset: { width: 0, height: 0 },
                    }}
                  >
                    In the footsteps of {guide.user.username}
                  </Text2>

                  <View
                    style={{
                      marginTop: 8,
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <GuideLocationView
                      disable
                      address={guide.address}
                      color={hasCover ? Colors.white : Colors.black}
                      style={{ flex: 1, marginTop: 8, marginBottom: 8 }}
                    />

                    {hasAudio ? (
                      <View
                        style={{
                          padding: 4,
                          borderRadius: 4,
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-end",
                          backgroundColor: Colors.semiTransparentBlack,
                        }}
                      >
                        <GIcon.Speaker
                          style={{ fontSize: 24, marginRight: 4, color: Colors.white }}
                        />
                        <Text2 style={{ textAlign: "center", color: Colors.white, fontSize: 17 }}>
                          Audio
                        </Text2>
                      </View>
                    ) : null}
                  </View>
                </View>
              </View>
            </View>
            <Text2 style={{ textAlign: "center" }}>Share to social</Text2>
            {false && (
              <View
                style={{
                  marginTop: 16,
                  marginHorizontal: 32,
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                <Button
                  style={{ width: 90 }}
                  onPress={onInstagram}
                  Icon={() => <GIcon.Instagram />}
                  label="Instagram"
                />

                <Button
                  style={{ width: 90 }}
                  onPress={onTwitter}
                  Icon={() => <GIcon.Twitter />}
                  label="Twitter"
                />

                <Button
                  style={{ width: 90 }}
                  onPress={onFacebook}
                  Icon={() => <GIcon.Facebook />}
                  label="Facebook"
                />
              </View>
            )}

            <View
              style={{
                marginTop: 16,
                marginHorizontal: 32,
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <Button
                style={{ width: 90 }}
                onPress={onCopyGuide}
                Icon={GIcon.Copy}
                label="Copy link"
              />

              <Button
                style={{ width: 90 }}
                onPress={() => {
                  createPoster("base64")
                    .then((result) => MediaLibrary.saveToLibraryAsync(result))
                    .then(() => Alert.alert("Saved to camera roll!"))
                    .catch(Errors.log);
                }}
                Icon={GIcon.Save}
                label={"Save to\ncamera roll"}
              />

              <Button
                style={{ width: 90 }}
                onPress={onShowSharingOptions}
                Icon={GIcon.MoreHoriz}
                label={"Share\noptions"}
              />
            </View>
          </View>
        </ScrollView>
      </View>
    </Container>
  );
}

export default connectActionSheet(observer(ShareGuideScreen));
