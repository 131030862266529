import React from "react";
import { WebView } from "react-native-webview";
import { Container, Header } from "../../components/ui";

export default function BrowserScreen({ route, navigation }) {
  const { url, title } = route.params;
  return (
    <Container>
      <Header onBack={() => navigation.goBack()} title={title} />
      <WebView source={{ uri: url }} startInLoadingState />
    </Container>
  );
}
