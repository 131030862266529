import * as EmailValidator from "email-validator";
import React from "react";
import { Alert, Keyboard, View } from "react-native";
import Api from "../../app/api/Api";
import {
  navigateToLogin,
  navigateToSelect,
  navigateToVerify,
} from "../../app/navigation/Navigator";
import Cavy from "../../app/test/Cavy";
import Errors from "../../app/util/Errors";
import Colors from "../../components/Colors";
import { Body2, Header1 } from "../../components/typography";
import { Container, Content, Header } from "../../components/ui";
import { EmailInputView, PhoneInputView } from "../../components/ui/inputs";
import countries from "../picker/countries.json";
import IOSTabView from "../place/ui/IOSTabView";

export default function EmailScreen({ route, navigation }) {
  const generateTestHook = Cavy.useCavy();
  const params = route.params || {};
  const { reset } = params;

  const [email, setEmail] = React.useState("");
  const [phoneValue, setPhoneValue] = React.useState("");
  const [country, setCountry] = React.useState("SE +46");
  const [loading, setLoading] = React.useState(false);
  const [tab, setTab] = React.useState(params.useEmail ? "Email" : "Phone");

  const phoneReg = new RegExp("^[0-9]+$");

  const useEmail = tab === "Email";

  function getPhone() {
    if (useEmail) {
      return "";
    }
    const countryCode = country?.split(" ")[1];
    if (phoneValue.startsWith("00")) {
      return phoneValue.replace("00", "+");
    }

    if (phoneValue.startsWith("0")) {
      return countryCode + phoneValue.slice(1);
    }

    return countryCode + phoneValue;
  }

  function onNext() {
    if (reset) {
      onVerify();
    } else {
      onCheckAvailability();
    }
  }

  function onVerify() {
    const phone = getPhone();
    Keyboard.dismiss();
    setLoading(true);
    Api.auth
      .verify(email, phone)
      .then(() => setLoading(false))
      .then(() => navigateToVerify(navigation, { email, phone, reset }))
      .catch((e) => {
        setLoading(false);
        Alert.alert(
          "Please make sure your phone number is correct! Don't forget to add your country code(+..) at the beginning!"
        );
        Errors.log(e);
      });
  }

  function onCheckAvailability() {
    const phone = getPhone();
    Keyboard.dismiss();
    setLoading(true);
    Api.auth
      .isAvailable(email || phone)
      .then((isAvailable) => {
        if (isAvailable) {
          onVerify();
        } else {
          setLoading(false);
          navigateToLogin(navigation, {
            uid: email || phone,
            onDone: () => {
              console.log(" on done");
              navigation.goBack(null);
            },
          });
        }
      })
      .catch((e) => {
        setLoading(false);
        Errors.log(e);
      });
  }

  const enableNext = EmailValidator.validate(email) || phoneReg.test(phoneValue);

  return (
    <Container>
      <Header
        transparent
        onBack={() => navigation.goBack(null)}
        right={{
          label: "Next",
          onPress: enableNext ? onNext : () => Alert.alert("Phone number is not valid!"),
          loading,
        }}
      />

      <Content
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ flex: 1 }}
        style={{
          flex: 1,
          paddingBottom: 24,
          paddingHorizontal: 24,
          flexDirection: "column",
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
          }}
        >
          <IOSTabView
            select={tab}
            onTab={setTab}
            tabs={["Phone", "Email"]}
            style={{ backgroundColor: Colors.white, padding: 4, marginVertical: 4 }}
          />
          <View>
            <Header1 style={{ textAlign: "center", color: Colors.black }}>
              What's your {useEmail ? "email" : "phone number"}?
            </Header1>
          </View>

          {useEmail ? (
            <EmailInputView
              ref={generateTestHook("Input.Email")}
              value={email}
              style={{ borderWidth: 1, height: 60 }}
              placeholder="hello@example.com"
              onChangeText={(text) => setEmail(text.trim().toLowerCase())}
            />
          ) : (
            <PhoneInputView
              ref={generateTestHook("Input.Phone")}
              value={phoneValue}
              style={{ borderWidth: 1, height: 60 }}
              placeholder="Phone number"
              onChangeText={(text) => setPhoneValue(text.trim())}
              country={country}
              onCountry={() =>
                navigateToSelect(navigation, {
                  showCode: true,
                  title: "Select a country",
                  options: countries,
                  onSelect: ({ code, dial_code }) => setCountry(`${code} ${dial_code}`),
                })
              }
            />
          )}

          <Body2 style={{ textAlign: "center", marginTop: 16 }}>
            We'll send you {useEmail ? "an email" : "SMS"} verification code
          </Body2>
        </View>
      </Content>
    </Container>
  );
}
