import React from "react";
import { TextInput, View } from "react-native";
import Api from "../../../app/api/Api";
import Errors from "../../../app/util/Errors";
import Colors from "../../../components/Colors";
import { Text2 } from "../../../components/ui";
import useSaveChanges from "../../../components/useSaveChanges";
import SavedView from "./SavedView";

export default function EditGuideName({ style, guide, ...props }) {
  const [text, setText] = React.useState(guide.name?.trim());
  const [saved, setSaved] = React.useState(false);
  const [saving, setSaving] = React.useState(false);
  const [showLenght, setShowLenght] = React.useState(false);

  const onSave = React.useCallback(
    (name) => {
      setSaving(true);
      return Api.guide
        .guideUpdate(guide.id, { name })
        .then(() => {
          setSaved(true);
          setTimeout(() => {
            setSaved(false);
          }, 1000);
        })
        .catch(Errors.showErrorMessage)
        .finally(() => setSaving(false));
    },
    [guide.id]
  );

  useSaveChanges({ data: text, onSave });

  return (
    <View
      style={[
        {
          minHeight: 52,
          borderWidth: 0,
          borderRadius: 10,
          alignItems: "center",
          flexDirection: "row",
          borderColor: Colors.lightGray,
          borderStyle: !text ? "dashed" : "solid",
        },
        style,
      ]}
    >
      <TextInput
        multiline
        {...props}
        maxLength={50}
        defaultValue={text}
        placeholder="Write a catchy guide title"
        placeholderTextColor={Colors.white}
        onFocus={() => setShowLenght(true)}
        onEndEditing={() => setShowLenght(false)}
        style={{
          fontSize: 30,
          shadowOpacity: 0.2,
          color: Colors.white,
          shadowColor: Colors.black,
          fontFamily: "OutfitSemiBold",
          shadowOffset: { width: 0, height: 2 },
        }}
        onChangeText={(value) => setText(value?.trim())}
      />

      {text && showLenght ? (
        <Text2
          style={{
            right: 4,
            bottom: 4,
            fontSize: 10,
            position: "absolute",
            color: Colors.darkerGray,
          }}
        >
          {(text || "").length + "/" + 50}
        </Text2>
      ) : null}

      <SavedView {...{ saving, saved }} />
    </View>
  );
}
