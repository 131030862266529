import { Linking, Platform } from "react-native";

const scheme = Platform.OS === "ios" ? "maps:0,0?q=" : "geo:0,0?q=";

export const openMap = ({ latitude, longitude }, name) => {
  const latLng = `${latitude},${longitude}`;
  const label = name || "here";
  const url = Platform.select({
    ios: `${scheme}${label}@${latLng}`,
    android: `${scheme}${latLng}(${label})`,
  });

  Linking.openURL(url).catch(console.error);
};
