import { useNavigation } from "@react-navigation/native";
import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import Notification from "../../app/constants/Notification";
import { navigatePushNotification } from "../../app/navigation/Navigator";
import Cavy from "../../app/test/Cavy";
import { screenWidth } from "../../app/util/util";
import { Text2 } from "../ui";
import ImageView from "../ui/ImageView";
import UserAvatarView from "../ui/UserAvatarView";
import EventView from "../EventView";
import Colors from "../Colors";
import store from "../../app/store/Store";
import Api from "../../app/api/Api";
import Errors from "../../app/util/Errors";

const GUIDEPAL = require("../../../assets/images/icon.png");

const fallback = "http://s3-eu-west-1.amazonaws.com/userimages.guidewall/empty.jpg";
const PLACEHOLDER = "http://s3.amazonaws.com/gajdavatar/dummyAvatar.jpg";
const NEW_APPLICATION_ICON = "https://gajdavatar.s3.amazonaws.com/email.jpg";

const GuideListItemView = Cavy.wrap(({ guide, onPress, liked, ...props }) => {
  const generateTestHook = Cavy.useCavy();

  const { url } = guide.media ? guide.media : { url: fallback, ratio: 1 };
  let avatarUri = fallback;
  if (guide.user.media && guide.user.media.url) {
    avatarUri = guide.user.media.url;
  }

  return (
    <TouchableOpacity
      ref={generateTestHook("Search.SelectGuide")}
      {...props}
      activeOpacity={0.7}
      style={styles.container}
      onPress={() => onPress(guide)}
    >
      <View
        style={{
          flexDirection: "row",
          marginHorizontal: 16,
        }}
      >
        <ImageView
          width={75}
          height={75}
          testID="image-view"
          style={{ width: 75, borderRadius: 10 }}
          uri={url || fallback}
        />

        <View
          style={{
            paddingHorizontal: 12,
            justifyContent: "center",
          }}
        >
          <Text2
            numberOfLines={1}
            style={{
              fontSize: 16,
              marginBottom: -4,
              fontWeight: "bold",
              width: screenWidth - 75 - 42,
            }}
          >
            {guide.name}
          </Text2>

          <UserAvatarView
            verified
            url={avatarUri}
            style={styles.avatar}
            name={guide.user.username}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
});

export const NotificationItemView = ({ message, liked, downloaded, ...props }) => {
  const { type, guide, user } = message;

  const navigation = useNavigation();

  const [part0, part1, part2] = Notification.createParts(message);

  let { url } = guide && guide.media ? guide.media : { url: fallback, ratio: 1 };
  let avatarUri = fallback;
  if (guide && guide.user && guide.user.media && guide.user.media.url) {
    avatarUri = guide.user.media.url;
  }

  if (type === Notification.Types.CREATOR_APPLICATION) {
    avatarUri = PLACEHOLDER;
    url = NEW_APPLICATION_ICON;
  }

  if (type === Notification.Types.USER_UPGRADED) {
    avatarUri = PLACEHOLDER; // TODO Replace this with Guidepal icon
    url = NEW_APPLICATION_ICON;
  }

  if (type === Notification.Types.GUIDE_REMINDER) {
    avatarUri = PLACEHOLDER; // TODO Replace this with Guidepal icon
    if (guide && guide.media) {
      url = guide.media.url;
    } else {
      url = NEW_APPLICATION_ICON;
    }
  }

  async function onPress() {
    if (type === Notification.Types.USER_UPGRADED) {
      try {
        store.setUser(await Api.user.me());
        navigation.navigate("gtab");
      } catch (error) {
        Errors.showGenericMessage(error);
      }
      return;
    }
    navigatePushNotification(navigation, {
      type,
      userId: user ? user.id : null,
      guideId: guide ? guide.id : null,
    });
  }

  return (
    <EventView
      {...{
        ...props,
        onPress,
        avatarUrl: avatarUri,
        part0,
        part1,
        part2,
        eventDate: message.createdAt,
        guideUrl: url,
      }}
    />
  );
};

export default GuideListItemView;

const styles = StyleSheet.create({
  avatar: {
    marginTop: 7,
  },
  container: {
    marginBottom: 16,
    backgroundColor: Colors.transparent,
  },
});
