/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from "expo-linking";
import * as Notifications from "expo-notifications";
import { navigatePushNotification, navigationRef } from "./Navigator";

export default {
  prefixes: [Linking.makeUrl("/")],
  config: {
    screens: {
      Root: {
        screens: {
          hometab: {
            screens: {
              guide: "guide/:guideId",
              profile: "user/:userId",
            },
          },
        },
      },
      NotFound: "*",
    },
  },
  async getInitialURL() {
    const initialUrl = await Linking.getInitialURL();

    if (initialUrl != null) {
      return initialUrl;
    }
    // Handle URL from expo push notifications
    const response = await Notifications.getLastNotificationResponseAsync();
    const url = response?.notification.request.content.data.url;

    return url;
  },
  subscribe(listener) {
    const onReceiveURL = ({ url }: { url: string }) => listener(url);

    // Listen to incoming links from deep linking
    Linking.addEventListener("url", onReceiveURL);

    // Listen to expo push notifications
    const subscription = Notifications.addNotificationResponseReceivedListener((response) => {
      const { data } = response.notification.request.content;
      const { type, userId, guideId } = data;

      navigatePushNotification(navigationRef.current, { type, userId, guideId });

      // Let React Navigation handle the URL
      listener(data);
    });

    return () => {
      // Clean up the event listeners
      Linking.removeEventListener("url", onReceiveURL);
      subscription.remove();
    };
  },
};

/*
Object {
  "actionIdentifier": "expo.modules.notifications.actions.DEFAULT",
  "notification": Object {
    "date": 1643972988.1800127,
    "request": Object {
      "content": Object {
        "attachments": Array [],
        "badge": null,
        "body": "Stockholm",
        "categoryIdentifier": "",
        "data": Object {
          "guideId": "60c0b90c2b5f495bd0da6a6f",
          "type": "guideLiked",
          "userId": "60c0b8c02b5f495bd0da6a6e",
        },
        "launchImageName": "",
        "sound": null,
        "subtitle": null,
        "summaryArgument": null,
        "summaryArgumentCount": 0,
        "targetContentIdentifier": null,
        "threadIdentifier": "",
        "title": "pascal liked your guide!",
      },
      "identifier": "C67DF735-FBFB-49FC-A3CC-A0CC55AD7D74",
      "trigger": Object {
        "class": "UNPushNotificationTrigger",
        "payload": Object {
          "aps": Object {
            "alert": Object {
              "body": "Stockholm",
              "launch-image": "",
              "subtitle": "",
              "title": "pascal liked your guide!",
            },
            "category": "",
            "thread-id": "",
          },
          "body": Object {
            "guideId": "60c0b90c2b5f495bd0da6a6f",
            "type": "guideLiked",
            "userId": "60c0b8c02b5f495bd0da6a6e",
          },
          "experienceId": "@guidepalx/guidepal",
          "projectId": "66b29e5a-e9a9-40bd-81b7-149ac13e3278",
          "scopeKey": "@guidepalx/guidepal",
        },
        "type": "push",
      },
    },
  },
}
/* */
