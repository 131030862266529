import { useNavigation } from "@react-navigation/native";
import { Audio } from "expo-av";
import React from "react";
import { Alert, View } from "react-native";
import Api from "../../../app/api/Api";
import Cavy from "../../../app/test/Cavy";
import { screenWidth } from "../../../app/util/util";
import AlertDialog from "../../../components/AlertDialog";
import Colors from "../../../components/Colors";
import MediaPicker, { createAudioMedia } from "../../../components/MediaPicker";
import MediaView from "./MediaView";

const CavyView = Cavy.wrap(View);

export default function MediaItemView({
  media,
  style,
  gPlaceId,
  recording,
  buttonWidth,
  onMediaUpdated,
  onCaptionFocus,
}) {
  const navigation = useNavigation();
  const videoRef = React.useRef(null);
  const [status, setStatus] = React.useState({});
  const [savedAudio, setSavedAudio] = React.useState(false);
  const [savingAudio, setSavingAudio] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const showImagePicker = MediaPicker.useImagePicker({ canSelectFromGoogle: true }, navigation);

  async function onAudio(params) {
    if (!recording.enabled) {
      Audio.getPermissionsAsync()
        .then(({ status }) => recording.ask(status !== "granted"))
        .catch((error) => Alert.alert(error.message));
    }

    setSavingAudio(true);

    let mediaId = null;
    if (params) {
      const { uri, duration } = params;
      const value = { uri, duration, type: "audio" };

      const audio = await createAudioMedia(value);
      mediaId = audio.id;
    }

    Api.media
      .mediaUpdate(media.id, { audio: mediaId })
      .then(onMediaUpdated)
      .then(() => {
        setSavedAudio(true);
        setTimeout(() => {
          setSavedAudio(false);
        }, 1000);
      })
      .catch((error) => console.log("Error: ", error))
      .finally(() => setSavingAudio(false));
  }

  async function onChange() {
    try {
      // setLoadingCover(true);
      const { id, ratio, width, height, type, url } = await showImagePicker(gPlaceId);

      console.log({ id, ratio, width, height, type, url, s: "ssss", "media.id": media.id });

      Api.media
        .mediaUpdate(media.id, { ratio, width, height, type, url })
        .then((s) => {
          console.log({ s });
          return s;
        })
        .then(onMediaUpdated)
        .catch((error) => console.log("Error: ", error));

      // onUpdateGuide({ media: newMedia.id });
    } catch (error) {
      console.log(error);
    } finally {
      // setLoadingCover(false);
    }
  }

  return (
    <CavyView style={style}>
      <View
        style={{
          margin: 16,
          borderRadius: 16,
          width: screenWidth - 32,
          borderColor: Colors.lightGray,
          backgroundColor: Colors.white,
          minHeight: screenWidth / media.ratio,
        }}
      >
        <MediaView
          canEdit
          {...{
            media,
            onAudio,
            status,
            onChange,
            videoRef,
            setStatus,
            recording,
            buttonWidth,
            savedAudio,
            savingAudio,
            onCaptionFocus,
            setShowDeleteDialog,
          }}
        />

        <AlertDialog
          title="Delete"
          message="Are you sure?"
          visible={showDeleteDialog}
          options={[
            { text: "No", onPress: () => setShowDeleteDialog(false) },
            {
              text: "Yes",
              onPress: () => {
                onAudio();
                setShowDeleteDialog(false);
              },
            },
          ]}
        />
      </View>
    </CavyView>
  );
}
