import React from "react";
import { Platform, StyleSheet, Text, View } from "react-native";
import Modal from "react-native-modal";
import Colors from "../Colors";
import { TouchableHighlight } from "../ui";

export default class ActionSheet extends React.Component {
  constructor(props) {
    super(props);
    this.state = { options: { options: [] }, onSelect: () => null };
  }

  showActionSheetWithOptions = (options, onSelect) => {
    this.setState({ isVisible: true, options, onSelect });
  };

  render() {
    const { generateTestHook } = this.props;
    const { isVisible, options, onSelect } = this.state;

    return isVisible ? (
      <Modal
        animationOutTiming={0}
        isVisible={isVisible}
        backdropColor="rgba(0, 0, 0, 0.3)"
        onBackdropPress={() => {
          this.setState({ isVisible: false });
          onSelect(options.options.length - 1);
        }}
        onBackButtonPress={() => {
          this.setState({ isVisible: false });
          onSelect(options.options.length - 1);
        }}
        style={{
          flex: 1,
          margin: 0,
          justifyContent: "flex-end",
        }}
      >
        <View style={styles.modalContent}>
          {options.options.map((actionItem, index) => {
            return (
              <TouchableHighlight
                style={[
                  styles.actionSheetView,
                  index === 0 && {
                    borderTopLeftRadius: 13,
                    borderTopRightRadius: 13,
                  },
                  index === options.options.length - 2 && {
                    borderBottomLeftRadius: 13,
                    borderBottomRightRadius: 13,
                  },
                  index === options.options.length - 1 && {
                    marginTop: 8,
                    borderRadius: 13,
                    borderBottomWidth: 0,
                    backgroundColor: Colors.white,
                  },
                ]}
                underlayColor="#f7f7f7"
                key={actionItem}
                ref={generateTestHook(actionItem)}
                onPress={() => {
                  this.setState({ isVisible: false });
                  onSelect(index);
                }}
              >
                <Text
                  allowFontScaling={false}
                  style={[
                    styles.actionSheetText,
                    index === options.destructiveButtonIndex && {
                      color: Colors.carminePink,
                    },
                    index === options.options.length - 1 && {
                      fontWeight: "bold",
                    },
                  ]}
                >
                  {actionItem}
                </Text>
              </TouchableHighlight>
            );
          })}
        </View>
      </Modal>
    ) : null;
  }
}

const styles = StyleSheet.create({
  modalContent: {
    marginLeft: 8,
    marginRight: 8,
    marginBottom: 35,
    borderTopLeftRadius: 13,
    borderTopRightRadius: 13,
    borderBottomLeftRadius: 13,
    borderBottomRightRadius: 13,
  },
  actionSheetText: {
    fontSize: 18,
    color: Colors.lightBlue,
  },
  actionSheetView: {
    paddingTop: 19,
    display: "flex",
    paddingBottom: 19,
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.white,
    justifyContent: "center",
    borderColor: Colors.pixelWhite,
    borderBottomWidth: StyleSheet.hairlineWidth,
  },
});
