import React from "react";
import { Image, TouchableWithoutFeedback, View } from "react-native";
import ClearButton from "../../../components/ClearButton";
import Colors from "../../../components/Colors";
import GIcon from "../../../components/ui/GIcon";

export default function ProfileAvatarView({ uri, onPress }) {
  return (
    <TouchableWithoutFeedback {...{ onPress }}>
      <View>
        {uri ? (
          <Image
            style={{
              width: 86,
              height: 86,
              borderRadius: 43,
              borderColor: Colors.chrisSilver,
            }}
            source={{ uri }}
          />
        ) : (
          <View
            style={{
              width: 86,
              height: 86,
              borderRadius: 43,
              alignItems: "center",
              borderColor: Colors.chrisSilver,
              justifyContent: "center",
              backgroundColor: Colors.lightGray,
            }}
          >
            <GIcon.Person style={{ fontSize: 64, color: Colors.gray }} />
            <ClearButton
              {...{ onPress }}
              color={Colors.white}
              Icon={GIcon.Add}
              style={{ position: "absolute", top: -4, right: -8 }}
            />
          </View>
        )}
      </View>
    </TouchableWithoutFeedback>
  );
}
