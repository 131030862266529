import React from "react";
import { TouchableOpacity, View } from "react-native";
import { PageSubtitle } from "../typography";
import GIcon from "../ui/GIcon";

export default function InsightSelectorView({ title, onPress, ...props }) {
  return (
    <TouchableOpacity style={{}} activeOpacity={0.6} {...{ onPress }}>
      <View
        {...props}
        style={{
          marginTop: 2,
          alignItems: "center",
          flexDirection: "row",
          marginHorizontal: 16,
          justifyContent: "space-between",
        }}
      >
        <PageSubtitle
          style={{
            flex: 1,
            color: "#000",
            fontSize: 16,
          }}
        >
          {title}
        </PageSubtitle>

        <View
          style={{
            flex: 1,
            justifyContent: "center",
            alignItems: onPress ? "center" : "flex-end",
          }}
        />

        {onPress ? (
          <View
            style={{
              width: 80,
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <GIcon.ArrowForward style={{ marginRight: -8 }} />
          </View>
        ) : null}
      </View>
    </TouchableOpacity>
  );
}
