import React from "react";
import { Text } from "react-native";

export function PulpDisplaySemiBold({ style, ...props }) {
  return <Text {...props} style={[style, { fontFamily: "PulpDisplaySemiBold" }]} />;
}

export function Header1({ style, ...props }) {
  return (
    <Text
      {...props}
      style={[
        style,
        {
          fontSize: 30,
          lineHeight: 34,
          fontFamily: "OutfitMedium",
        },
      ]}
    />
  );
}

export function Header2({ style, ...props }) {
  return <Text {...props} style={[style, { fontSize: 14, fontFamily: "PulpDisplayMedium" }]} />;
}

export function Header3({ style, ...props }) {
  return <Text {...props} style={[style, { fontSize: 30, fontFamily: "RecoletaBold" }]} />;
}

export function Body1({ style, ...props }) {
  return <Text {...props} style={[style, { fontSize: 20, fontFamily: "RecoletaBlack" }]} />;
}

export function Body2({ style, ...props }) {
  return (
    <Text
      {...props}
      style={[style, { fontSize: 14, lineHeight: 16, fontFamily: "PulpDisplayLight" }]}
    />
  );
}

export function PageTitle({ style, ...props }) {
  return <PulpDisplaySemiBold {...props} style={[style, { fontSize: 24 }]} />;
}

export function PageSubtitle({ style, ...props }) {
  return <PulpDisplaySemiBold {...props} style={[style, { fontSize: 15 }]} />;
}
