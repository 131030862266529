/**
 * If you are not familiar with React Navigation, check out the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import * as Analytics from "expo-firebase-analytics";
import * as React from "react";
import CreatorReferScreen from "../../features/creator/CreatorReferScreen";
import EditCreatorScreen from "../../features/creator/EditCreatorScreen";
import ImageCropScreen from "../../features/crop/ImageCropScreen";
import CheckoutScreen from "../../features/guide/CheckoutScreen";
import GuidePlacesScreen from "../../features/guide/GuidePlacesScreen";
import ShareGuideScreen from "../../features/guide/ShareGuideScreen";
import SelectScreen from "../../features/picker/SelectScreen";
import EditProfileScreen from "../../features/profile/EditProfileScreen";
import EditQuestionScreen from "../../features/question/EditQuestionScreen";
import EditThemeScreen from "../../features/theme/EditThemeScreen";
import BottomTabNavigator from "./BottomTabNavigator";
import LinkingConfiguration from "./LinkingConfiguration";
import ScreenNames from "./ScreenNames";
import { EditGuideNavigator, LoginNavigator, SignupNavigator } from "./StackNavigators";
import EditAdjustmentScreen from "../../features/guide/EditAdjustmentScreen";
import { navigationRef } from "./Navigator";
import ResourcesScreen from "../../features/gtab/ResourcesScreen";
import GuideScreen from "../../features/guide/GuideScreen/GuideScreen";
import BrowserScreen from "../../features/browser/BrowserScreen";
import CreatorApplicationsScreen from "../../features/creator/CreatorApplications";

export default function Navigation() {
  const routeNameRef = React.useRef();

  return (
    <NavigationContainer
      ref={navigationRef}
      linking={LinkingConfiguration}
      onReady={() => {
        routeNameRef.current = navigationRef.current.getCurrentRoute().name;
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current;
        const currentRouteName = navigationRef.current.getCurrentRoute().name;

        if (previousRouteName !== currentRouteName) {
          await Analytics.setCurrentScreen(currentRouteName);
        }

        routeNameRef.current = currentRouteName;
      }}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

const Stack = createStackNavigator();

function RootNavigator() {
  return (
    <Stack.Navigator mode="modal" screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Root" component={BottomTabNavigator} />
      <Stack.Screen name={ScreenNames.GUIDE} component={GuideScreen} />
      <Stack.Screen name={ScreenNames.SELECT} component={SelectScreen} />
      <Stack.Screen name={ScreenNames.LOGIN} component={LoginNavigator} />
      <Stack.Screen name={ScreenNames.BROWSE} component={BrowserScreen} />
      <Stack.Screen name={ScreenNames.SIGNUP} component={SignupNavigator} />
      <Stack.Screen name={ScreenNames.CHECKOUT} component={CheckoutScreen} />
      <Stack.Screen name={ScreenNames.RESOURCES} component={ResourcesScreen} />
      <Stack.Screen name={ScreenNames.EDIT_THEME} component={EditThemeScreen} />
      <Stack.Screen name={ScreenNames.IMAGE_CROP} component={ImageCropScreen} />
      <Stack.Screen name={ScreenNames.EDIT_GUIDE} component={EditGuideNavigator} />
      <Stack.Screen name={ScreenNames.SHARE_GUIDE} component={ShareGuideScreen} />
      <Stack.Screen name={ScreenNames.EDIT_PROFILE} component={EditProfileScreen} />
      <Stack.Screen name={ScreenNames.GUIDE_PLACES} component={GuidePlacesScreen} />
      <Stack.Screen name={ScreenNames.EDIT_CREATOR} component={EditCreatorScreen} />
      <Stack.Screen name={ScreenNames.EDIT_QUESTION} component={EditQuestionScreen} />
      <Stack.Screen name={ScreenNames.REFER_CREATOR} component={CreatorReferScreen} />
      <Stack.Screen name={ScreenNames.EDIT_ADJUSTMENT} component={EditAdjustmentScreen} />
      <Stack.Screen name={ScreenNames.CREATOR_APPLICATIONS} component={CreatorApplicationsScreen} />
    </Stack.Navigator>
  );
}
