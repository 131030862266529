import AWS from "aws-sdk";
import * as ImagePicker from "expo-image-picker";
import * as MediaLibrary from "expo-media-library";
import * as VideoThumbnails from "expo-video-thumbnails";
import * as FileSystem from "expo-file-system";
import { Video } from "react-native-compressor";
import Api from "../app/api/Api";
import { navigateToGooglePhotoPicker, navigateToImageCrop } from "../app/navigation/Navigator";
import { useActionSheet } from "./actionsheet/context";

const getOptions = (canSelectVideo) => ({
  base64: false,
  allowsEditing: false,
  mediaTypes: canSelectVideo
    ? ImagePicker.MediaTypeOptions.All
    : ImagePicker.MediaTypeOptions.Images,
});

/*
const v = {
  cancelled: false,
  duration: 21133.333984375,
  height: 1080,
  type: "video",
  uri: ".../ImagePicker/C0A83503-F5B7-4A2D-AB0E-62A2AA7731F2.mov",
  width: 1920,
};
*/

export function createAudioMedia({ uri, duration, type }) {
  return uploadToS3(uri).then((url) =>
    Api.media.mediaCreate({
      url,
      type,
      duration,
    })
  );
}

const uploadToS3 = async (uri) => {
  const filename = uri.substr(uri.lastIndexOf("/") + 1);

  // eslint-disable-next-line no-undef
  const response = await fetch(uri);

  const buffer = await response.blob();

  return new AWS.S3({
    apiVersion: "2006-03-01",
    accessKeyId: "AKIAIQCWU23X5DRPRZJQ",
    secretAccessKey: "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",
  })
    .putObject({
      Body: buffer,
      Key: filename,
      ACL: "public-read",
      Bucket: `guidepalimage.guidewall.com`,
    })
    .promise()
    .then(() => {
      return `https://s3.eu-west-1.amazonaws.com/guidepalimage.guidewall.com/${filename}`;
    });
};

async function onResult(result, ratio, lockAspectRatio, navigation) {
  if (result.cancelled) {
    throw new Error("Operation cancelled!");
  }

  if (result.type === "video") {
    const compressedUri = await Video.compress(
      result.uri,
      {
        compressionMethod: "auto",
        minimumFileSizeForCompress: 0.1,
      },
      (progress) => console.log({ progress })
    );

    const url = await uploadToS3(compressedUri);
    const asset = await MediaLibrary.createAssetAsync(compressedUri);
    const { width, height } = await MediaLibrary.getAssetInfoAsync(asset);
    const { uri: thumbnailUri } = await VideoThumbnails.getThumbnailAsync(compressedUri, {});
    const thumbnailUrl = await uploadToS3(thumbnailUri);

    return Api.media.mediaCreate({
      url,
      type: "video",
      ratio: (width * 1.0) / height,
      width,
      height,
      thumbnailUrl,
    });
  }

  return new Promise((resolve, reject) => {
    const { width, height } = result;
    navigateToImageCrop(navigation, {
      ratio: ratio || width / height,
      uri: result.uri,
      lockAspectRatio,
      onDone: resolve,
      onCancel: reject,
    });
  }).then((crop) => {
    const { type } = result;
    const width = Math.round(crop.width);
    const height = Math.round(crop.height);

    return uploadToS3(crop.uri)
      .then((url) => {
        return {
          url,
          type,
          ratio: (width * 1.0) / height,
          width,
          height,
        };
      })

      .then((media) => Api.media.mediaCreate(media));
  });
}

async function urlToLocalUri(url) {
  const { uri } = await FileSystem.downloadAsync(url, `${FileSystem.documentDirectory}meme.jpg`);
  return uri;
}

function openCamera(navigation, { ratio, lockAspectRatio, canSelectVideo }) {
  const options = getOptions(canSelectVideo);
  return ImagePicker.launchCameraAsync(options).then((result) =>
    onResult(result, ratio, lockAspectRatio, navigation)
  );
}

function openPhotos(navigation, { ratio, lockAspectRatio, canSelectVideo }) {
  const options = getOptions(canSelectVideo);
  return ImagePicker.launchImageLibraryAsync(options).then((result) => {
    return onResult(result, ratio, lockAspectRatio, navigation);
  });
}

function openGooglePhotos(navigation, { ratio, lockAspectRatio, gPlaceId }) {
  return new Promise((resolve, reject) => {
    navigateToGooglePhotoPicker(navigation, { gPlaceId, onDone: resolve, onCancel: reject });
  }).then(async ({ url, ratio: originalRatio, width, height }) => {
    const uri = await urlToLocalUri(url);
    return onResult(
      { uri, type: "image", ratio: originalRatio, width, height },
      ratio,
      lockAspectRatio,
      navigation
    );
  });
}

export function openPhotoUrl({ url, ratio, width, height }, navigation) {
  return urlToLocalUri(url).then((uri) => {
    return onResult({ uri, ratio, width, height, type: "image" }, null, null, navigation);
  });
}

function useImagePicker({ ratio, canSelectFromGoogle, lockAspectRatio }, navigation) {
  return useMediaPicker(
    { canSelectFromGoogle, canSelectVideo: false, lockAspectRatio, ratio },
    navigation
  );
}

function useMediaPicker(
  { canSelectFromGoogle, canSelectVideo, lockAspectRatio, ratio } = {
    canSelectVideo: true,
  },
  navigation
) {
  const { showActionSheetWithOptions } = useActionSheet();

  const options = [
    canSelectVideo ? "Open camera" : "Take a photo",
    canSelectVideo ? "Select a photo/video" : "Select a photo",
    canSelectFromGoogle ? "Select from Google Maps" : null,
    "Cancel",
  ].filter((item) => item);

  return (gPlaceId) =>
    new Promise((resolve, reject) => {
      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex: 2,
        },
        (index) => {
          setTimeout(() => {
            if (index === 0) {
              openCamera(navigation, { ratio, lockAspectRatio, canSelectVideo })
                .then(resolve)
                .catch(reject);
            } else if (index === 1) {
              openPhotos(navigation, { ratio, lockAspectRatio, canSelectVideo })
                .then(resolve)
                .catch(reject);
            } else if (index === 2 && options.length === 4) {
              openGooglePhotos(navigation, { ratio, lockAspectRatio, gPlaceId })
                .then(resolve)
                .catch(reject);
            } else {
              reject();
            }
          }, 200);
        }
      );
    });
}

export default {
  openCamera,
  openPhotos,
  openGooglePhotos,
  useImagePicker,
  useMediaPicker,
};
