import React from "react";
import { Keyboard, View } from "react-native";
import Api from "../../app/api/Api";
import { navigateToPassword } from "../../app/navigation/Navigator";
import Errors from "../../app/util/Errors";
import Colors from "../../components/Colors";
import { Header1 } from "../../components/typography";
import { Container, Content, Header, Text2 } from "../../components/ui";
import { PinInputView } from "../../components/ui/inputs";

export default function VerifyScreen({ route, navigation }) {
  const { email, phone, reset } = route.params;
  const [code, setCode] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  function onVerify() {
    Keyboard.dismiss();
    setLoading(true);
    Api.auth
      .verifyCode(email, phone, code)
      .then((userToken) => {
        setLoading(false);
        navigateToPassword(navigation, { email, phone, userToken, reset });
      })
      .catch((e) => {
        setLoading(false);
        Errors.showErrorMessage(e);
      });
  }

  return (
    <Container>
      <Header
        transparent
        onBack={() => navigation.goBack(null)}
        right={{ label: "Next", onPress: onVerify, loading }}
      />

      <Content
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ flex: 1 }}
        style={{
          flex: 1,
          paddingBottom: 24,
          paddingHorizontal: 24,
          flexDirection: "column",
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
          }}
        >
          <Header1 style={{ textAlign: "center", color: Colors.black }}>
            Enter confirmation code
          </Header1>

          <View style={{ alignItems: "center", marginTop: 5, marginBottom: 20 }}>
            <Text2 style={{ color: "#555", textAlign: "center", lineHeight: 20 }}>
              Enter the code we sent to {email || phone}
            </Text2>
          </View>
          <PinInputView
            value={code}
            onChangeText={setCode}
            style={{ borderWidth: 1, height: 70, width: 300 }}
          />
        </View>
      </Content>
    </Container>
  );
}
