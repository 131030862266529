import React from "react";
import { ActivityIndicator, StyleSheet, Text, View } from "react-native";
import Colors from "../Colors";

const LoadingView = ({ style, message }) => (
  <View style={[styles.container, style]}>
    <ActivityIndicator color={Colors.darkerGray} />
    {message && <Text style={styles.text}>{message}</Text>}
  </View>
);

export default LoadingView;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 20,
    paddingBottom: 20,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.white,
  },
  text: {
    marginTop: 10,
  },
});
