import React from "react";
import { TextInput, View } from "react-native";
import { useQueryClient } from "react-query";
import Api from "../../app/api/Api";
import Cavy from "../../app/test/Cavy";
import AlertDialog from "../../components/AlertDialog";
import Colors from "../../components/Colors";
import { Container, Content, Header } from "../../components/ui";
import ButtonView from "../../components/ui/ButtonView";

const TextTheme = Cavy.wrap(TextInput);

export default function EditThemeScreen({ route, navigation }) {
  const generateTestHook = Cavy.useCavy();
  const theme = route.params.theme || {};
  const [, setLoading] = React.useState(false);
  const [name, setName] = React.useState(theme.name);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [description, setDescription] = React.useState(theme.description);
  const queryClient = useQueryClient();

  function onAdd() {
    setLoading(true);
    Api.theme
      .themeCreate(name, description)
      .then((newTheme) => {
        setLoading(false);
        navigation.goBack();
        queryClient.setQueryData("themes", (themes) => [...themes, newTheme]);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  function onUpdate() {
    setLoading(true);
    Api.theme
      .themeUpdate(theme.id, name, description)
      .then((updatedTheme) => {
        setLoading(false);
        navigation.goBack();
        queryClient.setQueryData("themes", (themes) => {
          return themes.map((item) => (item.id === updatedTheme.id ? updatedTheme : item));
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  function onDelete() {
    setShowDeleteDialog(true);
  }

  function doDelete() {
    setLoading(true);
    Api.theme
      .themeDelete(theme.id)
      .then((deletedTheme) => {
        setLoading(false);
        navigation.goBack();
        queryClient.setQueryData("themes", (themes) => {
          return themes.filter((item) => item.id !== deletedTheme.id);
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  return (
    <Container>
      <Header
        transparent
        title={theme.id ? "Edit theme" : "Create theme"}
        left={{ label: "Cancel", onPress: navigation.goBack }}
        right={{ label: "Done", onPress: theme.id ? onUpdate : onAdd }}
      />

      <Content
        showsVerticalScrollIndicator={false}
        style={{ flex: 1, marginHorizontal: 16, paddingTop: 24 }}
      >
        <TextTheme
          ref={generateTestHook("EditTheme.Write")}
          multiline
          style={{
            marginTop: 24,
            borderWidth: 1,
            paddingTop: 16,
            minHeight: 56,
            borderRadius: 8,
            borderColor: Colors.superSilver,
            paddingHorizontal: 16,
          }}
          value={name}
          onChangeText={setName}
        />

        <TextTheme
          ref={generateTestHook("EditTheme.Description")}
          multiline
          style={{
            marginTop: 24,
            borderWidth: 1,
            paddingTop: 16,
            minHeight: 128,
            borderRadius: 8,
            borderColor: Colors.superSilver,
            paddingHorizontal: 16,
          }}
          value={description}
          onChangeText={setDescription}
        />

        {theme.id ? <ButtonView onPress={onDelete}>Delete</ButtonView> : null}

        <View style={{ flex: 1, height: 100 }} />
        <AlertDialog
          title="Delete"
          message="Are you sure?"
          visible={showDeleteDialog}
          options={[
            { text: "No", onPress: () => setShowDeleteDialog(false) },
            {
              text: "Yes",
              onPress: () => {
                doDelete();
                setShowDeleteDialog(null);
              },
            },
          ]}
        />
      </Content>
    </Container>
  );
}
