import client from "../client";
import { QUERY_EVENTS, EVENT_CREATE, EVENT_DELETE, EVENT_UPDATE } from "./graphql";

function events() {
  return client
    .query({
      variables: {},
      query: QUERY_EVENTS,
    })
    .then((response) => response.events);
}

function eventCreate({ type, message, userId, guideId, platform, version, source }) {
  return client
    .mutate({
      variables: {
        type,
        userId,
        guideId,
        message,
        platform,
        version,
        source,
      },
      mutation: EVENT_CREATE,
    })
    .then((response) => response.eventCreate);
}

function eventUpdate(id, { type, userId, guideId, message, platform, version, source }) {
  return client
    .mutate({
      variables: {
        id,
        type,
        userId,
        guideId,
        message,
        platform,
        version,
        source,
      },
      mutation: EVENT_UPDATE,
    })
    .then((response) => response.eventUpdate);
}

function eventDelete(id) {
  return client
    .mutate({
      variables: {
        id,
      },
      mutation: EVENT_DELETE,
    })
    .then((response) => response.eventDelete);
}

export default {
  events,
  eventCreate,
  eventUpdate,
  eventDelete,
};
