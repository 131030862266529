import React from "react";
import { View } from "react-native";
import Cavy from "../app/test/Cavy";
import ClearButton from "./ClearButton";
import Colors from "./Colors";
import { Border } from "./Theme";
import GIcon from "./ui/GIcon";

const AddContainerView = Cavy.wrap(({ style, children, hideAdd, index, onPress }) => {
  const generateTestHook = Cavy.useCavy();
  return (
    <View ref={generateTestHook(`Place${index}`)}>
      <View
        style={[
          {
            borderWidth: 1,
            borderRadius: 10,
            borderStyle: "dashed",
            borderColor: Border.dashedColor,
          },
          style,
        ]}
      >
        {children}
        {hideAdd ? null : (
          <ClearButton
            Icon={GIcon.Add}
            onPress={onPress}
            color={Colors.white}
            style={{ position: "absolute", top: -10, right: -14 }}
          />
        )}
      </View>
    </View>
  );
});

export default AddContainerView;
