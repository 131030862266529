import { gql } from "graphql-request";
import { THEME_MODEL } from "../models";

export const QUERY_THEMES = gql`
  query themes {
    themes {
     ${THEME_MODEL}
    }
  }
`;

export const THEME_CREATE = gql`
  mutation themeCreate(
    $name: String
    $description: String
  ) {
    themeCreate(
      name: $name
      description: $description
    ) {
      ${THEME_MODEL}
    }
  }
`;

export const THEME_UPDATE = gql`
  mutation themeUpdate(
    $id: String
    $name: String
    $description: String
  ) {
    themeUpdate(
      id: $id
      name: $name
      description: $description
    ) {
      ${THEME_MODEL}
    }
  }
`;

export const THEME_DELETE = gql`
  mutation themeDelete(
    $id: String
  ) {
    themeDelete(
      id: $id
    ) {
      ${THEME_MODEL}
    }
  }
`;
