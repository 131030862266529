import React from "react";
import { FlatList, TouchableOpacity, View } from "react-native";
import Colors from "../../components/Colors";
import { Container, Header, Text2 } from "../../components/ui";

export default function SelectScreen({ route, navigation }) {
  const { title, options, showCode } = route.params;

  const renderRow = (option) => {
    return <ListItem {...{ option, route, navigation, showCode }} />;
  };

  return (
    <Container>
      <Header transparent {...{ title }} onClose={navigation.goBack} />

      <FlatList
        data={options}
        disableVirtualization
        keyboardShouldPersistTaps="handled"
        keyExtractor={(item) => item.dial_code}
        renderItem={({ item: theme }) => renderRow(theme)}
      />
    </Container>
  );
}
function ListItem({ option, route, navigation, showCode }) {
  return (
    <TouchableOpacity
      key={option.name}
      onPress={() => {
        const { onSelect } = route.params;
        onSelect(option);
        navigation.goBack();
      }}
    >
      <View
        style={{
          padding: 16,
          marginLeft: 16,
          flexDirection: "row",
          borderBottomWidth: 1,
          borderBottomColor: "#d4d4d4",
        }}
      >
        <Text2 style={{ fontSize: 16 }}>{option.name}</Text2>
        {showCode ? (
          <Text2
            style={{
              flex: 1,
              color: Colors.darkGray,
              paddingRight: 8,
              textAlign: "right",
              fontSize: 16,
            }}
          >{`${option.dial_code}`}</Text2>
        ) : null}
      </View>
    </TouchableOpacity>
  );
}
