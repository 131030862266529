import { Platform } from "react-native";
import RNUserIdentity, { ICLOUD_ACCESS_ERROR } from "react-native-user-identity";

export default {
  Errors: {
    ICLOUD_ACCESS_ERROR,
  },
  getUserId: async () => {
    if (Platform.OS !== "ios") {
      return null;
    }

    return RNUserIdentity.getUserId().then((userId) => {
      if (!userId) {
        throw new Error("No user id");
      }

      return userId;
    });
  },
};
