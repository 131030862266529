import React from "react";
import { TouchableWithoutFeedback, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import GIcon from "../../../components/ui/GIcon";
import Cavy from "../../../app/test/Cavy";
import { Text2 } from "../../../components/ui";
import Colors from "../../../components/Colors";
import { isIOS } from "../../../app/util/util";

export default function AddMediaPlaceholderView({
  title,
  onOpenPhotos,
  onOpenCamera,
  onOpenGooglePhotos,
  ...props
}) {
  const generateTestHook = Cavy.useCavy();
  const [fullSize, setFullSize] = React.useState(props.fullSize);

  return (
    <View
      style={{
        flex: 1,
        marginTop: 16,
        borderWidth: 1,
        borderRadius: 16,
        marginHorizontal: 16,
        borderStyle: "dashed",
        backgroundColor: Colors.white,
        borderColor: Colors.white,
      }}
    >
      <TouchableWithoutFeedback onPress={!fullSize ? () => setFullSize(true) : null}>
        <View
          style={{
            height: 200,
            alignItems: "center",
            borderTopLeftRadius: 16,
            borderTopRightRadius: 16,
            justifyContent: "center",
            backgroundColor: Colors.white,
          }}
        >
          <GIcon.ImageOutline style={{ fontSize: 24, marginBottom: 6, color: Colors.darkGray }} />
          <Text2 style={{ color: Colors.darkGray, fontSize: 16 }}>{title || "Add photo"}</Text2>
        </View>
      </TouchableWithoutFeedback>

      {fullSize ? (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            margin: 16,
            borderWidth: 1,
            borderColor: Colors.middleGray,
            borderRadius: 10,
            borderStyle: "dashed",
          }}
        >
          <Button
            label={isIOS ? "Camera roll" : "Library"}
            onPress={onOpenPhotos}
            Icon={GIcon.ImageMultipleOutline}
            ref={generateTestHook("EditPlace.Photo")}
          />

          <Button
            Icon={GIcon.CameraOutline}
            label="Camera"
            onPress={onOpenCamera}
            ref={generateTestHook("EditPlace.Camera")}
          />

          <Button
            label="From Google"
            Icon={GIcon.Web}
            onPress={onOpenGooglePhotos}
            ref={generateTestHook("EditPlace.GoogleMaps")}
          />
        </View>
      ) : null}
    </View>
  );
}

function Button({ label, Icon, ...props }) {
  return (
    <TouchableOpacity {...props} activeOpacity={0.9}>
      <View
        style={{
          height: 100,
          paddingTop: 16,
          paddingBottom: 4,
          marginHorizontal: 8,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Icon style={{ fontSize: 32, color: Colors.primary }} />

        <Text2
          style={{ marginTop: 8, paddingHorizontal: 8, textAlign: "center", color: Colors.primary }}
        >
          {label}
        </Text2>
      </View>
    </TouchableOpacity>
  );
}
