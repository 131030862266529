import React from "react";
import { View } from "react-native";
import { GooglePlacesAutocomplete } from "react-native-google-places-autocomplete";
import Colors from "../../../components/Colors";
import mapStyle from "../../../components/map/mapStyle";
import MapMarker from "../../../components/map/Marker";
import MapView, { GoogleMapProvider } from "../../../components/MapView";
import ButtonView from "../../../components/ui/ButtonView";

export default function LocationPicker({
  title,
  onDone,
  onPress,
  loading,
  onCancel,
  placeholder,
  onLocationChange,
  ...props
}) {
  const ref = React.useRef();
  const [text, setText] = React.useState(null);
  const [showMarker, setShowMarker] = React.useState(!!props.address);
  const [location, setLocation] = React.useState(
    props.location || {
      latitude: props.location ? props.location.latitude : 40,
      longitude: props.location ? props.location.longitude : -33,
    }
  );

  const [locationInfo, setLocationInfo] = React.useState(null);

  React.useEffect(() => {
    if (ref.current && props.address) {
      ref.current.setAddressText(props.address);
    }
  }, [props.address]);

  const region = {
    ...location,
    latitudeDelta: props.location ? 0.0922 : 150,
    longitudeDelta: props.location ? 0.0421 : 150,
  };

  return (
    <View style={{ flex: 1, backgroundColor: "#fff" }}>
      <MapView
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          marginTop: 70,
          position: "absolute",
        }}
        region={region}
        onPress={onPress}
        initialRegion={region}
        customMapStyle={mapStyle}
        provider={GoogleMapProvider}
      >
        {showMarker && text ? <MapMarker {...{ location }} noIndex /> : null}
      </MapView>

      <GooglePlacesAutocomplete
        ref={ref}
        placeholder={props.address || placeholder || "Search"}
        showsVerticalScrollIndicator={false}
        onPress={(data, details = null) => {
          // 'details' is provided when fetchDetails = true

          const {
            name,
            name: originalName,
            place_id: gPlaceId,
            formatted_address: address,
            geometry: {
              location: { lat: latitude, lng: longitude },
            },
            international_phone_number: phone,
            website,
          } = details;

          setShowMarker(true);
          setLocation({ latitude, longitude });
          setLocationInfo({
            gPlaceId,
            name,
            originalName,
            address,
            latitude,
            longitude,
            phone,
            website,
          });
          if (onLocationChange) {
            onLocationChange({
              gPlaceId,
              name,
              originalName,
              address,
              latitude,
              longitude,
              phone,
              website,
            });
          }
        }}
        fetchDetails
        query={{
          language: "en",
          key: "AIzaSyBpVPUR8JmwhJbtpl0aJss45Jn-Hy5Xwv4",
          radius: props.location ? 30 : undefined,
          location: props.location
            ? `${props.location.latitude},${props.location.longitude}`
            : undefined,
        }}
        onFail={(error) => console.error(error)}
        filterReverseGeocodingByTypes={["locality", "administrative_area_level_3"]}
        textInputProps={{
          height: 38,
          borderRadius: 10,
          backgroundColor: Colors.lightGray,
          marginTop: 8,
          borderWidth: 0,
          paddingHorizontal: 8,
          marginHorizontal: 16,
          onChangeText: setText,
          placeholderTextColor: Colors.darkGray,
        }}
      />

      {locationInfo && text ? (
        <ButtonView
          loading={loading}
          disabled={loading}
          bgColor={Colors.primary}
          onPress={() => onDone(locationInfo)}
          style={{
            left: 0,
            right: 0,
            bottom: 12,
            height: 48,
            padding: 12,
            position: "absolute",
            marginHorizontal: 16,
          }}
        >
          {title}
        </ButtonView>
      ) : null}
    </View>
  );
}
