import React from "react";
import { FlatList, TouchableOpacity, View } from "react-native";
import { useThemes } from "../../app/api/theme/themeHooks";
import { navigateToEditTheme } from "../../app/navigation/Navigator";
import Cavy from "../../app/test/Cavy";
import { Container, Header, Text2 } from "../../components/ui";
import GIcon from "../../components/ui/GIcon";
import LoadingView from "../../components/ui/LoadingView";

const TouchTheme = Cavy.wrap(TouchableOpacity);

export default function ThemesScreen({ navigation }) {
  const generateTestHook = Cavy.useCavy();
  const { isLoading, data: themes } = useThemes();

  if (isLoading) {
    return <LoadingView />;
  }

  function renderRow(theme) {
    return (
      <TouchTheme
        ref={generateTestHook("Theme.Select")}
        key={theme.id}
        onPress={() => {
          navigateToEditTheme(navigation, { theme });
        }}
      >
        <View
          style={{
            borderBottomColor: "#d4d4d4",
            borderBottomWidth: 1,
            padding: 16,
            marginLeft: 16,
          }}
        >
          <Text2 style={{ fontSize: 16 }}>{theme.name}</Text2>
        </View>
      </TouchTheme>
    );
  }

  return (
    <Container>
      <Header
        onBack={() => navigation.goBack()}
        title="Guide themes"
        actions={
          <Header.Action
            ref={generateTestHook("Theme.Add")}
            Icon={GIcon.Add}
            onPress={() => navigateToEditTheme(navigation, {})}
          />
        }
      />

      <FlatList
        data={themes}
        keyboardShouldPersistTaps="handled"
        renderItem={({ item: theme }) => renderRow(theme)}
      />
    </Container>
  );
}
