import { Audio } from "expo-av";
import * as ImagePicker from "expo-image-picker";
import * as Notifications from "expo-notifications";
import React from "react";
import Errors from "../../../app/util/Errors";
import EnableCameraPermission from "../../../components/permissions/EnableCameraPermission";
import EnableCameraRollPermission from "../../../components/permissions/EnableCameraRollPermission";
import EnableRecordingPermission from "../../../components/permissions/EnableRecordingPermission";
import EnableNotificationPermission from "../../../components/permissions/EnableNotificationPermission";

export default function usePermissions() {
  const [cameraEnabled, setCameraEnabled] = React.useState(false);
  const [askForCameraPermission, setAskForCameraPermission] = React.useState(false);

  const [photosEnabled, setPhotosEnabled] = React.useState(false);
  const [askForPhotosPermission, setAskForPhotosPermission] = React.useState(false);

  const [recordingEnabled, setRecordingEnabled] = React.useState(false);
  const [askForRecordingPermission, setAskForRecordingPermission] = React.useState(false);

  const [permissionStatus, setPermissionStatus] = React.useState("");
  const [notificationEnabled, setNotificationEnabled] = React.useState(false);
  const [askForNotificationPermission, setAskForNotificationPermission] = React.useState(false);

  React.useEffect(() => {
    ImagePicker.getCameraPermissionsAsync()
      .then(({ status }) => setCameraEnabled(status === "granted"))
      .catch(Errors.log);

    ImagePicker.getMediaLibraryPermissionsAsync()
      .then(({ status }) => setPhotosEnabled(status === "granted"))
      .catch(Errors.log);

    Audio.getPermissionsAsync()
      .then(({ status }) => setRecordingEnabled(status === "granted"))
      .catch(Errors.log);

    Notifications.getPermissionsAsync()
      .then(({ status }) => {
        setNotificationEnabled(status === "granted");
        setPermissionStatus(status);
      })
      .catch(Errors.log);
  }, []);

  function CameraPermissionView() {
    return (
      <EnableCameraPermission
        onGranted={() => {
          setCameraEnabled(true);
          setAskForCameraPermission(false);
        }}
        onCancel={() => setAskForCameraPermission(false)}
      />
    );
  }

  function PhotosPermissionView() {
    return (
      <EnableCameraRollPermission
        onGranted={() => {
          setPhotosEnabled(true);
          setAskForPhotosPermission(false);
        }}
        onCancel={() => setAskForPhotosPermission(false)}
      />
    );
  }

  function RecordingPermissionView() {
    return (
      <EnableRecordingPermission
        onGranted={() => {
          setRecordingEnabled(true);
          setAskForRecordingPermission(false);
        }}
        onCancel={() => setAskForRecordingPermission(false)}
      />
    );
  }

  function NotificationPermissionView() {
    return (
      <EnableNotificationPermission
        onGranted={() => {
          setNotificationEnabled(true);
          setAskForNotificationPermission(false);
        }}
        onCancel={() => setAskForNotificationPermission(false)}
      />
    );
  }

  return {
    camera: {
      enabled: cameraEnabled,
      ask: setAskForCameraPermission,
      view: askForCameraPermission ? CameraPermissionView : null,
    },
    photos: {
      enabled: photosEnabled,
      ask: setAskForPhotosPermission,
      view: askForPhotosPermission ? PhotosPermissionView : null,
    },
    recording: {
      enabled: recordingEnabled,
      ask: setAskForRecordingPermission,
      view: askForRecordingPermission ? RecordingPermissionView : null,
    },

    notification: {
      status: permissionStatus,
      enabled: notificationEnabled,
      ask: setAskForNotificationPermission,
      view: askForNotificationPermission ? NotificationPermissionView : null,
    },
  };
}
