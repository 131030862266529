import React from "react";
import { Image, Text, View } from "react-native";
import { Callout, Marker } from "react-native-maps";
import { isIOS } from "../../app/util/util";
import Colors from "../Colors";
import { Text2B } from "../ui";

const icon = require("../../../assets/images/guidepal-white.png");

export default function MapMarker({
  id,
  name,
  address,
  location,
  noIndex,
  index,
  show,
  isGuide,
  onCalloutPress,
}) {
  const ref = React.useRef<Marker>(null);

  React.useEffect(() => {
    if (show && ref.current) {
      ref.current.showCallout();
    }
  }, [show]);

  return (
    <Marker
      ref={ref}
      key={id}
      title={name}
      description={address}
      coordinate={location}
      {...{ onCalloutPress }}
      onPress={() => console.log("onpress")}
    >
      {isIOS ? (
        <Callout onPress={onCalloutPress}>
          <View style={{ minWidth: 300 }}>
            <Text>{name}</Text>
            <Text>{address}</Text>
          </View>
        </Callout>
      ) : null}

      <View style={{ width: 50, height: 50, alignItems: "center", justifyContent: "center" }}>
        <View
          style={{
            left: 8,
            width: 34,
            height: 34,
            padding: 2,
            borderWidth: 1,
            borderColor: "#eeeeee",
            position: "absolute",
            borderTopLeftRadius: 24,
            borderTopRightRadius: 24,
            borderBottomLeftRadius: 24,
            backgroundColor: "#ffffff",
            transform: [{ rotate: "45deg" }],
          }}
        />
        <View
          style={{
            width: 30,
            height: 30,
            borderWidth: 0,
            borderRadius: 16,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#ffffff",
            borderColor: Colors.primaryDark,
          }}
        >
          {noIndex || isGuide ? null : (
            <Text2B
              style={{
                top: 1,
                fontSize: 16,
                textAlign: "center",
                color: Colors.black,
                textAlignVertical: "center",
              }}
            >
              {index + 1}
            </Text2B>
          )}
        </View>
        {isGuide ? (
          <Image
            style={{
              top: 8,
              width: 18,
              height: 18,
              position: "absolute",
            }}
            source={icon}
          />
        ) : null}
      </View>
    </Marker>
  );
}
