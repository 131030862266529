import { Audio } from "expo-av";
import * as DocumentPicker from "expo-document-picker";
import * as FileSystem from "expo-file-system";
import * as ImagePicker from "expo-image-picker";
import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { getMMSSFromMillis } from "../app/store/PlayerStore";
import Cavy from "../app/test/Cavy";
import AddContainerView from "./AddContainerView";
import Colors from "./Colors";
import RecordingIcon from "./RecordingIcon";
import { Text2 } from "./ui";
import GIcon from "./ui/GIcon";

const enableImportAudio = false;

function RecordIcon() {
  return (
    <View
      style={{
        width: 40,
        height: 40,
        borderRadius: 20,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <GIcon.Mic style={{ color: Colors.darkGray }} />
    </View>
  );
}
class RecordView extends React.Component {
  recording = null;

  recordingSettings = null;

  constructor(props) {
    super(props);
    this.recording = null;
    this.state = {
      isLoading: false,
      isRecording: false,
      recordingDuration: null,
    };

    // this.recordingSettings = JSON.parse(JSON.stringify(Audio.RECORDING_OPTIONS_PRESET_LOW_QUALITY));

    const rs = {
      android: {
        extension: ".m4a",
        outputFormat: Audio.RECORDING_OPTION_ANDROID_OUTPUT_FORMAT_MPEG_4,
        audioEncoder: Audio.RECORDING_OPTION_ANDROID_AUDIO_ENCODER_AAC,
        sampleRate: 44100,
        numberOfChannels: 1,
        bitRate: 128000,
      },

      ios: {
        extension: ".m4a",
        outputFormat: Audio.RECORDING_OPTION_IOS_OUTPUT_FORMAT_MPEG4AAC,
        audioQuality: Audio.RECORDING_OPTION_IOS_AUDIO_QUALITY_MEDIUM,
        sampleRate: 44100,
        numberOfChannels: 1,
        bitRate: 96400,
        linearPCMBitDepth: 16,
        linearPCMIsBigEndian: false,
        linearPCMIsFloat: false,
      },
    };
    this.recordingSettings = JSON.parse(JSON.stringify(rs));
    // // UNCOMMENT THIS TO TEST maxFileSize:
    // this.recordingSettings.android['maxFileSize'] = 12000;
  }

  onRecordingStatusUpdate = (status) => {
    if (status.canRecord) {
      this.setState({
        isRecording: status.isRecording,
        recordingDuration: status.durationMillis,
      });
    } else if (status.isDoneRecording) {
      this.setState({
        isRecording: false,
        recordingDuration: status.durationMillis,
      });

      const { isLoading } = this.state;
      if (!isLoading) {
        this.stopRecording();
      }
    }
  };

  selectFile = async () => {
    const { onDoneRecording } = this.props;

    const { status } = await ImagePicker.getMediaLibraryPermissionsAsync();
    if (status !== "granted") {
      // onAskForSelectFilePermission();
      return;
    }

    let result = await DocumentPicker.getDocumentAsync();

    if (result.type === "success") {
      result = { ...result, type: "audio" };
      const info = await FileSystem.getInfoAsync(result.uri);
      onDoneRecording({ uri: info.uri });
    }
  };

  onRecordPressed = () => {
    const { onPress, showActionSheetWithOptions } = this.props;
    if (onPress) {
      onPress();
      return;
    }

    const { isRecording } = this.state;
    if (isRecording) {
      this.stopRecording();
      return;
    }

    if (!enableImportAudio) {
      this.startRecording();
      return;
    }

    const options = ["Use microphpne", "Import audio", "Cancel"];

    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex: 2,
      },
      (index) => {
        if (index === 0) {
          this.startRecording();
        } else if (index === 1) {
          this.selectFile();
        }
      }
    );
  };

  async startRecording(): Promise<void> {
    const { onStartRecording } = this.props;
    if (onStartRecording) {
      onStartRecording();
    }

    this.setState({ isLoading: true });

    await Audio.setAudioModeAsync({
      allowsRecordingIOS: true,
      interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
      playsInSilentModeIOS: true,
      shouldDuckAndroid: true,
      interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
      playThroughEarpieceAndroid: false,
      staysActiveInBackground: true,
    });

    if (this.recording !== null) {
      this.recording.setOnRecordingStatusUpdate(null);
      this.recording = null;
    }

    const recording = new Audio.Recording();
    await recording.prepareToRecordAsync(this.recordingSettings);
    recording.setOnRecordingStatusUpdate(this.onRecordingStatusUpdate);

    this.recording = recording;
    await this.recording.startAsync(); // Will call this.onRecordingStatusUpdate to update the screen.
    this.setState({ isLoading: false });
  }

  async stopRecording(): Promise<void> {
    this.setState({ isLoading: true });
    try {
      await this.recording.stopAndUnloadAsync();
    } catch (error) {
      // Do nothing -- we are already unloaded.
    }
    const info = await FileSystem.getInfoAsync(this.recording.getURI());

    this.setState({ isLoading: false });
    const { onDoneRecording } = this.props;
    const { recordingDuration } = this.state;
    onDoneRecording({ uri: info.uri, duration: recordingDuration });
  }

  getRecordingTimestamp(): string {
    const { recordingDuration } = this.state;
    if (recordingDuration != null) {
      return `${getMMSSFromMillis(recordingDuration)}`;
    }
    return `${getMMSSFromMillis(0)}`;
  }

  render(): React.Node {
    const { children, color, style, title } = this.props;
    const { isLoading, isRecording } = this.state;

    if (children) {
      return children({
        style,
        color,
        isLoading,
        isRecording,
        timestamp: this.getRecordingTimestamp(),
        onPress: isLoading ? null : this.onRecordPressed,
      });
    }

    return renderRecordView({
      style,
      color,
      title,
      isLoading,
      isRecording,
      timestamp: this.getRecordingTimestamp(),
      onPress: isLoading ? null : this.onRecordPressed,
    });
  }
}

export default Cavy.hook(RecordView);

function renderRecordView({ title, style, isRecording, color, timestamp, onPress }): React.Node {
  return (
    <TouchableOpacity onPress={onPress}>
      <AddContainerView {...{ style }} hideAdd>
        <View
          style={{
            height: 52,
            paddingVertical: 8,
            paddingHorizontal: 12,
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          {isRecording ? <RecordingIcon /> : <RecordIcon />}

          {isRecording ? (
            <Text style={{ flex: 1, marginLeft: 8, color: color || Colors.black }}>Recording</Text>
          ) : (
            <Text2
              style={{
                left: 0,
                right: 0,
                fontSize: 18,
                textAlign: "center",
                position: "absolute",
                color: color || Colors.rainyGray,
              }}
            >
              {title || "Tell us about your guide"}
            </Text2>
          )}

          {isRecording ? (
            <Text style={{ marginLeft: 8, color: color || Colors.black }}>{timestamp} </Text>
          ) : null}
        </View>
      </AddContainerView>
    </TouchableOpacity>
  );
}
