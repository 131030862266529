import React from "react";
import { TouchableOpacity, View } from "react-native";
import { FlatList } from "react-native-gesture-handler";
import Cavy from "../../app/test/Cavy";
import Colors from "../../components/Colors";
import { Container, Header, Text1, Text2 } from "../../components/ui";
import ButtonView from "../../components/ui/ButtonView";
import ResourcesHeaderView, { RESOURCES_TABS } from "./ResourcesHeaderView";
import tips from "./tips.json";

function Page({ title, text, info, mainColor, link, buttonColor, buttonLabel, onSelect }) {
  const generateTestHook = Cavy.useCavy();
  return (
    <View style={{ flex: 1, justifyContent: "center", borderWidth: 0 }}>
      <View
        style={{
          borderWidth: 0,
          shadowOffset: {
            width: 0,
            height: 2,
          },
          marginTop: 20,
          marginLeft: 30,
          marginRight: 30,
          padding: 24,
          elevation: 5,
          borderRadius: 8,
          shadowRadius: 3.84,
          shadowColor: Colors.black,
          shadowOpacity: 0.25,
          alignItems: "center",
          backgroundColor: mainColor,
        }}
      >
        <View style={{ marginTop: 24, minHeight: 120 }}>
          <Text2
            style={{
              color: buttonColor,
              fontSize: 24,
              marginBottom: 8,
              textAlign: "center",
            }}
          >
            {title}
          </Text2>
          <Text1
            style={{
              textAlign: "center",
              color: buttonColor,
              marginBottom: 16,
              lineHeight: 22,
              fontSize: 22,
            }}
          >
            {text}
          </Text1>
          <Text2 style={{ textAlign: "center", color: buttonColor, lineHeight: 22 }}>{info}</Text2>
        </View>

        <ButtonView
          ref={generateTestHook("Pick.Question")}
          bgColor={buttonColor}
          childrenColor={mainColor}
          onPress={() => onSelect({ link })}
          style={{ marginTop: 32, marginBottom: 36, width: 200 }}
        >
          {buttonLabel}
        </ButtonView>
      </View>
    </View>
  );
}

export default function ResourcesScreen({ route, navigation }) {
  const { title, buttonLabel, onSelect } = route.params;
  const [tab, setTab] = React.useState(RESOURCES_TABS.TIPS);

  const news = [0];

  function renderCards() {
    return (
      <View>
        <FlatList
          data={tips}
          keyboardShouldPersistTaps="handled"
          keyExtractor={tips.title}
          renderItem={({
            item: { title: _title, text, info, mainColor, link, buttonColor },
            index,
          }) => {
            return (
              <Page
                onSelect={(option) => {
                  navigation.goBack();
                  onSelect(option);
                }}
                key={index}
                {...{ title: _title, text, info, mainColor, link, buttonColor, buttonLabel }}
              />
            );
          }}
        />
      </View>
    );
  }

  function renderList() {
    return (
      <View>
        <FlatList
          data={news}
          keyboardShouldPersistTaps="handled"
          renderItem={({ item: theme, index }) => {
            return (
              <TouchableOpacity
                key={`${theme.title}-${index}`}
                onPress={() => {
                  navigation.goBack();
                  onSelect(theme);
                }}
              >
                <View
                  style={{
                    padding: 16,
                    marginLeft: 16,
                    marginRight: 16,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.lightGray,
                  }}
                >
                  <Text2 style={{ fontSize: 16 }}>{theme.title}</Text2>
                </View>
              </TouchableOpacity>
            );
          }}
        />
      </View>
    );
  }

  return (
    <Container>
      <Header onBack={() => navigation.goBack()} title={title} />

      <FlatList
        ListHeaderComponent={<ResourcesHeaderView onTab={setTab} {...{ tab }} />}
        data={[0]}
        showsVerticalScrollIndicator={false}
        keyboardShouldPersistTaps="handled"
        renderItem={() => (tab === RESOURCES_TABS.TIPS ? renderCards() : renderList())}
      />
    </Container>
  );
}
