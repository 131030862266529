import TimeAgo from "javascript-time-ago";
// English.
import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);

// Create formatter (English).
const timeAgo = new TimeAgo("en-US");

export function time(d) {
  return timeAgo
    .format(d)
    .replace(" minute ago", "m")
    .replace(" minutes agos", "m")
    .replace(" hour ago", "h")
    .replace(" hours ago", "h")
    .replace(" day ago", "d")
    .replace(" days ago", "d")
    .replace(" week ago", "w")
    .replace(" weeks ago", "w")
    .replace(" month ago", "mo")
    .replace(" months ago", "mo")
    .replace(" year ago", "y")
    .replace(" years ago", "y");
}
