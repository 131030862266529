import * as Notifications from "expo-notifications";
import * as React from "react";
import { StyleSheet, View } from "react-native";
import Api from "../../app/api/Api";
import store from "../../app/store/Store";
import Errors from "../../app/util/Errors";
import { isAndroid } from "../../app/util/util";
import { Body2, PageTitle } from "../typography";
import { Container } from "../ui";
import ButtonView from "../ui/ButtonView";

export default ({ onGranted, onCancel }) => {
  async function registerForPushNotificationsAsync() {
    const expoPushToken = await Notifications.getExpoPushTokenAsync({
      experienceId: "@guidepalx/guidepal",
    });

    return expoPushToken.data;
  }

  const onContinue = async () => {
    const userId = store.getUserId();
    try {
      const { status } = await Notifications.requestPermissionsAsync();

      if (status !== "granted") {
        onCancel();
        return;
      }

      if (isAndroid) {
        Notifications.setNotificationChannelAsync("default", {
          name: "default",
          importance: Notifications.AndroidImportance.DEFAULT,
        });
      }

      await registerForPushNotificationsAsync().then((expoPushToken) => {
        Api.user.userUpdate(userId, { expoPushToken });
      });

      onGranted();
    } catch (e) {
      Errors.showGenericMessage(e);
    }
  };

  return (
    <Container style={{ flex: 1, padding: 24 }}>
      <View style={{ flex: 1 }} />
      <PageTitle category="h4" style={styles.text}>
        Allow push notifications
      </PageTitle>
      <Body2 style={styles.text}>
        Get important updates about the guides you've purchased and creators you follow
      </Body2>
      <ButtonView primary onPress={onContinue}>
        Continue
      </ButtonView>

      <ButtonView outline onPress={onCancel} style={{ marginTop: 16, marginBottom: 56 }}>
        Cancel
      </ButtonView>
    </Container>
  );
};

const styles = StyleSheet.create({
  text: {
    textAlign: "center",
    marginBottom: 16,
  },
});
