import React from "react";
import { Alert, FlatList, View } from "react-native";
import { useQuery, useQueryClient } from "react-query";
import Api from "../../app/api/Api";
import Queries from "../../app/constants/Queries";
import Errors from "../../app/util/Errors";
import Colors from "../../components/Colors";
import { Container, Header, Text2B, Text2L } from "../../components/ui";
import ButtonView from "../../components/ui/ButtonView";
import LoadingView from "../../components/ui/LoadingView";

export default function CreatorApplicationsScreen({ navigation }) {
  const queryClient = useQueryClient();

  const [loading, setLoading] = React.useState(false);

  const { data, isLoading } = useQuery(Queries.APPLICATIONS, () => Api.application.applications());

  if (isLoading) {
    return <LoadingView />;
  }

  function onApprove(applicationId) {
    setLoading(true);
    Api.application
      .applicationApprove(applicationId)
      .then(() => {
        setLoading(false);
        queryClient.invalidateQueries(Queries.APPLICATIONS);
        Alert.alert("User approved!");
      })
      .catch((error) => {
        setLoading(false);
        Errors.log(error);
      });
  }

  function onReject(applicationId) {
    setLoading(true);
    Api.application
      .applicationReject(applicationId)
      .then(() => {
        setLoading(false);
        queryClient.invalidateQueries(Queries.APPLICATIONS);
        Alert.alert("User rejected!");
      })
      .catch((error) => {
        setLoading(false);
        Errors.log(error);
      });
  }

  const ApplicationItemView = ({ application }) => {
    return (
      <View style={{ flex: 1, padding: 16, borderTopWidth: 1, borderColor: "#ccc" }}>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text2B
            style={{
              fontSize: 16,
            }}
          >
            Email:{" "}
          </Text2B>
          <Text2L
            style={{
              fontSize: 16,
            }}
          >
            {application.email}
          </Text2L>
        </View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text2B
            style={{
              fontSize: 16,
            }}
          >
            Country:{" "}
          </Text2B>
          <Text2L
            style={{
              fontSize: 16,
            }}
          >
            {application.country}
          </Text2L>
        </View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text2B
            style={{
              fontSize: 16,
            }}
          >
            Followers:{" "}
          </Text2B>
          <Text2L
            style={{
              fontSize: 16,
            }}
          >
            {application.numberOfFollowers}
          </Text2L>
        </View>
        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text2B
            style={{
              fontSize: 16,
            }}
          >
            Social media:{" "}
          </Text2B>
          <Text2L
            style={{
              textDecorationColor: Colors.primaryDark,
              fontSize: 16,
            }}
          >
            {application.socialUrl}
          </Text2L>
        </View>

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text2B
            style={{
              fontSize: 16,
            }}
          >
            Platform:{" "}
          </Text2B>
          <Text2L
            style={{
              fontSize: 16,
            }}
          >
            {application.platform}
          </Text2L>
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-around",
            padding: 8,
          }}
        >
          <ButtonView
            style={{
              flex: 1,
              padding: 8,
            }}
            disabled={loading}
            bgColor="#FA4B01"
            onPress={() => onReject(application.id)}
          >
            Reject
          </ButtonView>

          <ButtonView
            style={{
              flex: 1,
              padding: 8,
            }}
            bgColor="#2A5617"
            disabled={loading}
            onPress={() => onApprove(application.id)}
          >
            Approve
          </ButtonView>
        </View>
      </View>
    );
  };

  return (
    <Container>
      <Header title={`Applications (${data.length})`} onBack={() => navigation.goBack()} />

      <FlatList
        data={data}
        keyboardShouldPersistTaps="handled"
        renderItem={({ item: application }) => <ApplicationItemView {...{ application }} />}
      />
    </Container>
  );
}
