import { Platform } from "react-native";
import Constants from "expo-constants";
import store from "./store/Store";
import Api from "./api/Api";

const { version } = Constants.manifest;

const lastupdate = Constants.manifest.extra.date
  .replace("T", " ")
  .replace('Z"', "")
  .replace('"', "");

function sendEvent({ type, message, source, guideId }) {
  Api.event
    .eventCreate({
      type,
      source,
      message,
      guideId,
      platform: Platform.OS,
      userId: store.getUserId(),
      version: `${version} - ${lastupdate}`,
    })
    .catch((error) => console.log({ error }));
}

export default {
  sendEvent,
};
