import React from "react";
import { Image } from "react-native";
import Colors from "../Colors";

export default function ImageView({ uri, blurRadius, style, ...props }) {
  const [backgroundColor, setBackgroundColor] = React.useState(Colors.gray);

  let width = 0;
  let height = 0;
  if (style.width) {
    width = style.width;
    height = props.height * (width / props.width);
  }

  return (
    <Image
      source={{ uri }}
      {...{ blurRadius }}
      style={[
        {
          width,
          height,
          backgroundColor,
        },
        style,
      ]}
      onLoadEnd={() => setBackgroundColor(Colors.transparent)}
    />
  );
}
