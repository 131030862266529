import * as Device from "expo-device";
import { observer } from "mobx-react";
import React from "react";
import { FlatList } from "react-native";
import { useQuery } from "react-query";
import Api from "../../app/api/Api";
import Queries from "../../app/constants/Queries";
import { isAndroid } from "../../app/util/util";
import Colors from "../../components/Colors";
import { NotificationItemView } from "../../components/guide/GuideListItemView";
import PageHeader from "../../components/PageHeader";
import { Container, Text2 } from "../../components/ui";
import LoadingView from "../../components/ui/LoadingView";
import usePermissions from "../place/ui/usePermissions";

export default observer(function NotificationScreen() {
  const { isLoading, data } = useQuery(Queries.MESSAGES, () => Api.message.messages(0, 50));
  const [messages, setMessages] = React.useState([]);
  const { notification } = usePermissions();

  React.useEffect(() => {
    if (notification.status === "undetermined" && isPhysicalDevice()) {
      notification.ask(true);
    }

    const map = {};
    if (!isLoading && data) {
      const filteredMessages = data.filter((m) => {
        if (!map[m.type]) {
          map[m.type] = {};
        }

        if (map[m.type][m.message]) {
          return false;
        }

        map[m.type][m.message] = true;
        return true;
      });
      setMessages(filteredMessages);
    }
  }, [data, isLoading]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (notification.view) {
    return notification.view();
  }

  function isPhysicalDevice() {
    if (!Device.isDevice) {
      return false;
    }

    if (isAndroid && Device.productName?.includes("emulator")) {
      return false;
    }

    return true;
  }

  const renderEmptyList = () => (
    <Text2 style={{ textAlign: "center", color: Colors.darkGray }}>No activities to display!</Text2>
  );

  return (
    <Container>
      <FlatList
        data={messages}
        refreshing={isLoading && !messages}
        showsVerticalScrollIndicator={false}
        ListHeaderComponent={<PageHeader title="Activity" />}
        ListEmptyComponent={renderEmptyList()}
        renderItem={({ item }) => <NotificationItemView {...{ message: item }} />}
        ListHeaderComponentStyle={{ marginBottom: 30 }}
      />
    </Container>
  );
});
