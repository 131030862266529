import React from "react";
import { View } from "react-native";
import { Text2L, Text2SB } from "../../../components/ui";

export default function StatView({ label, dotColor, text, textSize, style, ...props }) {
  return (
    <View
      {...props}
      style={[
        {
          paddingVertical: 16,
          paddingHorizontal: 16,
        },
        style,
      ]}
    >
      <Text2SB
        style={{
          marginTop: 0,
          lineHeight: 28,
          fontSize: textSize ? textSize : 24,
        }}
      >
        {text}
      </Text2SB>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {dotColor ? (
          <View
            style={{
              width: 12,
              height: 12,
              marginRight: 8,
              backgroundColor: dotColor,
              borderColor: dotColor,
              borderRadius: 12,
            }}
          />
        ) : null}

        <Text2L style={{}}>{label}</Text2L>
      </View>
    </View>
  );
}
