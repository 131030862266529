import React from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { useQuery } from "react-query";
import Api from "../../app/api/Api";
import { useUser } from "../../app/api/user/userHooks";
import Queries from "../../app/constants/Queries";
import store from "../../app/store/Store";
import Cavy from "../../app/test/Cavy";
import Colors from "../../components/Colors";
import PagerView from "../../components/PagerView";
import { Container, Content, Header } from "../../components/ui";
import ErrorView from "../../components/ui/ErrorView";
import GIcon from "../../components/ui/GIcon";
import LoadingView from "../../components/ui/LoadingView";
import PlaceView from "../place/ui/PlaceView";
import { isAndroid, isWeb } from "../../app/util/util";
import { Pagination } from "../../components/Carousel";
import { usePlayerStore } from "../../app/store/PlayerStore";
import GetTheAppView from "./GuideScreen/GetTheAppView";

const HeaderAction = Cavy.wrap(Header.Action);

function IconButton({ style, onPress }) {
  return (
    <TouchableOpacity
      activeOpacity={0.9}
      style={{
        height: 48,
        minWidth: 48,
        alignItems: "center",
        alignContent: "center",
        justifyContent: "center",
        ...style,
      }}
      {...{ onPress }}
    >
      <View
        style={{
          width: 24,
          height: 24,
          borderRadius: 12,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "#00000080",
        }}
      >
        <GIcon.Close style={{ fontSize: 18, color: Colors.white }} />
      </View>
    </TouchableOpacity>
  );
}

export default function LikedPlacesPagerScreen({ route, navigation }) {
  const { placeId } = route.params;

  const [selectedPageIndex, setSelectedPageIndex] = React.useState(0);

  const userId = store.getUserId();
  const { data: user } = useUser(userId);

  const player = usePlayerStore();

  const {
    error,
    isLoading,
    data: places,
  } = useQuery(Queries.LIKED_PLACES, () => Api.place.likedPlaces(0, 1000));

  React.useEffect(() => {
    if (places) {
      document.getElementById(placeId).scrollIntoView({
        block: "start",
        behavior: "smooth",
      });
    }
  }, [places, placeId]);

  React.useEffect(() => {
    const unsubscribe = navigation.addListener("beforeRemove", () => {
      player.stop();
    });
    return unsubscribe;
  }, [navigation, player]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (error) {
    return <ErrorView error="Something went wrong, try again!" />;
  }

  const selectedIndex = (places || []).findIndex((place) => place.id === placeId);

  const headerPagination = () => (
    <Pagination
      dotsLength={places.length}
      activeDotIndex={selectedPageIndex}
      containerStyle={{ left: 0, right: 0, position: "absolute", top: isAndroid ? 20 : 30 }}
    />
  );

  return (
    <Container>
      <Content
        style={{ backgroundColor: Colors.lynxWhite, paddingTop: 56 }}
        showsVerticalScrollIndicator={false}
      >
        <div style={{ marginBottom: 100 }}>
          {places.map((place, index) => {
            return (
              <div id={place.id} key={place.id}>
                <View style={styles.content}>
                  <PlaceView
                    {...{ index, place, user }}
                    guideId={(place.guide || {}).id}
                    isVisible={index === selectedIndex}
                  />
                </View>
              </div>
            );
          })}

          <GetTheAppView style={{ marginTop: 24 }} />
        </div>
      </Content>

      <View
        style={{
          left: 0,
          right: 0,
          paddingBottom: 8,
          position: "absolute",
          backgroundColor: Colors.transparent,
        }}
      >
        <Header
          onBack={() => {
            navigation.goBack();
            player.stop();
          }}
          useCloseIconForBack
          circleBack
          transparent
          style={{ paddingTop: 16, paddingBottom: 16 }}
          actions={
            !isWeb ? (
              <HeaderAction
                loading={togglingLike}
                onPress={isLiked ? onUnlike : onLike}
                color={isLiked ? Colors.primary : Colors.black}
                Icon={isLiked ? GIcon.Bookmark : GIcon.BookmarkOutline}
                ref={generateTestHook("Profile.Bookmark")}
              />
            ) : null
          }
        />
      </View>
    </Container>
  );
}

const styles = StyleSheet.create({
  content: { flex: 1, marginBottom: 2 },
});
