import * as ImageManipulator from "expo-image-manipulator";
import * as React from "react";
import { Platform, StatusBar, StyleSheet, View } from "react-native";
import { RecoilRoot, useRecoilState } from "recoil";
import Colors from "../../../components/Colors";
import ControlBar from "./ControlBar";
import { EditingWindow } from "./EditingWindow";
import EditorContext from "./EditorContext";
import { Processing } from "./Processing";
import { editingModeState, imageDataState, processingState, readyState } from "./Store";
import { ImageEditorProps } from "./types";
import UniversalModal from "./UniversalModal";

const noScroll = require("no-scroll");

function ImageEditorCore(props: ImageEditorProps) {
  //
  const {
    asView,
    visible,
    imageUri,
    mode = "full",
    throttleBlur = true,
    minimumCropDimensions = { width: 0, height: 0 },
    fixedCropAspectRatio: fixedAspectRatio = 1.6,
    lockAspectRatio = false,
    allowedTransformOperations,
    allowedAdjustmentOperations,
    onEditingComplete,
  } = props;

  const [, setImageData] = useRecoilState(imageDataState);
  const [, setReady] = useRecoilState(readyState);
  const [, setEditingMode] = useRecoilState(editingModeState);

  // Initialise the image data when it is set through the props
  React.useEffect(() => {
    const initialise = async () => {
      if (imageUri) {
        const enableEditor = () => {
          setReady(true);
          // Set no-scroll to on
          noScroll.on();
        };
        // Platform check
        if (Platform.OS === "web") {
          // eslint-disable-next-line no-undef
          const img = document.createElement("img");
          img.onload = () => {
            setImageData({
              uri: imageUri ?? "",
              height: img.height,
              width: img.width,
            });
            enableEditor();
          };
          img.src = imageUri;
        } else {
          const { width: pickerWidth, height: pickerHeight } =
            await ImageManipulator.manipulateAsync(imageUri, []);
          setImageData({
            uri: imageUri,
            width: pickerWidth,
            height: pickerHeight,
          });
          enableEditor();
        }
      }
    };
    initialise();
  }, [imageUri, setImageData, setReady]);

  const onCloseEditor = () => {
    // Set no-scroll to off
    noScroll.off();
    props.onCloseEditor();
  };

  React.useEffect(() => {
    // Reset the state of things and only render the UI
    // when this state has been initialised
    if (!visible) {
      setReady(false);
    }

    setEditingMode("crop");
  }, [setEditingMode, setReady, visible]);

  return (
    <EditorContext.Provider
      value={{
        mode,
        minimumCropDimensions,
        lockAspectRatio,
        fixedAspectRatio,
        throttleBlur,
        allowedTransformOperations,
        allowedAdjustmentOperations,
        onCloseEditor,
        onEditingComplete,
      }}
    >
      <StatusBar hidden={visible} />
      {asView ? (
        <ImageEditorView {...props} />
      ) : (
        <UniversalModal visible={visible} presentationStyle="fullScreen" statusBarTranslucent>
          <ImageEditorView />
        </UniversalModal>
      )}
    </EditorContext.Provider>
  );
}

function ImageEditorView() {
  const [ready] = useRecoilState(readyState);
  const [processing] = useRecoilState(processingState);

  return (
    <>
      {ready ? (
        <View style={styles.container}>
          <ControlBar />
          <EditingWindow />
        </View>
      ) : null}
      {processing ? <Processing /> : null}
    </>
  );
}

export default function ImageEditor(props: ImageEditorProps) {
  return (
    <RecoilRoot>
      <ImageEditorCore {...props} />
    </RecoilRoot>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.lightGray,
  },
});
