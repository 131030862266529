import client from "../client";
import {
  APPLICATION_APPROVE,
  APPLICATION_CREATE,
  APPLICATION_REJECT,
  QUERY_APPLICATIONS,
} from "./graphql";

function applications() {
  return client
    .query({
      variables: {},
      query: QUERY_APPLICATIONS,
    })
    .then((response) => response.applications);
}

function applicationCreate({ email, country, socialUrl, platform, numberOfFollowers }) {
  return client
    .mutate({
      variables: {
        email,
        country,
        platform,
        socialUrl,
        numberOfFollowers,
      },
      mutation: APPLICATION_CREATE,
    })
    .then((response) => response.applicationCreate);
}

function applicationApprove(id) {
  return client
    .mutate({
      variables: {
        id,
      },
      mutation: APPLICATION_APPROVE,
    })
    .then((response) => response.applicationApprove);
}

function applicationReject(id) {
  return client
    .mutate({
      variables: {
        id,
      },
      mutation: APPLICATION_REJECT,
    })
    .then((response) => response.applicationReject);
}

export default {
  applications,
  applicationCreate,
  applicationApprove,
  applicationReject,
};
