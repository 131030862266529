import * as ImagePicker from "expo-image-picker";
import React from "react";
import { ActivityIndicator, Alert, Image, Keyboard, TouchableOpacity, View } from "react-native";
import Api from "../../app/api/Api";
import store from "../../app/store/Store";
import Cavy from "../../app/test/Cavy";
import Errors from "../../app/util/Errors";
import UsernameValidator from "../../app/util/UsernameValidator";
import Colors from "../../components/Colors";
import EnableCameraPermission from "../../components/permissions/EnableCameraPermission";
import EnableCameraRollPermission from "../../components/permissions/EnableCameraRollPermission";
import MediaPicker from "../../components/MediaPicker";
import { Container, Content, Header, Text2 } from "../../components/ui";
import { InputView } from "../../components/ui/inputs";
import GIcon from "../../components/ui/GIcon";

export default function EditProfileScreen({ navigation }) {
  const generateTestHook = Cavy.useCavy();
  const { user } = store;
  const isCreator = store.isCreator();

  const [changed, setChanged] = React.useState(false);

  const [bio, setBio] = React.useState(user.bio);
  const [name, setName] = React.useState(user.name);
  const [media, setMedia] = React.useState(user.media);
  const [username, setUsername] = React.useState(user.username);
  const [usernameError, setUsernameError] = React.useState("");

  const [loading, setLoading] = React.useState(false);
  const [updatingAvatar, setUpdatingAvatar] = React.useState(false);

  const showImagePicker = MediaPicker.useImagePicker(
    { ratio: 1.0, lockAspectRatio: true },
    navigation
  );

  const [cameraEnabled, setCameraEnabled] = React.useState(false);
  const [askForCameraPermission, setAskForCameraPermission] = React.useState(false);

  const [photosEnabled, setPhotosEnabled] = React.useState(false);
  const [askForPhotosPermission, setAskForPhotosPermission] = React.useState(false);

  React.useEffect(() => {
    ImagePicker.getCameraPermissionsAsync()
      .then(({ status }) => setCameraEnabled(status === "granted"))
      .catch(Errors.log);

    ImagePicker.getMediaLibraryPermissionsAsync()
      .then(({ status }) => setPhotosEnabled(status === "granted"))
      .catch(Errors.log);
  }, []);

  const validateInput = () => {
    return username;
  };

  function onSave() {
    Keyboard.dismiss();

    const mediaId = media ? media.id : null;

    setLoading(true);

    Api.user
      .userUpdate(user.id, { username, name, bio, media: mediaId })
      .then((updatedUser) => {
        setLoading(false);
        store.setUser(updatedUser);
        navigation.goBack();
      })
      .catch((error) => {
        setLoading(false);
        Alert.alert(`${error.message}`);
      });
  }

  function onNoPermissions() {
    if (!cameraEnabled) {
      setAskForCameraPermission(true);
    }

    if (!photosEnabled) {
      setAskForPhotosPermission(true);
    }
  }

  const hasPermissions = cameraEnabled && photosEnabled;

  async function onChangeAvatar() {
    if (!hasPermissions) {
      return onNoPermissions();
    }

    try {
      setChanged(true);
      setUpdatingAvatar(true);
      setMedia(await showImagePicker());
    } catch (error) {
      console.log(error);
    } finally {
      setUpdatingAvatar(false);
    }

    return true;
  }

  function validateUsername(text) {
    try {
      UsernameValidator.validate(text);
      setUsernameError("");
    } catch (error) {
      setUsernameError(error.message);
    }
  }

  if (askForCameraPermission) {
    return (
      <EnableCameraPermission
        onGranted={() => {
          setCameraEnabled(true);
          setAskForCameraPermission(false);
        }}
        onCancel={() => setAskForCameraPermission(false)}
      />
    );
  }

  if (askForPhotosPermission) {
    return (
      <EnableCameraRollPermission
        onGranted={() => {
          setPhotosEnabled(true);
          setAskForPhotosPermission(false);
        }}
        onCancel={() => setAskForPhotosPermission(false)}
      />
    );
  }

  return (
    <Container>
      <Header
        circleBack
        title="Edit profile"
        useCloseIconForBack
        onBack={navigation.goBack}
        right={
          changed
            ? {
                loading,
                label: "Save",
                onPress: !validateInput() || updatingAvatar ? null : onSave,
              }
            : null
        }
      />

      <Content showsVerticalScrollIndicator={false}>
        <View style={{ padding: 20 }}>
          <View style={{ alignItems: "center" }}>
            <TouchableOpacity activeOpacity={0.8} onPress={updatingAvatar ? null : onChangeAvatar}>
              {!updatingAvatar && media && media.url ? (
                <Image
                  style={{ width: 120, height: 120, borderRadius: 60 }}
                  source={{ uri: media.url.replace("300x300", "600x600") }}
                />
              ) : (
                <View
                  style={{
                    width: 120,
                    height: 120,
                    borderRadius: 60,
                    justifyContent: "center",
                    backgroundColor: Colors.lightGray,
                  }}
                >
                  <GIcon.Person style={{ fontSize: 64, color: Colors.gray, alignSelf: "center" }} />
                  <View
                    style={{
                      width: 60,
                      margin: 4,
                      borderRadius: 15,
                      alignSelf: "center",
                      backgroundColor: Colors.primary,
                    }}
                  >
                    <Text2 style={{ color: Colors.white, textAlign: "center" }}>EDIT</Text2>
                  </View>
                  {updatingAvatar && <ActivityIndicator />}
                </View>
              )}
            </TouchableOpacity>
          </View>

          <InputView
            ref={generateTestHook("Edit.Name")}
            testId={"Edit.Name"}
            value={name}
            label="Name"
            autoCorrect={false}
            onChangeText={(text) => {
              setChanged(true);
              setName(text);
            }}
          />

          <InputView
            ref={generateTestHook("Edit.Username")}
            testId={"Edit.Username"}
            value={username}
            label="Username"
            error={usernameError}
            autoCapitalize="none"
            autoCorrect={false}
            onChangeText={(text) => {
              setChanged(true);
              setUsername(text.trim());
              validateUsername(text.trim());
            }}
          />

          {isCreator ? (
            <InputView
              ref={generateTestHook("Edit.Bio")}
              label="Bio"
              autoCorrect={false}
              value={bio}
              multiline
              onChangeText={(text) => {
                setChanged(true);
                setBio(text);
              }}
            />
          ) : null}
        </View>
      </Content>
    </Container>
  );
}
