const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const MONTHS3 = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

function nextMonth(date = new Date()) {
  const d = new Date(Date.parse(date.toJSON()));
  d.setMonth(d.getMonth() + 1);
  return d;
}

function previousMonth(date = new Date()) {
  const d = new Date(Date.parse(date.toJSON()));
  d.setMonth(d.getMonth() - 1);
  return d;
}

function now() {
  return new Date();
}

function lastDay(date = new Date()) {
  const [y, m] = date.toJSON().split("-");

  const days = [31, 30, 29, 28];

  for (let i = 0; i < days.length; i++) {
    const parsedDate = Date.parse(`${y}-${m}-${days[i]}`);
    if (parsedDate) {
      const newDate = new Date(parsedDate);
      const [year, month] = newDate.toJSON().split("-");

      if (year === y && month === m) {
        return newDate;
      }
    }
  }

  return null;
}

function firstDay(date = new Date()) {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

function getMonthDetails(date = new Date()) {
  const endDate = lastDay(date).toJSON().split("T")[0];
  const [y, m, d] = endDate.split("-").map((i) => parseInt(i, 10));
  const mmm = MONTHS3[m];

  return {
    label: `${MONTHS[m - 1]} ${y}`,
    startEnd: `${mmm} 1 - ${mmm} ${d}`,
  };
}

export default {
  now,
  lastDay,
  firstDay,
  nextMonth,
  previousMonth,
  getMonthDetails,
};
