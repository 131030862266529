import React from "react";
import { View } from "react-native";
import { screenWidth } from "../../../app/util/util";
import Colors from "../../../components/Colors";
import mapStyle from "../../../components/map/mapStyle";
import MapMarker from "../../../components/map/Marker";
import MapView, { GoogleMapProvider } from "../../../components/MapView";

export default function GuidesMap({ guides, onGuide, style }) {
  const ref = React.useRef(null);
  const region = {
    ...guides[0].location,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
  };

  React.useEffect(() => {
    setTimeout(() => ref.current?.fitToElements(true), 1000);
  }, []);

  if (guides.length === 0) {
    return null;
  }

  return (
    <View style={style}>
      <MapView
        ref={ref}
        region={region}
        initialRegion={region}
        customMapStyle={mapStyle}
        provider={GoogleMapProvider}
        mapPadding={{ bottom: 20, left: 0, right: 0, top: 20 }}
        style={{
          borderWidth: 1,
          borderRadius: 8,
          width: screenWidth - 32,
          height: screenWidth - 32,
          borderColor: Colors.snowBank,
        }}
      >
        {guides
          .filter((guide) => guide.location)
          .map((guide, index) => (
            <MapMarker
              isGuide
              {...guide}
              key={guide.id}
              {...{ index }}
              onCalloutPress={() => onGuide(guide)}
            />
          ))}
      </MapView>
    </View>
  );
}
