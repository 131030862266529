import React from "react";
import { View } from "react-native";
import { isAndroid } from "../app/util/util";
import Colors from "./Colors";
import { PageTitle } from "./typography";

export default function PageHeader({ title, children }) {
  return (
    <View
      style={{
        marginLeft: 16,
        marginBottom: 8,
        flexDirection: "row",
        alignItems: "center",
        marginTop: isAndroid ? 50 : 70,
        justifyContent: "space-between",
      }}
    >
      <PageTitle style={{ color: Colors.black }}>{title}</PageTitle>
      {children}
    </View>
  );
}
