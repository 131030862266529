import React from "react";
import { View } from "react-native";
import Cavy from "../app/test/Cavy";
import ClearButton from "./ClearButton";
import Colors from "./Colors";

export default function ClosableView({ style, children, onClose }) {
  const generateTestHook = Cavy.useCavy();
  return (
    <View style={[{ borderRadius: 10 }, style]}>
      {children}
      {onClose ? (
        <ClearButton
          ref={generateTestHook("Dashboard.DeleteDraft")}
          color={Colors.black}
          onPress={onClose}
          backgroundColor={Colors.white}
          style={{ position: "absolute", top: -10, right: -14 }}
        />
      ) : null}
    </View>
  );
}
