import { observer } from "mobx-react";
import React from "react";
import { View } from "react-native";
import GIcon from "../../components/ui/GIcon";
import { SearchInputView } from "../../components/ui/inputs";
import TabsView from "../profile/ui/TabsView";

const CREATORS_TABS = {
  CHECK: "signed-up",
  REMOVE: "not-signed-up",
};

function CreatorsHeaderView({ n, tab, onTab, onClear, onChangeText }) {
  const [select, onSelect] = React.useState(tab);

  return (
    <View style={{ paddingTop: 7 }}>
      <TabsView
        tabs={[
          { id: CREATORS_TABS.CHECK, title: "Signed up", Icon: GIcon.AccountCheck },
          { id: CREATORS_TABS.REMOVE, title: "Not signed up", Icon: GIcon.AccountRemove },
        ].filter((t) => t)}
        {...{ select }}
        onSelect={(t) => {
          onTab(t);
          onSelect(t);
        }}
        style={{ marginTop: 1, marginHorizontal: 16, marginBottom: 6 }}
      />
      <SearchInputView {...{ onClear, onChangeText }} placeholder={`Search creators...(${n})`} />
    </View>
  );
}

export default observer(CreatorsHeaderView);
