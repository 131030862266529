/* eslint-disable no-param-reassign */
const Errors = {
  INVALID: "Invalid username",
  VERY_SHORT: "Username can not be shorter than 5 characters",
  VERY_LONG: "Username can not be longer than 20 characters",
  NOT_ALLOWED_CHARACTER:
    "Only letters A-Z, numbers, underscores, and periods are allowed. Max 20 characters.",
  STARTS_OR_ENDS_WITH_PERIOD: "Username can not starts or ends with period",
  CONSECUTIVE_PERIODS: "Username can not contains two consecutive periods",
  PERIOD_FOLLOWED_BY_UNDERSCORE: "Username can not contains period followed by underscore",
  UNDERSCORE_FOLLOWED_BY_PERIOD: "Username can not contains underscore followed by a period",
};

function validate(username) {
  if (!username) {
    throw new Error(Errors.INVALID);
  }

  username = username.trim();

  if (username.length < 5) {
    throw new Error(Errors.VERY_SHORT);
  }

  if (username.length > 20) {
    throw new Error(Errors.VERY_LONG);
  }

  if (!/^[0-9A-Za-z_.-]+$/.test(username)) {
    throw new Error(Errors.NOT_ALLOWED_CHARACTER);
  }

  if (username.startsWith(".") || username.endsWith(".")) {
    throw new Error(Errors.STARTS_OR_ENDS_WITH_PERIOD);
  }

  if (username.includes("..")) {
    throw new Error(Errors.CONSECUTIVE_PERIODS);
  }

  if (username.includes("._")) {
    throw new Error(Errors.PERIOD_FOLLOWED_BY_UNDERSCORE);
  }

  if (username.includes("_.")) {
    throw new Error(Errors.UNDERSCORE_FOLLOWED_BY_PERIOD);
  }
}

export default {
  Errors,
  validate,
};
