import { Audio, Video } from "expo-av";
import React from "react";
import { Image, Pressable, View } from "react-native";
import Errors from "../../../app/util/Errors";
import { isWeb, ScreenSize } from "../../../app/util/util";
import Colors from "../../../components/Colors";
import ImageViewing from "../../../components/ImageViewing";
import PlaceInfoView from "../../../components/place/PlaceInfoView";
import PlaceMapView from "../../../components/place/PlaceMapView";
import PlayerView from "../../../components/PlayerView";
import { Header2 } from "../../../components/typography";
import { Text1, Text2, TouchableOpacity } from "../../../components/ui";
import GIcon from "../../../components/ui/GIcon";
import ScaledImage from "../../../components/ui/ScaledImage";

const screenWidth = ScreenSize.width;

const mediaWidth = ScreenSize.width - 32;

function QuoteView({ style, question, answer }) {
  return (
    <View style={[{ padding: 24 }, style]}>
      <Header2 style={{ marginBottom: 24 }}>{question}</Header2>
      <Text1 style={{ fontSize: 16, lineHeight: 22 }}>{answer}</Text1>
    </View>
  );
}

export function PlaceMediaView({ uri, width, media, style, isVisible }) {
  const video = React.useRef(null);
  const [status, setStatus] = React.useState({});
  const [visible, setVisible] = React.useState(false);

  React.useEffect(() => {
    Audio.setAudioModeAsync({ playsInSilentModeIOS: true }).catch(Errors.log);
  }, []);

  React.useEffect(() => {
    return () => {
      if (status.isPlaying && video.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        video.current.pauseAsync();
      }
    };
  }, [isVisible, status.isPlaying]);

  if (media && media.type === "video") {
    return (
      <View
        style={[
          {
            width: mediaWidth,
            marginHorizontal: 16,
            height: width / media.ratio,
          },
          style,
        ]}
      >
        <Video
          ref={video}
          style={{
            width: mediaWidth,
            height: width / media.ratio,
            position: isWeb ? "absolute" : "relative",
          }}
          source={{ uri: media.url }}
          onLoad={async () => {
            if (!isWeb) {
              await video.current?.playAsync();
              await video.current?.pauseAsync();
            }
          }}
          useNativeControls
          resizeMode="cover"
          onPlaybackStatusUpdate={(newStatus) => {
            setStatus(() => newStatus);
            if (newStatus.didJustFinish) {
              if (video.current) {
                video.current.setStatusAsync({ positionMillis: 0 });
              }
            }
          }}
        />

        {!status.isPlaying && status.positionMillis === 0 ? (
          <View
            style={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              position: "absolute",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {isWeb ? (
              <ScaledImage
                uri={media.thumbnailUrl}
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  resizeMode: "cover",
                  width: width - 32,
                }}
              />
            ) : null}

            <TouchableOpacity onPress={() => video?.current?.playAsync(true)}>
              <GIcon.Play style={{ color: Colors.white, fontSize: 72 }} />
            </TouchableOpacity>
          </View>
        ) : null}
      </View>
    );
  }

  return (
    <View
      style={[
        {
          marginBottom: 16,
          marginHorizontal: 16,
          backgroundColor: Colors.white,
        },
        style,
      ]}
    >
      <Pressable onPress={() => setVisible(true)}>
        <Image
          source={{ uri }}
          style={{
            width: width - 32,
            height: width / media.ratio,
          }}
        />
      </Pressable>

      {!isWeb ? (
        <ImageViewing
          imageIndex={0}
          visible={visible}
          images={[{ uri }]}
          onRequestClose={() => setVisible(false)}
        />
      ) : null}

      {media.audio ? (
        <PlayerView
          style={{
            height: 52,
            marginTop: 16,
            marginBottom: 16,
            alignSelf: "center",
            backgroundColor: Colors.white,
            width: width - (isWeb ? 44 : 32),
          }}
          media={media.audio}
        />
      ) : null}

      {media.text ? (
        <Text1
          style={{
            fontSize: 18,
            paddingTop: 16,
            lineHeight: 26,
            paddingHorizontal: 2,
            color: Colors.black,
          }}
        >
          {media.text}
        </Text1>
      ) : null}
    </View>
  );
}

export default function PlaceView({ index, place, isVisible }) {
  const { audio, quotes } = place;
  let { media } = place;

  const medias = [];
  (place.medias || [])
    .filter((item) => item)
    .forEach((item) => {
      if (item.type !== "video" && !media) {
        media = item;
      } else {
        medias.push(item);
      }
    });

  return (
    <View style={{}}>
      <Text2
        style={{
          fontSize: 30,
          marginTop: 24,
          paddingHorizontal: 16,
          textAlign: "center",
          color: Colors.black,
        }}
      >
        {place.name}
      </Text2>
      {media ? (
        <PlaceMediaView
          style={{ marginTop: 12 }}
          {...{
            media,
            isVisible,
            text: media.text,
            width: screenWidth,
            uri: media.url.replace("300x300", "600x600"),
          }}
        />
      ) : null}

      {audio ? (
        <PlayerView
          style={{
            height: 52,
            marginTop: 12,
            alignSelf: "center",
            width: screenWidth - 32,
          }}
          media={audio}
        />
      ) : null}

      {quotes && quotes.length > 0 ? <QuoteView {...quotes[0]} /> : null}

      {medias && medias.length > 0 ? (
        <PlaceMediaView
          style={{ marginTop: 16 }}
          media={medias[0]}
          {...{
            isVisible,
            width: screenWidth,
            text: medias[0].text,
            uri: medias[0].url.replace("300x300", "600x600"),
          }}
        />
      ) : null}

      {quotes && quotes.length > 1 ? <QuoteView style={{ marginTop: 16 }} {...quotes[1]} /> : null}

      {medias && medias.length > 1 ? (
        <PlaceMediaView
          style={{ marginTop: 16 }}
          media={medias[1]}
          {...{
            isVisible,
            width: screenWidth,
            text: medias[1].text,
            uri: medias[1].url.replace("300x300", "600x600"),
          }}
        />
      ) : null}

      {quotes && quotes.length > 2 ? <QuoteView style={{ marginTop: 16 }} {...quotes[2]} /> : null}

      {medias && medias.length > 2 ? (
        <PlaceMediaView
          media={medias[2]}
          style={{ marginTop: 16 }}
          {...{
            isVisible,
            width: screenWidth,
            text: medias[2].text,
            uri: medias[2].url.replace("300x300", "600x600"),
          }}
        />
      ) : null}

      <PlaceInfoView phone={place.phone} website={place.website} active style={{ marginTop: 10 }} />

      {!isWeb && place.id ? (
        <PlaceMapView style={{ marginTop: 16 }} lite hidePopup {...{ place, index }} />
      ) : null}
    </View>
  );
}
