import React from "react";
import { FlatList, TouchableOpacity, View } from "react-native";
import { useQuestions } from "../../app/api/question/questionHooks";
import { navigateToEditQuestion } from "../../app/navigation/Navigator";
import Cavy from "../../app/test/Cavy";
import { Container, Header, Text2 } from "../../components/ui";
import GIcon from "../../components/ui/GIcon";
import LoadingView from "../../components/ui/LoadingView";

const TouchQuestion = Cavy.wrap(TouchableOpacity);

export default function QuestionsScreen({ navigation }) {
  const generateTestHook = Cavy.useCavy();
  const { isLoading, data } = useQuestions();

  if (isLoading) {
    return <LoadingView />;
  }

  function renderRow(question) {
    return (
      <TouchQuestion
        ref={generateTestHook("Question.Select")}
        key={question.id}
        onPress={() => {
          navigateToEditQuestion(navigation, { question });
        }}
      >
        <View
          style={{
            borderBottomColor: "#d4d4d4",
            borderBottomWidth: 1,
            padding: 16,
            marginLeft: 16,
          }}
        >
          <Text2 style={{ fontSize: 16 }}>{question.text}</Text2>
        </View>
      </TouchQuestion>
    );
  }

  return (
    <Container>
      <Header
        onBack={() => navigation.goBack()}
        title="Guide questions"
        actions={
          <Header.Action
            ref={generateTestHook("Question.Add")}
            Icon={GIcon.Add}
            onPress={() => navigateToEditQuestion(navigation, {})}
          />
        }
      />
      <FlatList
        data={data}
        keyboardShouldPersistTaps="handled"
        renderItem={({ item: question }) => renderRow(question)}
      />
    </Container>
  );
}
