import React from "react";
import { TouchableOpacity, View } from "react-native";
import Cavy from "../app/test/Cavy";
import Colors from "./Colors";
import GIcon from "./ui/GIcon";

const ClearButton = Cavy.wrap(({ Icon, style, color, borderColor, backgroundColor, onPress }) => {
  const IconView = Icon || GIcon.Close;

  return (
    <TouchableOpacity
      activeOpacity={0.9}
      style={{
        height: 32,
        minWidth: 32,
        paddingHorizontal: 8,
        justifyContent: "center",
        ...style,
      }}
      {...{ onPress }}
    >
      <View
        style={{
          width: 24,
          height: 24,
          borderRadius: 12,
          shadowColor: Colors.black,
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.25,
          shadowRadius: 3.84,
          elevation: 5,
          alignItems: "center",
          borderColor: borderColor || Colors.steam,
          backgroundColor: backgroundColor || Colors.black,
        }}
      >
        <IconView
          name="close"
          style={{
            fontSize: 16,
            color: color || Colors.white,
            marginTop: 4,
            marginLeft: 1,
          }}
        />
      </View>
    </TouchableOpacity>
  );
});

export default ClearButton;
