import React from "react";
import { navigateToGuide } from "../../app/navigation/Navigator";
import GuideGridView from "../../components/guide/GuideGridView";
import TipView from "../../components/TipView";
import { Container, Header } from "../../components/ui";
import EmptyView from "../../components/ui/EmptyView";

export default function GuidesScreen({ route, navigation }) {
  const { title, guides, note } = route.params;

  function onGuide(guide) {
    navigateToGuide(navigation, { guideId: guide.id });
  }

  return (
    <Container>
      <Header {...{ title }} onBack={() => navigation.goBack()} />

      {guides.length > 0 ? (
        <GuideGridView
          {...{ guides, onGuide }}
          ListHeaderComponent={
            note ? (
              <TipView
                hasBorder
                style={{ marginHorizontal: 16, marginBottom: 16, marginTop: 32 }}
                text={note}
              />
            ) : null
          }
        />
      ) : (
        <EmptyView message="No guides to show!" />
      )}
    </Container>
  );
}
