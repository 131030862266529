import { observer } from "mobx-react";
import React from "react";
import { TouchableOpacity, View } from "react-native";
import { useQuery } from "react-query";
import Api from "../../app/api/Api";
import { queryClient } from "../../app/api/client";
import { useUser } from "../../app/api/user/userHooks";
import Queries from "../../app/constants/Queries";
import {
  navigateToEditGuide,
  navigateToGuide,
  navigateToGuides,
  navigateToResources,
} from "../../app/navigation/Navigator";
import store from "../../app/store/Store";
import AlertDialog from "../../components/AlertDialog";
import Colors from "../../components/Colors";
import ArchiveView from "../../components/guide/ArchiveView";
import CreateGuideView from "../../components/guide/CreateGuideView";
import GuideGridView from "../../components/guide/GuideGridView";
import Status from "../../components/guide/Status";
import { Container, Text2, Text2SB } from "../../components/ui";
import LoadingView from "../../components/ui/LoadingView";
import Errors from "../../app/util/Errors";
import PageHeader from "../../components/PageHeader";

export default observer(function DashboardScreen({ navigation }) {
  const userId = store.getUserId();

  const [, setDeleting] = React.useState(false);
  const [deleteGuideId, setDeleteGuideId] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const { isLoading: loadingUser, data: user } = useUser(userId);

  const { isLoading: loadingGuides, data: guides } = useQuery([Queries.USER_GUIDES, userId], () =>
    Api.guide.userGuides(userId, 0, 1000)
  );

  if (loadingUser || loadingGuides) {
    return <LoadingView />;
  }

  const drafts = guides.filter((guide) => guide.status === Status.DRAFT || !guide.status);

  const pendingGuides = guides.filter((guide) => guide.status === Status.PENDING);
  const archivedGuides = guides.filter((guide) => guide.status === Status.ARCHIVED);
  const publishedGuides = guides.filter((guide) => guide.status === Status.PUBLISHED);

  function onArchivedGuides() {
    navigateToGuides(navigation, {
      title: "Archived guides",
      guides: archivedGuides,
      note: "An archived guide is not visible on your profile or in search results and can not be purchased, but the people who already purchased it can still view it. You can re-publish it at any time.",
    });
  }

  function onResources() {
    navigateToResources(navigation, {
      title: "Resources for creators",
      buttonLabel: "Read tips",
      onSelect: (option) => console.log(option),
    });
  }

  let myGuidesTitle = "Published guides";
  if (pendingGuides.length > 0 && publishedGuides.length > 0) {
    myGuidesTitle = "Published & pending guides";
  } else if (pendingGuides.length > 0) {
    myGuidesTitle = "Pending guides";
  }

  const nrGuides = pendingGuides.length + publishedGuides.length;

  function Header() {
    return (
      <View>
        <PageHeader title="Dashboard">
          {store.isAdmin() ? (
            <TouchableOpacity onPress={() => onResources()}>
              <View
                style={{
                  height: 28,
                  borderWidth: 1,
                  marginRight: 16,
                  borderRadius: 6,
                  alignItems: "center",
                  flexDirection: "row",
                  borderColor: Colors.primary,
                  backgroundColor: Colors.white,
                }}
              >
                <Text2SB style={{ color: Colors.primary, fontSize: 14, marginHorizontal: 12 }}>
                  Resources
                </Text2SB>
              </View>
            </TouchableOpacity>
          ) : null}
        </PageHeader>

        <CreateGuideView
          onCreate={() => navigateToEditGuide(navigation, {})}
          {...{ drafts, onDelete }}
          onEdit={({ id: guideId }) => navigateToEditGuide(navigation, { guideId })}
        />

        {archivedGuides.length > 0 ? (
          <ArchiveView {...{ archivedGuides, onArchivedGuides }} />
        ) : null}

        {nrGuides > 0 ? (
          <Text2
            style={{
              flex: 1,
              color: "#000",
              fontSize: 16,
              marginLeft: 16,
              marginBottom: 5,
            }}
          >
            {myGuidesTitle}
          </Text2>
        ) : null}
      </View>
    );
  }

  function onDelete(guide) {
    setDeleteGuideId(guide);
  }

  function doDelete(guide) {
    setDeleting(true);
    Api.guide
      .guideDelete(guide.id)
      .catch(Errors.showGenericMessage)
      .finally(() => setDeleting(false));
  }

  if (queryClient.isFetching()) {
    // console.log("At least one query is fetching!");
  }

  return (
    <Container>
      <GuideGridView
        showBadges
        ListHeaderComponent={<Header />}
        guides={pendingGuides.concat(publishedGuides)}
        onGuide={({ id: guideId }) => navigateToGuide(navigation, { guideId })}
      />
      <AlertDialog
        title="Delete"
        message="Are you sure?"
        visible={!!deleteGuideId}
        options={[
          { text: "No", onPress: () => setDeleteGuideId(null) },
          {
            text: "Yes",
            onPress: () => {
              doDelete(deleteGuideId);
              setDeleteGuideId(null);
            },
          },
        ]}
      />
    </Container>
  );
});
