import React from "react";
import { Keyboard, View } from "react-native";
import Api from "../../app/api/Api";
import store from "../../app/store/Store";
import Errors from "../../app/util/Errors";
import UsernameValidator from "../../app/util/UsernameValidator";
import Colors from "../../components/Colors";
import { Header1 } from "../../components/typography";
import { Container, Content, Header, Text2 } from "../../components/ui";
import { InputView } from "../../components/ui/inputs";

function isValidUsername(username) {
  return /^[a-zA-Z0-9_]{1,15}$/.test(username);
}

function checkUsername(value) {
  if (value.length > 15) {
    throw new Error("Username is longer than 20");
  } else if (value.length < 4) {
    throw new Error("Username is less than 4 characters");
  } else if (value.includes("guidepal")) {
    throw new Error("Username can not contains_guidepal");
  } else if (!isValidUsername(value)) {
    throw new Error("Invalid username");
  }
}

export default function UsernameScreen({ route, navigation }) {
  const { email, phone, userToken, password } = route.params;

  const {
    user: { id },
    token,
  } = userToken;

  const [username, setUsername] = React.useState("");
  const [usernameError, setUsernameError] = React.useState("");
  const [loading, setLoading] = React.useState(false);

  function onUsername() {
    Keyboard.dismiss();

    try {
      checkUsername(username);
    } catch (e) {
      Errors.showErrorMessage(e);
    }

    setLoading(true);
    Api.user
      .userUpdate(id, { email, phone, username, password, token })
      .then((user) => {
        setLoading(false);
        store.setToken(token);
        store.setUser(user);
        navigation.navigate("Root");
      })
      .catch((e) => {
        setLoading(false);
        Errors.showErrorMessage(e);
      });
  }

  function validateUsername(text) {
    try {
      UsernameValidator.validate(text);
      setUsernameError("");
    } catch (error) {
      setUsernameError(error.message);
    }
  }

  return (
    <Container>
      <Header
        transparent
        onBack={() => navigation.goBack(null)}
        right={{ label: "Next", onPress: username ? onUsername : null, loading }}
      />

      <Content
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ flex: 1 }}
        style={{
          flex: 1,
          paddingBottom: 24,
          paddingHorizontal: 24,
          flexDirection: "column",
        }}
      >
        <View
          style={{
            flex: 1,
            justifyContent: "center",
          }}
        >
          <Header1 style={{ textAlign: "center", color: Colors.black }}>Pick a username</Header1>

          <View style={{ alignItems: "center", marginTop: 5 }}>
            <Text2 style={{ color: "#555", textAlign: "center", lineHeight: 20 }}>
              You can change this later
            </Text2>
          </View>
          <InputView
            error={usernameError}
            value={username}
            autoCapitalize="none"
            onChangeText={(text) => {
              setUsername(text.trim());
              validateUsername(text.trim());
            }}
            style={{ borderWidth: 1, height: 70 }}
          />
        </View>
      </Content>
    </Container>
  );
}
