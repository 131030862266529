import { useNavigation } from "@react-navigation/native";
import React from "react";
import { Alert } from "react-native";
import Api from "../../../app/api/Api";
import { navigateToGuide } from "../../../app/navigation/Navigator";
import Errors from "../../../app/util/Errors";
import Colors from "../../../components/Colors";
import Status from "../../../components/guide/Status";
import { LogoDark, LogoNew } from "../../../components/Logo";
import { Header } from "../../../components/ui";

export default function EditGuideAppBar({ guide }) {
  const hasCover = !!guide.media;
  const navigation = useNavigation();
  const [publishing, setPublishing] = React.useState(false);

  let rightButton = null;

  if (guide.status === Status.DRAFT) {
    rightButton = { label: "Publish", onPress: onPublish, loading: publishing };
  } else if (guide.status === Status.PENDING) {
    rightButton = {
      label: "Pending",
      onPress: () => {
        Alert.alert("Your guide is sent for review and will get back to you when approved!");
      },
    };
  } else if (guide.status === Status.ARCHIVED) {
    rightButton = { label: "Archived", onPress: null };
  } else if (guide.status === Status.PUBLISHED) {
    rightButton = { label: "Published", onPress: null };
  }

  rightButton = {
    label: "Preview",
    outlined: true,
    primary: true,
    style: { marginRight: -6 },
    onPress: () => {
      if (guide.places.lenght === 0) {
        return Alert.alert("There must be at least One Place to be able to go into preview mode!");
      }
      navigateToGuide(navigation, { guideId: guide.id });
    },
  };

  function onPublish() {
    setPublishing(true);
    Api.guide
      .guideUpdate(guide.id, { status: Status.PENDING })
      .then(() =>
        Alert.alert("Your guide is sent for review and will get back to you when approved!")
      )
      .catch(Errors.showErrorMessage)
      .finally(() => setPublishing(false));
  }

  return (
    <Header
      circleBack
      transparent
      useCloseIconForBack
      right={rightButton}
      style={{ paddingBottom: 8 }}
      onBack={() => navigation.goBack()}
      Logo={hasCover ? LogoNew : LogoDark}
      color={hasCover ? Colors.white : Colors.black}
    />
  );
}
