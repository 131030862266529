import React from "react";
import { View } from "react-native";
import Colors from "../../../components/Colors";
import { Text1 } from "../../../components/ui";

export default function GuideDescriptionView({ text }) {
  return (
    <View
      style={{
        marginTop: 16,
        marginBottom: 8,
      }}
    >
      <Text1 style={{ lineHeight: 24, fontSize: 18, color: Colors.black, borderWidth: 0 }}>
        {text}
      </Text1>
    </View>
  );
}
