import { gql } from "graphql-request";
import { GUIDE_MODEL } from "../models";

export const GUIDE_ADD_PLACE = gql`
  mutation guideAddPlace($placeId: String, $guideId: String) {
    guideAddPlace(placeId: $placeId, guideId: $guideId) {
      ${GUIDE_MODEL}
    }
  }
`;

export const GUIDE_REMOVE_PLACE = gql`
  mutation guideRemovePlace($placeId: String, $guideId:String) {
    guideRemovePlace(placeId: $placeId, guideId: $guideId) {
      ${GUIDE_MODEL}
    }
  }
`;

export const GUIDE_BUY = gql`
  mutation guideBuy(
    $id: String,
    $orderId: String
    $appleReceipt: String
    $googleReceipt: String
    $iCloudUserId: String
  ) {
    guideBuy(
      id: $id
      orderId: $orderId
      appleReceipt: $appleReceipt
      googleReceipt: $googleReceipt
      iCloudUserId: $iCloudUserId
    ) {
      ${GUIDE_MODEL}
    }
  }
`;

export const GUIDE_LIKE = gql`
  mutation guideLike(
    $id: String
  ) {
    guideLike(
      id: $id
    ) {
      ${GUIDE_MODEL}
    }
  }
`;

export const GUIDE_UNLIKE = gql`
  mutation guideUnlike(
    $id: String
  ) {
    guideUnlike(
      id: $id
    ) {
      ${GUIDE_MODEL}
    }
  }
`;

export const GUIDE_VIEWED = gql`
  mutation guideViewed(
    $id: String
  ) {
    guideViewed(
      id: $id
    ) {
      ${GUIDE_MODEL}
    }
  }
`;

export const GUIDE_DELETE = gql`
  mutation guideDelete(
    $id: String
  ) {
    guideDelete(
      id: $id
    ) {
      ${GUIDE_MODEL}
    }
  }
`;

export const GUIDE_CREATE = gql`
  mutation guideCreate(
    $name: String
    $audio: String
    $media: String
    $address: String
    $gPlaceId: String
    $description: String
    $location: LocationInput
  ) {
    guideCreate(
      name: $name
      audio: $audio
      media: $media
      address: $address
      location: $location
      gPlaceId: $gPlaceId,
      description: $description
    ) {
      ${GUIDE_MODEL}
    }
  }
`;

export const GUIDE_UPDATE = gql`
  mutation guideUpdate(
    $id: String
    $name: String
    $audio: String
    $media: String
    $status: String
    $isFree: Boolean
    $places: [String]
    $address: String
    $gPlaceId: String
    $adjustment: String
    $description: String
    $location: LocationInput
  ) {
    guideUpdate(
      id: $id
      name: $name
      audio: $audio
      media: $media
      isFree: $isFree
      places: $places
      status: $status
      address: $address
      gPlaceId: $gPlaceId
      location: $location
      adjustment: $adjustment
      description: $description
    ) {
      ${GUIDE_MODEL}
    }
  }
`;

export const LIKED_GUIDES = gql`
  query likedGuides($skip: Int, $limit: Int) {
    likedGuides(skip: $skip, limit: $limit) {
      ${GUIDE_MODEL}
    }
  }
`;

export const PURCHASED_GUIDES = gql`
  query purchasedGuides($skip: Int, $limit: Int, $iCloudUserId: String) {
    purchasedGuides(skip: $skip, limit: $limit, iCloudUserId: $iCloudUserId) {
      ${GUIDE_MODEL}
    }
  }
`;

export const PENDING_GUIDES = gql`
  query pendingGuides($skip: Int, $limit: Int) {
    pendingGuides(skip: $skip, limit: $limit) {
      ${GUIDE_MODEL}
    }
  }
`;

export const POPULAR_GUIDES = gql`
  query popularGuides($skip: Int, $limit: Int) {
    popularGuides(skip: $skip, limit: $limit) {
      ${GUIDE_MODEL}
    }
  }
`;

export const PUBLISHED_GUIDES = gql`
  query publishedGuides($skip: Int, $limit: Int) {
    publishedGuides(skip: $skip, limit: $limit) {
      ${GUIDE_MODEL}
    }
  }
`;

export const GUIDE_GET = gql`
  query guide($id: String) {
    guide(id: $id){
      ${GUIDE_MODEL}
    }
  }
`;

export const USER_GUIDES = gql`
  query userGuides($userId: String, $skip: Int, $limit: Int) {
    userGuides(userId: $userId, skip: $skip, limit: $limit) {
      ${GUIDE_MODEL}
    }
  }
`;

export const SEARCH_GUIDES = gql`
  query searchGuides($keywords: String, $location: LocationInput, $skip: Int, $limit: Int) {
    searchGuides(keywords: $keywords, location: $location, skip: $skip, limit: $limit) {
      ${GUIDE_MODEL}
    }
  }
`;
