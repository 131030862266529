import { observer } from "mobx-react";
import React from "react";
import {
  Alert,
  findNodeHandle,
  KeyboardAvoidingView,
  ScrollView,
  TextInput,
  View,
} from "react-native";
import Api from "../../app/api/Api";
import { useGuide } from "../../app/api/guide/guideHooks";
import { navigateToShareGuide } from "../../app/navigation/Navigator";
import Errors from "../../app/util/Errors";
import { isIOS } from "../../app/util/util";
import AlertDialog from "../../components/AlertDialog";
import Colors from "../../components/Colors";
import Status from "../../components/guide/Status";
import { createAudioMedia } from "../../components/MediaPicker";
import StepsContainer, { StepView } from "../../components/StepsContainer";
import { Container, Header, Text2 } from "../../components/ui";
import ButtonView from "../../components/ui/ButtonView";
import ErrorView from "../../components/ui/ErrorView";
import LoadingView from "../../components/ui/LoadingView";
import { EditRecordView } from "../place/ui/MediaView";
import usePermissions from "../place/ui/usePermissions";
import { checkGuide, checkPlace, isPlaceDone } from "./GuideScreen/util";
import EditGuideAppBar from "./ui/EditGuideAppBar";
import EditGuideDescription from "./ui/EditGuideDescription";
import EditGuideHeader from "./ui/EditGuideHeader";
import EditGuidePlaces from "./ui/EditGuidePlaces";
import GuideMap from "./ui/GuideMap";
import LocationPicker from "./ui/LocationPicker";

function EditGuideMap({ guide }) {
  return (
    <GuideMap
      lite
      {...{ guide }}
      style={{ marginTop: 24 }}
      onPlace={(placeId) => console.log(placeId)}
    />
  );
}

function isPlacesDone(places) {
  try {
    places.forEach(checkPlace);
    return places.length === 6;
  } catch (e) {
    return false;
  }
}

function EditGuideStatusView({ guide, style }) {
  return (
    <StepsContainer {...{ style }}>
      <StepView done={guide.location}>Location</StepView>
      <StepView done={guide.name}>Title</StepView>
      <StepView done={guide.media}>Cover</StepView>
      <StepView done={guide.description || guide.audio}>Audio/Text</StepView>
      <StepView done={isPlacesDone(guide.places)}>{`${
        guide.places.filter(isPlaceDone).length
      }/6 Completed places`}</StepView>
    </StepsContainer>
  );
}

function EditGuideScreen({ route, navigation }) {
  const scrollRef = React.useRef(null);

  const [guideId, setGuideId] = React.useState(route.params.guideId);
  const [savingOrder, setSavingOrder] = React.useState(false);
  const [isComplete, setIsComplete] = React.useState(false);
  const [publishing, setPublishing] = React.useState(false);
  const [removeAudio, setRemoveAudio] = React.useState(null);
  const [savedAudio, setSavedAudio] = React.useState(false);
  const [savingAudio, setSavingAudio] = React.useState(false);
  const [removePlaceId, setRemovePlaceId] = React.useState(null);
  const [showEditLocation, setShowEditLocation] = React.useState(!route.params.guideId);
  const [loading, setLoading] = React.useState(false);
  const [solidAppBar, setSolidAppBar] = React.useState(false);

  const { error, isLoading, data: guide } = useGuide(guideId);
  const { recording, camera, photos } = usePermissions();

  const [audio, setAudio] = React.useState(null);

  React.useEffect(() => {
    if (guide) {
      setAudio(guide.audio);
    }
    try {
      checkGuide(guide);
      setIsComplete(true);
    } catch (e) {
      console.log(e.message);
      setIsComplete(false);
    }
  }, [guide]);

  if (recording.view) {
    return recording.view();
  }

  if (camera.view) {
    return camera.view();
  }

  if (photos.view) {
    return photos.view();
  }

  if (!guideId || showEditLocation) {
    return (
      <Container>
        <Header
          transparent
          circleBack
          useCloseIconForBack
          onBack={() => {
            setShowEditLocation(false);
            if (!guideId) {
              navigation.goBack();
            }
          }}
        />
        <View style={{ flex: 1, marginTop: 16 }}>
          <Text2 style={{ fontSize: 20, marginHorizontal: 16 }}>
            {guideId ? "Update guide location" : "Where do you guide us?"}
          </Text2>
          <LocationPicker
            title={guideId ? "Update guide location" : "Create guide"}
            onDone={(locationInfo) =>
              guideId ? onUpdateLocationInfo(locationInfo) : onCreate(locationInfo)
            }
            loading={loading}
            placeholder={guide?.address || "Search country, city or area"}
          />
        </View>
      </Container>
    );
  }

  if (isLoading && !guide) {
    return <LoadingView />;
  }

  if (error) {
    return <ErrorView error="Something went wrong, try again!" />;
  }

  const places = [...(guide.places || [])];

  const nrPlaces = (places || []).length;
  for (let i = nrPlaces; i < 6; i++) {
    places.push(null);
    if ((i + 1) % 3 === 0 && i > 3) {
      break;
    }
  }

  function onRemovePlace(place) {
    setRemovePlaceId(place.id);
  }

  function doRemovePlace(placeId) {
    setSavingOrder(true);
    Api.guide
      .guideRemovePlace(placeId, guideId)
      .catch(Errors.showErrorMessage)
      .finally(() => setSavingOrder(false));
  }

  function onSaveOrder(items) {
    setSavingOrder(true);
    Api.guide
      .guideUpdate(guideId, { places: items })
      .catch(Errors.showErrorMessage)
      .finally(() => setSavingOrder(false));
  }

  async function askForPermissions() {
    if (!photos.enabled) {
      photos.ask(true);
    }
    if (!camera.enabled) {
      camera.ask(true);
    }
  }

  async function onAudio(params) {
    if (!recording.enabled) {
      recording.ask(true);
    }

    setSavingAudio(true);

    let mediaId = null;
    if (params) {
      const { uri, duration } = params;
      const value = { uri, duration, type: "audio" };

      const media = await createAudioMedia(value);
      mediaId = media.id;
    }

    Api.guide
      .guideUpdate(guideId, { audio: mediaId })
      .then(() => {
        setSavedAudio(true);
        setTimeout(() => {
          setSavedAudio(false);
        }, 1000);
      })
      .catch(Errors.showErrorMessage)
      .finally(() => setSavingAudio(false));
  }

  function onEditLocation() {
    setShowEditLocation(true);
  }

  async function onPublish() {
    if (!guide.user.media?.url) {
      Alert.alert("Please add a profile picture to your profile!");
      return;
    }

    try {
      checkGuide(guide);
    } catch (err) {
      Errors.showErrorMessage(err);
      return;
    }

    setPublishing(true);
    try {
      await Api.guide.guideUpdate(guide.id, { status: Status.PUBLISHED }).then(() => {
        navigateToShareGuide(navigation, { guideId: guide.id });
        Alert.alert(`Yay, your guide "${guide.name}" is now published! Don't forget to share it!`);
      });
    } catch (e) {
      Errors.showErrorMessage(e);
    } finally {
      setPublishing(false);
    }
  }

  function onCreate({ gPlaceId, address, latitude, longitude }) {
    setLoading(true);
    Api.guide
      .guideCreate({
        name: "",
        gPlaceId,
        media: null,
        address,
        isDraft: true,
        description: null,
        location: { latitude, longitude },
      })
      .then(({ id }) => {
        setShowEditLocation(false);
        setGuideId(id);
      })
      .catch(Errors.showGenericMessage)
      .finally(() => setLoading(false));
  }

  function onUpdateLocationInfo({ gPlaceId, address, latitude, longitude }) {
    setLoading(true);
    Api.guide
      .guideUpdate(guideId, { gPlaceId, address, location: { latitude, longitude } })
      .then(({ id }) => {
        setShowEditLocation(false);
        setGuideId(id);
      })
      .catch(Errors.showErrorMessage)
      .finally(() => setLoading(false));
  }

  function onCaptionFocus() {
    if (TextInput.State.currentlyFocusedInput) {
      setTimeout(() => {
        console.log("currently focused", TextInput.State.currentlyFocusedInput());
        scrollRef.current
          .getScrollResponder()
          .scrollResponderScrollNativeHandleToKeyboard(
            findNodeHandle(TextInput.State.currentlyFocusedInput()),
            50,
            true
          );
      }, 100);
    }
  }

  return (
    <KeyboardAvoidingView style={{ flex: 1 }} behavior={isIOS ? "padding" : "height"}>
      <ScrollView
        ref={scrollRef}
        scrollEventThrottle={16}
        keyboardShouldPersistTaps="handled"
        showsVerticalScrollIndicator={false}
        style={{ backgroundColor: Colors.white }}
        onScroll={(event) => setSolidAppBar(event.nativeEvent.contentOffset.y > 440)}
      >
        <EditGuideHeader {...{ guide, onEditLocation, askForPermissions }} />

        {!isComplete ? (
          <EditGuideStatusView style={{ marginTop: 16, marginHorizontal: 16 }} {...{ guide }} />
        ) : null}

        <EditRecordView
          audio={audio}
          color={Colors.rainyGray}
          title="Tell us about your guide"
          onSave={(params) => onAudio(params)}
          onDelete={() => setRemoveAudio(true)}
          style={{ marginTop: 16, marginHorizontal: 16 }}
          {...{ savedAudio, savingAudio }}
        />

        <EditGuideDescription
          {...{ guide }}
          onFocus={onCaptionFocus}
          style={{ marginTop: 16, marginHorizontal: 16 }}
        />

        <EditGuidePlaces
          style={{ marginTop: 16, paddingHorizontal: 16 }}
          {...{ guide, onSaveOrder, savingOrder, places, onRemovePlace }}
        />
        <EditGuideMap {...{ guide }} />
      </ScrollView>

      <View
        style={{
          left: 0,
          right: 0,
          position: "absolute",
          backgroundColor: solidAppBar ? Colors.white : Colors.transparent,
        }}
      >
        <EditGuideAppBar {...{ guide }} />
      </View>
      <AlertDialog
        title="Delete"
        message="Are you sure?"
        visible={!!removePlaceId}
        options={[
          { text: "No", onPress: () => setRemovePlaceId(null) },
          {
            text: "Yes",
            onPress: () => {
              doRemovePlace(removePlaceId);
              setRemovePlaceId(null);
            },
          },
        ]}
      />

      <AlertDialog
        title="Delete"
        message="Are you sure?"
        visible={!!removeAudio}
        options={[
          { text: "No", onPress: () => setRemoveAudio(null) },
          {
            text: "Yes",
            onPress: () => {
              onAudio();
              setRemoveAudio(null);
            },
          },
        ]}
      />

      {guide.status !== Status.PUBLISHED ? (
        <ButtonView
          loading={publishing}
          bgColor={Colors.primary}
          onPress={() => onPublish()}
          style={{
            left: 0,
            right: 0,
            bottom: 12,
            height: 48,
            padding: 12,
            position: "absolute",
            marginHorizontal: 16,
          }}
        >
          Publish guide
        </ButtonView>
      ) : null}
    </KeyboardAvoidingView>
  );
}

export default observer(EditGuideScreen);
