import React from "react";
import { Image, View } from "react-native";
import Typical from "react-native-typical";
import photo from "../../../../assets/images/home.jpg";
import Cavy from "../../../app/test/Cavy";
import { screenWidth } from "../../../app/util/util";
import Colors from "../../../components/Colors";
import { Text2, TouchableOpacity } from "../../../components/ui";
import GIcon from "../../../components/ui/GIcon";
import { LogoNew } from "../../../components/Logo";

const messages = [
  { discover: "your new favorite dive bar", curatedBy: "a local musician" },
  { discover: "new roadtrip routes", curatedBy: "your favorite youtuber" },
  {
    discover: "designer boutiques in SoHo",
    curatedBy: "your fav fashion influencer",
  },
  {
    discover: "the ultimate shopping guide",
    curatedBy: "the dog you follow on instagram",
  },
  { discover: "the perfect date night", curatedBy: "a fitness guru" },
  { discover: "retro arcades", curatedBy: "smooth talking podcasters" },
  { discover: "spa getaways", curatedBy: "your favorite beauty tiktoker" },
  { discover: "a completely new city", curatedBy: "award nominated actors" },
  {
    discover: "underground nightclubs",
    curatedBy: "best-selling erotica writers",
  },
  {
    discover: "the trendiest pop-ups",
    curatedBy: "your favorite photographer",
  },
  {
    discover: "the best matcha latte in town",
    curatedBy: `a wellness "expert"`,
  },
  {
    discover: "dog-friendly hiking trails",
    curatedBy: "a celebrity crossfit trainer",
  },
];

export default function HomeHeader({ onSearch }) {
  const generateTestHook = Cavy.useCavy();

  const [index, setIndex] = React.useState(0);
  React.useEffect(() => {
    setInterval(() => {
      setIndex((idx) => (idx + 1) % messages.length);
    }, 6000);
  }, []);

  return (
    <View style={{ height: 272, width: screenWidth, marginBottom: 10 }}>
      <Image
        style={{ position: "absolute", height: 272, width: screenWidth }}
        source={photo}
        resizeMode="cover"
      />

      <LogoNew
        style={{
          marginTop: 44,
          marginHorizontal: 16,
          justifyContent: "flex-start",
        }}
      />

      <View style={{ flex: 1, marginHorizontal: 16 }}>
        <Text2
          style={{
            fontSize: 20,
            letterSpacing: 0.5,
            color: Colors.white,
          }}
        >
          Discover
          {` `}
          <Typical
            style={{ fontSize: 20, fontFamily: "OutfitMedium" }}
            steps={[messages[index].discover, 0]}
            editDelay={80}
            deleteDelay={10}
          />
        </Text2>
        <Text2
          style={{
            fontSize: 20,
            letterSpacing: 0.5,
            color: Colors.white,
          }}
        >
          curated by
          {` `}
          <Typical
            style={{ fontSize: 20, fontFamily: "OutfitMedium" }}
            steps={[messages[index].curatedBy, 0]}
            editDelay={80}
            deleteDelay={10}
          />
        </Text2>
      </View>
      <TouchableOpacity
        activeOpacity={0.8}
        style={{ marginBottom: 20, marginHorizontal: 16 }}
        ref={generateTestHook("Home.Search")}
        onPress={onSearch}
      >
        <View
          style={{
            height: 40,
            borderRadius: 10,
            alignItems: "center",
            flexDirection: "row",
            backgroundColor: Colors.lightGray,
            borderColor: Colors.middleGray,
          }}
        >
          <GIcon.Search style={{ marginLeft: 8, color: Colors.basaltGray, fontSize: 24 }} />

          <Text2 style={{ fontSize: 16, marginLeft: 8, color: Colors.basaltGray }}>
            Search guides, creators ...
          </Text2>
        </View>
      </TouchableOpacity>
    </View>
  );
}
