import React from "react";
import { Alert, Keyboard, StyleSheet, View } from "react-native";
import Api from "../../app/api/Api";
import Cavy from "../../app/test/Cavy";
import Errors from "../../app/util/Errors";
import { Container, Content, Header } from "../../components/ui";
import ButtonView from "../../components/ui/ButtonView";
import { PasswordInputView } from "../../components/ui/inputs";

export default function ChangePasswordScreen({ navigation }) {
  const [, setLoading] = React.useState(false);

  const [oldPassword, setOldPassword] = React.useState("");
  const [newPassword, setNewPassword] = React.useState("");
  const [confirmPassword, setConfirmPassword] = React.useState("");

  function onChangePassword() {
    Keyboard.dismiss();
    if (newPassword !== confirmPassword) {
      Alert.alert("New Password Mismatch");
      return;
    }
    if (newPassword.length < 6) {
      Alert.alert("Password should be more that 6 characters");
      return;
    }

    setLoading(true);
    Api.auth
      .updatePassword(oldPassword, newPassword)
      .then((message) => Alert.alert(message))
      .then(() => {
        setLoading(false);
        navigation.goBack(null);
      })
      .catch((error) => {
        setLoading(false);
        Errors.showErrorMessage(error);
      });
  }
  const generateTestHook = Cavy.useCavy();
  return (
    <Container>
      <Header title="Change password" onBack={navigation.goBack} />

      <Content style={styles.content}>
        <View style={styles.main}>
          <PasswordInputView
            ref={generateTestHook("Settings.ChangeOldPassword")}
            label="Old password"
            onChangeText={setOldPassword}
          />

          <PasswordInputView
            ref={generateTestHook("Settings.NewPassword")}
            label="New password"
            onChangeText={setNewPassword}
          />

          <PasswordInputView
            ref={generateTestHook("Settings.ConfirmPassword")}
            label="Confirm password"
            onChangeText={setConfirmPassword}
          />

          <ButtonView
            ref={generateTestHook("Settings.ChangePassword")}
            primary
            style={{ marginTop: 24 }}
            onPress={onChangePassword}
            disabled={!newPassword || !confirmPassword}
          >
            Change password
          </ButtonView>
        </View>
      </Content>
    </Container>
  );
}

const styles = StyleSheet.create({
  content: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  main: {
    flex: 1,
    paddingLeft: 20,
    paddingRight: 20,
    flexDirection: "column",
    justifyContent: "center",
  },
});
