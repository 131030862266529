import client from "../client";
import { QUERY_REPORTED_GUIDES, REPORT_CREATE, REPORT_DELETE, REPORT_UPDATE } from "./graphql";

function reportedGuides() {
  return client
    .query({
      variables: {},
      query: QUERY_REPORTED_GUIDES,
    })
    .then((response) => response.reportedGuides);
}

function reportCreate({ userId, guideId, category, message }) {
  return client
    .mutate({
      variables: {
        userId,
        guideId,
        category,
        message,
      },
      mutation: REPORT_CREATE,
    })
    .then((response) => response.reportCreate);
}

function reportUpdate(id, { category, message }) {
  return client
    .mutate({
      variables: {
        id,
        category,
        message,
      },
      mutation: REPORT_UPDATE,
    })
    .then((response) => response.reportUpdate);
}

function reportDelete(id) {
  return client
    .mutate({
      variables: {
        id,
      },
      mutation: REPORT_DELETE,
    })
    .then((response) => response.reportDelete);
}

export default {
  reportedGuides,
  reportCreate,
  reportUpdate,
  reportDelete,
};
