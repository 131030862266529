import client from "../client";
import { MEDIA_CREATE, MEDIA_GET, MEDIA_UPDATE } from "./graphql";

function mediaGet(id) {
  return client
    .query({
      variables: {
        id,
      },
      query: MEDIA_GET,
    })
    .then((response) => response.media);
}

function mediaUpdate(id, { text, audio, ratio, width, height, type, url }) {
  return client
    .mutate({
      variables: {
        id,
        text,
        audio,
        ratio,
        width,
        height,
        type,
        url,
      },
      mutation: MEDIA_UPDATE,
    })
    .then((response) => response.mediaUpdate);
}

function mediaCreate({ url, width, height, type, text, ratio, thumbnailUrl, audio }) {
  console.log("mediaCreate:", {
    url,
    width,
    height,
    type,
    text,
    ratio,
    thumbnailUrl,
    audio,
  });
  return client
    .mutate({
      variables: {
        url,
        type,
        text,
        ratio,
        width,
        height,
        thumbnailUrl,
        audio,
      },
      mutation: MEDIA_CREATE,
    })
    .then((response) => response.mediaCreate);
}

export default {
  mediaGet,
  mediaUpdate,
  mediaCreate,
};
