import { observer } from "mobx-react";
import React from "react";
import { ActivityIndicator, Pressable, View } from "react-native";
import { usePlayerStore } from "../app/store/PlayerStore";
import SavedView from "../features/guide/ui/SavedView";
import ClearButton from "./ClearButton";
import Colors from "./Colors";
import { Border, Radius } from "./Theme";
import { Text2 } from "./ui";
import GIcon from "./ui/GIcon";
import WaveFormView from "./WaveFormView";

const isWeb = false;

function LoadingMediaView() {
  return (
    <View
      style={{
        width: 40,
        height: 40,
        marginLeft: 8,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <ActivityIndicator color={Colors.primary} size="small" />
    </View>
  );
}

function PlayButtonView({ style, Icon }) {
  return (
    <View
      style={[
        {
          width: 40,
          height: 40,
          marginLeft: 8,
          alignItems: "center",
          justifyContent: "center",
        },
        style,
      ]}
    >
      <Icon style={{ fontSize: 32 }} color={Colors.primary} />
    </View>
  );
}

export default observer(
  ({ style, autoplay, preview, media, onDelete, savedAudio, savingAudio }) => {
    const player = usePlayerStore();
    const isActive = player.media && player.media.id === media.id;
    const Icon = isActive && player.isPlaying ? GIcon.Stop : GIcon.Play;

    function onPlayPausePressed() {
      player.play(media);
    }

    function onDeletePressed() {
      player.stop();
      onDelete();
    }

    React.useState(() => {
      if (autoplay) {
        player.pause().catch(() => null);
      }
      player.load(media).catch(() => null);
    }, []);

    React.useEffect(() => {
      if (preview) {
        onPlayPausePressed();
      }
    }, [preview]);

    const duration =
      (player.isPlaying || player.isPaused) && isActive ? player.getCountDownDuration() : "";
    return (
      <View
        style={[
          {
            height: 54,
            borderWidth: 1,
            borderRadius: Radius.M,
            alignItems: "center",
            flexDirection: "row",
            borderColor: Border.solidColor,
            backgroundColor: "#fff",
          },
          style,
        ]}
      >
        {!player.isPlaying &&
        isActive &&
        (player.isLoading || player.isBuffering || (player.webLoading && isWeb)) ? (
          <LoadingMediaView />
        ) : (
          <Pressable disabled={player.isLoading} onPress={onPlayPausePressed}>
            <PlayButtonView {...{ Icon }} />
          </Pressable>
        )}

        <WaveFormView style={{ flex: 1 }} playing={player.isPlaying} />

        <Text2
          style={{
            width: 64,
            fontSize: 18,
            marginRight: 8,
            textAlign: "center",
            color: Colors.black,
          }}
        >
          {duration}
        </Text2>

        <SavedView {...{ saving: savingAudio, saved: savedAudio }} />

        {onDelete && !savingAudio && !savedAudio ? (
          <ClearButton
            onPress={onDeletePressed}
            style={{ position: "absolute", top: -10, right: -14 }}
            backgroundColor="#fff"
            color="#000"
          />
        ) : null}
      </View>
    );
  }
);
