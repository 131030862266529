import React from "react";
import { TouchableOpacity, View } from "react-native";
import Cavy from "../../app/test/Cavy";
import { screenWidth } from "../../app/util/util";
import AddContainerView from "../AddContainerView";
import Colors from "../Colors";
import { Text2 } from "../ui";
import GIcon from "../ui/GIcon";
import GuideCellView from "./GuideCellView";

const tileWidth = (screenWidth - 48) / 2;

export default function CreateGuideView({ drafts, liked, downloaded, onEdit, onCreate, onDelete }) {
  const generateTestHook = Cavy.useCavy();

  const [guide0, guide1] = drafts;
  return (
    <View style={{ paddingVertical: 16, backgroundColor: Colors.white }}>
      <View style={{ flexDirection: "row" }}>
        {[guide0, guide1].map((guide) => {
          return !guide ? (
            <TouchableOpacity onPress={onCreate}>
              <AddContainerView
                onPress={onCreate}
                ref={generateTestHook("Dashboard.createGuide")}
                style={{
                  marginTop: 8,
                  marginRight: 8,
                  marginLeft: 12,
                  width: tileWidth,
                  height: tileWidth / 0.75,
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <GIcon.Book
                  style={{
                    fontSize: 72,
                    marginBottom: 12,
                    color: Colors.middleGray,
                  }}
                />

                <Text2
                  style={{
                    color: Colors.primary,
                    fontSize: 16,
                  }}
                >
                  Create a guide
                </Text2>
                <Text2 style={{ color: Colors.primary }}>in your footsteps</Text2>
              </AddContainerView>
            </TouchableOpacity>
          ) : (
            <GuideCellView
              key="Dashboard.draft"
              ref={generateTestHook("Dashboard.draft")}
              width={tileWidth}
              onPress={() => onEdit(guide)}
              {...{ guide, liked, downloaded }}
              onDelete={onDelete ? () => onDelete(guide) : null}
            />
          );
        })}
      </View>
    </View>
  );
}
