/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable import/no-unused-modules */
import * as React from "react";
import Cavy from "../../app/test/Cavy";
import ActionSheet from "./ActionSheet";
import { Provider } from "./context";
import { ActionSheetOptions } from "./types";

export default function ActionSheetProvider({ useNativeDriver, children }) {
  const generateTestHook = Cavy.useCavy();
  const actionSheetRef = React.useRef(null);

  const context = () => {
    return {
      showActionSheetWithOptions: (options: ActionSheetOptions, callback: (i: number) => void) => {
        actionSheetRef.current !== null &&
          actionSheetRef.current.showActionSheetWithOptions(options, callback);
      },
    };
  };

  return (
    <Provider value={context()}>
      {React.Children.only(children)}
      <ActionSheet ref={actionSheetRef} {...{ useNativeDriver, generateTestHook }} />
    </Provider>
  );
}
