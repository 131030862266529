import React from "react";
import { View } from "react-native";
import Colors from "../../../components/Colors";
import GIcon from "../../../components/ui/GIcon";
import { AnimatedSaveIcon } from "./SavingAnimation";

export default function SavedView({ saving, saved }) {
  return (
    <>
      {saving ? <AnimatedSaveIcon /> : null}

      {saved ? (
        <View
          style={{
            top: -4,
            right: -4,
            position: "absolute",
            borderRadius: 10,
            color: Colors.primary,
            backgroundColor: Colors.white,
          }}
        >
          <GIcon.Check
            style={{
              fontSize: 16,
              alignSelf: "center",
              color: Colors.primary,
            }}
          />
        </View>
      ) : null}
    </>
  );
}
