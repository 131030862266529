import { BlurView } from "expo-blur";
import React from "react";
import { Platform, View } from "react-native";
import { screenWidth } from "../../../app/util/util";
import Colors from "../../../components/Colors";
import mapStyle from "../../../components/map/mapStyle";
import MapMarker from "../../../components/map/Marker";
import MapView, { GoogleMapProvider } from "../../../components/MapView";
import GIcon from "../../../components/ui/GIcon";

export default function GuideMap({ lite, guide, onPress, onPlace, style, locked }) {
  const mapRef = React.useRef(null);
  const region = {
    ...guide.location,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
  };

  if (Platform.OS === "web") {
    return null;
  }

  React.useEffect(() => {
    setTimeout(() => mapRef.current?.fitToElements(true), 2000);
  }, []);

  return (
    <View style={style}>
      <MapView
        ref={mapRef}
        region={region}
        onPress={onPress}
        customMapStyle={mapStyle}
        // scrollEnabled={!lite}
        // pitchEnabled={!lite}
        // rotateEnabled={!lite}
        // zoomEnabled={!lite}
        provider={GoogleMapProvider}
        mapPadding={{ bottom: 0, left: 0, right: 0, top: 20 }}
        style={{
          width: screenWidth,
          height: screenWidth,
          borderColor: Colors.snowBank,
        }}
      >
        {(guide.places || [])
          .filter((place) => place.location)
          .map((place, index) => (
            <MapMarker
              key={place.id}
              {...{ index }}
              {...place}
              onCalloutPress={() => onPlace(place.id)}
            />
          ))}
      </MapView>
      {locked && (
        <BlurView
          intensity={70}
          tint="light"
          style={{
            height: screenWidth,
            position: "absolute",
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        />
      )}

      {locked ? (
        <View
          style={{
            borderRadius: 8,
            position: "absolute",
            alignSelf: "center",
            alignItems: "center",
            width: screenWidth - 32,
            height: screenWidth - 32,
            justifyContent: "center",
          }}
        >
          <GIcon.Lock style={{ color: Colors.darkerGray, fontSize: 40 }} />
        </View>
      ) : null}
    </View>
  );
}
