import React from "react";
import { ActivityIndicator, TouchableOpacity, View } from "react-native";
import Cavy from "../../app/test/Cavy";
import Colors from "../Colors";
import { Text2 } from "../ui";

function ButtonView({
  Icon,
  bgColor,
  gray,
  darkGray,
  style,
  small,
  outline,
  primary,
  loading,
  onPress,
  dontCenter,
  children,
  childrenColor,
  fontSize,
  disabled,
  transparent,
  secondary,
}) {
  let color = bgColor ? Colors.white : Colors.black;
  let borderColor = Colors.gray;
  let backgroundColor = bgColor || Colors.white;

  if (gray) {
    backgroundColor = Colors.lightGray;
  } else if (darkGray) {
    color = Colors.white;
    backgroundColor = Colors.darkGray;
  } else if (primary) {
    color = Colors.white;
    backgroundColor = Colors.primary;
  } else if (secondary) {
    color = Colors.black;
    backgroundColor = Colors.secondary;
  }

  if (transparent) {
    if (primary) {
      color = Colors.primary;
    } else if (secondary) {
      color = Colors.secondary;
    }
    backgroundColor = Colors.transparent;
  }

  if (outline) {
    backgroundColor = Colors.transparent;
    if (primary) {
      color = Colors.primary;
      borderColor = Colors.primary;
    } else if (secondary) {
      color = Colors.secondary;
      borderColor = Colors.secondary;
    }
  }

  return (
    <TouchableOpacity
      {...{ style }}
      activeOpacity={0.5}
      disabled={disabled || loading}
      onPress={loading ? null : onPress}
    >
      <View
        style={[
          {
            borderColor,
            backgroundColor,
            borderRadius: 100,
            alignItems: "center",
            flexDirection: "row",
            padding: small ? 10 : 16,
            borderWidth: outline ? 1 : 0,
          },
          style,
        ]}
      >
        {loading ? (
          <ActivityIndicator
            size="small"
            {...{ color }}
            style={{ marginLeft: 4, marginRight: 8, width: 14, height: 14 }}
          />
        ) : null}
        {Icon ? (
          <Icon
            style={{
              color,
              fontSize: small ? 16 : 24,
              marginLeft: 5,
              marginRight: small ? 10 : 16,
            }}
          />
        ) : null}
        <Text2
          style={{
            color: childrenColor || color,
            flex: 1,
            textAlign: dontCenter ? "left" : "center",
            fontSize: fontSize || (small ? 14 : 18),
          }}
        >
          {children}
        </Text2>
        {loading || (Icon && !dontCenter) ? <View style={{ width: 26 }} /> : null}
      </View>
    </TouchableOpacity>
  );
}

export default Cavy.wrap(ButtonView);
