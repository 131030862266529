/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import { Image } from "react-native";

export default class ScaledImage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    Image.getSize(this.props.uri, this.resizeImage);
  }

  resizeImage = (width, height) => {
    if (this.props.style.width && !this.props.height) {
      this.setState({
        ...this.state,
        width: this.props.style.width,
        height: height * (this.props.style.width / width),
      });
    } else if (!this.props.style.width && this.props.height) {
      this.setState({
        ...this.state,
        width: width * (this.props.height / height),
        height: this.props.height,
      });
    } else {
      this.setState({ ...this.state, width, height });
    }
  };

  render() {
    let { width, height } = this.props;

    if (width && height) {
      if (this.props.style.width) {
        width = this.props.style.width;
        height *= this.props.style.width / this.props.width;
      }
    }

    return (
      <Image
        source={{ uri: this.props.uri }}
        style={{
          height: height || this.state.height,
          width: width || this.state.width,
          resizeMode: "stretch",
          ...this.props.style,
        }}
      />
    );
  }
}
