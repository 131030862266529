import React from "react";
import { View } from "react-native";
import { Text2 } from "../../../components/ui";

import appstore from "../../../../assets/images/appstore.png";
import googleplay from "../../../../assets/images/googleplay.png";

export default function GetTheAppView({ style }) {
  return (
    <View style={{ textAlign: "center", ...style }}>
      <Text2>Get the app for better user experience!</Text2>

      <div style={{ marginTop: 20, textAlign: "center" }}>
        <a style={{ margin: 4 }} href="https://apps.apple.com/us/app/guidepal/id1595934848">
          <img src={appstore} alt="appstore" />
        </a>

        <a
          style={{ margin: 4 }}
          href="https://play.google.com/store/apps/details?id=com.guidepal.mobile"
        >
          <img src={googleplay} alt="googleplay" />
        </a>
      </div>
    </View>
  );
}
