import React from "react";
import { Animated, Easing } from "react-native";
import GIcon from "../../../components/ui/GIcon";
import Colors from "../../../components/Colors";

export const AnimatedSaveIcon = () => {
  const spinValue = new Animated.Value(0);

  Animated.loop(
    Animated.timing(spinValue, {
      toValue: 1,
      duration: 500,
      easing: Easing.linear,
      useNativeDriver: true,
    })
  ).start(() => spinValue.setValue(0));

  const spin = spinValue.interpolate({
    inputRange: [0, 1],
    outputRange: ["0deg", "360deg"],
  });

  return (
    <Animated.View
      style={{
        transform: [{ rotate: spin }],
        position: "absolute",
        top: -4,
        right: -4,
        backgroundColor: Colors.white,
        borderRadius: 8,
      }}
    >
      <GIcon.Sync style={{ fontSize: 16, color: Colors.primary }} />
    </Animated.View>
  );
};
