import React from "react";
import { Text, TouchableOpacity, View } from "react-native";
import { navigateToBrowse } from "../app/navigation/Navigator";
import Colors from "./Colors";

const TERMS_OF_USE = "https://www.guidepal.com/mterms";
const PRIVACY_POLICY = "https://www.guidepal.com/mprivacy";

export default function TermsAndPrivacyView({ navigation }) {
  const termsOfUse = () => navigateToBrowse(navigation, { url: TERMS_OF_USE });

  const privacyPolicy = () => navigateToBrowse(navigation, { url: PRIVACY_POLICY });

  return (
    <View>
      <Text
        style={{
          marginTop: 16,
          fontSize: 12,
          textAlign: "center",
          marginBottom: 2,
          color: Colors.black,
        }}
      >
        By continuing you agree to our
      </Text>
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <TouchableOpacity activeOpacity={0.5} onPress={termsOfUse}>
          <Text style={{ fontSize: 12, fontWeight: "bold", color: Colors.primary }}>
            Terms of use
          </Text>
        </TouchableOpacity>
        <Text
          style={{
            fontSize: 12,
            color: Colors.black,
            marginHorizontal: 4,
          }}
        >
          and
        </Text>
        <TouchableOpacity activeOpacity={0.5} onPress={privacyPolicy}>
          <Text style={{ fontSize: 12, fontWeight: "bold", color: Colors.primary }}>
            Privacy policy
          </Text>
        </TouchableOpacity>
      </View>
    </View>
  );
}
