import React from "react";
import { View } from "react-native";
import Colors from "./Colors";
import { Text2 } from "./ui";
import GIcon from "./ui/GIcon";

export default function TipView({ hasBorder, iconLeft, text, style }) {
  return (
    <View
      style={[
        {
          padding: 16,
          borderWidth: 1,
          borderRadius: 10,
          borderColor: hasBorder ? Colors.lightGray : Colors.transparent,
        },
        style,
      ]}
    >
      {iconLeft ? (
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GIcon.LightbulbOn
            style={{
              width: 48,
              fontSize: 24,
              textAlign: "center",
              color: Colors.gray,
              backgroundColor: Colors.white,
            }}
          />

          <Text2
            style={{
              lineHeight: 18,
              textAlign: "center",
              color: Colors.gray,
            }}
          >
            {text}
          </Text2>
        </View>
      ) : (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GIcon.LightbulbOn
            style={{
              marginTop: -32,
              width: 48,
              fontSize: 24,
              textAlign: "center",
              color: Colors.darkGray,
              backgroundColor: Colors.white,
            }}
          />
        </View>
      )}

      {iconLeft ? null : (
        <Text2
          style={{
            lineHeight: 18,
            textAlign: "center",
            color: Colors.darkGray,
          }}
        >
          {text}
        </Text2>
      )}
    </View>
  );
}
