/* eslint-disable eqeqeq */
import React from "react";
import Api from "../../../app/api/Api";
import Errors from "../../../app/util/Errors";
import { TextEditView } from "../../../components/ui/inputs";
import useSaveChanges from "../../../components/useSaveChanges";

export default function EditCaptionView({ media, hideAdd, ...props }) {
  const [text, setText] = React.useState(props.caption);
  const [saved, setSaved] = React.useState(false);
  const [saving, setSaving] = React.useState(false);

  const onSave = React.useCallback(
    (newText) => {
      setSaving(true);
      Api.media
        .mediaUpdate(media.id, { text: newText })
        .then(() => {
          setSaved(true);
          setTimeout(() => setSaved(false), 1000);
        })
        .catch(Errors.showErrorMessage)
        .finally(() => setSaving(false));
    },
    [media.id]
  );

  useSaveChanges({ data: text, onSave });

  return (
    <TextEditView
      testId={"Place.Caption"}
      {...props}
      {...{ text, setText, saved, saving, maxLenght: 200, placeholder: "Write about it" }}
    />
  );
}
