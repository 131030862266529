import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { Image, TouchableOpacity as RNTouchableOpacity, View } from "react-native";
import { navigateToProfile } from "../../../../app/navigation/Navigator";
import Cavy from "../../../../app/test/Cavy";
import { screenWidth, isWeb } from "../../../../app/util/util";
import Colors from "../../../../components/Colors";
import { Header, Text1, Text2 } from "../../../../components/ui";
import GIcon from "../../../../components/ui/GIcon";
import { GuideLocationView, GuideTitleView } from "../../ui";

const fallback = "http://s3.amazonaws.com/gajdavatar/dummyAvatar.jpg";

const TouchableOpacity = Cavy.wrap(RNTouchableOpacity);
const LikeButton = Cavy.wrap(Header.Action);

export default function GuideHeader({
  media,
  guide,
  onLike,
  isLiked,
  onUnlike,
  hasCover,
  navigation,
  onShareGuide,
  togglingLike,
}) {
  const generateTestHook = Cavy.useCavy();

  return (
    <View>
      {hasCover ? (
        <Image
          resizeMode="cover"
          style={{ height: screenWidth / 0.75, width: screenWidth }}
          source={{ uri: media.url.replace("300x300", "600x600") }}
        />
      ) : (
        <View
          style={{
            width: screenWidth,
            height: screenWidth / 0.75,
          }}
        />
      )}

      {hasCover ? (
        <View
          style={{
            width: screenWidth,
            position: "absolute",
            height: screenWidth / 0.75,
            backgroundColor: "rgba(0,0,0,0.1)",
          }}
        />
      ) : null}

      <View style={{ width: screenWidth, height: screenWidth / 0.75, position: "absolute" }}>
        <View
          style={{
            bottom: 0,
            padding: 16,
            width: screenWidth,
            position: "absolute",
            borderColor: Colors.white,
          }}
        >
          <LinearGradient
            colors={[Colors.transparent, "rgba(0,0,0,0.1)"]}
            style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0 }}
          />

          <GuideTitleView
            title={guide.name}
            color={hasCover ? Colors.white : Colors.black}
            style={{ marginTop: 13, width: screenWidth - 32 }}
          />

          <GuideLocationView
            address={guide.address}
            color={hasCover ? Colors.white : Colors.black}
            style={{ flex: 1, marginTop: 8, marginBottom: 16 }}
          />

          <View style={{ alignItems: "center", flexDirection: "row" }}>
            <TouchableOpacity
              ref={generateTestHook("Guide.UserProfile")}
              style={{ flex: 1 }}
              onPress={() => navigateToProfile(navigation, { userId: guide.user.id })}
            >
              <View style={{ flex: 1, flexDirection: "row", alignItems: "center" }}>
                <Image
                  source={{ uri: (guide.user.media || {}).url || fallback }}
                  style={{
                    width: 40,
                    height: 40,
                    marginRight: 8,
                    borderRadius: 20,
                    shadowOpacity: 0.2,
                    shadowColor: Colors.black,
                    shadowOffset: { width: 0, height: 0 },
                  }}
                />
                <View>
                  <Text1
                    style={{
                      fontSize: 16,
                      paddingRight: 8,
                      shadowOpacity: 0.3,
                      color: Colors.white,
                      shadowColor: Colors.black,
                      shadowOffset: { width: 0, height: 0 },
                    }}
                  >
                    In the footsteps of
                  </Text1>

                  <Text2
                    style={{
                      fontSize: 16,
                      paddingRight: 8,
                      shadowOpacity: 0.3,
                      color: Colors.white,
                      shadowColor: Colors.black,
                      shadowOffset: { width: 0, height: 0 },
                    }}
                  >
                    {guide.user.name}
                  </Text2>
                </View>
              </View>
            </TouchableOpacity>

            {!isWeb ? (
              <TouchableOpacity
                activeOpacity={0.5}
                onPress={onShareGuide}
                ref={generateTestHook("Guide.Share")}
              >
                <View
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 24,
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "#ffffffe6",
                  }}
                >
                  <GIcon.ShareOutline style={{ padding: 4, color: Colors.black, fontSize: 20 }} />
                </View>
              </TouchableOpacity>
            ) : null}

            {!isWeb ? (
              <View
                style={{
                  width: 40,
                  height: 40,
                  marginLeft: 8,
                  borderRadius: 24,
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#ffffffe6",
                }}
              >
                <LikeButton
                  style={{ padding: 4 }}
                  loading={togglingLike}
                  onPress={isLiked ? onUnlike : onLike}
                  ref={generateTestHook("Guide.Like")}
                  color={isLiked ? Colors.black : Colors.black}
                  Icon={isLiked ? GIcon.Bookmark : GIcon.BookmarkOutline}
                />
              </View>
            ) : null}
          </View>
        </View>
      </View>
    </View>
  );
}
