import React from "react";
import { View } from "react-native";
import { QAButton } from "./QAButton";

export default function RenderQuotesView({
  quoteList,
  options,
  editAnswer,
  quote0,
  quote1,
  quote2,
  setPlace,
  place,
  onPlaceUpdate,
}) {
  if (quoteList.filter((q) => q).length === 0) {
    return null;
  }

  return (
    <View style={{ marginTop: 16, marginHorizontal: 20 }}>
      {quoteList
        .filter((q) => q)
        .map((quote, index) => (
          <QAButton
            options={options}
            key={index}
            quote={quote}
            style={{ marginBottom: 8 }}
            onEdit={() => editAnswer(options[index])}
            onPress={() => editAnswer(options[index])}
            onClear={() => {
              const updatedQuotes = [quote0, quote1, quote2];
              updatedQuotes[index] = null;
              setPlace({ ...place, quotes: updatedQuotes });

              onPlaceUpdate({
                quotes: updatedQuotes
                  .filter((q) => q)
                  .map(({ question, answer }) => ({ question, answer })),
              });
            }}
          />
        ))}
    </View>
  );
}
