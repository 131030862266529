import React from "react";
import { TouchableOpacity, View } from "react-native";
import Cavy from "../../../app/test/Cavy";
import { screenWidth } from "../../../app/util/util";
import Colors from "../../../components/Colors";
import { Text2 } from "../../../components/ui";
import GIcon from "../../../components/ui/GIcon";
import PlaceCellView from "./PlaceCellView";

const size = Math.floor((screenWidth - 50) / 3);

const TouchablePlaceCellView = Cavy.wrap(
  ({ place, style, index, hideIndex, showName, locked, onPress }) => {
    return (
      <TouchableOpacity
        onPress={onPress}
        activeOpacity={0.9}
        style={{
          width: size,
          height: size + 24,
          marginVertical: 5,
          marginLeft: index % 3 !== 0 ? 9 : 0,
        }}
      >
        <PlaceCellView {...{ place, index, showName, locked, hideIndex, style }} />
      </TouchableOpacity>
    );
  }
);

export const ShowAllPlacesCellView = Cavy.wrap(({ onPress }) => {
  return (
    <TouchableOpacity
      onPress={onPress}
      activeOpacity={0.9}
      style={{
        width: size,
        height: size,
        marginVertical: 5,
        marginLeft: 9,
      }}
    >
      <View
        style={{
          width: size,
          height: size,
          borderRadius: 8,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text2 style={{ color: Colors.gray }}>Show all</Text2>
        <View
          style={{
            backgroundColor: Colors.lightGray,
            width: 48,
            height: 48,
            marginTop: 4,
            borderRadius: 24,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GIcon.ArrowForward style={{ fontSize: 32 }} />
        </View>
      </View>
    </TouchableOpacity>
  );
});

export default TouchablePlaceCellView;
