import { Alert } from "react-native";

const Sentry = { Native: { captureException: () => null } };
function log(error) {
  console.log(error);
}

function showErrorMessage(error) {
  if (error?.message) {
    Alert.alert(error.message);
  } else {
    Alert.alert("Something went wrong!");
  }

  console.log(error);
}

function showGenericMessage(error) {
  Sentry.Native.captureException(error);
  Alert.alert("Something went wrong!");
  console.log(error);
}

export default { log, showErrorMessage, showGenericMessage };
