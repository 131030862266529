import React from "react";
import { Alert, View } from "react-native";
import Api from "../../app/api/Api";
import Errors from "../../app/util/Errors";
import AlertDialog from "../../components/AlertDialog";
import Colors from "../../components/Colors";
import { Container, Content, Header } from "../../components/ui";
import ButtonView from "../../components/ui/ButtonView";
import { InputView } from "../../components/ui/inputs";

export default function EditCreatorScreen({ route, navigation }) {
  const creator = route.params.creator || {};
  const [, setLoading] = React.useState(false);
  const [emails, setEmails] = React.useState(creator.emails);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  function onDone() {
    navigation.goBack();
  }

  const onUpdate = React.useCallback(
    (role) => {
      setLoading(true);
      Api.user
        .updateRole(emails, role)
        .then(() => {
          setLoading(false);
          Alert.alert(role === "user" ? "Downgraded successfully!" : "Upgraded successfully!");
          navigation.goBack();
        })
        .catch((error) => {
          setLoading(false);
          Errors.log(error);
        });
    },
    [emails, navigation]
  );

  function onUpgrade() {
    onUpdate("creator");
  }

  function onDowngrade() {
    setShowDeleteDialog(true);
  }

  return (
    <Container>
      <Header
        transparent
        title="Manage creators"
        left={{ label: "Cancel", onPress: navigation.goBack }}
        right={{ label: "Done", onPress: onDone }}
      />

      <Content
        showsVerticalScrollIndicator={false}
        style={{ flex: 1, marginHorizontal: 16, paddingTop: 24 }}
      >
        <InputView
          testId={"Creators.EnterEmail"}
          multiline
          autoCapitalize="none"
          label="Enter emails comma separated"
          placeholder="test@gmail.com, test2@yahoo.com"
          style={{
            marginTop: 24,
            borderWidth: 1,
            paddingTop: 16,
            minHeight: 128,
            borderRadius: 8,
            borderColor: Colors.superSilver,
            paddingHorizontal: 16,
          }}
          value={emails}
          onChangeText={setEmails}
        />

        <ButtonView primary style={{ marginTop: 16 }} onPress={onUpgrade}>
          Approve/Upgrade to creator
        </ButtonView>

        <ButtonView primary outline style={{ marginTop: 16 }} onPress={onDowngrade}>
          Downgrade to consumer
        </ButtonView>

        <View style={{ flex: 1, height: 100 }} />

        <AlertDialog
          title="Downgrade"
          message="Are you sure?"
          visible={showDeleteDialog}
          options={[
            { text: "No", onPress: () => setShowDeleteDialog(false) },
            {
              text: "Yes",
              onPress: () => {
                onUpdate("user");
                setShowDeleteDialog(null);
              },
            },
          ]}
        />
      </Content>
    </Container>
  );
}
