import * as React from "react";
import { EditorContextType } from "./types";

const EditorContext = React.createContext<EditorContextType>({
  throttleBlur: true,
  minimumCropDimensions: {
    width: 0,
    height: 0,
  },
  fixedAspectRatio: 1.6,
  lockAspectRatio: false,
  mode: "full",
  onCloseEditor: () => {},
  onEditingComplete: () => {},
});

export default EditorContext;
