export const MEDIA_MODEL = `
id
url
type
text
ratio
width
height
duration
thumbnailUrl
audio{
  id
  url
  type
  duration
}
`;

export const USER_MODEL = `
id
bio
name
role
email
phone
username
country
nrGuides
approvedBy{
  id
  name
  email
  username
}
nrPlaces
media {
  ${MEDIA_MODEL}
}
likedPlaces
likedGuides
followers
following
purchasedGuides
stripeAccountReady
`;

export const PLACE_MODEL = `
id
name
likes
price
phone
website
address
gPlaceId
category
categories
originalName
location {
    latitude
    longitude
}
media {
  ${MEDIA_MODEL}
}
audio {
  ${MEDIA_MODEL}
}
quotes {
    question
    answer
}
medias {
   ${MEDIA_MODEL}
}
guide {
  id
}
`;

export const GUIDE_MODEL = `
id
log
name
sales
likes
isFree
status
address
gPlaceId,
adjustment
description
audio {
  ${MEDIA_MODEL}
}
media {
  ${MEDIA_MODEL}
}
places {
  ${PLACE_MODEL}
}
nrPlaces
nrViews
user {
  ${USER_MODEL}
}
location {
  latitude
  longitude
}
createdAt
updatedAt
`;

export const THEME_MODEL = `
id
name
createdAt
updatedAt
description
`;

export const QUESTION_MODEL = `
id
text
createdAt
updatedAt
description
`;

export const APPLICATION_MODEL = `
id
email
country
platform
socialUrl
createdAt
updatedAt
numberOfFollowers
`;

export const PAYOUT_MODEL = `
id
year
month
webAmount
appleAmount
googleAmount
webSalesNr
appleSalesNr
googleSalesNr
payoutDate
paidOn
status
user{
  ${USER_MODEL}
}
createdAt
updatedAt
`;

export const EVENT_MODEL = `
id
type
message
platform
version
source
user{
  ${USER_MODEL}
}
guide{
  ${GUIDE_MODEL}
}
createdAt
updatedAt
`;

export const MESSAGE_MODEL = `
id
type
title
message
user{
  ${USER_MODEL}
}
guide{
  ${GUIDE_MODEL}
}
createdAt
updatedAt
`;

export const REPORT_MODEL = `
id
message
category
user{
  ${USER_MODEL}
}
guide{
  ${GUIDE_MODEL}
}
createdAt
updatedAt
reportedBy{
  ${USER_MODEL}
}
`;
