import { GraphQLClient } from "graphql-request";
import { QueryClient } from "react-query";
import store from "../store/Store";

// const serverUrl = "http://192.168.1.186:4000";
const serverUrl = "https://guidepalserver.herokuapp.com/";

export const queryClient = new QueryClient();

const client = new GraphQLClient(serverUrl, { headers: {} });

function query({ query: _query, variables }) {
  const headers = { authorization: store.token || "" };
  return client.request(_query, variables, headers).catch((body) => {
    if (body.response && body.response.errors && body.response.errors.length > 0) {
      throw new Error(body.response.errors[0].message);
    }

    throw new Error("Something went wrong! ");
  });
}

function mutate({ variables, mutation }) {
  const headers = { authorization: store.token || "" };
  return client
    .request(mutation, variables, headers)
    .then((response) => {
      queryClient.refetchQueries();
      return response;
    })
    .catch((body) => {
      if (body.response && body.response.errors && body.response.errors.length > 0) {
        throw new Error(body.response.errors[0].message);
      }

      throw new Error("Something went wrong!");
    });
}

export default {
  query,
  mutate,
  resetStore: () => queryClient.clear(),
};
