import React from "react";
import { Image, TouchableOpacity, View } from "react-native";
import Cavy from "../../app/test/Cavy";
import Colors from "../Colors";
import { Text2L, Text2SB } from "../ui";

const UserListItemView = Cavy.wrap(({ user, onUser }) => {
  const PLACEHOLDER = "http://s3.amazonaws.com/gajdavatar/dummyAvatar.jpg";
  const url = user.media ? user.media.url : PLACEHOLDER;

  function onPress() {
    onUser(user);
  }

  return (
    <TouchableOpacity {...{ onPress }} activeOpacity={0.5}>
      <View
        style={{
          alignItems: "center",
          flexDirection: "row",
          paddingVertical: 6,
          paddingHorizontal: 16,
        }}
      >
        <Image
          style={{
            width: 52,
            height: 52,
            marginRight: 0,
            borderRadius: 26,
            backgroundColor: Colors.lynxWhite,
          }}
          source={{ uri: url || PLACEHOLDER }}
        />

        <View style={{ flexDirection: "column", paddingHorizontal: 10 }}>
          {user.username ? <Text2SB>{user.username}</Text2SB> : <Text2SB>not signed up</Text2SB>}
          <Text2L>{user.email}</Text2L>
          {user.approvedBy ? (
            <Text2SB style={{ color: Colors.darkGray }}>
              approved by: {user.approvedBy.username}
            </Text2SB>
          ) : null}
        </View>
      </View>
    </TouchableOpacity>
  );
});

export default UserListItemView;
