import { observer } from "mobx-react";
import React from "react";
import { useQuery } from "react-query";
import Api from "../../app/api/Api";
import Queries from "../../app/constants/Queries";
import { navigateToLikedPlacesPager } from "../../app/navigation/Navigator";
import Colors from "../../components/Colors";
import { Container, Content, Header } from "../../components/ui";
import ErrorView from "../../components/ui/ErrorView";
import LoadingView from "../../components/ui/LoadingView";
import { PlaceGridView } from "./ui/PlaceCellView";
import TouchablePlaceCellView from "./ui/TouchablePlaceCellView";

function LikedPlacesScreen({ navigation }) {
  const {
    error,
    isLoading,
    data: places,
  } = useQuery(Queries.LIKED_PLACES, () => Api.place.likedPlaces(0, 10000));

  if (isLoading) {
    return <LoadingView />;
  }

  if (error) {
    return <ErrorView error="Something went wrong, try again!" />;
  }

  const openPlace = (placeId) => {
    navigateToLikedPlacesPager(navigation, { placeId });
  };

  return (
    <Container style={{ flex: 1 }}>
      <Header transparent title="Liked places" onBack={() => navigation.goBack()} />
      <Content
        showsVerticalScrollIndicator={false}
        style={{
          flex: 1,
          padding: 16,
          backgroundColor: Colors.lightGray,
        }}
      >
        <PlaceGridView>
          {places.map((place, index) => (
            <TouchablePlaceCellView
              showName
              hideIndex
              key={place.id}
              {...{ index, place }}
              style={{ borderWidth: 0 }}
              onPress={() => openPlace(place.id, (place.guide || {}).id)}
            />
          ))}
        </PlaceGridView>
      </Content>
    </Container>
  );
}

export default observer(LikedPlacesScreen);
