const Types = {
  USER_UPGRADED: "userUpgraded",
  GUIDE_UPDATED: "guideUpdated",
  GUIDE_APPROVED: "guideApproved",
  GUIDE_REPORTED: "guideReported",
  GUIDE_REMINDER: "guideReminder",
  GUIDE_PUBLISHED: "guidePublished",
  GUIDE_PRELANUCH: "guidePreLanuch",
  CREATOR_APPLICATION: "creatorApplication",
  GUIDE_PENDING_APPROVAL: "guidePendingApproval",
  GUIDE_ATTENTION_REQUIRED: "guideAttentionRequired",
  CREATOR_MONTHLY_EARNINGS: "creatorMonthlyEarnings",
};

function createParts({ type, guide, title, message }) {
  if (type === Types.GUIDE_PRELANUCH) {
    return [];
  }

  if (type === Types.GUIDE_REPORTED) {
    return ["A guide has been reported and needs to be reviewed."];
  }

  if (type === Types.GUIDE_REMINDER) {
    return [`Hey! Don't forget to complete your "${guide.name}" guide!`];
  }

  if (type === Types.GUIDE_PENDING_APPROVAL) {
    return ["A new guide is waiting for review!"];
  }

  if (type === Types.CREATOR_APPLICATION) {
    return ["A new creator application is waiting for review!"];
  }

  if (type === Types.CREATOR_MONTHLY_EARNINGS) {
    return [];
  }

  if (type === Types.USER_UPGRADED) {
    return ["Congrats!", "You have been upgraded to a Creator! Click here to create a guide!"];
  }

  if (!guide) {
    return [title, message];
  }

  if (type === Types.GUIDE_UPDATED) {
    return [guide.user.username, "updated the guide", `"${guide.name}"`];
  }

  if (type === Types.GUIDE_APPROVED) {
    return [`"${guide.name}"`, "has been published. Share it on your socials now!"];
  }

  if (type === Types.GUIDE_PUBLISHED) {
    return [guide.user.username, "published the guide", `"${guide.name}"`];
  }

  if (type === Types.GUIDE_ATTENTION_REQUIRED) {
    return [`"${guide.name}"`, "requires your attention. Tap to read the message."];
  }

  return [];
}

export default {
  Types,
  createParts,
};
