import React from "react";
import PDFReader from "rn-pdf-reader-js";
import Share from "../../components/Share";
import { Container, Header } from "../../components/ui";
import GIcon from "../../components/ui/GIcon";

export default function PdfViewerScreen({ route, navigation }) {
  const { url, title } = route.params;

  return (
    <Container>
      <Header
        {...{ title }}
        onBack={() => navigation.goBack()}
        actions={
          <Header.Action
            Icon={GIcon.ShareOutline}
            onPress={() =>
              Share.open({
                dialogTitle: title,
                url,
                title,
              }).catch(() => null)
            }
          />
        }
      />
      <PDFReader source={{ uri: url }} />
    </Container>
  );
}
