import React from "react";
import { Image, PixelRatio, ScrollView, View } from "react-native";
import { captureRef } from "react-native-view-shot";
import { useQuery } from "react-query";
import Api from "../../app/api/Api";
import Queries from "../../app/constants/Queries";
import { screenWidth } from "../../app/util/util";
import Colors from "../../components/Colors";
import { LogoNew } from "../../components/Logo";
import { Container, Header, Text2 } from "../../components/ui";
import ErrorView from "../../components/ui/ErrorView";
import GIcon from "../../components/ui/GIcon";
import LoadingView from "../../components/ui/LoadingView";
import { GuideLocationView, GuideTitleView } from "./ui";

const imageWidth = screenWidth - 64;

export default function CheckScreen({ route, navigation }) {
  const { guideId } = route.params;

  const ref = React.useRef(null);

  const {
    error,
    isLoading,
    data: guide,
  } = useQuery([Queries.GUIDE, guideId], () => Api.guide.guideGet(guideId));

  if (isLoading) {
    return <LoadingView />;
  }

  if (error) {
    return <ErrorView error="Something went wrong, try again!" />;
  }

  const { name, media } = guide;
  const hasCover = media && media.url;

  console.log(name, createPoster);

  function createPoster(result) {
    const targetPixelCount = 1080; // If you want full HD pictures
    const pixelRatio = PixelRatio.get(); // The pixel ratio of the device
    // pixels * pixelratio = targetPixelCount, so pixels = targetPixelCount / pixelRatio
    const pixels = targetPixelCount / pixelRatio;
    const params = result ? { result } : {};
    return captureRef(ref, {
      result: "tmpfile",
      width: pixels,
      height: pixels / 0.75,
      quality: 1,
      format: "png",
      ...params,
    });
  }

  return (
    <Container style={{ flex: 1 }}>
      <Header title="Checkout" onClose={() => navigation.goBack(null)} />

      <View>
        <ScrollView showsVerticalScrollIndicator={false}>
          <View>
            <View
              ref={ref}
              style={{
                marginTop: 24,
                marginBottom: 32,
                marginHorizontal: 32,
                shadowColor: Colors.black,
                shadowOffset: {
                  width: 0,
                  height: 0,
                },
                shadowOpacity: 0.25,
                shadowRadius: 3.84,
                elevation: 5,
              }}
            >
              {hasCover ? (
                <Image
                  resizeMode="cover"
                  style={{ height: imageWidth / 0.75, width: imageWidth }}
                  source={{ uri: media.url.replace("300x300", "600x600") }}
                />
              ) : (
                <View
                  style={{
                    width: imageWidth,
                    height: imageWidth / 0.75,
                  }}
                />
              )}

              {hasCover ? (
                <View
                  style={{
                    width: imageWidth,
                    height: imageWidth / 0.75,
                    position: "absolute",
                    backgroundColor: "rgba(0,0,0,0.2)",
                  }}
                />
              ) : null}

              <View
                style={{
                  width: imageWidth,
                  height: imageWidth / 0.75,
                  position: "absolute",
                }}
              >
                <LogoNew
                  style={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    opacity: 0.5,
                  }}
                  height={36.5}
                />

                <View
                  style={{
                    bottom: 0,
                    padding: 16,
                    width: imageWidth,
                    borderColor: Colors.white,
                    position: "absolute",
                  }}
                >
                  <GuideTitleView
                    color={hasCover ? Colors.white : Colors.black}
                    style={{ marginTop: 13 }}
                    title={guide.name}
                  />

                  <GuideLocationView
                    address={guide.address}
                    color={hasCover ? Colors.white : Colors.black}
                    style={{ marginTop: 0, marginBottom: 8 }}
                  />
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <GIcon.Person
                      style={{
                        color: hasCover ? Colors.white : Colors.black,
                        marginRight: 4,
                      }}
                    />
                    <Text2 style={{ color: hasCover ? Colors.white : Colors.black }}>
                      {guide.user.username}
                    </Text2>
                  </View>
                </View>
              </View>
            </View>
            <Text2 style={{ textAlign: "center" }} />
          </View>
        </ScrollView>
      </View>
    </Container>
  );
}
