import React from "react";
import { View } from "react-native";
import Cavy from "../../../app/test/Cavy";
import InsightSelectorView from "../../../components/guide/InsightSelectorView";
import GIcon from "../../../components/ui/GIcon";
import TabsView from "../../profile/ui/TabsView";

export const INSIGHT_TABS = {
  STATS: "stats",
  PAYOUT: "payout",
};

const InsightHeaderView = Cavy.wrap(({ title, onPress, onTab }) => {
  const [select, onSelect] = React.useState(INSIGHT_TABS.STATS);

  return (
    <View style={{ paddingTop: 7 }}>
      {title ? <InsightSelectorView {...{ title, onPress }} style={{ borderWidth: 0 }} /> : null}
      <TabsView
        flip
        tabs={[
          { id: INSIGHT_TABS.STATS, title: "Stats", Icon: GIcon.Chart },
          { id: INSIGHT_TABS.PAYOUT, title: "Payout", Icon: GIcon.Payout },
        ].filter((t) => t)}
        {...{ select }}
        onSelect={(t) => {
          onTab(t);
          onSelect(t);
        }}
        style={{ marginTop: 12, marginHorizontal: 16, marginBottom: 6 }}
      />
    </View>
  );
});
export default InsightHeaderView;
