import { Audio } from "expo-av";
import { makeAutoObservable, runInAction } from "mobx";
import { createContext, useContext } from "react";

const logEvent = (event) => {
  //console.log(event);
};

const PLAY_MODE = {
  allowsRecordingIOS: false,
  interruptionModeIOS: Audio.INTERRUPTION_MODE_IOS_DO_NOT_MIX,
  playsInSilentModeIOS: true,
  playsInSilentLockedModeIOS: true,
  shouldDuckAndroid: true,
  interruptionModeAndroid: Audio.INTERRUPTION_MODE_ANDROID_DO_NOT_MIX,
  playThroughEarpieceAndroid: false,
  staysActiveInBackground: true,
};

const PLAY_CONFIG = {
  shouldCorrectPitch: true,
  isLooping: false,
  isMuted: false,
  volume: 1.0,
  rate: 1.0,
};

const padWithZero = (number) => {
  const string = number.toString();
  return number < 10 ? `0${string}` : string;
};

export const getMMSSFromMillis = (millis: number) => {
  const totalSeconds = millis / 1000;
  const seconds = Math.floor(totalSeconds % 60);
  const minutes = Math.floor(totalSeconds / 60);
  if (!seconds && !minutes) {
    return "00:00";
  }

  return `${padWithZero(minutes)}:${padWithZero(seconds)}`;
};
class PlayerStore {
  isLoaded = false;

  isSeeking = false;

  shouldPlayAtEndOfSeek = false;

  durationMillis = 0;

  positionMillis = 0;

  shouldPlay = false;

  isPlaybackAllowed = false;

  isPlaying = false;

  isPaused = false;

  isFinished = false;

  isLoading = false;

  webLoading = false;

  isBuffering = false;

  media = null;

  sound = null;

  constructor() {
    makeAutoObservable(this);
  }

  play = async (media: any) => {
    if (!media) {
      return;
    }
    this.isFinished = false;
    try {
      if (this.sound != null) {
        if (this.media && this.media.id === media.id) {
          if (this.isPlaying) {
            await this.pause();
          } else {
            await this.resume();
          }
        } else {
          this.setLoading(true);
          await this.unload();
          await this.loadAndPlay(media);
        }
      } else {
        await this.loadAndPlay(media);
      }
    } catch (e) {
      this.stop();
    }
  };

  loadAndPlay = async (media: any) => {
    this.setLoading(true);
    await this.load(media);
    this.setWebLoading(true);
    await this.sound.playAsync();
    logEvent("play", this.media);
    this.setLoading(false);
    this.setMedia(media);
  };

  pause = async () => {
    if (this.sound !== null) {
      await this.sound.pauseAsync();
      this.setPaused(true);
      logEvent("pause", this.media);
    }
  };

  resume = async () => {
    if (this.sound !== null) {
      await this.sound.playAsync();
      this.setPaused(false);
      logEvent("resume", this.media);
    }
  };

  load = async (media: any) => {
    this.setMedia(media);
    const { uri, url } = media;
    await Audio.setAudioModeAsync(PLAY_MODE);
    const { sound } = await Audio.Sound.createAsync(
      { uri: uri || url },
      PLAY_CONFIG,
      this.onPlaybackStatusUpdate
    );
    this.setSound(sound);
  };

  unload = async () => {
    if (this.sound !== null) {
      await this.sound.unloadAsync();
      this.sound?.setOnPlaybackStatusUpdate(null);
      this.setSound(null);
    }
  };

  stop = () => {
    this.unload();
    logEvent("stop", this.media);
    this.setMedia(null);
  };

  onPlaybackStatusUpdate = (status) => {
    this.setLoaded(status.isLoaded);
    if (status.isLoaded) {
      if (this.media && this.media?.duration) {
        this.setDurationMillis(this.media.duration);
      } else {
        this.setDurationMillis(status.durationMillis);
      }

      if (status.positionMillis > 0) {
        this.setWebLoading(false);
      }

      this.setPositionMillis(status.positionMillis);
      this.setShouldPlay(status.shouldPlay);
      this.setPlaying(status.isPlaying);
      this.setBuffering(status.isBuffering);
      this.setPlaybackAllowed(true);

      if (status.didJustFinish) {
        logEvent("player_finished", this.media);
      }
      if (status.didJustFinish || status.positionMillis >= this.durationMillis) {
        this.setFinished(true);
        this.stop();
      }

      return;
    }

    this.setDurationMillis(null);
    this.setPositionMillis(null);
    this.setPlaybackAllowed(false);
    this.setPlaying(false);

    if (status.error) {
      this.stop();
      console.log(`FATAL PLAYER ERROR: ${status.error}`);
    }
  };

  getPlaybackTimestamp(): string {
    if (this.sound != null && this.positionMillis != null && this.durationMillis != null) {
      const current = getMMSSFromMillis(this.positionMillis);
      return `${current}`;
    }

    return "";
  }

  getDuration(): string {
    if (this.sound != null && this.positionMillis != null && this.durationMillis != null) {
      const duration = getMMSSFromMillis(this.durationMillis);
      return `${duration}`;
    }
    return "";
  }

  getCountDownDuration(): string {
    if (this.sound != null && this.positionMillis != null && this.durationMillis != null) {
      const duration = getMMSSFromMillis(this.durationMillis - this.positionMillis) || "00";
      return `${duration}`;
    }
    return "";
  }

  setLoaded(value) {
    runInAction(() => {
      this.isLoaded = value;
    });
  }

  setSeeking(value) {
    runInAction(() => {
      this.isSeeking = value;
    });
  }

  setShouldPlayAtEndOfSeek(value) {
    runInAction(() => {
      this.shouldPlayAtEndOfSeek = value;
    });
  }

  setDurationMillis(value) {
    runInAction(() => {
      this.durationMillis = value;
    });
  }

  setPositionMillis(value) {
    runInAction(() => {
      this.positionMillis = value;
    });
  }

  setShouldPlay(value) {
    runInAction(() => {
      this.shouldPlay = value;
    });
  }

  setPlaybackAllowed(value) {
    runInAction(() => {
      this.isPlaybackAllowed = value;
    });
  }

  setPlaying(value) {
    runInAction(() => {
      this.isPlaying = value;
    });
  }

  setPaused(value) {
    runInAction(() => {
      this.isPaused = value;
    });
  }

  setFinished(value) {
    runInAction(() => {
      this.isFinished = value;
    });
  }

  setLoading(value) {
    runInAction(() => {
      this.isLoading = value;
    });
  }

  setWebLoading(value) {
    runInAction(() => {
      this.webLoading = value;
    });
  }

  setBuffering(value) {
    runInAction(() => {
      this.isBuffering = value;
    });
  }

  setMedia(value) {
    runInAction(() => {
      this.media = value;
    });
  }

  setSound(value) {
    runInAction(() => {
      this.sound = value;
    });
  }
}

const playerStore = new PlayerStore();
const PlayerStoreContext = createContext(playerStore);
export const usePlayerStore = () => useContext(PlayerStoreContext);
