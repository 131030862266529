/* eslint-disable eqeqeq */
import React from "react";
import { View } from "react-native";
import { TouchableWithoutFeedback } from "react-native-gesture-handler";
import Cavy from "../../../app/test/Cavy";
import Colors from "../../../components/Colors";
import { Header1 } from "../../../components/typography";
import { Text2 } from "../../../components/ui";
import GIcon from "../../../components/ui/GIcon";

export function GuideTitleView({ title, style }) {
  return (
    <Header1
      style={[
        style,
        {
          lineHeight: 36,
          shadowOpacity: 0.3,
          color: Colors.white,
          shadowColor: Colors.black,
          shadowOffset: { width: 0, height: 0 },
        },
      ]}
    >
      {title || "+ Add title"}
    </Header1>
  );
}

export function GuideLocationView({ style, address, onEdit }) {
  const generateTestHook = Cavy.useCavy();
  return (
    <TouchableWithoutFeedback
      ref={generateTestHook("Guide.Location")}
      onPress={onEdit}
      {...{ style }}
    >
      <View style={{ alignItems: "center", flexDirection: "row" }}>
        <GIcon.MapMarker
          style={{
            fontSize: 18,
            marginRight: 4,
            shadowOpacity: 0.3,
            color: Colors.white,
            shadowColor: Colors.black,
            shadowOffset: { width: 0, height: 0 },
          }}
        />
        <Text2
          style={{
            fontSize: 16,
            lineHeight: 16,
            shadowOpacity: 0.3,
            color: Colors.white,
            alignSelf: "flex-end",
            shadowColor: Colors.black,
            shadowOffset: { width: 0, height: 0 },
          }}
        >
          {address}
        </Text2>
      </View>
    </TouchableWithoutFeedback>
  );
}
