import { gql } from "graphql-request";
import { PLACE_MODEL } from "../models";

export const PLACE_GET = gql`
  query placeGet($id: String) {
    placeGet(id: $id) {
     ${PLACE_MODEL}
    }
  }
`;

export const LIKED_PLACES = gql`
  query likedPlaces($skip: Int, $limit: Int) {
    likedPlaces(skip: $skip, limit: $limit) {
      ${PLACE_MODEL}
    }
  }
`;

export const PLACE_LIKE = gql`
  mutation placeLike(
    $id: String
    $guideId: String
  ) {
    placeLike(
      id: $id
      guideId: $guideId
    ) {
      ${PLACE_MODEL}
    }
  }
`;

export const PLACE_UNLIKE = gql`
  mutation placeUnlike(
    $id: String
  ) {
    placeUnlike(
      id: $id
    ) {
      ${PLACE_MODEL}
    }
  }
`;

export const PLACE_CREATE = gql`
  mutation placeCreate($name: String, $guideId: String, $gPlaceId: String, $address: String, $location: LocationInput, $website: String, $phone: String,  $originalName: String  ) {
    placeCreate(name: $name, guideId: $guideId,  gPlaceId: $gPlaceId, address: $address, location: $location, website: $website ,phone: $phone, originalName: $originalName) {
      ${PLACE_MODEL}
    }
  }
`;

export const PLACE_UPDATE = gql`
  mutation placeUpdate(
    $id: String
    $name: String
    $price: String
    $media: String
    $audio: String
    $phone: String
    $website: String
    $medias: [String]
    $address: String
    $gPlaceId: String
    $category: String
    $categories: [String]
    $quotes: [QuoteInput]
    $location: LocationInput
  ) {
    placeUpdate(
      id: $id
      name: $name
      price: $price
      audio: $audio
      media: $media
      phone: $phone
      medias: $medias
      quotes: $quotes
      website: $website
      address: $address
      location: $location
      category: $category
      gPlaceId: $gPlaceId
      categories: $categories
    ) {
      ${PLACE_MODEL}
    }
  }
`;
