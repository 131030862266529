import { Dimensions, Platform } from "react-native";

const { width, height } = Dimensions.get("window");

export const isIOS = Platform.OS === "ios";
export const isWeb = Platform.OS === "web";
export const isAndroid = Platform.OS === "android";
export const screenWidth = width > 800 && isWeb ? 800 : width;
export const screenHeight = height;

export const ScreenSize = {
  width: screenWidth,
  height: screenHeight,
};
