import React from "react";
import { useQuery } from "react-query";
import Api from "../../app/api/Api";
import Queries from "../../app/constants/Queries";
import { navigateToGuide } from "../../app/navigation/Navigator";
import Cavy from "../../app/test/Cavy";
import GuideGridView from "../../components/guide/GuideGridView";
import { Container, Header } from "../../components/ui";
import EmptyView from "../../components/ui/EmptyView";
import LoadingView from "../../components/ui/LoadingView";

export default function PendingGuidesScreen({ navigation }) {
  const generateTestHook = Cavy.useCavy();
  const { data: guides, isLoading } = useQuery(Queries.PENDING_GUIDES, () =>
    Api.guide.pendingGuides(0, 50)
  );

  if (isLoading) {
    return <LoadingView />;
  }

  function onGuide(guide) {
    navigateToGuide(navigation, { guideId: guide.id });
  }

  return (
    <Container ref={generateTestHook(guides ? "data.loaded" : "data.not.loaded")}>
      <Header title="Pending guides" onBack={() => navigation.goBack()} />

      {guides.length > 0 ? (
        <GuideGridView {...{ guides, onGuide }} />
      ) : (
        <EmptyView message="No guides to show!" />
      )}
    </Container>
  );
}
