import store from "../../../app/store/Store";
import Status from "../../../components/guide/Status";

export const Options = {
  EDIT: "Edit",
  DELETE: "Delete",
  REPORT: "Report",
  COPY: "Copy Link",
  SHARE: "Share to...",
  APPROVE: "Approve",
  REQUEST_ADJUSTMENT: "Request adjustment",
  ARCHIVE: "Archive",
  MAKE_IT_FREE: "Make it free",
  MAKE_IT_FOR_PURCHASE: "Make it for purchase",
  REPUBLISH: "Republish",
  CANCEL: "Cancel",
};

function createUserOptions() {
  return {
    options: [Options.REPORT, Options.COPY, Options.SHARE, Options.CANCEL],
    destructiveButtonIndex: 0,
    cancelButtonIndex: 3,
  };
}

function createOwnerOptions(guide) {
  if (guide.status === Status.PUBLISHED) {
    return {
      options: [Options.EDIT, Options.COPY, Options.SHARE, Options.ARCHIVE, Options.CANCEL],
      destructiveButtonIndex: 3,
      cancelButtonIndex: 4,
    };
  }

  if (guide.status === Status.ARCHIVED) {
    return {
      options: [Options.EDIT, Options.REPUBLISH, Options.DELETE, Options.CANCEL],
      destructiveButtonIndex: 2,
      cancelButtonIndex: 3,
    };
  }

  // pending or draft
  return {
    options: [Options.EDIT, Options.DELETE, Options.CANCEL],
    destructiveButtonIndex: 1,
    cancelButtonIndex: 2,
  };
}

function createAdminOptions(guide) {
  if (guide.status === Status.PENDING) {
    return {
      options: [
        Options.APPROVE,
        Options.REQUEST_ADJUSTMENT,
        guide.isFree ? Options.MAKE_IT_FOR_PURCHASE : Options.MAKE_IT_FREE,
        Options.CANCEL,
      ],
      cancelButtonIndex: 3,
    };
  }

  if (guide.status === Status.PUBLISHED) {
    return {
      options: [
        Options.COPY,
        Options.SHARE,
        Options.REQUEST_ADJUSTMENT,
        guide.isFree ? Options.MAKE_IT_FOR_PURCHASE : Options.MAKE_IT_FREE,
        Options.ARCHIVE,
        Options.CANCEL,
      ],
      destructiveButtonIndex: 4,
      cancelButtonIndex: 5,
    };
  }

  if (guide.status === Status.ARCHIVED) {
    return {
      options: [Options.REPUBLISH, Options.CANCEL],
      cancelButtonIndex: 1,
    };
  }

  return {
    options: ["Nothing todo!", Options.CANCEL],
    cancelButtonIndex: 1,
  };
}

function createAdminOwnerOptions(guide) {
  if (guide.status === Status.PENDING) {
    return {
      options: [
        Options.EDIT,
        Options.APPROVE,
        Options.REQUEST_ADJUSTMENT,
        guide.isFree ? Options.MAKE_IT_FOR_PURCHASE : Options.MAKE_IT_FREE,
        Options.DELETE,
        Options.CANCEL,
      ],
      destructiveButtonIndex: 4,
      cancelButtonIndex: 5,
    };
  }

  if (guide.status === Status.ARCHIVED) {
    return {
      options: [Options.EDIT, Options.REPUBLISH, Options.DELETE, Options.CANCEL],
      destructiveButtonIndex: 2,
      cancelButtonIndex: 3,
    };
  }

  if (guide.status === Status.PUBLISHED) {
    return {
      options: [
        Options.EDIT,
        Options.COPY,
        Options.SHARE,
        Options.REQUEST_ADJUSTMENT,
        guide.isFree ? Options.MAKE_IT_FOR_PURCHASE : Options.MAKE_IT_FREE,
        Options.ARCHIVE,
        Options.CANCEL,
      ],
      destructiveButtonIndex: 5,
      cancelButtonIndex: 6,
    };
  }

  return {
    options: [Options.EDIT, Options.DELETE, Options.CANCEL],
    destructiveButtonIndex: 1,
    cancelButtonIndex: 2,
  };
}

export function createOptions(guide) {
  if (!store.isLoggedIn()) {
    return {
      options: [Options.COPY, Options.SHARE, Options.CANCEL],
      cancelButtonIndex: 2,
    };
  }

  const owner = guide.user.id === store.user.id;

  if (store.isAdmin()) {
    if (owner) {
      return createAdminOwnerOptions(guide);
    }

    return createAdminOptions(guide);
  }

  if (owner) {
    return createOwnerOptions(guide);
  }

  return createUserOptions(guide);
}

export function isPlaceDone(place) {
  try {
    checkPlace(place);
    return true;
  } catch (e) {
    return false;
  }
}

export function checkPlace(place) {
  if (!place.id) {
    return;
  }

  const hasMedia =
    ((place.medias || []).filter((media) => media.type === "image").length !== 0 &&
      (place.medias || []).filter((media) => media.text || media.audio).length !== 0) ||
    (place.medias || []).filter((media) => media.type === "video").length !== 0;

  if (!hasMedia) {
    throw new Error(`Please add media content to "${place.name}"!`);
  }

  /* const hasImage = (place.medias || []).filter((media) => media.type === "image").length !== 0;

  if (!hasImage) {
    throw new Error(`Please add at least one image to "${place.name}"!`);
  }

  const hasTextOrAudio =
    (place.medias || []).filter((media) => media.text || media.audio).length !== 0;

  if (!hasTextOrAudio) {
    throw new Error(`Please write or talk about the "${place.name}"!`);
  } */
}

export function checkGuide(guide) {
  if (!guide.name) {
    throw new Error("Please add a 'title' to your guide!");
  }

  if (!(guide.description || guide.audio)) {
    throw new Error("Please add a 'description' or an 'audio' to your guide!");
  }

  if (!guide.media) {
    throw new Error("Please add a 'cover image' to your guide!");
  }

  (guide.places || []).forEach(checkPlace);

  if ((guide.places || []).length < 6) {
    throw new Error("Guide must have minimum 6 complete places!");
  }

  guide.places.forEach(checkPlace);
}
