import * as EmailValidator from "email-validator";
import { observer } from "mobx-react";
import React from "react";
import { Alert, Image, Keyboard, View } from "react-native";
import Api from "../../app/api/Api";
import { navigateToSelect } from "../../app/navigation/Navigator";
import store from "../../app/store/Store";
import Errors from "../../app/util/Errors";
import { screenHeight, screenWidth } from "../../app/util/util";
import Colors from "../../components/Colors";
import { Container, Header, Text1, Text2SB } from "../../components/ui";
import ButtonView from "../../components/ui/ButtonView";
import GIcon from "../../components/ui/GIcon";
import { EmailInputView, InputView } from "../../components/ui/inputs";
import countries from "../picker/countries.json";
import followersRanges from "../picker/followers-ranges.json";

const photo = require("../../../assets/images/apply_bg2.png");

export default observer(function ApplicationScreen({ navigation }) {
  const [email, setEmail] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [socialUrl, setSocialUrl] = React.useState("");
  const [numberOfFollowers, setNumberOfFollowers] = React.useState("");

  const userId = store.getUserId();
  const [loading] = React.useState(false);

  React.useEffect(() => {
    if (userId && store.isCreator()) {
      navigation.goBack();
    }
  }, [navigation, userId]);

  const validateInput = () => {
    return EmailValidator.validate(email) && country && numberOfFollowers && socialUrl;
  };

  function onSave() {
    Keyboard.dismiss();

    Api.application
      .applicationCreate({
        email,
        country,
        socialUrl,
        platform: "app",
        numberOfFollowers,
      })
      .then(() => Alert.alert("Application sent!"))
      .then(() => navigation.goBack())
      .catch((error) => {
        Errors.showErrorMessage(error);
      });
  }

  function onCountry() {
    navigateToSelect(navigation, {
      title: "Select a country",
      options: countries,
      onSelect: ({ name }) => setCountry(name),
    });
  }

  function onFollowers() {
    navigateToSelect(navigation, {
      title: "Number of followers",
      options: followersRanges,
      onSelect: ({ name }) => setNumberOfFollowers(name),
    });
  }

  const isValid = validateInput();

  return (
    <Container>
      <Image
        source={photo}
        resizeMode="cover"
        style={{ position: "absolute", height: screenHeight, width: screenWidth }}
      />
      <Header color={Colors.white} transparent onBack={() => navigation.goBack()} />

      <View>
        <Text1
          style={{
            fontSize: 36,
            lineHeight: 44,
            textAlign: "center",
            color: Colors.pinkySwear,
          }}
        >
          Creator
        </Text1>
        <Text2SB
          style={{ color: Colors.pinkySwear, fontSize: 36, lineHeight: 44, textAlign: "center" }}
        >
          application
        </Text2SB>
      </View>

      <View
        style={{
          flex: 1,
          padding: 20,
          justifyContent: "center",
        }}
      >
        <EmailInputView
          testId="Application.WriteEmail"
          value={email}
          onChangeText={(text) => setEmail(text.trim().toLowerCase())}
          placeholder="Email address"
          Icon={() => <GIcon.Mail style={{ fontSize: 20 }} />}
        />

        <InputView
          testId="Application.SelectCountry"
          marginTop={8}
          value={country}
          onPress={onCountry}
          onFocus={onCountry}
          showSoftInputOnFocus={false}
          caretHidden={true}
          autoCorrect={false}
          onChangeText={setCountry}
          placeholder="Country of residence"
          Icon={() => <GIcon.MapMarker style={{ fontSize: 20 }} />}
        />

        <InputView
          testId="Application.NoOfFollowers"
          value={numberOfFollowers}
          marginTop={8}
          onPress={onFollowers}
          onFocus={onFollowers}
          showSoftInputOnFocus={false}
          caretHidden={true}
          onChangeText={setNumberOfFollowers}
          placeholder="How many followers on social media?"
          Icon={() => <GIcon.AccountCheck style={{ fontSize: 20 }} />}
        />

        <InputView
          testId="Application.SocialMedia1"
          marginTop={8}
          value={socialUrl}
          onChangeText={setSocialUrl}
          placeholder="Link your main social media channel"
          Icon={() => <GIcon.Link style={{ fontSize: 20 }} />}
        />
      </View>

      <View
        style={{
          paddingHorizontal: 24,
          paddingBottom: 12,
          alignItems: "center",
        }}
      >
        <ButtonView
          gray={!isValid}
          primary={isValid}
          onPress={isValid ? onSave : null}
          {...{ loading }}
          style={{
            width: 240,
            marginBottom: 12,
            alignItems: "center",
            justifyContent: "center",
          }}
          disabled={!isValid}
        >
          Submit
        </ButtonView>
      </View>
    </Container>
  );
});
