import React from "react";
import ImageEditor from "./src/ImageEditor";

export default function ImageCropScreen({ route, navigation }) {
  const { uri, ratio, lockAspectRatio, onDone, onCancel } = route.params || {};

  return (
    <ImageEditor
      visible
      imageUri={uri}
      mode="crop-only"
      {...{ lockAspectRatio }}
      onCloseEditor={() => {
        onCancel();
        navigation.goBack();
      }}
      fixedCropAspectRatio={ratio}
      minimumCropDimensions={{
        width: 100,
        height: 100,
      }}
      onEditingComplete={onDone}
    />
  );
}
