import React from "react";
import { View } from "react-native";
import Colors from "./Colors";
import { Text1, Text2 } from "./ui";

export function LogoNew({ style }) {
  return (
    <View
      style={[
        {
          flex: 1,
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
        },
        style,
      ]}
    >
      <Text1 style={{ color: Colors.white, fontSize: 24, marginTop: -10 }}>guide</Text1>
      <Text2 style={{ color: Colors.white, fontSize: 24, marginTop: -4 }}>pal</Text2>
    </View>
  );
}

export const LogoDark = ({ style }) => (
  <View
    style={[
      {
        flex: 1,
        alignItems: "center",
        flexDirection: "row",
        justifyContent: "center",
      },
      style,
    ]}
  >
    <Text1 style={{ color: Colors.black, fontSize: 24, marginTop: -10 }}>guide</Text1>
    <Text2 style={{ color: Colors.black, fontSize: 24, marginTop: -6 }}>pal</Text2>
  </View>
);
