import React from "react";
import { View } from "react-native";
import Colors from "./Colors";
import { Border, Radius } from "./Theme";
import { Text2 } from "./ui";
import GIcon from "./ui/GIcon";

export default function StepsContainer({ center, style, ...props }) {
  return (
    <View
      {...props}
      style={[
        {
          flexWrap: "wrap",
          flexDirection: "row",
          justifyContent: center ? "center" : "flex-start",
        },
        style,
      ]}
    />
  );
}

export function StepView({ done, children }) {
  return (
    <View
      style={{
        margin: 3,
        height: 28,
        borderWidth: 1,
        borderRadius: Radius.XS,
        paddingVertical: 3,
        alignItems: "center",
        flexDirection: "row",
        paddingHorizontal: 5,
        borderColor: done ? Colors.black : Border.dashedColor,
      }}
    >
      {done ? (
        <View
          style={{
            padding: 1,
            borderRadius: 10,
            marginHorizontal: 2,
            backgroundColor: Colors.black,
          }}
        >
          <GIcon.Check style={{ fontSize: 14, color: Colors.white }} />
        </View>
      ) : (
        <GIcon.CheckBoxBlank style={{ fontSize: 20, color: Colors.darkGray }} />
      )}

      <Text2 style={{ fontSize: 13, color: done ? Colors.black : Colors.darkGray, marginLeft: 4 }}>
        {children}
      </Text2>
    </View>
  );
}
