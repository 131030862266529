import client from "../client";
import { QUERY_THEMES, THEME_CREATE, THEME_DELETE, THEME_UPDATE } from "./graphql";

function themes() {
  return client
    .query({
      variables: {},
      query: QUERY_THEMES,
    })
    .then((response) => response.themes);
}

function themeCreate(name, description) {
  return client
    .mutate({
      variables: {
        name,
        description,
      },
      mutation: THEME_CREATE,
    })
    .then((response) => response.themeCreate);
}

function themeUpdate(id, name, description) {
  return client
    .mutate({
      variables: {
        id,
        name,
        description,
      },
      mutation: THEME_UPDATE,
    })
    .then((response) => response.themeUpdate);
}

function themeDelete(id) {
  return client
    .mutate({
      variables: {
        id,
      },
      mutation: THEME_DELETE,
    })
    .then((response) => response.themeDelete);
}

export default {
  themes,
  themeCreate,
  themeUpdate,
  themeDelete,
};
