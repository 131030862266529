import Colors from "./Colors";

export const Border = {
  solidColor: Colors.crystal,
  dashedColor: Colors.middleGray,
  dottedColor: Colors.middleGray,
};

export const Radius = {
  XXXL: 24,
  XXL: 16,
  XL: 14,
  L: 12,
  M: 10,
  S: 9,
  XS: 6,
  XXS: 4,
};

export const Space = {
  L: 24,
  M: 16,
  S: 8,
};
