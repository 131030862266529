/* eslint-disable import/no-named-as-default-member */
/**
 * If you are not familiar with React Navigation, check out the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import { createStackNavigator } from "@react-navigation/stack";
import { observer } from "mobx-react";
import * as React from "react";
import BrowserScreen from "../../features/browser/BrowserScreen";
import CreatorApplications from "../../features/creator/CreatorApplications";
import CreatorsScreen from "../../features/creator/CreatorsScreen";
import ApplicationScreen from "../../features/gtab/ApplicationScreen";
import ApplyScreen from "../../features/gtab/ApplyScreen";
import DashboardScreen from "../../features/gtab/DashboardScreen";
import MonthlyReportsScreen from "../../features/gtab/MonthlyReportsScreen";
import PdfViewerScreen from "../../features/gtab/PdfViewerScreen";
import EditGuideScreen from "../../features/guide/EditGuideScreen";
import GuideScreen from "../../features/guide/GuideScreen/GuideScreen";
import GuidesScreen from "../../features/guide/GuidesScreen";
import LikedPlacesPagerScreen from "../../features/guide/LikedPlacesPagerScreen";
import LikedPlacesScreen from "../../features/guide/LikedPlacesScreen";
import PendingGuidesScreen from "../../features/guide/PendingGuidesScreen";
import ReportedGuidesScreen from "../../features/guide/ReportedGuidesScreen";
import HomeScreen from "../../features/home/HomeScreen";
import InsightScreen from "../../features/insight/InsightScreen";
import EmailScreen from "../../features/login/EmailScreen";
import LoginScreen from "../../features/login/LoginScreen";
import PasswordScreen from "../../features/login/PasswordScreen";
import UsernameScreen from "../../features/login/UsernameScreen";
import VerifyScreen from "../../features/login/VerifyScreen";
import NotificationScreen from "../../features/notification/NotificationScreen";
import GooglePhotoPickerScreen from "../../features/picker/GooglePhotoPickerScreen";
import EditPlaceScreen from "../../features/place/EditPlaceScreen";
import ChangePasswordScreen from "../../features/profile/ChangePasswordScreen";
import GuestScreen from "../../features/profile/GuestScreen";
import ProfileScreen from "../../features/profile/ProfileScreen";
import QuestionsScreen from "../../features/question/QuestionsScreen";
import SearchScreen from "../../features/search/SearchScreen";
import SettingsScreen from "../../features/settings/SettingsScreen";
import StatsScreen from "../../features/theme/StatsScreen";
import ThemesScreen from "../../features/theme/ThemesScreen";
import store from "../store/Store";
import { isWeb } from "../util/util";
import ScreenNames from "./ScreenNames";

const LoginStack = createStackNavigator();

export function LoginNavigator() {
  return (
    <LoginStack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={ScreenNames.LOGIN}
    >
      <LoginStack.Screen name={ScreenNames.LOGIN} component={LoginScreen} />
      <LoginStack.Screen name={ScreenNames.EMAIL} component={EmailScreen} />
      <LoginStack.Screen name={ScreenNames.VERIFY} component={VerifyScreen} />
      <LoginStack.Screen name={ScreenNames.PASSWORD} component={PasswordScreen} />
      <LoginStack.Screen name={ScreenNames.USERNAME} component={UsernameScreen} />
    </LoginStack.Navigator>
  );
}

const SignupStack = createStackNavigator();

export function SignupNavigator() {
  return (
    <SignupStack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={ScreenNames.EMAIL}
    >
      <SignupStack.Screen name={ScreenNames.EMAIL} component={EmailScreen} />
      <SignupStack.Screen name={ScreenNames.LOGIN} component={LoginScreen} />
      <SignupStack.Screen name={ScreenNames.VERIFY} component={VerifyScreen} />
      <SignupStack.Screen name={ScreenNames.PASSWORD} component={PasswordScreen} />
      <SignupStack.Screen name={ScreenNames.USERNAME} component={UsernameScreen} />
    </SignupStack.Navigator>
  );
}

const ProfileStack = createStackNavigator();

export const ProfileNavigator = observer(() => {
  return (
    <ProfileStack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={ScreenNames.PROFILE_TAB}
    >
      {store.isLoggedIn() ? (
        <ProfileStack.Screen name={ScreenNames.PROFILE_TAB} component={ProfileScreen} />
      ) : (
        <ProfileStack.Screen name={ScreenNames.PROFILE_TAB} component={GuestScreen} />
      )}

      <LoginStack.Screen name={ScreenNames.EMAIL} component={EmailScreen} />
      <ProfileStack.Screen name={ScreenNames.STATS} component={StatsScreen} />
      <ProfileStack.Screen name={ScreenNames.THEMES} component={ThemesScreen} />
      <ProfileStack.Screen name={ScreenNames.CREATORS} component={CreatorsScreen} />
      <ProfileStack.Screen
        name={ScreenNames.CREATOR_APPLICATIONS}
        component={CreatorApplications}
      />
      <ProfileStack.Screen name={ScreenNames.GUIDES} component={GuidesScreen} />
      <ProfileStack.Screen name={ScreenNames.PENDING_GUIDES} component={PendingGuidesScreen} />

      <ProfileStack.Screen name={ScreenNames.BROWSE} component={BrowserScreen} />
      <ProfileStack.Screen name={ScreenNames.PROFILE} component={ProfileScreen} />
      <ProfileStack.Screen name={ScreenNames.SETTINGS} component={SettingsScreen} />
      <ProfileStack.Screen name={ScreenNames.QUESTIONS} component={QuestionsScreen} />
      <ProfileStack.Screen name={ScreenNames.LIKED_PLACES} component={LikedPlacesScreen} />
      <ProfileStack.Screen name={ScreenNames.CHANGE_PASSWORD} component={ChangePasswordScreen} />
      <ProfileStack.Screen name={ScreenNames.REPORTED_GUIDES} component={ReportedGuidesScreen} />
      <ProfileStack.Screen
        name={ScreenNames.LIKED_PLACES_PAGER}
        component={LikedPlacesPagerScreen}
      />
    </ProfileStack.Navigator>
  );
});

const NotificationStack = createStackNavigator();

export function NotificationNavigator() {
  return (
    <NotificationStack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={ScreenNames.NOTIFICATION}
    >
      <NotificationStack.Screen name={ScreenNames.NOTIFICATION} component={NotificationScreen} />
      <NotificationStack.Screen name={ScreenNames.PROFILE} component={ProfileScreen} />
    </NotificationStack.Navigator>
  );
}

const EditGuideStack = createStackNavigator();

export function EditGuideNavigator() {
  return (
    <EditGuideStack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={ScreenNames.EDIT_GUIDE}
    >
      <EditGuideStack.Screen name={ScreenNames.EDIT_GUIDE} component={EditGuideScreen} />

      <EditGuideStack.Screen
        name={ScreenNames.GOOGLE_PHOTO_PICKER}
        component={GooglePhotoPickerScreen}
      />
      <EditGuideStack.Screen name={ScreenNames.EDIT_PLACE} component={EditPlaceScreen} />
    </EditGuideStack.Navigator>
  );
}

const ApplyStack = createStackNavigator();

function ApplyNavigator() {
  return (
    <ApplyStack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={ScreenNames.APPLY}
    >
      <ApplyStack.Screen name={ScreenNames.APPLY} component={ApplyScreen} />
      <ApplyStack.Screen name={ScreenNames.PROFILE} component={ProfileScreen} />
    </ApplyStack.Navigator>
  );
}

const GTabStack = createStackNavigator();

function renderGTab() {
  if (!store.isCreator()) {
    return <GTabStack.Screen name={ScreenNames.GTAB} component={ApplyNavigator} />;
  }

  if (store.isAdmin() || store.isCreator()) {
    return <GTabStack.Screen name={ScreenNames.GTAB} component={DashboardScreen} />;
  }
}

export const GTabNavigator = observer(() => {
  return (
    <GTabStack.Navigator screenOptions={{ headerShown: false }} initialRouteName={ScreenNames.GTAB}>
      {renderGTab()}
      <GTabStack.Screen name={ScreenNames.GUIDES} component={GuidesScreen} />
      <GTabStack.Screen name={ScreenNames.APPLICATION} component={ApplicationScreen} />
    </GTabStack.Navigator>
  );
});

const HomeStack = createStackNavigator();

export function HomeNavigator() {
  return (
    <HomeStack.Navigator screenOptions={{ headerShown: false }} initialRouteName={ScreenNames.HOME}>
      <HomeStack.Screen name={ScreenNames.HOME} component={HomeScreen} />
      {isWeb ? <HomeStack.Screen name={ScreenNames.GUIDE} component={GuideScreen} /> : null}
      <HomeStack.Screen name={ScreenNames.GUIDES} component={GuidesScreen} />
      <HomeStack.Screen name={ScreenNames.SEARCH} component={SearchScreen} />
      <HomeStack.Screen name={ScreenNames.PROFILE} component={ProfileScreen} />
    </HomeStack.Navigator>
  );
}

const InsightStack = createStackNavigator();

export function InsightNavigator() {
  return (
    <InsightStack.Navigator
      screenOptions={{ headerShown: false }}
      initialRouteName={ScreenNames.INSIGHT}
    >
      <InsightStack.Screen name={ScreenNames.INSIGHT} component={InsightScreen} />
      <InsightStack.Screen name={ScreenNames.MONTHLY_REPORTS} component={MonthlyReportsScreen} />
      <InsightStack.Screen name={ScreenNames.PDF_VIEWER} component={PdfViewerScreen} />
    </InsightStack.Navigator>
  );
}
