import React from "react";
import { Button, ScrollView, StyleSheet, Text, View } from "react-native";
import Colors from "../Colors";
import { InputView } from "./inputs";

const debug = false;
const ErrorView = ({ error, retry }) => {
  console.log(error);
  const message = error && error.message ? error.message : error;

  if (debug) {
    return (
      <ScrollView style={{ flex: 1 }}>
        <InputView
          bordered
          rowSpan={20}
          style={{ flex: 1, marginTop: 40 }}
          value={JSON.stringify(error, null, 2)}
        />
        <Button title="Retry" style={styles.button} onPress={() => retry()} />
      </ScrollView>
    );
  }

  return (
    <View style={styles.container}>
      {!debug ? <Text style={styles.sad}>:(</Text> : null}
      {!debug ? <Text style={styles.message}>{message}</Text> : null}

      {retry ? (
        <View>
          <Button title="Retry" style={styles.button} onPress={() => retry()} />
        </View>
      ) : null}
    </View>
  );
};

export default ErrorView;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingTop: 20,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.white,
  },
  sad: {
    fontSize: 30,
    color: Colors.gray,
  },
  message: {
    padding: 20,
    lineHeight: 31,
    textAlign: "center",
  },
  button: {
    height: 40,
    borderRadius: 20,
    backgroundColor: Colors.crystal,
  },
});
