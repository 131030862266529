import { gql } from "graphql-request";
import { USER_MODEL } from "../models";

export const IS_AVAILABLE_QUERY = gql`
  query isAvailable($uid: String) {
    isAvailable(uid: $uid)
  }
`;

export const VERIFY_MUTATION = gql`
  mutation verify($email: String, $phone: String) {
    verify(email: $email, phone: $phone)
  }
`;

export const VERIFY_CODE_MUTATION = gql`
  mutation verifyCode($email: String, $phone: String, $code: String) {
    verifyCode(email: $email, phone: $phone, code: $code){
      token
      user {
        ${USER_MODEL}
      }
    }
  }
`;

export const LOGIN_MUTATION = gql`
  mutation login($uid: String, $password: String) {
    login(uid: $uid, password: $password) {
      token
      user {
        ${USER_MODEL}
      }
    }
  }
`;

// Outdated
export const APPLICATION_MUTATION = gql`
  mutation application(
    $note: String
    $email: String
    $country: String
    $website: String
    $social1: String
    $social2: String
    $followers: String
  ) {
    application(
      note: $note
      email: $email
      country: $country
      website: $website
      social1: $social1
      social2: $social2
      followers: $followers
    )
  }
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation updatePassword($oldPassword: String, $newPassword: String) {
    updatePassword(oldPassword: $oldPassword, newPassword: $newPassword)
  }
`;
