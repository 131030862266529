import * as ImagePicker from "expo-image-picker";
import * as React from "react";
import { ActivityIndicator, Alert, Linking, StyleSheet, Text, View } from "react-native";
import { isAndroid, isIOS } from "../../app/util/util";
import { PageTitle } from "../typography";
import { Container } from "../ui";
import ButtonView from "../ui/ButtonView";

export default ({ onGranted, onCancel }) => {
  const [canOpen, setCanOpen] = React.useState(null);
  const [cameraRollPermission, setCameraRollPermission] = React.useState(null);

  React.useEffect(() => {
    let isCancelled = false;
    async function checkPermissions() {
      setCameraRollPermission(await ImagePicker.getMediaLibraryPermissionsAsync());
      if (!isCancelled) {
        if (isAndroid) {
          setCanOpen(true);
          return;
        }
        setCanOpen(await Linking.canOpenURL("app-settings:"));
      }
    }

    checkPermissions();
    return () => {
      isCancelled = true;
    };
  }, []);

  const onPress = async () => {
    const cameraRoll = await ImagePicker.getMediaLibraryPermissionsAsync();
    if (cameraRoll.status === "denied") {
      if (canOpen) {
        Linking.openSettings();
        return;
      }

      Alert.alert("Allow access to photo library", "Allow access to photo library in settings");
      return;
    }

    if (cameraRoll.status === "granted") {
      onGranted();
      return;
    }

    if (cameraRoll.status === "undetermined") {
      const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (status === "granted") {
        onGranted();
      }
    }
  };

  if (canOpen === null || cameraRollPermission === null) {
    return (
      <View style={styles.container}>
        <ActivityIndicator />
      </View>
    );
  }

  const showButton = canOpen || cameraRollPermission !== "denied";

  return (
    <Container style={{ flex: 1, padding: 24 }}>
      <View style={{ flex: 1 }} />
      <PageTitle category="h4" style={styles.text}>
        Allow photos access
      </PageTitle>
      <Text style={styles.text}>
        Allow guidepal access to your {isIOS ? "camera roll" : "gallery"} to be able to add an image
        to your profile pic and guides.
      </Text>
      {showButton === true && (
        <ButtonView primary onPress={onPress}>
          Continue
        </ButtonView>
      )}
      {showButton === false && <Text style={styles.text}>Allow access to photos in settings</Text>}
      <ButtonView outline onPress={onCancel} style={{ marginTop: 16, marginBottom: 56 }}>
        Cancel
      </ButtonView>
    </Container>
  );
};

const styles = StyleSheet.create({
  container: {
    flexGrow: 1,
    justifyContent: "center",
  },
  text: {
    textAlign: "center",
    marginBottom: 16,
  },
});
