import React from "react";
import { isWeb } from "../../../../app/util/util";
import Colors from "../../../../components/Colors";
import { LogoDark, LogoNew } from "../../../../components/Logo";
import { Header } from "../../../../components/ui";

export default function GuideAppBar({ guide, hasCover, isDraft, navigation, buildActions }) {
  return (
    <Header
      style={isWeb ? { marginTop: -36 } : {}}
      transparent
      color={hasCover ? Colors.white : Colors.black}
      actions={
        !isDraft && !isWeb
          ? buildActions({
              guide,
              color: hasCover ? Colors.white : Colors.black,
            })
          : null
      }
      Logo={hasCover ? LogoNew : LogoDark}
      useCloseIconForBack
      circleBack
      onBack={() => {
        if (isWeb) {
          navigation.navigate("home");
        } else {
          navigation.goBack();
        }
      }}
    />
  );
}
