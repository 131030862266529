import React from "react";
import { BarChart } from "react-native-chart-kit";
import { screenWidth } from "../../../app/util/util";
import Colors from "../../../components/Colors";

const response = {
  paidOut: 4985,
  nextPayout: 1320,
  earnings: [
    {
      month: "Feb",
      amount: 45,
      paid: true,
    },
    {
      month: "Mar",
      amount: 28,
      paid: true,
    },
    {
      month: "Apr",
      amount: 80,
      paid: true,
    },
    {
      month: "May",
      amount: 99,
      paid: false,
    },
    {
      month: "Jun",
      amount: 43,
      paid: false,
    },
  ],
};

export default function BarChartView({ earnings }) {
  return (
    <BarChart
      data={{
        labels: earnings.map(({ month }) => month),
        datasets: [
          {
            data: earnings.map(({ amount }) => amount),
            colors: earnings.map(({ paid }) => {
              return () => (paid ? Colors.primary : Colors.pinkySwear);
            }),
          },
        ],
      }}
      fromZero
      showBarTops={false}
      style={{ marginHorizontal: 16 }}
      width={screenWidth - 32}
      height={220}
      yAxisLabel="$"
      withInnerLines
      withOuterLines
      verticalLabelRotation={30}
      chartConfig={{
        propsForBackgroundLines: {
          strokeDasharray: "",
          strokeWidth: 1,
          stroke: Colors.lynxWhite,
        },

        backgroundColor: Colors.white,
        backgroundGradientTo: Colors.white,
        backgroundGradientFrom: Colors.white,
        decimalPlaces: 0, // optional, defaults to 2dp
        color: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
        labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
      }}
      withCustomBarColorFromData
      flatColor
    />
  );
}
