import LottieView from "lottie-react-native";
import React from "react";
import { View } from "react-native";

export default class RecordingIcon extends React.Component {
  componentDidMount() {
    this.animation.play();
  }

  render() {
    const { backgroundColor } = this.props;
    return (
      <View
        style={{
          borderWidth: 0,
          backgroundColor: backgroundColor || "transparent",
          borderRadius: 20,
        }}
      >
        <LottieView
          ref={(animation) => {
            this.animation = animation;
          }}
          style={{
            width: 40,
            height: 40,
          }}
          source={require("../../assets/files/recording.json")}
        />
      </View>
    );
  }
}
