import client from "../client";
import {
  APPLICATION_MUTATION,
  IS_AVAILABLE_QUERY,
  LOGIN_MUTATION,
  UPDATE_PASSWORD_MUTATION,
  VERIFY_CODE_MUTATION,
  VERIFY_MUTATION,
} from "./graphql";

function isAvailable(uid) {
  return client
    .query({
      variables: {
        uid,
      },
      query: IS_AVAILABLE_QUERY,
    })
    .then((response) => response.isAvailable);
}

function verify(email, phone) {
  return client
    .mutate({
      variables: {
        email,
        phone,
      },
      mutation: VERIFY_MUTATION,
    })
    .then((response) => response.verify);
}

function verifyCode(email, phone, code) {
  return client
    .mutate({
      variables: {
        email,
        phone,
        code,
      },
      mutation: VERIFY_CODE_MUTATION,
    })
    .then((response) => response.verifyCode);
}

function application({ email, country, social1, followers }) {
  return client
    .mutate({
      variables: {
        email,
        country,
        social1,
        followers,
      },
      mutation: APPLICATION_MUTATION,
    })
    .then((response) => response.application);
}

function login(uid, password) {
  return client
    .mutate({
      variables: {
        uid,
        password,
      },
      mutation: LOGIN_MUTATION,
    })
    .then((response) => response.login);
}

function updatePassword(oldPassword, newPassword) {
  return client
    .mutate({
      variables: {
        oldPassword,
        newPassword,
      },
      mutation: UPDATE_PASSWORD_MUTATION,
    })
    .then((response) => response.updatePassword);
}

export default {
  login,
  verify,
  verifyCode,
  application,
  isAvailable,
  updatePassword,
};
