export default {
  APPLICATION: "application",
  APPLY: "apply",
  BROWSE: "browse",
  CHECKOUT: "checkout",
  CHANGE_PASSWORD: "changepassword",
  CREATORS: "creators",
  STATS: "stats",
  CREATOR_APPLICATIONS: "creatorapplications",
  CONFIRM_COUNTRY: "confirmcountry",
  EDIT_ADJUSTMENT: "editadjustment",
  EDIT_CREATOR: "editcreator",
  EDIT_GUIDE: "editguide",
  EDIT_PLACE: "editplace",
  EDIT_PROFILE: "editprofile",
  EDIT_QUESTION: "editquestion",
  EDIT_THEME: "edittheme",
  EMAIL: "email",
  GTAB: "gtab",
  FOLLOWING: "following",
  GOOGLE_PHOTO_PICKER: "googlephotopicker",
  GUIDE_PLACES: "guideplaces",
  GUIDE: "guide",
  GUIDES: "guides",
  HOME: "home",
  IMAGE_CROP: "imagecrop",
  INSIGHT: "insight",
  PDF_VIEWER: "pdfviewer",
  MONTHLY_REPORTS: "monthlyreports",
  LIKED_PLACES_PAGER: "likedplacespager",
  LIKED_PLACES: "likedplaces",
  LOGIN: "login",
  NOTIFICATION: "notification",
  PASSWORD: "password",
  PENDING_GUIDES: "pendingguides",
  PROFILE_TAB: "profiletab",
  RESOURCES: "resources",
  PROFILE: "profile",
  QUESTIONS: "questions",
  REFER_CREATOR: "refercreatoe",
  REPORTED_GUIDES: "reportedguides",
  SEARCH: "search",
  SELECT: "select",
  SETTINGS: "settings",
  SHARE_GUIDE: "shareguide",
  SIGNUP: "signup",
  THEMES: "themes",
  USERNAME: "username",
  VERIFY: "verify",
};
