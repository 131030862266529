/* eslint-disable import/no-unused-modules */
import { View } from "react-native";

export default {
  Tester: View,
  hook: (comp) => comp,
  wrap: (comp) => comp,
  TestHookStore: null,
  useCavy: () => () => null,
};
