import React from "react";
import { Pressable, View } from "react-native";
import Colors from "../../../components/Colors";
import { Text2 } from "../../../components/ui";

export default function IOSTabView({ tabs, select, style, onTab }) {
  const [selectedTab, setSelectedTab] = React.useState(select || tabs[0]);

  React.useEffect(() => {
    setSelectedTab(select);
  }, [select]);
  return (
    <View style={[{ alignItems: "center" }, style]}>
      <View
        style={{
          padding: 2,
          borderWidth: 2,
          borderRadius: 9,
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
          borderColor: Colors.middleGray,
        }}
      >
        {tabs.map((tab) => (
          <TabButton
            key={tab}
            text={tab}
            onPress={() => onTab(tab)}
            selected={tab === selectedTab}
          />
        ))}
      </View>
    </View>
  );
}

function TabButton({ text, style, selected, ...props }) {
  return (
    <Pressable
      {...props}
      style={[
        {
          height: 28,
          minWidth: 80,
          borderRadius: 6,
          paddingHorizontal: 16,
          alignItems: "center",
          justifyContent: "center",
          color: selected ? Colors.primary : Colors.transparent,
          backgroundColor: selected ? Colors.primary : Colors.transparent,
        },
        style,
      ]}
    >
      <Text2 style={{ fontSize: 14, color: selected ? Colors.white : Colors.darkerGray }}>
        {text}
      </Text2>
    </Pressable>
  );
}
