import React from "react";
import { View } from "react-native";
import { screenWidth } from "../../app/util/util";
import MapMarker from "../map/Marker";
import mapStyle from "../map/mapStyle";
import MapView, { GoogleMapProvider } from "../MapView";
import { openMap } from "../map";

export default function PlaceMapView({ lite, hidePopup, index, place, style }) {
  const region = {
    ...place.location,
    latitudeDelta: 0.0922,
    longitudeDelta: 0.0421,
  };

  return (
    <View {...{ style }}>
      <MapView
        style={{ flex: 1, width: screenWidth, height: screenWidth }}
        region={region}
        initialRegion={region}
        customMapStyle={mapStyle}
        provider={GoogleMapProvider}
        // scrollEnabled={!lite}
        // pitchEnabled={!lite}
        // rotateEnabled={!lite}
        // zoomEnabled={!lite}
      >
        <MapMarker
          show={!hidePopup}
          {...place}
          {...{ index }}
          onCalloutPress={() => openMap(place.location, place.name)}
        />
      </MapView>
    </View>
  );
}
