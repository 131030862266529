import { MaterialCommunityIcons as Icon } from "@expo/vector-icons";
import React from "react";
import { Image, View } from "react-native";
import { isIOS } from "../../app/util/util";

const stripe = require("../../../assets/images/stripe_white.png");
const payout = require("../../../assets/images/payout.png");
const chart = require("../../../assets/images/chart.png");

function GIcon({ name, icon, style, ...props }) {
  return <Icon style={[{ fontSize: 24 }, style]} {...props} name={icon} />;
}

export default {
  About: (props) => <GIcon {...props} icon="information" />,
  AccountCheck: (props) => <GIcon {...props} icon="account-check" />,
  AccountRemove: (props) => <GIcon {...props} icon="account-remove" />,
  Add: (props) => <GIcon {...props} icon="plus" />,
  AudioSource: (props) => <GIcon {...props} icon="volume-source" />,
  Apple: (props) => <GIcon {...props} icon="apple" />,
  Archived: (props) => <GIcon {...props} icon="eye-off" />,
  ArrowBack: (props) => <GIcon {...props} icon="chevron-left" />,
  ArrowUp: (props) => <GIcon {...props} icon="chevron-up" />,
  ArrowDown: (props) => <GIcon {...props} icon="chevron-down" />,
  MenuDown: (props) => <GIcon {...props} icon="menu-down" />,
  ArrowForward: (props) => <GIcon {...props} icon="chevron-right" />,
  BodyMods: (props) => <GIcon {...props} icon="anchor" />,
  Book: (props) => <GIcon {...props} icon="book-outline" />,
  Bookmark: (props) => <GIcon {...props} icon="bookmark" />,
  BookmarkOutline: (props) => <GIcon {...props} icon="bookmark-outline" />,
  Calendar: (props) => <GIcon {...props} icon="calendar" />,
  Camera: (props) => <GIcon {...props} icon="camera" />,
  CameraOutline: (props) => <GIcon {...props} icon="camera-outline" />,
  Cart: (props) => <GIcon {...props} icon="cart" />,
  Cash: (props) => <GIcon {...props} icon="cash" />,
  CellPhone: (props) => <GIcon {...props} icon="phone" />,
  Chart: ({ style, ...props }) => (
    <Image {...props} source={chart} style={[style, { width: 24, height: 24 }]} />
  ),
  Check: (props) => <GIcon {...props} icon="check" />,
  CheckCircle: (props) => <GIcon {...props} icon="check-circle-outline" />,
  CheckBoxBlank: (props) => <GIcon {...props} icon="checkbox-blank-circle-outline" />,
  CheckBoxMarked: (props) => <GIcon {...props} icon="checkbox-marked-circle-outline" />,
  Close: (props) => <GIcon {...props} icon="close" />,
  CloseCircle: (props) => <GIcon {...props} icon="close-circle" />,
  Compass: (props) => <GIcon {...props} icon="compass" />,
  Copy: (props) => <GIcon {...props} icon="content-copy" />,
  Create: (props) => <GIcon {...props} icon="create" />,
  Creator: (props) => <GIcon {...props} icon="account-star" />,
  Stats: (props) => <GIcon {...props} icon="poll" />,
  Culture: (props) => <GIcon {...props} icon="palette" />,
  Currency: (props) => <GIcon {...props} icon="currency-usd-circle" />,
  DateSpots: (props) => <GIcon {...props} icon="glass-wine" />,
  Delete: (props) => <GIcon {...props} icon="delete" />,
  Document: (props) => <GIcon {...props} icon="file-document" />,
  Download: (props) => <GIcon {...props} icon="download" />,
  Edit: (props) => <GIcon {...props} icon="pencil" />,
  Events: (props) => <GIcon {...props} icon="calendar-month" />,
  Exit: (props) => <GIcon {...props} icon="exit-to-app" />,
  Facebook: (props) => <GIcon {...props} icon="facebook" />,
  Food: (props) => <GIcon {...props} icon="noodles" />,
  Flag: (props) => <GIcon {...props} icon="flag" />,
  Gaming: (props) => <GIcon {...props} icon="controller-classic" />,
  Gear: (props) => <GIcon {...props} icon="bike" />,
  Gift: (props) => <GIcon {...props} icon="gift" />,
  Globe: (props) => <GIcon {...props} icon="globe" />,
  Heart: (props) => <GIcon {...props} icon="heart" />,
  HeartOutline: (props) => <GIcon {...props} icon="heart-outline" />,
  Home: (props) => <GIcon {...props} icon="home" />,
  Image: (props) => <GIcon {...props} icon="image" />,
  ImageOutline: (props) => <GIcon {...props} icon="image-outline" />,
  ImageMultiple: (props) => <GIcon {...props} icon="image-multiple" />,
  ImageMultipleOutline: (props) => <GIcon {...props} icon="image-multiple-outline" />,
  Info: (props) => <GIcon {...props} icon="information-outline" />,
  Instagram: (props) => <GIcon {...props} icon="instagram" />,
  LightbulbOn: (props) => <GIcon {...props} icon="lightbulb-on" />,
  Link: (props) => <GIcon {...props} icon="link" />,
  Lock: (props) => <GIcon {...props} icon="lock" />,
  Lodging: (props) => <GIcon {...props} icon="bed" />,
  Mail: (props) => <GIcon {...props} icon="email" />,
  Map: (props) => <GIcon {...props} icon="map" />,
  MapCheck: (props) => <GIcon {...props} icon="map-check-outline" />,
  MapMarker: (props) => <GIcon {...props} icon="map-marker" />,
  MapMarkerOutline: (props) => <GIcon {...props} icon="map-marker-outline" />,
  Mic: (props) => <GIcon {...props} icon="microphone" />,
  Miscellaneous: (props) => <GIcon {...props} icon="alien-outline" />,
  More: (props) => <GIcon {...props} icon="dots-vertical" />,
  MoreHoriz: (props) => <GIcon {...props} icon="dots-horizontal" />,
  Music: (props) => <GIcon {...props} icon="microphone-variant" />,
  News: (props) => <GIcon {...props} icon="newspaper-variant-outline" />,
  Nightlife: (props) => <GIcon {...props} icon="glass-cocktail" />,
  Notification: (props) => <GIcon {...props} icon="bell" />,
  NotificationOn: (props) => <GIcon {...props} icon="bell-ring" />,
  NotificationOff: (props) => <GIcon {...props} icon="bell-off" />,
  Outdoors: (props) => <GIcon {...props} icon="tree" />,
  Password: (props) => <GIcon {...props} icon="key" />,
  Person: (props) => <GIcon {...props} icon="account" />,
  PersonOutline: (props) => <GIcon {...props} icon="account-outline" />,
  Phone: (props) => <GIcon {...props} icon="phone" />,
  Play: (props) => <GIcon {...props} icon="play" />,
  Payout: ({ style, ...props }) => (
    <Image {...props} source={payout} style={[style, { width: 24, height: 24 }]} />
  ),
  Privacy: (props) => <GIcon {...props} icon="shield" />,
  Quote: (props) => <GIcon {...props} icon="format-quote-close" />,
  Refresh: (props) => <GIcon {...props} icon="refresh" />,
  Record: (props) => <GIcon {...props} icon="record" />,
  Restore: (props) => <GIcon {...props} icon="restore" />,
  Search: (props) => <GIcon {...props} icon="magnify" />,
  Selfcare: (props) => <GIcon {...props} icon="meditation" />,
  Save: (props) => <GIcon {...props} style={{ transform: [{ rotate: "90deg" }] }} icon="import" />,
  Settings: (props) => <GIcon {...props} icon="cog" />,
  Share: (props) => <GIcon {...props} icon="share" />,
  ShareOutline: (props) => <GIcon {...props} icon={isIOS ? "export-variant" : "share-outline"} />,
  Shopping: (props) => <GIcon {...props} icon="shopping" />,
  Sightseeing: (props) => <GIcon {...props} icon="airballoon" />,
  Speaker: (props) => (
    <View
      style={[{ borderWidth: 2, borderRadius: 12, borderColor: props?.style?.color || "#fff" }]}
    >
      <GIcon {...props} icon="volume-medium" />
    </View>
  ),
  Stop: (props) => <GIcon {...props} icon="stop" />,
  Sports: (props) => <GIcon {...props} icon="basketball" />,
  Stripe: ({ style, ...props }) => (
    <Image {...props} source={stripe} style={[style, { width: 48, height: 26 }]} />
  ),
  Sync: (props) => <GIcon {...props} icon="chart-donut" />,
  Terms: (props) => <GIcon {...props} icon="format-list-bulleted" />,
  Text: (props) => <GIcon {...props} icon="text" />,
  TimerSand: (props) => <GIcon {...props} icon="timer-sand" />,
  Tune: (props) => <GIcon {...props} icon="tune" />,
  Twitter: (props) => <GIcon {...props} icon="twitter" />,
  Unlocked: (props) => <GIcon {...props} icon="lock-open-outline" />,
  Verified: (props) => <GIcon {...props} icon="checkbox-marked-circle" />,
  Web: (props) => <GIcon {...props} icon="web" />,
  Youtube: (props) => <GIcon {...props} icon="youtube" />,
};
