import client from "../client";
import {
  ME,
  QUERY_POPULAR_USERS,
  QUERY_SEARCH_USERS,
  UPDATE_ROLE,
  UPDATE_USER_MUTATION,
  UPGRADE_TO_CREATOR_MUTATION,
  USER_FOLLOW,
  USER_GET,
  USER_UNFOLLOW,
} from "./graphql";

function me() {
  return client
    .query({
      query: ME,
      variables: {},
    })
    .then((response) => response.me);
}

function userGet(id) {
  return client
    .query({
      query: USER_GET,
      variables: {
        id,
      },
    })
    .then((response) => response.user);
}

function searchUsers(keywords, location, skip, limit) {
  return client
    .query({
      query: QUERY_SEARCH_USERS,
      variables: {
        skip,
        limit,
        keywords,
        location,
      },
    })
    .then((response) => response.searchUsers);
}

function popularUsers(skip, limit) {
  return client
    .query({
      query: QUERY_POPULAR_USERS,
      variables: {
        skip,
        limit,
      },
    })
    .then((response) => response.popularUsers);
}

function userUpdate(id, { bio, name, media, username, password, token, country, expoPushToken }) {
  return client
    .mutate({
      variables: {
        id,
        bio,
        name,
        media,
        username,
        password,
        country,
        token,
        expoPushToken,
      },
      mutation: UPDATE_USER_MUTATION,
    })
    .then((response) => response.userUpdate);
}

function updateRole(emails, role) {
  return client
    .mutate({
      variables: {
        emails,
        role,
      },
      mutation: UPDATE_ROLE,
    })
    .then((response) => response.updateRole);
}

function userFollow(userId) {
  return client
    .mutate({
      variables: {
        userId,
      },
      mutation: USER_FOLLOW,
    })
    .then((response) => response.userFollow);
}

function userUnfollow(userId) {
  return client
    .mutate({
      variables: {
        userId,
      },
      mutation: USER_UNFOLLOW,
    })
    .then((response) => response.userUnfollow);
}

function upgradeToCreator(code) {
  return client
    .mutate({
      variables: {
        code,
      },
      mutation: UPGRADE_TO_CREATOR_MUTATION,
    })
    .then((response) => response.upgradeToCreator);
}

export default {
  me,
  userGet,
  searchUsers,
  popularUsers,
  userUpdate,
  updateRole,
  userFollow,
  userUnfollow,
  upgradeToCreator,
};
