import React from "react";
import { View } from "react-native";
import Colors from "../Colors";
import { Text2SB } from "../ui";
import ButtonView from "./ButtonView";

export default function EmptyView({ Icon, message, style, onPress, actionText }) {
  return (
    <View
      style={[
        {
          flex: 1,
          paddingTop: 20,
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: Colors.white,
        },
        style,
      ]}
    >
      {Icon ? <Icon style={{ fontSize: 150, color: Colors.lightGray }} /> : null}
      <Text2SB
        style={{
          padding: 20,
          lineHeight: 18,
          color: Colors.darkGray,
          textAlign: "center",
        }}
      >
        {message}
      </Text2SB>

      {onPress ? (
        <ButtonView outline primary {...{ onPress }} style={{ width: 240 }}>
          {actionText}
        </ButtonView>
      ) : null}
    </View>
  );
}
