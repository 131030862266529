import React from "react";
import { Alert, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import Api from "../../app/api/Api";
import {
  navigateToBrowse,
  navigateToLogin,
  navigateToSignup,
} from "../../app/navigation/Navigator";
import ICloud from "../../app/store/ICloud";
import store from "../../app/store/Store";
import Cavy from "../../app/test/Cavy";
import { isIOS, isWeb } from "../../app/util/util";
import Colors from "../../components/Colors";
import { LogoDark } from "../../components/Logo";
import { Container, Content, Text1, Text2L } from "../../components/ui";
import ButtonView from "../../components/ui/ButtonView";
import GIcon from "../../components/ui/GIcon";
import GetTheAppView from "../guide/GuideScreen/GetTheAppView";

const TERMS_OF_USE = "https://www.guidepal.com/mterms";
const PRIVACY_POLICY = "https://www.guidepal.com/mprivacy";

export default function GuestScreen({ navigation }) {
  const generateTestHook = Cavy.useCavy();
  const [restoring, setRestoring] = React.useState(false);

  const login = () => navigateToLogin(navigation, {});

  const termsOfUse = () => navigateToBrowse(navigation, { url: TERMS_OF_USE });

  const privacyPolicy = () => navigateToBrowse(navigation, { url: PRIVACY_POLICY });

  const onRestore = async () => {
    setRestoring(true);
    try {
      const iCloudUserId = await ICloud.getUserId();
      const guides = await Api.guide.purchasedGuides(0, 10000, iCloudUserId);

      if (guides.length > 0) {
        store.savePurchasedGuides(guides.map(({ id }) => id));
        Alert.alert("Purchases restored!");
      } else {
        Alert.alert("Restore purchases", "We did't find in-app purchases that could be restored.");
      }
    } catch (error) {
      console.log({ error });
      Alert.alert("Restore purchases", "We did't find in-app purchases that could be restored.");

      if (error !== ICloud.Errors.ICLOUD_ACCESS_ERROR) {
        // Errors.showErrorMessage(error);
      }
    } finally {
      setRestoring(false);
    }
  };

  return (
    <Container style={{ backgroundColor: Colors.lynxWhite }}>
      <Content style={styles.content}>
        <View style={[styles.main, { borderWidth: 0 }]}>
          <LogoDark style={{ marginTop: 80, marginBottom: 16 }} />

          <Text1 style={[styles.description, { fontSize: 16 }]}>
            Get started by creating an account
          </Text1>
          <Text2L
            style={{
              fontSize: 12,
              textAlign: "center",
              marginBottom: 2,
              color: Colors.black,
            }}
          >
            If you've received a creator pre-approval invitation,{"\n"}
            sign up using the email address where you received it.
          </Text2L>

          <ButtonView
            Icon={GIcon.Mail}
            style={{ marginTop: 8 }}
            ref={generateTestHook("Profile.ContinueWithEmail")}
            onPress={() => navigateToSignup(navigation, { useEmail: true })}
          >
            Sign up with email
          </ButtonView>

          <ButtonView
            Icon={GIcon.Phone}
            style={{ marginTop: 8 }}
            ref={generateTestHook("Profile.ContinueWithPhone")}
            onPress={() => navigateToSignup(navigation, { useEmail: false })}
          >
            Sign up with phone
          </ButtonView>

          <Text
            style={{
              marginTop: 24,
              fontSize: 12,
              textAlign: "center",
              marginBottom: 2,
              color: Colors.black,
            }}
          >
            By signing up, you agree to our
          </Text>
          <View
            style={{
              flexDirection: "row",
              marginBottom: 40,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <TouchableOpacity activeOpacity={0.5} onPress={termsOfUse}>
              <Text style={{ fontSize: 12, fontWeight: "bold", color: Colors.primary }}>
                Terms of use
              </Text>
            </TouchableOpacity>
            <Text
              style={{
                fontSize: 12,
                color: Colors.black,
                marginHorizontal: 4,
              }}
            >
              and
            </Text>
            <TouchableOpacity activeOpacity={0.5} onPress={privacyPolicy}>
              <Text style={{ fontSize: 12, fontWeight: "bold", color: Colors.primary }}>
                Privacy policy
              </Text>
            </TouchableOpacity>
          </View>

          <View
            style={{
              marginTop: 60,
              alignItems: "center",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Text1
              style={{
                fontSize: 16,
                marginTop: 8,
                marginRight: 10,
                textAlign: "center",
              }}
            >
              Already have an account?
            </Text1>
          </View>

          <ButtonView
            onPress={login}
            style={{ marginTop: 8 }}
            ref={generateTestHook("Profile.SignIn")}
          >
            Log in
          </ButtonView>

          {isIOS ? (
            <ButtonView
              style={{ marginTop: 8, marginBottom: 16 }}
              loading={restoring}
              ref={generateTestHook("Profile.Restore")}
              onPress={onRestore}
            >
              Restore purchases
            </ButtonView>
          ) : null}

          {isWeb ? <GetTheAppView style={{ marginTop: 24, marginBottom: 100 }} /> : null}
        </View>
      </Content>
    </Container>
  );
}

const styles = StyleSheet.create({
  main: {
    flex: 1,
    marginBottom: 40,
    flexDirection: "column",
    justifyContent: "center",
  },
  content: {
    padding: 20,
  },

  description: {
    marginTop: 15,
    marginBottom: 20,
    textAlign: "center",
  },
});
