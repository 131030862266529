import { observer } from "mobx-react";
import React from "react";
import { View } from "react-native";
import store from "../../../app/store/Store";
import Colors from "../../../components/Colors";
import { Text2, Text2B } from "../../../components/ui";
import ButtonView from "../../../components/ui/ButtonView";
import GIcon from "../../../components/ui/GIcon";

import appstore from "../../../../assets/images/appstore.png";
import googleplay from "../../../../assets/images/googleplay.png";

export default observer(({ onOpenGuide }) => {
  return (
    <View
      style={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: "#00000080",
        position: "absolute",
        justifyContent: "flex-end",
      }}
    >
      <View
        style={{
          margin: 16,
          backgroundColor: Colors.white,
          padding: 24,
          borderRadius: 16,
          textAlign: "center",
          borderWidth: 2,
          borderColor: Colors.primary,
        }}
      >
        <View
          style={{
            backgroundColor: "#CFFF65",
            width: 32,
            height: 32,
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: 40,
            marginBottom: 16,
            borderRadius: 16,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <GIcon.Check />
        </View>
        <Text2B style={{ marginTop: 24 }}>Thanks!</Text2B>
        <Text2 style={{ marginBottom: 16 }}>The payment was succcessful.</Text2>
        <Text2>A confirmation has been sent to your email!</Text2>
        <Text2B style={{ marginBottom: 24 }}>{store.getUserEmail()}</Text2B>
        <ButtonView style={{ marginBottom: 16 }} primary onPress={onOpenGuide}>
          Open guide
        </ButtonView>

        <Text2>Get the app for better user experience!</Text2>

        <div style={{ marginTop: 20, textAlign: "center" }}>
          <a style={{ margin: 4 }} href="https://apps.apple.com/us/app/guidepal/id1595934848">
            <img src={appstore} alt="appstore" />
          </a>

          <a
            style={{ margin: 4 }}
            href="https://play.google.com/store/apps/details?id=com.guidepal.mobile"
          >
            <img src={googleplay} alt="googleplay" />
          </a>
        </div>
      </View>
    </View>
  );
});
