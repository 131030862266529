import React from "react";
import { View } from "react-native";
import Colors from "../../../components/Colors";
import { Text2, Text2L } from "../../../components/ui";

export default function QuoteItemView({ title, text }) {
  return (
    <View
      style={{
        flex: 1,
        borderRadius: 10,
        paddingHorizontal: 16,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text2
        style={{
          fontSize: 16,
          lineHeight: 16,
          marginBottom: 8,
          color: Colors.black,
          textAlign: "center",
        }}
      >
        {title}
      </Text2>
      <Text2L
        style={{
          lineHeight: 16,
          textAlign: "center",
          fontStyle: "italic",
          color: Colors.darkerGray,
        }}
      >
        {text}
      </Text2L>
    </View>
  );
}
