import { useQuery } from "react-query";
import Queries from "../../constants/Queries";
import Api from "../Api";

export function useQuestions() {
  return useQuery(Queries.QUESTIONS, () => Api.question.questions());
}

export function useQuoteOptions() {
  const { isLoading, data } = useQuestions();

  return {
    isLoading,
    options: (data || []).map(({ text, description }) => ({ title: text, text: description })),
  };
}
