/* eslint-disable import/no-mutable-exports */
import { Platform, View } from "react-native";

let Carousel = View;
let Pagination1 = View;

if (Platform.OS !== "web") {
  Carousel = require("react-native-snap-carousel").default;
  Pagination1 = require("react-native-snap-carousel").Pagination;
}

export default Carousel;

export const Pagination = Pagination1;
