import AsyncStorage from "@react-native-async-storage/async-storage";
import { makeAutoObservable } from "mobx";
import Errors from "../util/Errors";
import SecureStore from "./SecureStore";

class Store {
  user = {};

  rememberMe = "";

  token = "";

  isTablet = false;

  notificationBagde = false;

  purchasedGuides = [];

  constructor() {
    makeAutoObservable(this);
  }

  load() {
    AsyncStorage.getItem("rememberme")
      .then((val) => {
        this.rememberMe = val || "true";
      })
      .catch(Errors.log);
    AsyncStorage.getItem("purchasedGuides")
      .then((ids) => {
        this.purchasedGuides = JSON.parse(ids) || [];
      })
      .catch(Errors.log);
  }

  getUserId() {
    return this.user ? this.user.id : null;
  }

  isCreator() {
    return isCreator(this.user);
  }

  savePurchasedGuides(ids) {
    this.purchasedGuides = ids || [];
    AsyncStorage.setItem("purchasedGuides", JSON.stringify(ids || [])).catch(Errors.log);
  }

  getUserEmail() {
    return this.user ? this.user.email : null;
  }

  isLoggedIn() {
    return this.getUserId();
  }

  isAdmin() {
    return this.user && this.user.role === "admin";
  }

  isFollowing(userId) {
    return this.user && (this.user.following || []).find((id) => id === userId);
  }

  clear() {
    this.user = {};
    this.token = "";
    return AsyncStorage.removeItem("token");
  }

  setUser(user) {
    this.user = user;
  }

  setIsTablet(isTablet) {
    this.isTablet = isTablet;
  }

  setToken(token) {
    this.token = token;
    AsyncStorage.setItem("token", token || "").catch(Errors.log);
  }

  setRememberMe(rememberme) {
    this.rememberMe = rememberme;
    AsyncStorage.setItem("rememberme", JSON.stringify(rememberme) || "").catch(Errors.log);
  }

  setNotificationBadge(badge) {
    this.notificationBagde = badge;
  }

  isUnlocked(guide) {
    if (guide.isFree) {
      return true;
    }
    // check ownership
    const userId = this.getUserId();
    const isOwner = guide.user.id === userId;

    // check purchase
    const isPurchased =
      (this.user && (this.user.purchasedGuides || []).find((id) => id === guide.id)) ||
      this.purchasedGuides.find((id) => id === guide.id);

    return isOwner || isPurchased || this.isAdmin();
  }
}

export async function saveLoginCredentials(uid, upass) {
  await SecureStore.setItemAsync("uid", uid);
  await SecureStore.setItemAsync("password", upass);
}

export async function getLoginCredentials() {
  const uid = await SecureStore.getItemAsync("uid");
  const upass = await SecureStore.getItemAsync("password");
  if (uid) return [uid, upass];
  return ["", ""];
}

const store = new Store();
export default store;

export function isCreator(user) {
  return user && (user.role === "creator" || user.role === "admin");
}
