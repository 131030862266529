import React from "react";
import { View } from "react-native";
import Api from "../../app/api/Api";
import { useGuide } from "../../app/api/guide/guideHooks";
import Cavy from "../../app/test/Cavy";
import Errors from "../../app/util/Errors";
import Colors from "../../components/Colors";
import Status from "../../components/guide/Status";
import { Container, Content, Header, Text2, Text2B } from "../../components/ui";
import ErrorView from "../../components/ui/ErrorView";
import { InputView } from "../../components/ui/inputs";
import UserListItemView from "../../components/ui/listItems";
import LoadingView from "../../components/ui/LoadingView";

export default function EditAdjustmentScreen({ route, navigation }) {
  const generateTestHook = Cavy.useCavy();
  const { guideId } = route.params;

  const [sending, setSending] = React.useState(false);
  const [adjustment, setAdjustment] = React.useState("");

  const { error, isLoading, data: guide } = useGuide(guideId);

  React.useEffect(() => {
    if (guide) {
      setAdjustment(guide.adjustment || "");
    }
  }, [guide]);

  if (isLoading) {
    return <LoadingView />;
  }

  if (error) {
    return <ErrorView error="Something went wrong, try again!" />;
  }

  function onDone() {
    setSending(true);
    Api.guide
      .guideUpdate(guideId, { adjustment, status: Status.PENDING })
      .then(() => {
        setSending(false);
        navigation.goBack();
      })
      .catch((e) => {
        setSending(false);
        Errors.showErrorMessage(e);
      });
  }

  return (
    <Container>
      <Header
        transparent
        title="Request adjustment"
        left={{ label: "Cancel", onPress: navigation.goBack }}
        right={{ label: "Send", onPress: onDone, loading: sending }}
      />

      <Content showsVerticalScrollIndicator={false} style={{ flex: 1, marginHorizontal: 16 }}>
        <Text2 style={{ marginTop: 24 }}>Guide:</Text2>
        <Text2B style={{ marginBottom: 16, color: Colors.primary }}>{guide.name}</Text2B>

        <UserListItemView user={guide.user} onUser={() => null} />

        <InputView
          ref={generateTestHook("Adjustment.adjustment")}
          multiline
          label="Adjustment description"
          style={{
            borderWidth: 1,
            paddingTop: 16,
            minHeight: 128,
            borderRadius: 8,
            borderColor: Colors.superSilver,
            paddingHorizontal: 16,
          }}
          value={adjustment}
          onChangeText={setAdjustment}
        />

        <View style={{ flex: 1, height: 100 }} />
      </Content>
    </Container>
  );
}
