import client from "../client";
import { QUERY_QUESTIONS, QUESTION_CREATE, QUESTION_DELETE, QUESTION_UPDATE } from "./graphql";

function questions() {
  return client
    .query({
      variables: {},
      query: QUERY_QUESTIONS,
    })
    .then((response) => response.questions);
}

function questionCreate(text, description) {
  return client
    .mutate({
      variables: {
        text,
        description,
      },
      mutation: QUESTION_CREATE,
    })
    .then((response) => response.questionCreate);
}

function questionUpdate(id, text, description) {
  return client
    .mutate({
      variables: {
        id,
        text,
        description,
      },
      mutation: QUESTION_UPDATE,
    })
    .then((response) => response.questionUpdate);
}

function questionDelete(id) {
  return client
    .mutate({
      variables: {
        id,
      },
      mutation: QUESTION_DELETE,
    })
    .then((response) => response.questionDelete);
}

export default {
  questions,
  questionCreate,
  questionUpdate,
  questionDelete,
};
