import React from "react";
import { FlatList } from "react-native";
import { useQuery } from "react-query";
import Api from "../../app/api/Api";
import Queries from "../../app/constants/Queries";
import { navigateToEditCreator, navigateToProfile } from "../../app/navigation/Navigator";
import Cavy from "../../app/test/Cavy";
import { Container, Header } from "../../components/ui";
import GIcon from "../../components/ui/GIcon";
import UserListItemView from "../../components/ui/listItems";
import LoadingView from "../../components/ui/LoadingView";
import CreatorsHeaderView from "./CreatorsHeaderView";

const TABS = {
  SIGNED_UP: "signed-up",
  NOT_SIGNED_UP: "not-signed-up",
};

export default function CreatorsScreen({ navigation }) {
  const generateTestHook = Cavy.useCavy();
  const [text, onChangeText] = React.useState("");

  const { data, isLoading } = useQuery(Queries.POPULAR_USERS, () =>
    Api.user.popularUsers(0, 200000)
  );

  const [tab, setTab] = React.useState(TABS.SIGNED_UP);

  if (isLoading) {
    return <LoadingView />;
  }

  const keywords = text
    .split(" ")
    .map((word) => word.trim().toLowerCase())
    .filter((word) => word);

  const creators = data
    .filter((user) => user.role === "creator")
    .filter((user) => {
      if (keywords.length === 0) {
        return true;
      }

      const targetText = `${user.username} ${user.name} ${user.email}`.toLowerCase();

      for (let i = 0; i < keywords.length; i++) {
        const word = keywords[i];
        if (targetText.includes(word)) {
          return true;
        }
      }
      return false;
    });

  const users =
    tab === TABS.SIGNED_UP
      ? creators.filter((user) => user.username)
      : creators.filter((user) => !user.username);

  function onUser(user) {
    navigateToProfile(navigation, { userId: user.id });
  }

  function onClear() {
    onChangeText("");
  }

  return (
    <Container>
      <Header
        title="Creators"
        onBack={() => navigation.goBack()}
        actions={
          <Header.Action
            ref={generateTestHook("Add.Creator")}
            Icon={GIcon.Add}
            onPress={() => navigateToEditCreator(navigation, {})}
          />
        }
      />
      <FlatList
        ListHeaderComponent={
          <CreatorsHeaderView n={users.length} onTab={setTab} {...{ tab, onClear, onChangeText }} />
        }
        data={users}
        keyboardShouldPersistTaps="handled"
        renderItem={({ item: user, index }) => (
          <UserListItemView
            ref={generateTestHook(`user${index}`)}
            {...{ user, onUser, onPress: () => onUser(user) }}
          />
        )}
      />
    </Container>
  );
}
