import { useNavigation } from "@react-navigation/native";
import React from "react";
import { Alert, Linking, TouchableOpacity, View } from "react-native";
import { navigateToBrowse } from "../../app/navigation/Navigator";
import Cavy from "../../app/test/Cavy";
import { isWeb } from "../../app/util/util";
import Colors from "../Colors";
import { Radius } from "../Theme";
import { Text2 } from "../ui";
import GIcon from "../ui/GIcon";

export default function PlaceInfoView({ style, active, phone, website }) {
  const generateTestHook = Cavy.useCavy();
  const navigation = useNavigation();

  function makeCall() {
    if (!phone) {
      Alert.alert("There is no phone number!");
      return;
    }

    Linking.openURL(`tel:${phone}`);
  }

  function navigateToWebsite() {
    if (!website) {
      Alert.alert("There is no website information!");
      return;
    }

    isWeb ? Linking.openURL(website) : navigateToBrowse(navigation, { url: website });
  }

  if (!phone && !website) {
    return null;
  }

  return (
    <View
      style={[
        {
          flex: 1,
          marginTop: 32,
          marginBottom: 16,
          flexDirection: "row",
          paddingHorizontal: 16,
        },
        style,
      ]}
    >
      {website ? (
        <Button
          info={website}
          {...{ active }}
          label="Website"
          Icon={GIcon.Web}
          onPress={() => navigateToWebsite()}
          ref={generateTestHook("EditPlace.Website")}
        />
      ) : null}
      {phone ? (
        <Button
          label="Call"
          info={phone}
          {...{ active }}
          Icon={GIcon.CellPhone}
          onPress={() => makeCall()}
          ref={generateTestHook("EditPlace.Phone")}
        />
      ) : null}
    </View>
  );
}

export function Button({ label, active, info, Icon, ...props }) {
  return (
    <TouchableOpacity {...props} activeOpacity={0.9}>
      <View
        style={{
          borderRadius: Radius.L,
          marginRight: 12,
          paddingVertical: 12,
          alignItems: "center",
          flexDirection: "row",
          paddingHorizontal: 16,
          justifyContent: "center",
          backgroundColor: Colors.white,
        }}
      >
        <Icon style={{ fontSize: 28, color: active ? Colors.black : Colors.charcoal }} />

        <Text2
          style={{
            paddingHorizontal: 8,
            textAlign: "center",
            color: active ? Colors.black : Colors.charcoal,
          }}
        >
          {label}
        </Text2>
      </View>
    </TouchableOpacity>
  );
}
