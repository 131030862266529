import React from "react";
import { Pressable, View } from "react-native";
import Cavy from "../../../app/test/Cavy";
import Colors from "../../../components/Colors";
import { Text2L } from "../../../components/ui";

const Touchable = Cavy.wrap(Pressable);

const TabView = ({ tab, flip, selected, onPress }) => {
  const generateTestHook = Cavy.useCavy();
  const { Icon } = tab;
  const { title } = tab;
  const color = Icon && !flip ? Colors.darkGray : Colors.black;

  return (
    <View
      style={{
        flex: 1,
        alignContent: "stretch",
        borderBottomWidth: 1,
        borderColor: Colors.gray,
      }}
    >
      <Touchable {...{ onPress }} ref={generateTestHook(tab.id)}>
        <View
          style={{
            flex: 1,
            height: Icon ? (flip ? 32 : 52) : 26,
            borderColor: selected ? Colors.primary : "transparent",
            borderBottomWidth: 2,
            flexDirection: flip ? "row" : "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {Icon ? <Icon style={{ color: Colors.black }} /> : null}
          <Text2L style={{ color, fontSize: flip ? 14 : 12, marginLeft: flip ? 4 : 0 }}>
            {title}
          </Text2L>
        </View>
      </Touchable>
    </View>
  );
};

export default function TabsView({ tabs, select, onSelect, flip, style }) {
  return (
    <View style={[style, { flexDirection: "row" }]}>
      {tabs.map((tab) => (
        <TabView
          key={tab.id}
          {...{ tab }}
          flip={flip}
          selected={tab.id === select}
          onPress={() => onSelect(tab.id)}
        />
      ))}
    </View>
  );
}
