/* eslint-disable import/no-unused-modules */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import { LogBox, StatusBar as AndroidBar } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Device from "expo-device";
import * as Font from "expo-font";
import * as SplashScreen from "expo-splash-screen";
import { StatusBar } from "expo-status-bar";
import * as Notifications from "expo-notifications";
import React from "react";
import "react-native-gesture-handler";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { QueryClientProvider } from "react-query";
import FlashMessage from "react-native-flash-message";
import Api from "./src/app/api/Api";
import { queryClient } from "./src/app/api/client";
import Navigation from "./src/app/navigation";
import store from "./src/app/store/Store";
import ActionSheetProvider from "./src/components/actionsheet/ActionSheetProvider";
import AppContainer from "./src/components/AppContainer";
import Errors from "./src/app/util/Errors";
import { tracker } from "./src/components/OpenReplay";
import Track from "./src/app/Track";

const Sentry = { init: () => null };
Sentry.init({
  dsn: "https://0ef8039a29a54182bdf42a90f85d0945@o193368.ingest.sentry.io/1296210",
  enableInExpoDevelopment: false,
  debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
});

LogBox.ignoreLogs(["Setting a timer"]);
LogBox.ignoreLogs(["Encountered two children with the same key"]);
LogBox.ignoreLogs(["Each child in a list should have a unique"]);
LogBox.ignoreLogs(["Possible Unhandled Promise Rejection"]);
// if app is in foreground
Notifications.setNotificationHandler({
  handleNotification: async () => ({}),
  handleSuccess: async () => store.setNotificationBadge(true),
  handleError: async (error) => console.log(error),
});

function useCachedResources() {
  const [isLoadingComplete, setLoadingComplete] = React.useState(false);

  // Load any resources or data that we need prior to rendering the app
  React.useEffect(() => {
    async function loadResourcesAndDataAsync() {
      try {
        SplashScreen.preventAutoHideAsync();

        store.load();

        const type = await Device.getDeviceTypeAsync();
        if (type === Device.DeviceType.TABLET) {
          store.setIsTablet(true);
        }

        // Load fonts
        await Font.loadAsync({
          // RecoletaBold: require("./assets/fonts/NanumMyeongjo_700Bold.ttf"),
          RecoletaBlack: require("./assets/fonts/NanumMyeongjo_800ExtraBold.ttf"),
          NanumMyeongjoRegular: require("./assets/fonts/NanumMyeongjo-Regular.ttf"),
          RecoletaBold: require("./assets/fonts/ZenKakuGothicNew-Regular.ttf"),

          OutfitLight: require("./assets/fonts/Outfit-Light.ttf"),
          OutfitMedium: require("./assets/fonts/Outfit-Medium.ttf"),
          OutfitSemiBold: require("./assets/fonts/Outfit-SemiBold.ttf"),
          OutfitBold: require("./assets/fonts/Outfit-Bold.ttf"),

          PulpDisplayBold: require("./assets/fonts/Outfit-Bold.ttf"),
          PulpDisplayLight: require("./assets/fonts/Outfit-Light.ttf"),
          PulpDisplayMedium: require("./assets/fonts/Outfit-Medium.ttf"),
          PulpDisplaySemiBold: require("./assets/fonts/Outfit-SemiBold.ttf"),
        });

        try {
          const token = await AsyncStorage.getItem("token");
          store.setToken(token);
          if (token) {
            store.setUser(await Api.user.me());
          }
        } catch (e) {
          console.log("Failed to load user: ", e);
        }
      } catch (e) {
        Errors.showGenericMessage(e);
      } finally {
        setLoadingComplete(true);
        SplashScreen.hideAsync();
      }

      Track.sendEvent({ type: "Open app" });
    }

    loadResourcesAndDataAsync();
  }, []);

  return isLoadingComplete;
}

export default function App() {
  const isLoadingComplete = useCachedResources();
  const notificationListener = React.useRef();
  const responseListener = React.useRef();

  React.useEffect(() => {
    notificationListener.current = Notifications.addNotificationReceivedListener();
    responseListener.current = Notifications.addNotificationResponseReceivedListener();

    return () => {
      Notifications.removeNotificationSubscription(notificationListener.current);
      Notifications.removeNotificationSubscription(responseListener.current);
    };
  }, []);

  React.useEffect(() => {
    tracker.start();
  }, []);

  if (!isLoadingComplete) {
    return null;
  }

  return (
    <AppContainer>
      <ActionSheetProvider>
        <QueryClientProvider client={queryClient}>
          <SafeAreaProvider>
            <Navigation />
            <StatusBar style="dark" />
            <FlashMessage position="top" style={{ marginTop: AndroidBar.currentHeight }} />
          </SafeAreaProvider>
        </QueryClientProvider>
      </ActionSheetProvider>
    </AppContainer>
  );
}
