import { gql } from "graphql-request";
import { REPORT_MODEL } from "../models";

export const QUERY_REPORTED_GUIDES = gql`
  query reportedGuides {
    reportedGuides {
     ${REPORT_MODEL}
    }
  }
`;

export const REPORT_CREATE = gql`
  mutation reportCreate(
    $userId: String
    $guideId: String
    $category: String
    $message: String
  ) {
    reportCreate(
      userId: $userId
      guideId: $guideId
      message: $message
      category: $category
    ) {
      ${REPORT_MODEL}
    }
  }
`;

export const REPORT_UPDATE = gql`
  mutation reportUpdate(
    $id: String
    $category: String
    $message: String
  ) {
    reportUpdate(
      id: $id
      category: $category
      message: $message
    ) {
      ${REPORT_MODEL}
    }
  }
`;

export const REPORT_DELETE = gql`
  mutation reportDelete(
    $id: String
  ) {
    reportDelete(
      id: $id
    ) {
      ${REPORT_MODEL}
    }
  }
`;
