import React, { useState } from "react";
import { TouchableOpacity, View } from "react-native";
import Cavy from "../../app/test/Cavy";
import { PulpDisplaySemiBold } from "../typography";
import store from "../../app/store/Store";
import Colors from "../Colors";
import GIcon from "./GIcon";

function CheckBoxView({ style, children, checked }) {
  const [check, setCheck] = useState(checked);

  function onPressIcon() {
    if (check) {
      setCheck(false);
      store.setRememberMe("false");
    } else {
      setCheck(true);
      store.setRememberMe("true");
    }
  }

  return (
    <TouchableOpacity {...{ style }} activeOpacity={0.5}>
      <View
        style={[
          {
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          },
        ]}
      >
        <TouchableOpacity onPress={() => onPressIcon()}>
          {check ? (
            <GIcon.CheckBoxMarked
              style={{
                color: Colors.primary,
                fontSize: 24,
                paddingRight: 8,
              }}
            />
          ) : (
            <GIcon.CheckBoxBlank
              style={{
                color: Colors.primary,
                fontSize: 24,
                paddingRight: 8,
              }}
            />
          )}
        </TouchableOpacity>

        <PulpDisplaySemiBold
          style={{
            fontSize: 14,
          }}
        >
          {children}
        </PulpDisplaySemiBold>
      </View>
    </TouchableOpacity>
  );
}

export default Cavy.wrap(CheckBoxView);
