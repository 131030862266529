import Constants from "expo-constants";
import React from "react";
import { ActivityIndicator, Alert, Linking, Share, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import Api from "../../app/api/Api";
import client from "../../app/api/client";
import {
  navigateToBrowse,
  navigateToChangePassword,
  navigateToCreatorApplications,
  navigateToCreators,
  navigateToEditProfile,
  navigateToPendingGuides,
  navigateToQuestions,
  navigateToReferCreator,
  navigateToReportedGuides,
  navigateToStats,
  navigateToThemes,
} from "../../app/navigation/Navigator";
import ICloud from "../../app/store/ICloud";
import store from "../../app/store/Store";
import Cavy from "../../app/test/Cavy";
import { isAndroid } from "../../app/util/util";
import Colors from "../../components/Colors";
import { Container, Content, Header, Text2, Text2B } from "../../components/ui";
import GIcon from "../../components/ui/GIcon";

const ABOUT_US = "http://www2.guidepal.com/views/about/about.html";
const TERMS_OF_USE = "https://www.guidepal.com/mterms";
const PRIVACY_POLICY = "https://www.guidepal.com/mprivacy";

const SettingsItem = Cavy.wrap(({ Icon, text, loading, onPress }) => {
  const generateTestHook = Cavy.useCavy();

  return (
    <TouchableOpacity
      onPress={loading ? null : onPress}
      ref={generateTestHook(`Settings.${text.replace(/ /g, "-")}`)}
    >
      <View
        style={{
          height: 48,
          alignItems: "center",
          flexDirection: "row",
          paddingHorizontal: 16,
        }}
      >
        <Icon />
        <Text2 style={{ marginHorizontal: 16 }}>{text}</Text2>
        {loading ? <ActivityIndicator size="small" style={{}} /> : null}
      </View>
    </TouchableOpacity>
  );
});

export default function SettingsScreen({ navigation }) {
  const generateTestHook = Cavy.useCavy();
  const [restoring, setRestoring] = React.useState(false);

  function logout() {
    client.resetStore();
    store.clear().then(() => navigation.goBack());
  }

  const aboutUs = () => navigateToBrowse(navigation, { url: ABOUT_US });

  const termsOfUse = () => navigateToBrowse(navigation, { url: TERMS_OF_USE });

  const privacyPolicy = () => navigateToBrowse(navigation, { url: PRIVACY_POLICY });

  const feedback = () => Linking.openURL("mailto:info@guidepal.com");

  const onRestore = async () => {
    setRestoring(true);
    try {
      const iCloudUserId = await ICloud.getUserId();
      const guides = await Api.guide.purchasedGuides(0, 10000, iCloudUserId);

      if (guides.length > 0) {
        store.savePurchasedGuides(guides.map(({ id }) => id));
        Alert.alert("Purchases restored!");
      } else {
        Alert.alert("Restore purchases", "We did't find in-app purchases that could be restored.");
      }
    } catch (error) {
      console.log({ error });
      Alert.alert("Restore purchases", "We did't find in-app purchases that could be restored.");

      if (error !== ICloud.Errors.ICLOUD_ACCESS_ERROR) {
        // Errors.showErrorMessage(error);
      }
    } finally {
      setRestoring(false);
    }
  };

  const shareApp = () =>
    Share.share({
      title: "Check out guidepal!",
      message:
        "Check out guidepal! Places & experiences by your favorite creators, always ad free.\n https://apps.apple.com/us/app/guidepal/id1595934848",
    });

  return (
    <Container removeClippedSubviews={false}>
      <Header onBack={() => navigation.goBack()} title="Settings" />

      <Content>
        {store.isAdmin() && <View style={{ height: 20 }} />}

        {store.isAdmin() && (
          <SettingsItem
            text="Stats"
            Icon={GIcon.Stats}
            onPress={() => navigateToStats(navigation, {})}
          />
        )}

        {store.isAdmin() && (
          <SettingsItem
            text="Creators"
            Icon={GIcon.Creator}
            onPress={() => navigateToCreators(navigation, {})}
          />
        )}

        {store.isAdmin() && (
          <SettingsItem
            Icon={GIcon.Document}
            text="Applications"
            onPress={() => navigateToCreatorApplications(navigation, {})}
          />
        )}

        {store.isAdmin() && (
          <SettingsItem
            Icon={GIcon.Book}
            text="Pending guides"
            onPress={() => navigateToPendingGuides(navigation, {})}
          />
        )}

        {store.isAdmin() && (
          <SettingsItem
            Icon={GIcon.Flag}
            text="Reported guides"
            onPress={() => navigateToReportedGuides(navigation, {})}
          />
        )}

        {store.isAdmin() && (
          <SettingsItem
            ref={generateTestHook("Guide.Themes")}
            Icon={GIcon.LightbulbOn}
            text="Guide themes"
            onPress={() => navigateToThemes(navigation, {})}
          />
        )}

        {store.isAdmin() && (
          <SettingsItem
            ref={generateTestHook("Guide.Quotes")}
            Icon={GIcon.Quote}
            text="Quotes"
            onPress={() => navigateToQuestions(navigation, {})}
          />
        )}

        <View style={{ height: 20 }} />

        <SettingsItem
          ref={generateTestHook("Edit.Profile")}
          Icon={GIcon.Person}
          text="Edit profile"
          onPress={() => navigateToEditProfile(navigation, {})}
        />

        <SettingsItem
          Icon={GIcon.Password}
          text="Change password"
          onPress={() => navigateToChangePassword(navigation, {})}
        />

        {store.isAdmin() && (
          <SettingsItem
            Icon={GIcon.Gift}
            text="Invite a creator and get $15"
            onPress={() => navigateToReferCreator(navigation, {})}
          />
        )}

        <View style={{ height: 20 }} />

        {false && <SettingsItem Icon={GIcon.About} text="About us" onPress={aboutUs} />}
        <SettingsItem Icon={GIcon.Privacy} text="Privacy policy" onPress={privacyPolicy} />
        <SettingsItem Icon={GIcon.Terms} text="Terms of use" onPress={termsOfUse} />
        <View style={{ height: 20 }} />
        <SettingsItem
          ref={generateTestHook("Settings.ShareApp")}
          Icon={GIcon.Share}
          text="Share the app"
          onPress={shareApp}
        />
        <SettingsItem
          ref={generateTestHook("Settings.Feedback")}
          Icon={GIcon.Mail}
          text="Feedback"
          onPress={feedback}
        />
        {!isAndroid && (
          <SettingsItem
            ref={generateTestHook("Settings.Restore")}
            Icon={GIcon.Restore}
            loading={restoring}
            text="Restore purchases"
            onPress={onRestore}
          />
        )}

        <View style={{ height: 20 }} />
        <SettingsItem Icon={GIcon.Exit} text="Log out" onPress={logout} />

        <View style={{ marginTop: 16, marginBottom: 48 }}>
          <Text2B
            style={{
              fontSize: 11,
              lineHeight: 14,
              borderWidth: 0,
              textAlign: "center",
              color: Colors.darkGray,
            }}
          >
            {`VERSION: ${Constants.manifest.version}`}
            {"\nLAST UPDATE: "}
            {Constants.manifest.extra.date.replace("T", " ").replace('Z"', "").replace('"', "")}
            {
              // store.isAdmin() ? `\n${userAgent()}` : null
            }
          </Text2B>
        </View>
      </Content>
    </Container>
  );
}
