import React from "react";
import { TextInput, View } from "react-native";
import { useQueryClient } from "react-query";
import Api from "../../app/api/Api";
import Cavy from "../../app/test/Cavy";
import AlertDialog from "../../components/AlertDialog";
import Colors from "../../components/Colors";
import { Container, Content, Header } from "../../components/ui";
import ButtonView from "../../components/ui/ButtonView";

const TextQuestion = Cavy.wrap(TextInput);

export default function EditQuestionScreen({ route, navigation }) {
  const generateTestHook = Cavy.useCavy();
  const question = route.params.question || {};
  const [, setLoading] = React.useState(false);
  const [text, setText] = React.useState(question.text);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [description, setDescription] = React.useState(question.description);
  const queryClient = useQueryClient();

  function onAdd() {
    setLoading(true);
    Api.question
      .questionCreate(text, description)
      .then((newQuestion) => {
        setLoading(false);
        navigation.goBack();
        queryClient.setQueryData("questions", (questions) => [...questions, newQuestion]);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  function onUpdate() {
    setLoading(true);
    Api.question
      .questionUpdate(question.id, text, description)
      .then((updatedQuestion) => {
        setLoading(false);
        navigation.goBack();
        queryClient.setQueryData("questions", (questions) => {
          return questions.map((item) => (item.id === updatedQuestion.id ? updatedQuestion : item));
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  function onDelete() {
    setShowDeleteDialog(true);
  }

  function doDelete() {
    setLoading(true);
    Api.question
      .questionDelete(question.id)
      .then((deletedQuestion) => {
        setLoading(false);
        navigation.goBack();
        queryClient.setQueryData("questions", (questions) => {
          return questions.filter((item) => item.id !== deletedQuestion.id);
        });
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }

  return (
    <Container>
      <Header
        transparent
        title={question.id ? "Edit question" : "Create question"}
        left={{ label: "Cancel", onPress: navigation.goBack }}
        right={{ label: "Done", onPress: question.id ? onUpdate : onAdd }}
      />

      <Content
        showsVerticalScrollIndicator={false}
        style={{ flex: 1, marginHorizontal: 16, paddingTop: 24 }}
      >
        <TextQuestion
          ref={generateTestHook("Edit.Question")}
          multiline
          style={{
            marginTop: 24,
            borderWidth: 1,
            paddingTop: 16,
            minHeight: 56,
            borderRadius: 8,
            borderColor: Colors.superSilver,
            paddingHorizontal: 16,
          }}
          value={text}
          onChangeText={setText}
        />

        <TextQuestion
          ref={generateTestHook("Edit.Desc")}
          multiline
          style={{
            marginTop: 24,
            borderWidth: 1,
            paddingTop: 16,
            minHeight: 128,
            borderRadius: 8,
            borderColor: Colors.superSilver,
            paddingHorizontal: 16,
          }}
          value={description}
          onChangeText={setDescription}
        />

        {question.id ? (
          <ButtonView ref={generateTestHook("Question.Delete")} onPress={onDelete}>
            Delete
          </ButtonView>
        ) : null}

        <View style={{ flex: 1, height: 100 }} />

        <AlertDialog
          title="Delete"
          message="Are you sure?"
          visible={showDeleteDialog}
          options={[
            { text: "No", onPress: () => setShowDeleteDialog(false) },
            {
              text: "Yes",
              onPress: () => {
                doDelete();
                setShowDeleteDialog(null);
              },
            },
          ]}
        />
      </Content>
    </Container>
  );
}
