import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { ActivityIndicator, TouchableOpacity, View } from "react-native";
import store from "../../app/store/Store";
import Cavy from "../../app/test/Cavy";
import { isWeb, screenWidth } from "../../app/util/util";
import ClosableView from "../ClosableView";
import Colors from "../Colors";
import { Text2 } from "../ui";
import GIcon from "../ui/GIcon";
import ImageView from "../ui/ImageView";
import UserAvatarView from "../ui/UserAvatarView";
import Status from "./Status";

const tileWidth = (screenWidth - 16) * 0.6;

const fallback = "http://s3-eu-west-1.amazonaws.com/userimages.guidewall/empty.jpg";
const AVATAR_FALLBACK = "http://s3.amazonaws.com/gajdavatar/dummyAvatar.jpg";

export const IconButton = Cavy.wrap(
  ({ Icon, iconSize, color, loading, backgroundColor, style, ...props }) => {
    return (
      <TouchableOpacity {...props} activeOpacity={0.9}>
        <View
          style={[
            {
              width: 48,
              height: 48,
              borderRadius: 24,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: backgroundColor || Colors.white,
            },
            style,
          ]}
        >
          {loading ? (
            <ActivityIndicator />
          ) : (
            <Icon style={{ fontSize: iconSize || 24, color: color || Colors.black }} />
          )}
        </View>
      </TouchableOpacity>
    );
  }
);

function BadgeView({ Icon, text, style }) {
  return (
    <View style={[{ flexDirection: "row", alignItems: "center" }, style]}>
      <Icon style={{ color: Colors.white, fontSize: 16 }} />
      <Text2
        style={{
          marginLeft: 8,
          color: Colors.white,
        }}
      >
        {text}
      </Text2>
    </View>
  );
}

function SampleBadgeView() {
  return (
    <View
      style={{
        width: 64,
        borderRadius: 32,
        paddingVertical: 7,
        backgroundColor: Colors.primary,
      }}
    >
      <Text2
        style={{
          fontSize: 10,
          textAlign: "center",
          color: Colors.white,
        }}
      >
        SAMPLE
      </Text2>
    </View>
  );
}

function hasAudio(guide) {
  return (
    guide.audio ||
    guide.places?.map((p) => p.medias?.filter((m) => m.audio).length > 0).filter((m) => m).length >
      0 ||
    guide.places?.filter((p) => p.audio).length > 0
  );
}

const GuideCellView = Cavy.wrap(({ showBadges, width, guide, onPress, onDelete, ...props }) => {
  const { url } = guide.media ? guide.media : { url: fallback, ratio: 1 };

  const { user } = guide;
  const avatarUrl = (user.media || {}).url || AVATAR_FALLBACK;

  const isPending = guide.status === Status.PENDING;

  const isOwner = guide.user.id === store.getUserId();
  const showAdjustment = guide.adjustment && (store.isAdmin() || isOwner);

  const content = (
    <TouchableOpacity
      {...props}
      activeOpacity={0.9}
      style={[
        {
          marginLeft: 16,
          backgroundColor: Colors.transparent,
        },
        onDelete ? null : { marginTop: 8, marginBottom: 10 },
      ]}
      onPress={() => onPress(guide)}
    >
      <View style={{ width: width || tileWidth }}>
        <ImageView
          height={tileWidth / 0.75}
          width={width || tileWidth}
          style={{
            borderRadius: 10,
            width: width || tileWidth,
            height: (width || tileWidth) / 0.75,
            backgroundColor: Colors.transparent,
          }}
          uri={url || fallback}
        />

        {url ? (
          <LinearGradient
            colors={[Colors.transparent, "rgba(0,0,0,0.2)"]}
            style={{ position: "absolute", top: 0, right: 0, left: 0, bottom: 0, borderRadius: 10 }}
          />
        ) : null}

        {false ? (
          <View
            style={{
              borderRadius: 10,
              position: "absolute",
              width: width || tileWidth,
              height: (width || tileWidth) / 0.75,
              backgroundColor: "rgba(0,0,0,0.2)",
            }}
          />
        ) : null}

        {guide.status === Status.ARCHIVED ? (
          <View
            style={{
              position: "absolute",
              width: width || tileWidth,
              height: (width || tileWidth) / 0.75,
              backgroundColor: "#ffffff4d",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <GIcon.Archived style={{ color: Colors.white }} />
          </View>
        ) : null}

        {showBadges ? (
          <View
            style={{
              position: "absolute",
              width: width || tileWidth,
              height: (width || tileWidth) / 0.75,
              padding: 8,
            }}
          >
            {guide.isFree ? <SampleBadgeView /> : null}

            {hasAudio(guide) ? (
              <View
                style={{
                  top: 4,
                  right: 8,
                  width: 24,
                  height: 30,
                  position: "absolute",
                  borderTopLeftRadius: 0,
                  borderTopRightRadius: 0,
                  borderBottomLeftRadius: 4,
                  borderBottomRightRadius: 4,
                  alignItems: "center",
                  shadowColor: Colors.black,
                  shadowOffset: {
                    width: 0,
                    height: 2,
                  },
                  shadowOpacity: 0.25,
                  shadowRadius: 3.84,
                  elevation: 5,
                  justifyContent: "center",
                }}
              >
                <GIcon.Speaker
                  style={{
                    fontSize: 18,
                    color: Colors.white,
                  }}
                />
              </View>
            ) : null}

            {false ? <BadgeView Icon={GIcon.Cart} text="12 sold" style={{ marginTop: 4 }} /> : null}
            {false ? <BadgeView Icon={GIcon.Cash} text="$36" style={{ marginTop: 4 }} /> : null}
            {isPending ? (
              <BadgeView Icon={GIcon.TimerSand} text="pending" style={{ marginTop: 4 }} />
            ) : null}
            {showAdjustment ? (
              <BadgeView Icon={GIcon.Info} text="requires attention" style={{ marginTop: 4 }} />
            ) : null}
          </View>
        ) : null}

        <View style={{ bottom: 0, padding: 10, position: "absolute", width: width || tileWidth }}>
          <Text2
            numberOfLines={2}
            style={{
              fontSize: 14,
              color: Colors.white,
              paddingBottom: 4,
              fontWeight: "bold",
              shadowOpacity: 0.3,
              shadowColor: isWeb ? Colors.transparent : Colors.black,
              shadowOffset: { width: 0, height: 0 },
            }}
          >
            {guide.name || "Draft"}
          </Text2>
          <UserAvatarView
            color={Colors.white}
            uppercase
            verified
            url={avatarUrl}
            name={user.username}
            onPress={() => null}
          />
        </View>
      </View>
    </TouchableOpacity>
  );

  if (!onDelete) {
    return content;
  }

  return (
    <ClosableView
      onClose={() => onDelete(guide)}
      style={{ borderWidth: 0, marginTop: 8, marginBottom: 10 }}
    >
      {content}
    </ClosableView>
  );
});

export default GuideCellView;
