import { gql } from "graphql-request";
import { EVENT_MODEL } from "../models";

export const QUERY_EVENTS = gql`
  query events {
    events {
     ${EVENT_MODEL}
    }
  }
`;

export const EVENT_CREATE = gql`
  mutation eventCreate(
    $type: String
    $source: String
    $userId: String
    $guideId: String
    $message: String
    $version: String
    $platform: String
  ) {
    eventCreate(
      type: $type
      userId: $userId
      source: $source
      message: $message
      guideId: $guideId
      version: $version
      platform: $platform
    ) {
      ${EVENT_MODEL}
    }
  }
`;

export const EVENT_UPDATE = gql`
  mutation eventUpdate(
    $id: String
    $type: String
    $userId: String
    $guideId: String
    $message: String
    $platform: String
    $version: String
    $source: String
  ) {
    eventUpdate(
      id: $id
      type: $type
      userId: $userId
      guideId: $guideId
      message: $message
      platform: $platform
      version: $version
      source: $source
    ) {
      ${EVENT_MODEL}
    }
  }
`;

export const EVENT_DELETE = gql`
  mutation eventDelete(
    $id: String
  ) {
    eventDelete(
      id: $id
    ) {
      ${EVENT_MODEL}
    }
  }
`;
