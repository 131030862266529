import { useNavigation } from "@react-navigation/native";
import React from "react";
import { ActivityIndicator, Alert, View } from "react-native";
import { DragSortableView } from "react-native-drag-sort";
import { navigateToEditPlace } from "../../../app/navigation/Navigator";
import Cavy from "../../../app/test/Cavy";
import { screenWidth } from "../../../app/util/util";
import Colors from "../../../components/Colors";
import { Body2 } from "../../../components/typography";
import { Text2B } from "../../../components/ui";
import PlaceCellView, { PlaceGridView } from "./PlaceCellView";

const size = Math.floor((screenWidth - 56) / 3);

export default function EditGuidePlaces({
  style,
  guide,
  places,
  savingOrder,
  onSaveOrder,
  onRemovePlace,
}) {
  const isBusy = false;
  const navigation = useNavigation();
  const generateTestHook = Cavy.useCavy();

  function onEditPlace(place, index) {
    navigateToEditPlace(navigation, {
      index,
      guideId: guide.id,
      placeId: place?.id,
      location: guide.location,
    });
  }

  return (
    <View {...{ style }}>
      <Text2B style={{ color: Colors.darkerGray, marginBottom: 4 }}>Places</Text2B>

      {savingOrder ? (
        <View
          style={{
            paddingTop: 16,
            borderRadius: 10,
            marginBottom: 16,
            paddingBottom: 32,
            alignItems: "center",
            backgroundColor: Colors.white,
            height: (places.length / 3) * (size + 16) + size,
          }}
        >
          <ActivityIndicator
            style={{
              width: 48,
              height: 48,
              borderRadius: 10,
              backgroundColor: Colors.white,
            }}
          />
          <Body2>Saving...</Body2>
        </View>
      ) : (
        <PlaceGridView>
          <DragSortableView
            sortable={!isBusy}
            dataSource={[...places]}
            parentWidth={screenWidth}
            childrenWidth={size + 12}
            childrenHeight={size + 40}
            keyExtractor={(item, index) => index}
            onDataChange={(indexes) => {
              const items = indexes.filter((v) => v).map(({ id }) => id);
              onSaveOrder(items);
            }}
            onClickItem={(data, place, index) => {
              if (isBusy) {
                Alert.alert("Please wait...");
              } else if (!places[index]) {
                let emptyIndex = 0;
                for (let i = 0; i < places.length; i++) {
                  if (!places[i]) {
                    emptyIndex = i;
                    break;
                  }
                }
                onEditPlace(place, emptyIndex);
              } else {
                onEditPlace(place, index);
              }
            }}
            renderItem={(place, index) => {
              return (
                <PlaceCellView
                  showName
                  onEditPlace
                  {...{ index, place }}
                  key={place?.id || index}
                  onClose={() => onRemovePlace(place)}
                  onPress={() => onEditPlace(place, index)}
                  ref={generateTestHook(`EditGuide.Place${index}`)}
                  style={{ marginTop: 5, marginRight: 8, marginBottom: 8 }}
                />
              );
            }}
          />
        </PlaceGridView>
      )}
    </View>
  );
}
