import React from "react";
import { Dimensions } from "react-native";
import { LineChart } from "react-native-chart-kit";
import Colors from "../../../components/Colors";

export default function LineChartView({ data }) {
  const max = Math.max(4, ...data.values);

  return (
    <LineChart
      data={{
        labels: data.labels,
        datasets: [
          {
            data: data.values,
          },
          {
            data: [max],
          },
        ],
      }}
      width={Dimensions.get("window").width - 32} // from react-native
      height={220}
      yAxisLabel=""
      yAxisSuffix=""
      withVerticalLines={false}
      withHorizontalLines
      fromZero
      yAxisInterval={1} // optional, defaults to 1
      chartConfig={{
        strokeWidth: 1,
        stroke: Colors.white,
        propsForBackgroundLines: {
          strokeDasharray: "",
          strokeWidth: 1,
          stroke: Colors.lynxWhite,
        },
        backgroundColor: Colors.white,
        backgroundGradientFrom: Colors.white,
        backgroundGradientTo: Colors.white,
        decimalPlaces: 0, // optional, defaults to 2dp

        fillShadowGradient: `rgba(176, 255, 0, 1)`,
        fillShadowGradientToOpacity: 1,
        fillShadowGradientFromOpacity: 1,
        color: () => `rgba(176, 255, 0, 1)`,
        labelColor: (opacity = 1) => `rgba(0, 0, 0, ${opacity})`,
        style: {
          borderRadius: 16,
        },
      }}
      bezier={false}
      withDots={false}
      style={{
        borderWidth: 0,
        marginVertical: 8,
        marginHorizontal: 8,
      }}
    />
  );
}
