import { observer } from "mobx-react";
import React from "react";
import { TouchableOpacity, View } from "react-native";
import { useQuery } from "react-query";
import Api from "../../../app/api/Api";
import Queries from "../../../app/constants/Queries";
import store from "../../../app/store/Store";
import Cavy from "../../../app/test/Cavy";
import Dates from "../../../app/util/Dates";
import { useActionSheet } from "../../../components/actionsheet/context";
import Colors from "../../../components/Colors";
import { Text2L, Text2SB } from "../../../components/ui";
import GIcon from "../../../components/ui/GIcon";
import BarChartView from "./BarChartView";
import LineChartView from "./LineChartView";
import StatView from "./StatView";

const MONTHS3 = {
  1: "Jan",
  2: "Feb",
  3: "Mar",
  4: "Apr",
  5: "May",
  6: "Jun",
  7: "Jul",
  8: "Aug",
  9: "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dec",
};

const options = ["This month", "Last month", "Cancel"];

const TouchTime = Cavy.wrap(TouchableOpacity);

function numberWithCommas(x, digits = 2) {
  return x
    .toFixed(digits)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function money(amount) {
  return `$${numberWithCommas(amount)}`;
}

function StatRowView({ label, text }) {
  return (
    <View
      style={{
        marginTop: 12,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Text2L>{label}</Text2L>
      <Text2SB style={{ fontSize: 22 }}>{text}</Text2SB>
    </View>
  );
}

export default observer(function StatTabView() {
  const generateTestHook = Cavy.useCavy();

  const userId = store.getUserId();

  const [MONTHS] = React.useState([Dates.lastDay(), Dates.lastDay(Dates.previousMonth())]);

  const { showActionSheetWithOptions } = useActionSheet();
  const [optionIndex, setOptionIndex] = React.useState(0);
  const [paidOut, setPaidOut] = React.useState(0);
  const [nextPayout, setNextPayout] = React.useState(0);
  const [earnings, setEarnings] = React.useState([]);
  const [, setSoldGuides] = React.useState(0);
  const [lineChartData, setLineChartData] = React.useState(null);
  const [monthSales, setMonthSales] = React.useState(0);

  const { data } = useQuery([Queries.STATS, userId], () => Api.payment.paymentStats({ userId }));

  const selectedMonth = MONTHS[optionIndex];

  React.useEffect(() => {
    const map = {};
    if (data) {
      let paid = 0;
      let next = 0;
      setSoldGuides(data.count);
      data.payouts.forEach((payout) => {
        if (payout.status === "paid") {
          paid += (payout.webAmount || 0) + (payout.appleAmount || 0) + (payout.googleAmount || 0);
        } else {
          next += (payout.webAmount || 0) + (payout.appleAmount || 0) + (payout.googleAmount || 0);
        }
      });

      setEarnings(
        data.payouts.map((payout) => ({
          month: MONTHS3[payout.month],
          amount: (payout.webAmount || 0) + (payout.appleAmount || 0) + (payout.googleAmount || 0),
          paid: payout.status === "paid",
        }))
      );

      data.sales.forEach(({ date, count }) => {
        map[date] = count;
      });

      setPaidOut(paid);
      setNextPayout(next);
    }

    const endDate = selectedMonth.toJSON().split("T")[0];
    const [y, m, d] = endDate.split("-");
    const lastDay = parseInt(d, 10);

    const chartData = { labels: [], values: [] };

    let sum = 0;
    for (let i = 1; i <= lastDay; i++) {
      const day = i < 10 ? `0${i}` : i;
      const date = `${y}-${m}-${day}`;

      if (i === 1 || i === 15 || i === lastDay) {
        chartData.labels.push(i);
      } else {
        chartData.labels.push("");
      }

      chartData.values.push(map[date] || 0);
      sum += map[date] || 0;
    }

    setMonthSales(sum);
    setLineChartData(chartData);

    // console.log({ start, end });
  }, [data, selectedMonth]);

  function onSelectTime() {
    showActionSheetWithOptions(
      {
        options,
        cancelButtonIndex: 3,
      },
      (index) => {
        if (index !== 2) {
          setOptionIndex(index);
        }
      }
    );
  }

  const { label, startEnd } = Dates.getMonthDetails(selectedMonth || new Date());

  return (
    <View>
      <View>
        <StatView label="Total earnings" text={money(paidOut + nextPayout)} />
        <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
          <StatView
            textSize={22}
            label="Paid out"
            text={money(paidOut)}
            dotColor={Colors.primary}
          />
          <StatView
            dotColor={Colors.pinkySwear}
            label="Next payout"
            text={money(nextPayout)}
            textSize={22}
          />
        </View>
      </View>

      <BarChartView {...{ earnings }} />

      <View
        style={{
          marginTop: 36,
          flexDirection: "row",
          alignItems: "center",
          marginHorizontal: 24,
          justifyContent: "space-between",
        }}
      >
        <TouchTime onPress={onSelectTime} ref={generateTestHook(`Insights.Month${optionIndex}`)}>
          <View style={{ alignItems: "center", flexDirection: "row" }}>
            <GIcon.Tune style={{ fontSize: 20, marginRight: 4 }} />
            <Text2SB style={{ fontSize: 24 }}>{label}</Text2SB>
          </View>
        </TouchTime>

        <Text2SB style={{ fontSize: 14 }}>{startEnd}</Text2SB>
      </View>

      <View style={{ marginHorizontal: 24 }}>
        <StatRowView label="Sold guides" text={numberWithCommas(monthSales, 0)} />
        {false && <StatRowView label="Guide previews" text={numberWithCommas(34029, 0)} />}
      </View>

      {lineChartData ? <LineChartView data={lineChartData} /> : null}
    </View>
  );
});
