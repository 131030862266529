import { useNavigation } from "@react-navigation/native";
import { LinearGradient } from "expo-linear-gradient";
import React from "react";
import { Image, View } from "react-native";
import { GuideLocationView } from ".";
import Api from "../../../app/api/Api";
import Errors from "../../../app/util/Errors";
import { screenWidth } from "../../../app/util/util";
import Colors from "../../../components/Colors";
import MediaPicker from "../../../components/MediaPicker";
import usePermissions from "../../place/ui/usePermissions";
import ChangeCoverButton from "./ChangeCoverButton";
import EditGuideName from "./EditGuideName";

export default function EditGuideHeader({ guide, onEditLocation, askForPermissions }) {
  const hasCover = !!guide.media;
  const navigation = useNavigation();
  const [loadingCover, setLoadingCover] = React.useState(false);

  const { camera, photos } = usePermissions();
  const hasPermissions = camera.enabled && photos.enabled;

  const showImagePicker = MediaPicker.useImagePicker(
    { ratio: 0.75, lockAspectRatio: true, canSelectFromGoogle: true },
    navigation
  );

  const onUpdateGuide = React.useCallback(
    (fields) => {
      return (
        Api.guide
          .guideUpdate(guide.id, { ...fields })
          // .then(() => showMessage({ message: "Changes saved!", type: "success" }))
          .catch(Errors.showErrorMessage)
      );
    },
    [guide.id]
  );

  async function onChangeGuideCover() {
    if (!hasPermissions) {
      return askForPermissions();
    }
    try {
      setLoadingCover(true);
      const newMedia = await showImagePicker(guide.gPlaceId);
      onUpdateGuide({ media: newMedia.id });
    } catch (error) {
      console.log(error);
    } finally {
      setLoadingCover(false);
    }
  }

  return (
    <View style={{ width: screenWidth, height: screenWidth / 0.75 }}>
      <LinearGradient
        colors={[Colors.transparent, "rgba(0,0,0,0.1)"]}
        style={{ position: "absolute", right: 0, left: 0, bottom: 0, height: screenWidth / 0.75 }}
      />

      {hasCover ? (
        <Image
          resizeMode="cover"
          source={{ uri: guide.media.url.replace("300x300", "600x600") }}
          style={{ height: screenWidth / 0.75, width: screenWidth, position: "absolute" }}
        />
      ) : null}

      <View
        style={{
          width: screenWidth,
          position: "absolute",
          alignItems: "center",
          justifyContent: "center",
          height: screenWidth / 0.75,
        }}
      >
        <ChangeCoverButton {...{ loading: loadingCover, onPress: onChangeGuideCover, hasCover }} />
      </View>

      <View
        style={{
          bottom: 0,
          borderWidth: 0,
          paddingBottom: 16,
          position: "absolute",
          marginHorizontal: 16,
          width: screenWidth - 32,
          borderColor: Colors.black,
        }}
      >
        <EditGuideName {...{ guide }} />

        <GuideLocationView
          address={guide.address}
          onEdit={onEditLocation}
          style={{ marginTop: 8, marginBottom: 8, borderWidth: 0 }}
        />
      </View>
    </View>
  );
}
