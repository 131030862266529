import React from "react";
import {
  Pressable,
  TextInput as RNTextInput,
  TouchableOpacity as RNTouchableOpacity,
  View,
} from "react-native";
import Cavy from "../../app/test/Cavy";
import { isIOS } from "../../app/util/util";
import SavedView from "../../features/guide/ui/SavedView";
import Colors from "../Colors";
import { Border, Radius } from "../Theme";
import { Body2 } from "../typography";
import { Header, Text2 } from "../ui";
import GIcon from "./GIcon";

const Input = Cavy.wrap(RNTextInput);
const TouchableOpacity = Cavy.wrap(RNTouchableOpacity);

const TextInput = Cavy.wrap(({ style, ...props }) => {
  return (
    <Input
      placeholderTextColor={Colors.darkGray}
      {...props}
      style={[{ flex: 1, paddingHorizontal: 8 }, style]}
    />
  );
});

export const SearchInputView = ({ style, onClear, onChangeText, ...props }) => {
  const generateTestHook = Cavy.useCavy();
  const [text, setText] = React.useState("");
  return (
    <View
      style={{
        height: 48,
        marginTop: 12,
        alignItems: "center",
        flexDirection: "row",
        paddingHorizontal: 16,
      }}
    >
      <TextInput
        autoFocus
        {...props}
        value={text}
        ref={generateTestHook("Search.Input")}
        onChangeText={(value) => {
          setText(value);
          onChangeText(value);
        }}
        style={{
          height: 38,
          fontSize: 16,
          borderRadius: 10,
          paddingHorizontal: 10,
          fontFamily: "OutfitMedium",
          backgroundColor: Colors.lightGray,
        }}
      />
      {text ? (
        <View style={{ position: "absolute", right: 10 }}>
          <Header.Action
            color={Colors.basaltGray}
            Icon={GIcon.CloseCircle}
            onPress={() => {
              setText("");
              if (onClear) {
                onClear();
              }
            }}
            ref={generateTestHook("Search.Clear")}
          />
        </View>
      ) : null}
    </View>
  );
};

export function InputLabel({ style, children }) {
  return <Text2 style={[{ fontSize: 15, color: Colors.tinSoldier }, style]}>{children}</Text2>;
}

export const InputView = ({
  Icon,
  error,
  label,
  testId,
  onPress,
  marginTop,
  maxLength,
  hideMaxLimit,
  multiline,
  inputStyle,
  maxLengthColor,
  maxLengthAlignLeft,
  onChangeText,
  letterSpacing,
  ...props
}) => {
  const generateTestHook = Cavy.useCavy();
  const [value, setValue] = React.useState(props.value || props.defaultValue || "");

  function onChange(text) {
    onChangeText(text);
    setValue(text);
  }

  const content = (
    <View>
      <View
        style={[
          { marginTop: marginTop || 24 },
          multiline ? { height: label ? 72 : 56 } : { height: label ? 72 : 56 },
        ]}
      >
        {label ? <InputLabel>{label}</InputLabel> : null}

        {Icon ? (
          <View
            style={{
              flexDirection: "row",
              borderWidth: 1,
              borderRadius: 10,
              borderColor: Border.dashedColor,
              backgroundColor: Colors.white,
              padding: 16,
            }}
          >
            <Icon />
            <TextInput
              {...props}
              ref={!onPress ? generateTestHook(testId) : null}
              {...{ multiline, maxLength, onChangeText: onChange }}
              style={[
                {
                  marginTop: label ? 2 : 0,
                  paddingLeft: 16,
                },
                letterSpacing ? { letterSpacing } : {},
                inputStyle,
              ]}
            />
          </View>
        ) : (
          <TextInput
            {...props}
            ref={!onPress ? generateTestHook(testId) : null}
            {...{ multiline, maxLength, onChangeText: onChange }}
            style={[
              {
                marginTop: label ? 2 : 0,
                borderWidth: 1,
                borderRadius: 10,
                borderColor: Border.dashedColor,
              },
              letterSpacing ? { letterSpacing } : {},
              inputStyle,
            ]}
          />
        )}

        {onPress ? (
          <View
            style={{
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              position: "absolute",
            }}
          />
        ) : null}
      </View>
      {!hideMaxLimit && maxLength ? (
        <Text2
          style={{
            fontSize: 10,
            marginTop: 4,
            textAlign: maxLengthAlignLeft ? "left" : "right",
            color: maxLengthColor || Colors.darkerGray,
            marginRight: 4,
          }}
        >
          {maxLength - (value || "").length} Characters left
        </Text2>
      ) : null}
      {error ? <Body2 style={{ color: Colors.primary, marginTop: 4 }}>{error}</Body2> : null}
    </View>
  );

  if (onPress) {
    return (
      <TouchableOpacity ref={generateTestHook(testId)} activeOpacity={0.9} {...{ onPress }}>
        {content}
      </TouchableOpacity>
    );
  }

  return content;
};

export function TextEditView({
  text,
  style,
  saved,
  testId,
  saving,
  setText,
  maxLenght,
  placeholder,
  hideMaxLimit,
  ...props
}) {
  const generateTestHook = Cavy.useCavy();

  const [showLenght, setShowLenght] = React.useState(false);
  return (
    <View
      style={[
        {
          minHeight: 52,
          borderWidth: 1,
          alignItems: "center",
          flexDirection: "row",
          borderRadius: Radius.M,
          borderStyle: !text ? "dashed" : "solid",
          borderColor: !text ? Border.dashedColor : Border.solidColor,
        },
        style,
      ]}
    >
      {!text ? <GIcon.Edit style={{ color: Colors.darkGray, marginLeft: 16 }} /> : null}

      <TextInput
        multiline
        {...props}
        defaultValue={text}
        maxLength={maxLenght}
        placeholder={placeholder}
        placeholderTextColor={Colors.rainyGray}
        onFocus={() => setShowLenght(true)}
        onEndEditing={() => setShowLenght(false)}
        style={{
          flex: 1,
          fontSize: 18,
          marginHorizontal: 12,
          color: Colors.charcoal,
          fontFamily: "OutfitMedium",
          paddingBottom: isIOS ? 4 : 0,
          textAlign: !text ? "center" : "left",
        }}
        onChangeText={(value) => setText(value?.trim())}
        ref={generateTestHook(testId)}
      />

      {!text ? <View style={{ width: 40 }} /> : null}

      {text && showLenght ? (
        <Text2
          style={{
            right: 4,
            bottom: 4,
            fontSize: 10,
            position: "absolute",
            color: Colors.darkerGray,
          }}
        >
          {`${(text || "").length}/${maxLenght}`}
        </Text2>
      ) : null}

      <SavedView {...{ saving, saved }} />
    </View>
  );
}

function DashView() {
  return (
    <View
      style={{
        width: 30,
        height: 1,
        backgroundColor: Colors.gray,
        marginHorizontal: 5,
      }}
    />
  );
}

export const PinInputView = (props) => (
  <View style={{ borderWidth: 0, alignItems: "center" }}>
    <InputView
      {...props}
      maxLength={6}
      hideMaxLimit
      letterSpacing={30}
      autoCapitalize="none"
      keyboardType="number-pad"
      textContentType="oneTimeCode"
      inputStyle={{ borderWidth: 0, marginLeft: 32, width: 250 }}
    />
    <View style={{ flexDirection: "row" }}>
      <DashView />
      <DashView />
      <DashView />
      <DashView />
      <DashView />
      <DashView />
    </View>
  </View>
);

export const EmailInputView = Cavy.wrap((props) => (
  <InputView
    {...props}
    autoFocus
    autoCapitalize="none"
    keyboardType="email-address"
    textContentType="emailAddress"
  />
));

export const PhoneInputView = Cavy.wrap(({ country, onCountry, ...props }) => (
  <InputView
    Icon={() => (
      <Pressable onPress={onCountry}>
        <View style={{ flexDirection: "row", borderRightWidth: 1, borderRightColor: "#ccc" }}>
          <Text2 style={{ fontSize: 16, paddingHorizontal: 4 }}>{country?.split(" ")[0]}</Text2>
          <Text2
            style={{
              fontSize: 16,
              paddingHorizontal: 4,
              color: Colors.darkGray,
            }}
          >
            {country?.split(" ")[1]}
          </Text2>
          <GIcon.MenuDown />
        </View>
      </Pressable>
    )}
    {...props}
    autoFocus
    autoCapitalize="none"
    keyboardType="number-pad"
    inputStyle={{ fontSize: 16 }}
    textContentType="numeric"
  />
));

export const PasswordInputView = Cavy.wrap((props) => (
  <InputView {...props} autoCapitalize="none" secureTextEntry />
));
