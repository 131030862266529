import { gql } from "graphql-request";
import { USER_MODEL } from "../models";

export const ME = gql`
query me{
  me{
   ${USER_MODEL}
  }
}
`;

export const USER_GET = gql`
query getUser($id: String) {
  user(id: $id) {
   ${USER_MODEL}
  }
}
`;

export const QUERY_SEARCH_USERS = gql`
  query searchUsers($keywords: String, $location: LocationInput, $skip: Int, $limit: Int) {
    searchUsers(keywords: $keywords, location: $location, skip: $skip, limit: $limit) {
      ${USER_MODEL}
    }
  }
`;

export const QUERY_POPULAR_USERS = gql`
  query popularUsers( $skip: Int, $limit: Int) {
    popularUsers(skip: $skip, limit: $limit) {
      ${USER_MODEL}
    }
  }
`;

export const UPDATE_USER_MUTATION = gql`
  mutation userUpdate(
    $id: String
    $bio: String
    $name: String
    $media: String
    $token: String
    $country: String
    $username: String
    $password: String
    $expoPushToken: String
  ) {
    userUpdate(
      user: {
        id: $id
        bio: $bio
        name: $name
        media: $media
        token: $token
        country: $country
        username: $username
        password: $password
        expoPushToken: $expoPushToken
      }
    ) {
      ${USER_MODEL}
    }
  }
`;

export const UPDATE_ROLE = gql`
  mutation updateRole($emails: String, $role: String) {
    updateRole(emails: $emails, role: $role)
  }
`;

export const USER_FOLLOW = gql`
  mutation userFollow($userId: String) {
    userFollow(userId: $userId)
  }
`;

export const USER_UNFOLLOW = gql`
  mutation userUnfollow($userId: String) {
    userUnfollow(userId: $userId)
  }
`;

export const UPGRADE_TO_CREATOR_MUTATION = gql`
  mutation upgradeToCreator( $code: String ) {
    upgradeToCreator( code: $code ) {
      ${USER_MODEL}
    }
  }
`;
