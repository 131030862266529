import { observer } from "mobx-react";
import React from "react";
import { FlatList } from "react-native";
import { useQuery } from "react-query";
import Api from "../../app/api/Api";
import Queries from "../../app/constants/Queries";
import { navigateToGuide } from "../../app/navigation/Navigator";
import Colors from "../../components/Colors";
import EventView from "../../components/EventView";
import { Container, Header, Text2 } from "../../components/ui";
import LoadingView from "../../components/ui/LoadingView";

export default observer(({ navigation }) => {
  const { isLoading, data } = useQuery(Queries.REPORTED_GUIDES, () =>
    Api.report.reportedGuides(0, 50)
  );

  if (isLoading) {
    return <LoadingView />;
  }

  const renderEmptyList = () => (
    <Text2 style={{ textAlign: "center", color: Colors.darkGray }}>No reports to display!</Text2>
  );

  const renderRow = ({ guide, message, reportedBy, createdAt }) => {
    console.log({ createdAt });
    return (
      <EventView
        {...{
          avatarUrl: reportedBy.media?.url,
          part0: reportedBy.username,
          part1: message,
          part2: guide.name,
          eventDate: createdAt,
          guideUrl: guide.media.url,
          onPress: () => navigateToGuide(navigation, { guideId: guide.id }),
        }}
      />
    );
  };

  const reports = (data || []).filter((report) => report.guide);

  return (
    <Container>
      <Header onBack={() => navigation.goBack()} title="Reported Guides" />
      <FlatList
        data={reports}
        refreshing={isLoading}
        ListEmptyComponent={renderEmptyList()}
        showsVerticalScrollIndicator={false}
        renderItem={({ item }) => renderRow(item)}
      />
    </Container>
  );
});
