import React from "react";
import { FlatList, View } from "react-native";
import Cavy from "../../app/test/Cavy";
import { screenWidth } from "../../app/util/util";
import GuideCellView from "./GuideCellView";

const tileWidth = (screenWidth - 48) * 0.5;

export default function GuideGridView({
  guides,
  onGuide,
  showBadges,
  ListFooterComponent,
  ...props
}) {
  const generateTestHook = Cavy.useCavy();

  return (
    <FlatList
      {...props}
      data={guides}
      numColumns={2}
      showsVerticalScrollIndicator={false}
      ListFooterComponent={ListFooterComponent || <View style={{ height: 48 }} />}
      renderItem={({ item: guide, index }) => (
        <GuideCellView
          key={guide.id}
          width={tileWidth}
          {...{ guide, showBadges }}
          onPress={() => onGuide(guide)}
          ref={generateTestHook(`Home.Guide${index}`)}
        />
      )}
    />
  );
}
