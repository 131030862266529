export default {
  EVENTS: "events",
  THEMES: "themes",
  USER_GET: "userGet",
  QUESTIONS: "questions",
  SEARCH_USERS: "searchUSers",
  POPULAR_USERS: "popularUsers",

  // guide
  GUIDE: "guide",
  // place
  PLACE: "place",
  // guides
  USER_GUIDES: "userGuides",
  LIKED_GUIDES: "likedGuides",
  PURCHASED_GUIDES: "purchasedGuides",
  SEARCH_GUIDES: "searchGuides",
  POPULAR_GUIDES: "popularGuides",
  PUBLISHED_GUIDES: "publishedGuides",
  PENDING_GUIDES: "pendingGuides",
  // places
  LIKED_PLACES: "likedPlaces",
  // stats
  STATS: "stats",
  // payment
  PAYOUTS: "payouts",
  // message
  MESSAGES: "messages",
  // report
  REPORTED_GUIDES: "reportedGuides",
  // application
  APPLICATIONS: "applications",
};
