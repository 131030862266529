import React from "react";
import { Image, Text, View } from "react-native";
import Colors from "../Colors";
import GIcon from "./GIcon";

const PLACEHOLDER = "http://s3.amazonaws.com/gajdavatar/dummyAvatar.jpg";

const UserAvatarView = ({ url, name, color, style, onPress }) => (
  <View
    style={[
      {
        alignItems: "center",
        flexDirection: "row",
      },
      style,
      {},
    ]}
    onPress={onPress}
  >
    <Image
      style={{
        width: 26,
        height: 26,
        marginRight: 8,
        borderRadius: 13,
        backgroundColor: Colors.lynxWhite,
      }}
      source={{ uri: url || PLACEHOLDER }}
    />
    <Text
      style={{
        fontSize: 12,
        fontWeight: "bold",
        color: color || Colors.black,
        fontFamily: "PulpDisplayMedium",
      }}
    >
      {name}
    </Text>

    {false && (
      <View
        style={{
          marginLeft: 2,
          width: 20,
          height: 20,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <View
          style={{
            width: 12,
            height: 12,
            backgroundColor: Colors.black,
            borderRadius: 18,
          }}
        />
        <GIcon.Verified style={{ position: "absolute", color: Colors.secondary, fontSize: 16 }} />
      </View>
    )}
  </View>
);

export default UserAvatarView;
