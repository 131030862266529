import * as React from "react";
import { Linking, View } from "react-native";
import { isAndroid, isIOS } from "../app/util/util";
import AlertDialog from "./AlertDialog";

export default function StoreReviewView({}) {
  const [showDialog, setShowDialog] = React.useState(true);

  async function storeReview() {
    const appleId = 1595934848;
    const androidPackageName = "com.guidepal.mobile";

    if (isIOS) {
      Linking.openURL(
        `itms-apps://itunes.apple.com/app/viewContentsUserReviews/id${appleId}?action=write-review`
      );
    }
    if (isAndroid) {
      Linking.openURL(`market://details?id=${androidPackageName}&showAllReviews=true`);
    }
  }

  return (
    <View style={{ flex: 1 }}>
      <AlertDialog
        title={`Rate us on ${isAndroid ? "Play" : isIOS ? "App" : null} Store`}
        visible={showDialog}
        options={[
          { text: "Not Now", onPress: () => setShowDialog(false) },
          {
            text: "Sure",
            onPress: () => {
              storeReview();
              setShowDialog(false);
            },
          },
        ]}
      />
    </View>
  );
}
