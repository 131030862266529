import { gql } from "graphql-request";
import { MEDIA_MODEL } from "../models";

export const MEDIA_GET = gql`
  query media($id: String) {
    media(id: $id) {
     ${MEDIA_MODEL}
    }
  }
`;

export const MEDIA_UPDATE = gql`
  mutation mediaUpdate(
    $id: String
    $url: String
    $text: String
    $type: String
    $ratio: Float
    $audio: String
    $width: Int
    $height: Int
    $thumbnailUrl: String
    $dominantColor: String
  ) {
    mediaUpdate(
      id: $id
      url: $url
      text: $text
      type :$type
      ratio :$ratio
      audio :$audio
      width :$width
      height :$height
      thumbnailUrl :$thumbnailUrl
      dominantColor :$dominantColor
    ) {
      ${MEDIA_MODEL}
    }
  }
`;

export const MEDIA_CREATE = gql`
  mutation mediaCreate(
    $url: String
    $type: String
    $text: String
    $ratio: Float
    $width: Int
    $height: Int
    $thumbnailUrl: String
    $audio: String
  ) {
    mediaCreate(
      media: {
        url: $url
        type: $type
        text: $text
        ratio: $ratio
        width: $width
        height: $height
        thumbnailUrl: $thumbnailUrl
        audio: $audio
      }
    ) {
      ${MEDIA_MODEL}
    }
  }
`;
