/**
 * Learn more about createBottomTabNavigator:
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */

import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { observer } from "mobx-react";
import * as React from "react";
import { Image, Pressable, TouchableOpacity } from "react-native";
import Colors from "../../components/Colors";
import GIcon from "../../components/ui/GIcon";
import store from "../store/Store";
import Cavy from "../test/Cavy";
import {
  HomeNavigator,
  InsightNavigator,
  GTabNavigator,
  NotificationNavigator,
  ProfileNavigator,
} from "./StackNavigators";

import { isWeb } from "../util/util";

const Touchable = Cavy.wrap(Pressable);

function TabBarButton({ label, children, ...props }) {
  const generateTestHook = Cavy.useCavy();

  return (
    <Touchable ref={generateTestHook(label)} {...props}>
      {children}
    </Touchable>
  );
}

const BottomTab = createBottomTabNavigator();

function BottomTabNavigator() {
  return (
    <BottomTab.Navigator
      initialRouteName="hometab"
      tabBarOptions={{ activeTintColor: Colors.primary }}
    >
      <BottomTab.Screen
        name="hometab"
        component={HomeNavigator}
        options={{
          tabBarLabel: "",
          tabBarButton: (props) => <TabBarButton label="Tab.Home" {...props} />,
          tabBarIcon: ({ color }) => <GIcon.Home {...{ color }} style={{ fontSize: 24 }} />,
        }}
      />

      {!isWeb && store.isCreator() && store.isLoggedIn() ? (
        <BottomTab.Screen
          name="insight"
          component={InsightNavigator}
          options={{
            tabBarLabel: "",
            tabBarButton: (props) => <TabBarButton label="Tab.Insight" {...props} />,
            tabBarIcon: ({ color }) => <GIcon.Currency {...{ color }} style={{ fontSize: 24 }} />,
          }}
        />
      ) : null}

      {!isWeb ? (
        <BottomTab.Screen
          name="gtab"
          component={GTabNavigator}
          options={{
            tabBarLabel: "",
            tabBarButton: (props) => <TabBarButton label="Tab.GTab" {...props} />,

            //TODO New version icons has this icon *book-plus-outline*
            tabBarIcon: ({ focused }) => {
              const inspireIcon = focused
                ? require("../../../assets/images/guidepal-color.png")
                : require("../../../assets/images/guidepal.png");
              const sourceIcon = focused
                ? require("../../../assets/images/book-active.png")
                : require("../../../assets/images/book.png");

              return (
                <Image
                  style={{
                    width: 21,
                    height: 21,
                    marginTop: 3,
                    marginBottom: 5,
                  }}
                  source={store.isLoggedIn() && store.isCreator() ? sourceIcon : inspireIcon}
                />
              );
            },
          }}
        />
      ) : null}

      {!isWeb && store.isLoggedIn() ? (
        <BottomTab.Screen
          name="notification"
          component={NotificationNavigator}
          options={{
            tabBarLabel: "",
            tabBarButton: (props) => <TabBarButton label="Tab.Notification" {...props} />,
            tabBarIcon: ({ color }) => (
              <GIcon.Notification {...{ color }} style={{ fontSize: 24 }} />
            ),
            tabBarBadge: store.notificationBagde ? "" : null,
            tabBarBadgeStyle: {
              backgroundColor: Colors.primaryDark,
              height: 12,
              minWidth: 12,
              borderRadius: 6,
            },
          }}
          listeners={() => ({
            tabPress: () => {
              store.setNotificationBadge(false);
            },
          })}
        />
      ) : null}

      <BottomTab.Screen
        name="profiletab"
        component={ProfileNavigator}
        options={{
          tabBarLabel: "",
          tabBarButton: (props) => <TabBarButton label="Tab.Profile" {...props} />,
          tabBarIcon: ({ color }) => <GIcon.Person {...{ color }} style={{ fontSize: 24 }} />,
        }}
      />
    </BottomTab.Navigator>
  );
}

export default observer(BottomTabNavigator);
