import application from "./application";
import auth from "./auth";
import event from "./event";
import guide from "./guide";
import media from "./media";
import message from "./message";
import payment from "./payment";
import place from "./place";
import question from "./question";
import report from "./report";
import theme from "./theme";
import user from "./user";

export default {
  auth,
  user,
  event,
  place,
  guide,
  media,
  theme,
  payment,
  message,
  report,
  question,
  application,
};
