import React from "react";
import StepsContainer, { StepView } from "../../../components/StepsContainer";

export default function EditPlaceStatusView({ place, ...props }) {
  // console.log("status: ", { place });

  return (
    <StepsContainer center {...props}>
      <StepView done={place.name}>Name</StepView>
      <StepView done={place.location}>Location</StepView>
      <StepView done={(place.medias || []).filter((media) => media.type === "image").length !== 0}>
        Image
      </StepView>
      <StepView
        done={
          ((place.medias || []).filter((media) => media.type === "image").length !== 0 &&
            (place.medias || []).filter((media) => media.text || media.audio).length !== 0) ||
          (place.medias || []).filter((media) => media.type === "video").length !== 0
        }
      >
        Audio/Text
      </StepView>
    </StepsContainer>
  );
}
